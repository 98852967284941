import { selectStyles } from '@ictlife/marketplace/components/business/CreateOffering';
import {
  getCategories,
  useCategories,
  useSuperCategories,
} from '@ictlife/marketplace/state/actions/categoriesAction';
import { useRef } from 'react';
import ReactSelect from 'react-select';
import AsyncReactSelect from 'react-select/async';
import addPostStyles from './styles/AddPost.module.scss';

const DealsCategory = ({
  selectedSuperCategory,
  setSelectedSuperCategory,
  selectedCategory,
  setSelectedCategory,
  post,
}) => {
  const { superCategories } = useSuperCategories({
    onSuccess: superCategories => {
      if (post?.super_category_ids?.length) {
        const superCategory = superCategories.find(
          sc => sc.id === post.super_category_ids[0]
        );
        setSelectedSuperCategory(superCategory);
      }
    },
  });

  const { data } = useCategories({
    super_category_slug: selectedSuperCategory?.slug,
    preventFetch: !selectedSuperCategory,
  });

  const categorySelectRef = useRef();

  return (
    <div className="w-full">
      <div
        className={addPostStyles.textfieldContainer}
        id="textfield-container"
      >
        <div className={addPostStyles.header}>
          <div className={addPostStyles.title}>Select Categories *</div>
        </div>
        <div>
          {superCategories && (
            <ReactSelect
              value={
                selectedSuperCategory
                  ? {
                      ...selectedSuperCategory,
                      label: selectedSuperCategory.name,
                      value: selectedSuperCategory.id,
                    }
                  : null
              }
              options={superCategories.map(sc => ({
                ...sc,
                label: sc.name,
                value: sc.slug,
              }))}
              styles={selectStyles}
              placeholder="Select a super category (required)"
              onChange={(superCategory, actionMeta) => {
                setSelectedCategory();
                if (actionMeta.action === 'select-option') {
                  setSelectedSuperCategory(superCategory);
                  categorySelectRef?.current?.focus();
                }
                if (actionMeta.action === 'clear') {
                  setSelectedSuperCategory(null);
                }
              }}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#ccc',
                  primary: '#ff8e22',
                },
              })}
            />
          )}
        </div>
        <div className="mt-4">
          {selectedSuperCategory && (
            <AsyncReactSelect
              value={selectedCategory || null}
              defaultOptions={data?.merchant_categories.map(category => ({
                value: category.id,
                label: category.name,
                slug: category.slug,
              }))}
              ref={categorySelectRef}
              openMenuOnFocus
              loadOptions={input =>
                getCategories({
                  super_category_slug: selectedSuperCategory.slug,
                  search: input,
                }).then(categories =>
                  categories.merchant_categories.map(category => ({
                    value: category.id,
                    label: category.name,
                    slug: category.slug,
                  }))
                )
              }
              isClearable
              styles={selectStyles}
              placeholder="Select a category"
              // isLoading={loadingSuperCategories}
              onChange={(category, actionMeta) => {
                if (actionMeta.action === 'select-option') {
                  setSelectedCategory(category);
                }
                if (actionMeta.action === 'clear') {
                  setSelectedCategory(null);
                }
              }}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#ccc',
                  primary: '#ff8e22',
                },
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default DealsCategory;
