import pluralize from './pluralize';

export function formatMinuteToReadableTime(minutes) {
  if (minutes < 60) return `${minutes} minutes`;

  if (minutes < 1440)
    return pluralize(Math.round(minutes / 60), 'hour', 'hours');

  if (minutes < 10080)
    return pluralize(Math.round(minutes / 1440), 'day', 'days');

  if (minutes < 43800)
    return pluralize(Math.round(minutes / 10080), 'week', 'weeks');

  if (minutes < 525600)
    return pluralize(Math.round(minutes / 43800), 'month', 'months');

  if (minutes >= 525600)
    return pluralize(Math.round(minutes / 525600), 'year', 'years');
}
