import { combineReducers } from 'redux';

import { CORE_REDUCERS } from '@ictlife/core/state/reducers';
import {
  appThemeReducer,
  merchantProfileReducer,
  merchantProfileSummaryReducer,
  searchMerchantReducer,
} from './merchantProfileReducer';
import qrCodeLoginReducer from './qrCodeLoginReducer';
import { userInfoReducer, businessListReducer } from './loginReducer';
import { uploadProfilePhotoReducer } from './profilePhotoReducer';
import {
  businessReviewsReducer,
  reviewsSummaryReducer,
  reviewTagsReducer,
} from './businessReviewsReducer';
import {
  verificationCodeReducer,
  registerUserReducer,
  listUserSessionsReducer,
  logoutReducer,
} from './authenticationReducer';
import { getBusinessKeywordsReducer } from './businessKeywordsReducer';
import { associatedNumbersReducer } from './associatedNumbersReducer';
import {
  businessPostsReducer,
  getLikesListReducer,
  getPostReducer,
} from './postsReducer';
import {
  aboutBusinessReducer,
  updateAboutBusinessReducer,
} from './aboutBusinessReducer';
import { getMerchantTagsReducer, searchTagsReducer } from './tagsReducer';
import { reportReasonsReducer, reportReducer } from './flagsReducer';
import { notificationsReducer } from './notificationsReducer';
import {
  chatListReducer,
  userActivityReducer,
} from './customerActivityReducer';

export const MERCHANT_APP_REDUCERS = {
  merchantProfileState: merchantProfileReducer,
  qrCodeLoginState: qrCodeLoginReducer,
  userInfoState: userInfoReducer,
  profilePhotoState: uploadProfilePhotoReducer,
  businessReviewsState: businessReviewsReducer,
  reviewsSummaryState: reviewsSummaryReducer,
  businessListState: businessListReducer,
  verificationCodeState: verificationCodeReducer,
  registerUserState: registerUserReducer,
  businessKeywordsState: getBusinessKeywordsReducer,
  associatedNumbersState: associatedNumbersReducer,
  businessPostsState: businessPostsReducer,
  aboutBusinessState: aboutBusinessReducer,
  updateAboutBusinessState: updateAboutBusinessReducer,
  specificPostState: getPostReducer,
  getMerchantTagsState: getMerchantTagsReducer,
  searchTagsState: searchTagsReducer,
  merchantProfileSummaryState: merchantProfileSummaryReducer,
  appThemeState: appThemeReducer,
  searchMerchantState: searchMerchantReducer,
  likesListState: getLikesListReducer,
  userSessionsState: listUserSessionsReducer,
  logoutState: logoutReducer,
  reportReasonsState: reportReasonsReducer,
  reportState: reportReducer,
  notificationsState: notificationsReducer,
  chatListState: chatListReducer,
  userActivityState: userActivityReducer,
  reviewTagsState: reviewTagsReducer,
};

const appReducer = combineReducers({
  ...MERCHANT_APP_REDUCERS,
  ...CORE_REDUCERS,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    const {
      userInfoState,
      businessListState,
      appThemeState,
      businessReviewsState,
      notificationsState,
    } = state;
    const persistedState = action.persistedState || {};
    state = {
      userInfoState,
      businessListState,
      appThemeState,
      businessReviewsState,
      notificationsState,
      ...persistedState,
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
