import React, { useState, useReducer, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import uniqBy from 'lodash.uniqby';
import SendIcon from '@mui/icons-material/Send';
import cn from 'classnames';
import {
  IconButton,
  CircularProgress,
  TextField,
  InputAdornment,
  Dialog,
} from '@mui/material';
import DateFormatter from '@ictlife/core/components/date';

import {
  createCommentReply,
  getPostCommentReplies,
  deleteCommentReply,
} from '../../state/actions/postCommentsAction';
import {
  getPostCommentsReducer,
  initialGetPostCommentsState,
  createPostCommentReducer,
  initialCreateCommentState,
  deletePostCommentReducer,
  initialDeletePostCommentState,
} from '../../state/reducers/postCommentsReducer';

import AppAvatar from '../../utils/AppAvatar';
import styles from './styles/PostComments.module.scss';

const PostCommentReplies = ({
  comment,
  setCommentRepliesCount,
  commentRepliesCount,
  notificationReply,
  setNotificationReply,
}) => {
  const userInfoState = useSelector(store => store.userInfoState);
  const {
    user: { id: user_id },
  } = userInfoState;
  const [commentReply, setCommentReply] = useState('');
  const [replies, setReplies] = useState(
    notificationReply?.original_post_comment_id === comment.id
      ? [notificationReply]
      : []
  );
  const [repliesInfo, setRepliesInfo] = useState({
    page: 1,
    per: 20,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({
    replyId: null,
    replyIndex: -1,
  });

  const [commentRepliesState, dispatchGetCommentReplies] = useReducer(
    getPostCommentsReducer,
    initialGetPostCommentsState
  );
  const [createCommentReplyState, dispatchCreateCommentReply] = useReducer(
    createPostCommentReducer,
    initialCreateCommentState
  );
  const [deleteReplyState, dispatchDeleteReply] = useReducer(
    deletePostCommentReducer,
    initialDeletePostCommentState
  );

  const { page, per } = repliesInfo;
  useEffect(() => {
    getPostCommentReplies(dispatchGetCommentReplies, comment.id, page, per);
  }, [comment, page, per]);

  const socketEventsState = useSelector(store => store.socketEventsState);

  const { socketReply } = socketEventsState;

  useEffect(() => {
    if (socketReply && socketReply.original_post_comment_id === comment.id) {
      setReplies(replies => [...replies, socketReply]);
    }
  }, [comment.id, socketReply]);

  useEffect(() => {
    if (notificationReply) {
      const commentReplyElements = document.querySelectorAll(
        `.comment-reply-${notificationReply.id}`
      );
      commentReplyElements.forEach(commentReplyElement => {
        setTimeout(() => {
          commentReplyElement?.classList.remove(styles.targetResource);
        }, 1600);
        commentReplyElement?.classList.add(styles.targetResource);
      });
    }
  }, [notificationReply]);

  if (commentRepliesState.isSuccess) {
    if (notificationReply) {
      setNotificationReply(null);
    }
    setReplies(replies =>
      uniqBy(
        [
          ...replies,
          ...commentRepliesState.comment_replies.merchant_post_comment_replies.reverse(),
        ],
        'id'
      )
    );
    dispatchGetCommentReplies({ type: 'DEFAULT' });
  }

  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      merchant: { id: business_id },
    },
    isBusinessOwner,
  } = merchantProfileState;

  const handleCreateReply = () => {
    const bodyData = {
      merchant_post_comment_id: comment.id,
      message: commentReply,
      user_id,
    };
    if (isBusinessOwner) {
      bodyData.merchant_id = business_id;
    }
    createCommentReply(dispatchCreateCommentReply, bodyData);
  };

  const deleteReply = () => {
    const { replyId, replyIndex } = deleteInfo;
    deleteCommentReply(dispatchDeleteReply, replyId, replyIndex);
  };

  const handleToggleDialog = () => {
    setOpenDialog(open => !open);
  };

  if (deleteReplyState.isSuccess) {
    const newReplies = replies;
    newReplies.splice(deleteReplyState.replyIndex, 1);
    setReplies([...newReplies]);
    setCommentRepliesCount(count => --count);
    handleToggleDialog();
    dispatchDeleteReply({ type: 'DEFAULT' });
  }

  if (createCommentReplyState.isSuccess) {
    setCommentReply('');
    setReplies(replies => [...replies, createCommentReplyState.comment_reply]);
    setCommentRepliesCount(count => ++count);
    dispatchCreateCommentReply({ type: 'DEFAULT' });
  }

  return (
    <Fragment>
      {replies.map((reply, replyIndex) => (
        <div
          key={reply.id}
          className={cn({
            [styles.commentCard]: true,
            [styles.merchantMessage]: reply.merchant_info,
            [`comment-reply-${reply.id}`]: true,
          })}
        >
          <div className={cn(styles.commentHolder, styles.commentReply)}>
            <div className={styles.commentHeader}>
              <div className={styles.left}>
                <div className={styles.commentAvatar}>
                  <AppAvatar
                    src={
                      reply.merchant_info?.profile_photo?.url ||
                      reply.user.profile_photo?.thumbnail_url
                    }
                    alt={
                      reply.merchant_info?.merchant.business_name ||
                      `${reply.user.first_name} ${reply.user.last_name}`
                    }
                  />
                </div>
                <div className={styles.commentIdentity}>
                  {reply.merchant_info?.merchant.business_name ||
                    `${reply.user.first_name} ${reply.user.last_name} ${
                      user_id === reply.user.id ? '(You)' : ''
                    }`}
                </div>
              </div>

              <div className={styles.commentTime}>
                <DateFormatter date={reply.created_at} />
              </div>
            </div>
            <div className={styles.commentBody}>{reply.message}</div>
            <div className={cn(styles.commentFooter, styles.commentReplies)}>
              {user_id === reply.user.id && (
                <div className={styles.iconButton}>
                  {deleteReplyState.isLoading &&
                  deleteReplyState.replyIndex === replyIndex ? (
                    <CircularProgress size={15} />
                  ) : (
                    <div
                      onClick={() => {
                        setDeleteInfo({
                          replyId: reply.id,
                          replyIndex,
                        });
                        handleToggleDialog();
                      }}
                      className={styles.actionIcon}
                    >
                      <span className="material-icons">&#xe872;</span>
                      <span>Delete</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      {commentRepliesState.isLoading && (
        <div className={styles.repliesLoader}>
          <CircularProgress size={15} />
        </div>
      )}
      {!commentRepliesState.isLoading && replies.length < commentRepliesCount && (
        <div className={styles.repliesLoader}>
          <span
            className={styles.loadMore}
            onClick={() =>
              setRepliesInfo(info => ({ ...info, page: ++info.page }))
            }
          >
            load more replies...
          </span>
        </div>
      )}
      <div
        className="reply-holder override"
        id={`field-comment-id-${comment.id}`}
      >
        <TextField
          value={commentReply}
          onChange={e => setCommentReply(e.target.value)}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              e.preventDefault();
              !createCommentReplyState.isLoading &&
                commentReply !== '' &&
                handleCreateReply();
            }
          }}
          variant="outlined"
          fullWidth
          margin="dense"
          className={styles.commentReplyField}
          placeholder="reply to comment"
          multiline
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={handleCreateReply}
                  disabled={
                    commentReply === '' || createCommentReplyState.isLoading
                  }
                >
                  {createCommentReplyState.isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <SendIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 250,
            id: `input-comment-id-${comment.id}`,
          }}
        />
      </div>

      <Dialog open={openDialog} onClose={handleToggleDialog}>
        <div className="dialog-wrapper">
          <div className="dialog-title">
            <span className="title">Delete Reply</span>
            <span className="material-icons icon" onClick={handleToggleDialog}>
              &#xe5cd;
            </span>
          </div>
          <div className="dialog-body">
            You are about to delete this reply permanently.
          </div>
          <div className="dialog-controls">
            {deleteReplyState && (
              <div className="error-section">
                {deleteReplyState.errorMessage}
              </div>
            )}
            <div className="button">
              <button
                onClick={handleToggleDialog}
                className="custom-button sm outlined"
                disabled={deleteReplyState.isLoading}
              >
                Cancel
              </button>
            </div>

            <div className="button">
              <button
                onClick={() => deleteReply()}
                className="custom-button sm"
                disabled={deleteReplyState.isLoading}
              >
                {deleteReplyState.isLoading ? 'Deleting...' : 'Delete'}
              </button>
              {deleteReplyState.isLoading && (
                <CircularProgress size={25} className="button-loader" />
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default PostCommentReplies;
