import * as Sentry from '@sentry/node';
const { configureScope } = Sentry;
import { RewriteFrames } from '@sentry/integrations';

const SOURCEMAP_FILENAME_REGEX = /^.*\/\.next\//;

/** Default RewriteFrames integration to match filenames in Sentry. */
export const init = () => {
  const integrations = [];
  if (process.env.NEXT_IS_SERVER === 'true' && process.env.INITIALIZE_SENTRY) {
    integrations.push(
      new RewriteFrames({
        iteratee: frame => {
          frame.filename = frame.filename?.replace(
            SOURCEMAP_FILENAME_REGEX,
            'app:///_next/'
          );
          return frame;
        },
      })
    );
  }
  Sentry.init({
    integrations,
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.SENTRY_DSN,
  });
  const runTime = process.env.NEXT_IS_SERVER === 'true' ? 'server' : 'browser';
  configureScope(scope => {
    scope.setTag('runtime', runTime);
    scope.setTag('app', 'merchant');
  });
};

export const handleUserInfoChange = userObject => {
  if (userObject.id) {
    Sentry.setUser({
      id: userObject.id,
    });
  } else {
    Sentry.configureScope(scope => scope.setUser(null));
  }
};
