import axiosConfig from '@ictlife/merchant/config/axiosConfig';

export const deleteTimeAttributeById = async id => {
  const response = await axiosConfig.delete(
    `/merchant_offering_time_attributes/${id}`
  );
  return response.data;
};

export const updateTimeAttributeById = async ({ id, payload }) => {
  const response = await axiosConfig.put(
    `/merchant_offering_time_attributes/${id}`,
    payload
  );
  return response.data;
};
