import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { fetchUserInfo } from '@ictlife/merchant/state/actions/loginAction';
import { getSocketToken } from '@ictlife/merchant/state/actions/socketsAction';
import {
  getSpecificPostComment,
  getSpecificPostCommentReply,
} from '@ictlife/merchant/state/actions/postCommentsAction';
import { getUserPostLike } from '@ictlife/merchant/state/actions/postsAction';
import Cookies from '@ictlife/core/config/cookies';
import { clearMessagingDatabase } from '@ictlife/merchant/components/dashboard-blueprint/TopBar';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { Dialog } from '@mui/material';
import { Transition } from '@ictlife/merchant/components/posts/AddPost';
import useAccountInit from '../../hooks/useAccountInit';
import usePageLoader from '../../hooks/usePageLoader';
import SnackInfo from '../../components/SnackInfo';
import logUserActivity from '../../utils/logUserActivity';
import useServiceWorkerLifecycle from '../../hooks/useServiceWorkerLifecycle';
import firebaseCloudMessaging, {
  onPushNotificationListener,
} from '../../utils/firebaseCloudMessaging';
import useMounted from '../../hooks/useMounted';
import AppImage from '../../components/app-image/AppImage';
import NotificationRequestDialog from './NotificationRequestPopper';

// const BottomNavy = dynamic(() => import('../../components/BottomNavy'));
const GetAppNav = dynamic(() => import('../../components/BottomNavy/GetApp'));

const InitializeApp = ({
  children,
  initialServerSideState,
  handleAppEvents,
  handleUserInfoChange,
  notificationConfigs = {
    DialogBody: null,
    icon: undefined,
    firebaseConfig: null,
  },
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const userInfoState = useSelector(store => store.userInfoState);
  const { user: userInfo } = userInfoState;
  const { id: user_id } = userInfo;
  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      merchant: { id: business_id, business_username, user_id: merchantUserId },
      merchant,
    },
    merchantProfile,
  } = merchantProfileState;

  const [openNotificationPopper, setOpenNotificationPopper] = useState(false);

  useEffect(() => {
    handleUserInfoChange(userInfo);
  }, [userInfo, handleUserInfoChange]);

  useEffect(() => {
    clearMessagingDatabase();
  }, []);

  const sourcePackage = process.env.NEXT_PUBLIC_SOURCE_PACKAGE;

  const isBusinessOwner =
    merchantUserId &&
    user_id &&
    merchantUserId === user_id &&
    sourcePackage === 'merchant';

  useEffect(() => {
    if (isBusinessOwner) {
      const prof = JSON.stringify(merchantProfile);
      localStorage.activeMerchant = prof; //Error line
      const ictl_merchant = {
        id: business_id,
        business_username,
      };

      Cookies.set('ictl_merchant', JSON.stringify(ictl_merchant), {
        domain: process.env.NODE_ENV === 'production' && 'ictlife.com',
        expires: 365,
      });
    }
  }, [business_id, business_username, isBusinessOwner, merchantProfile]);

  useEffect(() => {
    const userNotificationStatus = Cookies.get('ictl_push_notification_status');
    const nonBlockingStatus =
      !userNotificationStatus || userNotificationStatus !== 'remind_later';
    const sourcePackage = process.env.NEXT_PUBLIC_SOURCE_PACKAGE;
    const isTargetPage =
      sourcePackage === 'marketplace' ||
      (sourcePackage === 'merchant' &&
        router.pathname === '/business/[b_username]');
    const showNotificationDialog =
      user_id &&
      Notification.permission === 'default' &&
      nonBlockingStatus &&
      isTargetPage;

    const { checkDeviceMessagingSupport } = firebaseCloudMessaging;

    showNotificationDialog &&
      checkDeviceMessagingSupport().then(itSupports => {
        if (itSupports) {
          setOpenNotificationPopper(true);
        }
      });
  }, [router.pathname, user_id]);

  const messaging = useMemo(() => {
    if (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      notificationConfigs.firebaseConfig
    ) {
      const app = initializeApp(notificationConfigs.firebaseConfig);
      return getMessaging(app);
    }
  }, [notificationConfigs.firebaseConfig]);

  useEffect(() => {
    const userNotificationStatus = Cookies.get('ictl_push_notification_status');
    const { init, handleRegisterPushToken, checkDeviceMessagingSupport } =
      firebaseCloudMessaging;

    user_id &&
      Notification.permission === 'granted' &&
      userNotificationStatus !== sourcePackage &&
      checkDeviceMessagingSupport()
        .then(itSupports => {
          if (itSupports && messaging) {
            init(messaging)
              .then(pushToken => {
                handleRegisterPushToken(pushToken)
                  .then(() => {
                    Cookies.set(
                      'ictl_push_notification_status',
                      sourcePackage,
                      {
                        expires: 1,
                      }
                    );
                  })
                  .catch(() =>
                    Cookies.set('ictl_push_notification_status', 'granted', {
                      expires: 2100,
                    })
                  );
              })
              .catch(error => {
                if (error.code) {
                  Cookies.set('ictl_push_notification_status', error.code, {
                    expires: 2100,
                  });
                }
              });
          }
        })
        .catch(error => console.error(error));
  });

  typeof window !== 'undefined' &&
    messaging &&
    onPushNotificationListener(messaging).then(payload => {
      if (!payload.notification) {
        // handle clicked notification
        console.log(payload.data);
      } else {
        console.log(payload.data);

        // handle in-app notifications
      }
    });

  // record page visits
  useEffect(() => {
    const pageVisit = `${process.env.NEXT_PUBLIC_SOURCE_PACKAGE}_web${router.pathname}`;
    const resolvedDynamicPageVisit = `${process.env.NEXT_PUBLIC_SOURCE_PACKAGE}_web${router.asPath}`;
    logUserActivity({ action: pageVisit, dispatch });

    if (pageVisit !== resolvedDynamicPageVisit) {
      logUserActivity({ action: resolvedDynamicPageVisit, dispatch });
    }
  }, [dispatch, router.asPath, router.pathname]);

  const fetchResource = useCallback(
    evt => {
      const data = JSON.parse(evt.data);
      const { event_type } = data;
      switch (event_type) {
        case 'merchant_post_comment':
          {
            const { merchant_post_comment_id: commentId } = data;
            getSpecificPostComment(dispatch, commentId).then(response => {
              dispatch({
                type: 'POST_COMMENT_IN_SOCKET',
                comment: response.merchant_post_comments[0],
              });
            });
          }
          break;

        case 'merchant_post_comment_reply':
          {
            const { merchant_post_comment_reply_id: replyId } = data;
            getSpecificPostCommentReply(dispatch, replyId).then(response => {
              dispatch({
                type: 'POST_COMMENT_REPLY_IN_SOCKET',
                reply: response,
              });
            });
          }
          break;

        case 'merchant_post_comment_like':
          dispatch({
            type: 'POST_COMMENT_LIKE_IN_SOCKET',
            like: data,
          });

          break;

        case 'merchant_post_like':
          {
            getUserPostLike({
              notificationPostLike: data.merchant_post_like_id,
              dispatch,
            }).then(response => {
              dispatch({
                type: 'POST_LIKE_IN_SOCKET',
                like: response.data,
              });
            });
          }

          break;

        default:
          break;
      }
    },
    [dispatch]
  );

  const connectWebSocket = useCallback(() => {
    getSocketToken().then(data => {
      if (data.status === 'success') {
        const token = data.token;
        const url = `${process.env.NEXT_PUBLIC_EVENTS_SOCKET_URL}?token=${token}`;
        let ws = new WebSocket(url);
        ws.onopen = () => console.info('socket on');
        ws.onerror = () => {
          console.error('socket error');
        };
        ws.onmessage = evt => {
          // messaging event types
          const eventTypesToIgnore = [
            'user_offline',
            'user_online',
            'start_typing',
            'stop_typing',
          ];
          const data = JSON.parse(evt.data);
          const { event_type } = data;
          fetchResource(evt);
          if (handleAppEvents && !eventTypesToIgnore.includes(event_type))
            handleAppEvents({ evt, dispatch, merchant, router, user_id });
        };
        ws.onclose = () => {
          console.info('socket off');
          if (user_id) {
            connectWebSocket();
          }
        };
      }
    });
  }, [dispatch, fetchResource, handleAppEvents, merchant, router, user_id]);

  const user = Cookies.get('ictl_user');

  useEffect(() => {
    if (user_id) {
      connectWebSocket();
    } else if (user) {
      fetchUserInfo(dispatch).then(() => {
        connectWebSocket();
      });
    } else {
      dispatch({ type: 'ANONYMOUS_USER' });
    }
  }, [user_id, user, business_id, connectWebSocket, dispatch]);

  useAccountInit({ initialServerSideState });
  usePageLoader();
  const swSnackInfo = useServiceWorkerLifecycle();

  const [internetSnackInfo, setInternetSnackInfo] = useState({
    open: false,
    message: '',
    vertical: 'top',
    horizontal: 'center',
    severity: 'info',
    autoHideDuration: 5000,
  });

  if (typeof window !== 'undefined') {
    try {
      localStorage.activeMerchant && JSON.parse(localStorage.activeMerchant);
      Cookies.get('ictl_user') && JSON.parse(Cookies.get('ictl_user'));
    } catch (error) {
      localStorage.clear();
      dispatch({ type: 'RESET_STORE' });
      router.replace('/');
    }

    window.onoffline = () => {
      if (internetSnackInfo.message !== 'You lost connection to the internet') {
        setInternetSnackInfo(snackInfo => ({
          ...snackInfo,
          open: true,
          message: 'You lost connection to the internet',
          severity: 'error',
          vertical: 'top',
          horizontal: 'center',
          autoHideDuration: null,
        }));
      }
    };

    window.ononline = () => {
      connectWebSocket();
      if (internetSnackInfo.message !== 'You are back online') {
        setInternetSnackInfo(snackInfo => ({
          ...snackInfo,
          open: true,
          message: 'You are back online',
          severity: 'success',
          vertical: 'top',
          horizontal: 'center',
          autoHideDuration: 3000,
        }));
      }
    };
  }

  const isMounted = useMounted();

  const isAndroidPhone =
    typeof window !== 'undefined'
      ? /(Android)/i.test(navigator.userAgent)
      : false;

  const show =
    typeof window !== 'undefined'
      ? sessionStorage.getItem('showMobileDialog')
      : 'hide';
  const [showMobileDialog, setShowMobileDialog] = useState(
    show === 'hide' ? false : true
  );

  return (
    <div className="app-wrapper">
      {children}
      <SnackInfo snackInfo={swSnackInfo} />
      <SnackInfo
        snackInfo={internetSnackInfo}
        handleClose={() =>
          setInternetSnackInfo(internetSnackInfo => ({
            ...internetSnackInfo,
            open: false,
          }))
        }
      />
      {isAndroidPhone && (
        <>
          {isMounted && <GetAppNav />}

          <Dialog
            open={showMobileDialog}
            TransitionComponent={Transition}
            className="override"
            onClose={() => {
              sessionStorage.setItem('showMobileDialog', 'hide');
              setShowMobileDialog(false);
            }}
          >
            <div className="p-4 flex items-center flex-col space-y-4">
              <div style={{ height: '40px' }} className="w-full">
                <AppImage
                  asset={{
                    url: '/images/logo/sasa-logo.png',
                    description: 'Sasa logo',
                  }}
                  alt="Sasa logo"
                  src="'/images/logo/sasa-logo.png'"
                />
              </div>
              <div className="font-semibold text-center">
                Try AfricaSasa Events app for a better mobile experience
              </div>
              <a href="https://play.google.com/store/apps/details?id=com.ictlife.africasasa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  className="h-20"
                />
              </a>
              <div
                className="font-semibold text-sm cursor-pointer"
                onClick={() => {
                  sessionStorage.setItem('showMobileDialog', 'hide');
                  setShowMobileDialog(false);
                }}
              >
                Later
              </div>
            </div>
          </Dialog>
        </>
      )}

      {openNotificationPopper && notificationConfigs.DialogBody && (
        <NotificationRequestDialog
          setOpenNotificationPopper={setOpenNotificationPopper}
          notificationConfigs={notificationConfigs}
        />
      )}
    </div>
  );
};

export default InitializeApp;
