import { LinearProgress } from '@mui/material';
import { useMemo } from 'react';
import cn from 'classnames';

import AppImage from '@ictlife/core/components/app-image/AppImage';
import ReactPlayer from 'react-player';
import AppToolTip from '@ictlife/marketplace/components/shared/ToolTip';
import thumbsStyles from '../components/posts/styles/Thumbs.module.scss';

const ImageThumbnail = ({
  file,
  activeThumbIndex,
  index,
  handleRetry,
  handleClickThumbnail,
  showControls,
  handleRemoveItem,
  styles,
  hideUpload,
}) => {
  const itsAVideoFile = useMemo(
    () =>
      file.asset?.content_type?.includes('video/') ||
      file?.file?.type?.includes('video/'),
    [file.asset?.content_type, file?.file?.type]
  );
  return (
    <AppToolTip
      title={file.error_section || file.asset.description}
      placement="top"
    >
      <div
        className={cn({
          [thumbsStyles.thumb]: true,
          [thumbsStyles.active]: activeThumbIndex === index,
          [thumbsStyles.thumbLoading]: file.upload_loading,
          [thumbsStyles.uploadError]: file.upload_error,
        })}
        style={styles}
        onClick={file.upload_error && showControls ? handleRetry : undefined}
      >
        {file.upload_loading &&
          file.upload_progress &&
          file.upload_progress !== 100 && (
            <LinearProgress
              variant="buffer"
              value={file.upload_progress || 0}
              valueBuffer={file.upload_progress || 0}
              className={thumbsStyles.imageLinearProgress}
            />
          )}
        <div className={thumbsStyles.thumbOverlay}>
          {file.upload_loading && (
            <span className={thumbsStyles.overlayInfo}>
              {file.upload_progress === 100
                ? 'Creating thumbnail...'
                : file.upload_progress
                ? `${file.upload_progress}%`
                : 'Working...'}
            </span>
          )}
          {file.upload_error && (
            <span className={thumbsStyles.overlayInfo}>
              Upload failed.{' '}
              {file.upload_error && showControls && 'Click to retry'}
            </span>
          )}
        </div>
        <div className={thumbsStyles.thumbInner} onClick={handleClickThumbnail}>
          {itsAVideoFile ? (
            <ReactPlayer url={file.asset.url} height="max-content" controls />
          ) : (
            <AppImage
              asset={file.asset}
              message={{ error: 'Image could not be fully loaded' }}
            />
          )}
        </div>
        {showControls && (
          <div className={thumbsStyles.removeImage}>
            <div title="Remove" onClick={handleRemoveItem}>
              <span className="material-icons">&#xe872;</span>
            </div>
            {!file.upload_success && !hideUpload && (
              <div
                className="icon"
                title="Upload new image"
                onClick={handleRetry}
              >
                <span className="material-icons">&#xe2c6;</span>
              </div>
            )}
          </div>
        )}
      </div>
    </AppToolTip>
  );
};

export default ImageThumbnail;
