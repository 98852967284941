import {
  GET_PROMOTION_MERCHANT_TAGS,
  GET_CATEGORY_MERCHANT_TAGS,
  GET_PROMOTION_MERCHANT_TAGS_SUCCESS,
  GET_CATEGORY_MERCHANT_TAGS_SUCCESS,
  GET_MERCHANT_TAGS_ERROR,
  SEARCH_TAGS,
  SEARCH_TAGS_SUCCESS,
  SEARCH_TAGS_ERROR,
} from '../dispatchTypes';

export const initialGetMerchantTagsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  promotion_tags: {
    merchant_tags: [],
  },
  category_tags: {
    merchant_tags: [],
  },
  errorMessage: '',
};

export const getMerchantTagsReducer = (
  state = initialGetMerchantTagsState,
  action
) => {
  switch (action.type) {
    case GET_PROMOTION_MERCHANT_TAGS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_CATEGORY_MERCHANT_TAGS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_PROMOTION_MERCHANT_TAGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        promotion_tags: action.tags,
      };
    }
    case GET_CATEGORY_MERCHANT_TAGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        category_tags: action.tags,
      };
    }
    case GET_MERCHANT_TAGS_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export const initialSearchTagsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  tag_results: {
    tags: [],
  },

  errorMessage: '',
};

export const searchTagsReducer = (state = initialSearchTagsState, action) => {
  switch (action.type) {
    case SEARCH_TAGS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SEARCH_TAGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        tag_results: action.tag_results,
      };
    }
    case SEARCH_TAGS_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};
