import axiosConfig from '@ictlife/merchant/config/axiosConfig';
import useSWR from 'swr';

export const useBusinessCategories = ({
  super_category_slug,
  onSuccess,
  preventFetch,
  search,
  latitude,
  longitude,
}) => {
  const url = '/business_categories';
  const { data, error, mutate, isValidating } = useSWR(
    preventFetch
      ? null
      : {
          url,
          super_category_slug,
          search,
          latitude,
          longitude,
        },
    () =>
      axiosConfig.get(url, {
        params: {
          super_category_slug,
          per: 100,
          search,
          latitude,
          longitude,
        },
      }),
    {
      onSuccess: data => onSuccess(data.data),
    }
  );

  return {
    businessCategories: data?.data?.business_categories,
    error,
    loading: isValidating && !data,
    mutate,
  };
};

export const getBusinessCategories = async ({
  super_category_slug,
  search,
  per,
}) => {
  const response = await axiosConfig.get('/business_categories', {
    params: {
      super_category_slug,
      search,
      per,
    },
  });
  return response.data.business_categories;
};
