import axiosConfig from '../../config/axios';

export const recordUserActivity = (dispatch, bodyData) => {
  axiosConfig
    .post('/session_logs', bodyData)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};
