import { v4 as uuidv4 } from 'uuid';
import Cookies from '../config/cookies';

export const getSessionTokenCookie = () => {
  const cookieKey = 'ictluseruuid';
  let session_token = Cookies.get(cookieKey);

  !session_token && Cookies.set(cookieKey, uuidv4(), { expires: 2100 });

  return Cookies.get(cookieKey);
};
