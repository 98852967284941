import cn from 'classnames';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import {
  updateOpenGraphImage,
  useOpenGraphMetaData,
} from '../../state/actions/openGraphActions';
import styles from './AppImage.module.scss';

const AppImage = ({
  asset,
  className,
  priority,
  isStaticImport,
  setImageError,
}) => {
  const [imageLoadError, setImageLoadError] = useState(false);
  const [src, setSrc] = useState(asset.url);
  const [requestURL, setRequestURL] = useState();

  useEffect(() => {
    if (asset) {
      setSrc(asset.url);
      setRequestURL();
    }
  }, [asset]);

  useOpenGraphMetaData({
    url: imageLoadError ? requestURL : undefined,
    onSuccess: data => {
      setImageLoadError(false);
      setSrc(data.image);
      updateOpenGraphImage({
        id: asset.open_graph_link.id,
        payload: {
          image_url: data.image,
        },
      });
    },
  });

  if (!asset || !src || src === 'test-file-stub')
    return <div className={cn(styles.carouselImageWrapper, 'theme-text')} />;

  if (imageLoadError)
    return (
      <div className={cn(styles.carouselImageWrapper, 'theme-text')}>
        <div className={styles.error}>
          <div className={styles.message}>
            <span className="material-icons">&#xe000;</span>
            <span>{asset.description}</span>
          </div>
        </div>
      </div>
    );

  return (
    <div className={cn(styles.carouselImageWrapper, 'theme-text')}>
      {asset.blur_hash && (
        <Blurhash hash={asset.blur_hash} height="100%" width="100%" punch={0} />
      )}
      {asset.isUnoptimized && !isStaticImport && (
        <div
          style={{
            backgroundImage: `url(${src})`,
            filter: 'blur(40px)',
            backgroundSize: '100% 100%',
          }}
        />
      )}

      <Image
        src={src}
        alt={asset.description}
        layout="fill"
        objectFit="contain"
        unoptimized={
          asset.isUnoptimized || (!isStaticImport && src?.includes('blob'))
        }
        className={className}
        priority={priority}
        placeholder={isStaticImport || asset.blur_hash ? 'blur' : 'empty'}
        blurDataURL={!isStaticImport && asset.blur_hash}
        onError={() => {
          if (asset.open_graph_link && !requestURL) {
            setRequestURL(asset.open_graph_link.link_url);
          }

          setImageLoadError(true);
          setImageError && setImageError(true);
        }}
      />
    </div>
  );
};

export default AppImage;
