function generatePostMetaDataFrom(aPost) {
  if (!aPost.merchant_post_assets || aPost.merchant_post_assets.length === 0)
    return null;
  const asset = aPost.merchant_post_assets[0].asset;
  return {
    title: `${aPost.title} | ${aPost.business_name}`,
    siteName: aPost.business_name || 'AfricaSasa Merchant',
    image: asset.url,
    image_width: asset.image_width || null,
    image_height: asset.image_height || null,
    description: `${asset.description} ${
      aPost?.merchant_post_assets?.length > 1
        ? `(${aPost?.merchant_post_assets?.length - 1} more images)`
        : ''
    }`,
    url: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/business/${aPost.business_username}/posts/${aPost.uuid}`,
  };
}
export default function boundary(aPost) {
  const metaData = generatePostMetaDataFrom(aPost);
  return {
    rawPost: aPost,
    metaData,
  };
}
