import useEventSchedule from '@ictlife/core/hooks/useEventSchedule';
import { IconButton } from '@mui/material';

const ScheduledEventCard = ({
  offeringTimeAttribute,
  handleSetTimeAttributeForEdit,
  handleRemoveTimeAttribute,
  timeAttributeIdx,
  isExperience,
}) => {
  const {
    start_date,
    start_time,
    end_time,
    frequency,
    event_expiry_date,
    daysOfTheWeek,
    isAllDay,
    event_location,
  } = useEventSchedule({
    time_attribute: offeringTimeAttribute,
  });

  return (
    <div className="shadow w-3/5 p-4 rounded-lg mt-4">
      <div className="font-semibold flex items-center">
        <div className="flex-1">Schedule {timeAttributeIdx + 1}</div>
        <div className="space-x-4">
          <IconButton onClick={handleSetTimeAttributeForEdit}>
            <span className="material-icons">&#xe3c9;</span>
          </IconButton>

          <IconButton onClick={handleRemoveTimeAttribute}>
            <span className="material-icons">&#xe872;</span>
          </IconButton>
        </div>
      </div>
      <div>
        {event_location && (
          <FieldValue label="Location" value={event_location} />
        )}
        {!isExperience && <FieldValue label="Date" value={start_date} />}
        {isAllDay ? (
          <FieldValue label="Time" value="All day" />
        ) : (
          <div className="flex items-center justify-between">
            <FieldValue label="Start time" value={start_time} />
            <FieldValue label="End time" value={end_time} />
            <div />
          </div>
        )}
        {!isExperience && (
          <div>
            <FieldValue label="Occurs" value={frequency || 'Once'} />
          </div>
        )}

        {daysOfTheWeek && daysOfTheWeek.length > 0 && (
          <div>
            <FieldValue
              label="On these days"
              value={daysOfTheWeek.join(', ')}
            />
          </div>
        )}
      </div>

      {event_expiry_date && !isExperience && (
        <div>
          <FieldValue label="Repeats Until" value={event_expiry_date} />
        </div>
      )}
    </div>
  );
};

const FieldValue = ({ label, value }) => {
  return (
    <div className="flex flex-col mb-4">
      <span className="text-xs font-semibold">{label}</span>
      <span className="text-sm">{value}</span>
    </div>
  );
};

export default ScheduledEventCard;
