import { CircularProgress, Dialog, Switch } from '@mui/material';
import React, { useState } from 'react';

const attributeHolderStyles = {
  display: 'grid',
  gap: '1em',
  gridTemplateColumns: '1fr 1fr 1fr max-content',
  marginBottom: '1.5em',
};

const AttributeField = ({ attribute, setAttribute }) => {
  return (
    <div style={attributeHolderStyles}>
      <div className="app-form">
        <label>Category name</label>
        <input
          type="text"
          placeholder="Category name"
          style={{ height: '2.5em', backgroundColor: '#fff' }}
          value={attribute.name}
          onChange={e => {
            attribute.name = e.target.value;
            setAttribute({ ...attribute });
          }}
        />
      </div>

      <div className="override">
        <div className="app-form">
          <label>Active</label>
        </div>

        <Switch
          checked={attribute.status}
          size="small"
          value={attribute.status}
          onChange={e => {
            attribute.status = e.target.checked;
            setAttribute({ ...attribute });
          }}
        />
      </div>
    </div>
  );
};

const EditCategoryDialog = ({
  setUpdateCategory,
  isLoading,
  isError,
  setError,
  handleEditCategory,
  updateCategory,
}) => {
  const [attribute, setAttribute] = useState({
    name: updateCategory?.label,
    status: updateCategory?.status,
    attribute_id: updateCategory?.id,
  });
  const handleCloseDialog = () => {
    setUpdateCategory(false);
    setError();
  };

  return (
    <Dialog open={true} onClose={handleCloseDialog} maxWidth="md">
      <div className="dialog-wrapper">
        <div className="dialog-title">
          <span className="title">Edit Category</span>

          <span className="material-icons icon" onClick={handleCloseDialog}>
            &#xe5cd;
          </span>
        </div>
        <div
          className="dialog-body"
          style={{
            fontSize: '0.95em',
            minHeight: '10em',
            paddingBottom: '2em',
          }}
        >
          <AttributeField
            key={0}
            attribute={attribute}
            setAttribute={setAttribute}
          />
        </div>
        <div className="dialog-controls">
          {isError && <div className="error-section">{isError}</div>}
          <div className="button">
            <button
              onClick={handleCloseDialog}
              className="custom-button outlined sm"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>

          <div className="button">
            <button
              className="custom-button sm"
              disabled={isLoading}
              onClick={() => {
                handleEditCategory(attribute);
              }}
            >
              Save Category
            </button>
            {isLoading && (
              <CircularProgress size={25} className="button-loader" />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default EditCategoryDialog;
