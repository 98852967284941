import {
  LOADING,
  SUCCESS,
  ERROR,
  SET_PROFILE_PICTURE,
  PROFILE_PICTURE_SUCCESS,
  PROFILE_PICTURE_ERROR,
} from '../dispatchTypes';

export const initialUploadBusinessLogoState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

const uploadBusinessLogoReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isSuccess: false,
        isError: false,
        isLoading: true,
        errorMessage: '',
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }
    case ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialUploadBusinessLogoState;
  }
};

export const initialUploadProfilePhotoState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const uploadProfilePhotoReducer = (
  state = initialUploadProfilePhotoState,
  action
) => {
  switch (action.type) {
    case SET_PROFILE_PICTURE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PROFILE_PICTURE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }
    case PROFILE_PICTURE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    case 'RESET_PROFILE_PICTURE': {
      return initialUploadProfilePhotoState;
    }

    default:
      return state;
  }
};

export default uploadBusinessLogoReducer;
