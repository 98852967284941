import axiosConfig from '../../config/axiosConfig';
import {
  BUSINESS_PROFILE,
  BUSINESS_PROFILE_SUCCESS,
  BUSINESS_PROFILE_ERROR,
  BUSINESS_PROFILE_UPDATE_SUCCESS,
  BUSINESS_PROFILE_UPDATE_ERROR,
  BUSINESS_PROFILE_SUMMARY,
  BUSINESS_PROFILE_SUMMARY_SUCCESS,
  BUSINESS_PROFILE_SUMMARY_ERROR,
  SEARCH_BUSINESS,
  SEARCH_BUSINESS_SUCCESS,
  SEARCH_BUSINESS_ERROR,
  UPDATE_LIVE_STATUS,
  UPDATE_LIVE_STATUS_SUCCESS,
  UPDATE_LIVE_STATUS_ERROR,
} from '../dispatchTypes';

export const getMerchantProfileSummary = (dispatch, business_id) => {
  dispatch({
    type: BUSINESS_PROFILE_SUMMARY,
  });

  axiosConfig
    .get(`merchants/${business_id}/content_summary`)
    .then(response => {
      dispatch({
        type: BUSINESS_PROFILE_SUMMARY_SUCCESS,
        merchantProfile: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: BUSINESS_PROFILE_SUMMARY_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: BUSINESS_PROFILE_SUMMARY_ERROR,
        errorMessage:
          'Business profile summary could not be loaded. Lost connection to the server',
      });
    });
};

export const getMerchantProfile = (
  dispatch,
  business_username,
  isBusinessRetrieve = false
) => {
  dispatch({
    type: BUSINESS_PROFILE,
    isBusinessRetrieve,
  });

  axiosConfig
    .get(`/merchant_profiles/${business_username}`)
    .then(response => {
      dispatch({
        type: BUSINESS_PROFILE_SUCCESS,
        merchantProfile: response.data,
        viewMode: process.env.NEXT_PUBLIC_SOURCE_PACKAGE !== 'merchant',
      });
      getMerchantProfileSummary(dispatch, response.data.merchant.id);
    })
    .catch(error => {
      dispatch({
        type: BUSINESS_PROFILE_ERROR,
        errorMessage: error.response.data.error_message,
        errorCode: error.response.status,
      });
    })
    .catch(() => {
      dispatch({
        type: BUSINESS_PROFILE_ERROR,
        errorMessage:
          'Business profile could not be loaded. Lost connection to the server',
      });
    });
};

export const updateMerchantProfile = (
  dispatchUpdateProfile,
  bodyData,
  business_id,
  business_username,
  routeUser
) => {
  dispatchUpdateProfile({
    type: BUSINESS_PROFILE,
  });

  axiosConfig
    .put(`/merchants/${business_id}/profile`, bodyData)
    .then(() => {
      dispatchUpdateProfile({
        type: BUSINESS_PROFILE_UPDATE_SUCCESS,
        username: business_username,
      });
      getMerchantProfile(dispatchUpdateProfile, business_username);
      routeUser && routeUser();
    })
    .catch(error => {
      dispatchUpdateProfile({
        type: BUSINESS_PROFILE_UPDATE_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchUpdateProfile({
        type: BUSINESS_PROFILE_UPDATE_ERROR,
        errorMessage: 'Something went wrong',
      });
    });
};

export const searchMerchant = (dispatch, searchTerm) => {
  dispatch({
    type: SEARCH_BUSINESS,
  });

  axiosConfig
    .get('/merchants', {
      params: {
        search: searchTerm,
        per: 4,
      },
    })
    .then(response => {
      dispatch({
        type: SEARCH_BUSINESS_SUCCESS,
        merchants: response.data.merchant_infos,
      });
    })
    .catch(error => {
      dispatch({
        type: SEARCH_BUSINESS_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: SEARCH_BUSINESS_ERROR,
        errorMessage: 'Failed to get suggestions. Try reloading the page',
      });
    });
};

export const recordResourceShare = (bodyData, resource) => {
  let url;
  switch (resource) {
    case 'business_profile':
      url = '/merchant_profile_shares';
      break;
    case 'post':
      url = '/merchant_post_shares';
      break;
    case 'review':
      url = '/transaction_review_shares';
      break;
    default:
      break;
  }

  return new Promise((resolve, reject) => {
    axiosConfig
      .post(url, bodyData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error.response.data.error_message);
      });
  });
};

export const updateLiveStatus = (dispatch, business_id, status) => {
  dispatch({
    type: UPDATE_LIVE_STATUS,
  });

  return axiosConfig
    .put(
      `merchants/${business_id}/status`,
      {},
      {
        params: {
          status,
        },
      }
    )
    .then(response => {
      dispatch({
        type: UPDATE_LIVE_STATUS_SUCCESS,
        status: response.data.status,
      });
    })
    .catch(error => {
      dispatch({
        type: UPDATE_LIVE_STATUS_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: UPDATE_LIVE_STATUS_ERROR,
        errorMessage: 'Something went wrong',
      });
    });
};

export const claimMerchantProfile = async ({ business_id, bodyData }) => {
  const response = await axiosConfig.post(
    `/merchants/${business_id}/claim_send_money_merchant`,
    bodyData
  );

  return response.data;
};
