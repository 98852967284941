import AppTextField from '@ictlife/core/components/AppTextField/AppTextField';
import useOpenGraphState from '@ictlife/core/hooks/useOpenGraphState';
import { Dialog } from '@mui/material';
import { useState } from 'react';

const ExternalLinkDialog = ({
  setOpenExternalLinkDialog,
  handleClear,
  handleSetOpenGraphLink,
}) => {
  const [externalLinkValue, setExternalLinkValue] = useState('');
  const {
    ogData,
    loadingOGData,
    requestURL,
    open_graph_links,
    eventBriteData,
  } = useOpenGraphState(externalLinkValue);

  return (
    <Dialog
      open={true}
      onClose={() => setOpenExternalLinkDialog(false)}
      fullWidth
    >
      <div>
        <div className="bg-gray-100 px-4 py-2 font-semibold flex items-center justify-between text-sm">
          <span>Pre-fill form with link data</span>
          <span
            className="material-icons cursor-pointer"
            onClick={() => setOpenExternalLinkDialog(false)}
          >
            &#xe5c9;
          </span>
        </div>
        <div className="p-4">
          <div>
            <div className="text-sm">
              <label htmlFor="name">
                Enter a valid URL (format: https://example.com) *
              </label>
              <div className="mt-1">
                <AppTextField
                  openGraphProps={{
                    ogData,
                    isLoading: loadingOGData,
                    requestURL,
                  }}
                  id="name"
                  name="name"
                  fullWidth
                  value={externalLinkValue}
                  onChange={e => setExternalLinkValue(e.target.value)}
                  required
                  type="url"
                />
              </div>
            </div>
            <div className="flex mt-4 space-x-4">
              <div className="flex-1" />
              <button
                className="custom-button outlined"
                onClick={() => {
                  handleClear();
                  setExternalLinkValue('');
                  setOpenExternalLinkDialog(false);
                }}
              >
                Cancel
              </button>
              <button
                className="custom-button"
                disabled={!ogData}
                onClick={() => {
                  const { link_title } = open_graph_links[0];

                  handleSetOpenGraphLink({
                    OGLink: open_graph_links[0],
                    title: link_title.slice(0, 200),
                    eventBriteData,
                  });
                  setOpenExternalLinkDialog(false);
                }}
              >
                Use Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ExternalLinkDialog;
