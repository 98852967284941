import axiosConfig from '@ictlife/core/config/axios';
import useSWRImmutable from 'swr/immutable';

export const useOpenGraphMetaData = ({ url, onSuccess }) => {
  const fetcher = url => fetch(url).then(res => res.json());

  const { data, isValidating } = useSWRImmutable(
    url
      ? `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/api/metadata?url=${url}`
      : null,
    fetcher,
    {
      onSuccess: onSuccess && onSuccess,
    }
  );

  return {
    ogData: data && { ...data, requestURL: url },
    isLoading: isValidating && !data,
  };
};

export const useEventBriteAPIData = ({ eventBriteId, onSuccess }) => {
  const fetcher = url => axiosConfig(url).then(res => res);

  const { data, isValidating } = useSWRImmutable(
    eventBriteId ? `merchant_offerings/eventbrite/${eventBriteId}` : null,
    fetcher,
    {
      onSuccess: onSuccess && onSuccess,
    }
  );

  return {
    data: data?.data,
    isLoading: isValidating && !data,
  };
};

export const updateOpenGraphImage = async ({ id, payload }) => {
  try {
    const response = await axiosConfig.put(`/open_graph_links/${id}`, payload);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
