import { useLocationInformation } from '@ictlife/core/state/actions/locationActions';
import { capitalizeFirstLetter } from '@ictlife/core/utils/capitalizeFirstLetter';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { DEFAULT_LOCATION, DEFAULT_SUPER_CATEGORY } from '../constants';

const useSuperCategoryAndLocation = () => {
  const router = useRouter();
  const { superCategory: superCategoryAndLocation, distance } = router.query;

  const { superCategoryLocationList, currentLocation, currentSuperCategory } =
    useMemo(() => {
      const superCategoryLocationList =
        superCategoryAndLocation?.split('-in-') ?? [];
      const currentLocation = superCategoryLocationList[1];

      return {
        superCategoryLocationList,
        currentLocation,
        currentSuperCategory:
          superCategoryLocationList[0] || DEFAULT_SUPER_CATEGORY,
      };
    }, [superCategoryAndLocation]);

  const { locationInformation, isLoading } =
    useLocationInformation(currentLocation);

  const distanceRadius = useMemo(() => {
    if (distance && !isNaN(distance)) {
      return parseInt(distance);
    } else {
      return 300;
    }
  }, [distance]);

  return currentLocation
    ? {
        superCategory: currentSuperCategory,
        latLng: locationInformation?.latitude
          ? {
              lat: locationInformation.latitude,
              lng: locationInformation.longitude,
            }
          : {},
        address:
          locationInformation?.formattedAddress ??
          capitalizeFirstLetter(currentLocation.replace(/-/g, ' ')),
        locationForURL: superCategoryLocationList[1]
          ? `-in-${superCategoryLocationList[1]}`
          : '',
        superCategoryAndLocation:
          superCategoryAndLocation || DEFAULT_SUPER_CATEGORY,
        loadingLocation: isLoading,
        distanceRadius,
      }
    : {
        ...DEFAULT_LOCATION,
        superCategory: currentSuperCategory,
        superCategoryAndLocation: `${currentSuperCategory}${DEFAULT_LOCATION.locationForURL}`,
        distanceRadius,
      };
};

export default useSuperCategoryAndLocation;
