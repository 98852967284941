import React, {
  useState,
  Fragment,
  useReducer,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { AddPhotoAlternateOutlined } from '@mui/icons-material';
import { CircularProgress, Dialog, Slide } from '@mui/material';
import { EditorState, ContentState } from 'draft-js';
import cn from 'classnames';

import logUserActivity from '@ictlife/core/utils/logUserActivity';
import SnackInfo from '@ictlife/core/components/SnackInfo';
import ImageCropper from '@ictlife/core/components/image-cropper';
import compressImages from '@ictlife/core/utils/compressImages';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { selectStyles } from '@ictlife/marketplace/components/business/CreateOffering';
import dayjs from 'dayjs';
import ScheduleEvent from '@ictlife/marketplace/components/business/ScheduleEvent';
import useEventSchedule from '@ictlife/core/hooks/useEventSchedule';
import DraftTextEditor, {
  draftCompositeDecorator,
} from '../../utils/DraftTextEditor';
import ImageThumbnail from '../../utils/ImageThumbnail';

import { getMerchantTags } from '../../state/actions/tagsAction';
import {
  uploadPostMedia,
  publishPost,
  UpdatePostCaption,
  deletePostImage,
  getSpecificPost,
} from '../../state/actions/postsAction';
import {
  UploadPostMediaReducer,
  initialUploadPostMediaState,
  PublishPostReducer,
  initialPublishPostState,
  deletePostReducer,
  initialDeletePostState,
} from '../../state/reducers/postsReducer';

import Post from './Post';
import thumbsStyles from './styles/Thumbs.module.scss';
import addPostStyles from './styles/AddPost.module.scss';
import DealsCategory from './DealsCategory';

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddPost = ({
  posts,
  setPosts,
  postInfo,
  postUUID,
  editMode,
  openSnackBar,
  setPostsCount,
  handleItemChange,
  handleCloseDialog,
}) => {
  const dispatch = useDispatch();
  const [post, setPost] = useState();
  const [files, setFiles] = useState([]);
  const [proposedFiles, setproposedFiles] = useState([]);
  const [title, setTitle] = useState('');
  const [snackInfo, setSnackInfo] = useState({
    open: false,
    message: '',
    vertical: 'top',
    horizontal: 'center',
    severity: 'info',
  });
  const [postId, setPostId] = useState();
  const [publish, setPublish] = useState(true);

  const [activeThumbIndex, setActiveThumbIndex] = useState(0);
  const [disableImageRightScroll, setDisableImageRightScroll] = useState(true);
  const [disableImageLeftScroll, setDisableImageLeftScroll] = useState(true);
  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      merchant: { id: business_id, business_name, business_username },
    },
    merchantProfile: activeMerchant,
  } = merchantProfileState;
  const [previewPost, setPreviewPost] = useState({
    id: null,
    business_name,
    business_username,
    title: '',
    comments_count: 0,
    deleted_at: null,
    likes_count: 0,
    merchant_id: business_id,
    merchant_info: activeMerchant,
    merchant_post_assets: [],
    merchant_post_flag: null,
    merchant_post_tags: null,
    published_at: new Date().toISOString(),
    shares_count: {
      anonymous_users_share_count: null,
      merchant_post_id: null,
      registered_users_share_count: null,
      unique_registered_users_share_count: null,
    },
    status: 'active',
    suspended_at: null,
    url: '',
    user_id: null,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  });

  const [categoryTags, setCategoryTags] = useState([]);

  const [editorState, setEditorState] = useState(() => {
    if (files.length > 0) {
      return EditorState.createWithContent(
        ContentState.createFromText(
          files[activeThumbIndex].asset.description || ''
        ),
        draftCompositeDecorator
      );
    } else {
      return EditorState.createEmpty(draftCompositeDecorator);
    }
  });

  const [postEditorState, setPostEditorState] = useState(
    EditorState.createEmpty(draftCompositeDecorator)
  );

  const [uploadPostMediaState, dispatchUploadPostMedia] = useReducer(
    UploadPostMediaReducer,
    initialUploadPostMediaState
  );
  const [publishPostState, dispatchPublishPost] = useReducer(
    PublishPostReducer,
    initialPublishPostState
  );

  useEffect(() => {
    if (postUUID) {
      getSpecificPost(postUUID)
        .then(post => {
          setPost(post);

          if (post.merchant_post_tags)
            setCategoryTags(
              post.merchant_post_tags
                .filter(tag => tag.merchant_tag.type === 'category')
                .map(tag => ({
                  name: tag.merchant_tag.tag.name,
                  type: tag.merchant_tag.type,
                }))
            );

          if (post.merchant_post_assets) {
            const uploadedAssets = post.merchant_post_assets.map(asset => ({
              upload_success: true,
              ...asset,
            }));
            setFiles(uploadedAssets);

            if (uploadedAssets.length > 0)
              setEditorState(
                EditorState.createWithContent(
                  ContentState.createFromText(
                    uploadedAssets[0].asset.description || ''
                  ),
                  draftCompositeDecorator
                )
              );
          }

          setTitle(post.title);
          setPreviewPost(post);
          setPostId(post.id);
          setPostEditorState(
            EditorState.createWithContent(
              ContentState.createFromText(post.title),
              draftCompositeDecorator
            )
          );
          setLocation(
            post.addressLine && {
              latitude: post.latitude,
              longitude: post.longitude,
              addressLine: post.addressLine,
            }
          );
          setSelectedCategory(post.category_id);
        })
        .catch(error => {
          handleCloseDialog();
          openSnackBar({
            severity: 'error',
            message: `Failed to setup editor: ${error.response.data.error_message}`,
          });
        });
    }
  }, [handleCloseDialog, openSnackBar, postUUID]);

  const proposePostMedia = async acceptedFiles => {
    const compressedFiles = await compressImages(
      acceptedFiles.slice(0, 10 - files.length)
    );

    const addedFiles = compressedFiles.map(file => ({
      id: null,
      asset: {
        url: URL.createObjectURL(file),
        description: '',
      },
      file: file,
      upload_loading: false,
      upload_success: false,
      upload_error: false,
      ...file,
    }));
    setproposedFiles(addedFiles);
  };

  const [location, setLocation] = useState();

  const [selectedSuperCategory, setSelectedSuperCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const { frequency, byweekday, bymonthday } = useEventSchedule({
    time_attribute: post?.post_time_attribute,
    next_event_time: post?.event_time?.next_event_time,
    upcoming_event_times:
      post?.upcoming_events?.map(time => time.next_event_time) ?? [],
  });
  const [offeringTimeAttribute, setOfferingTimeAttribute] = useState(
    post?.post_time_attribute
      ? {
          ...post?.offering_time_attribute,
          frequency: frequency?.toUpperCase() ?? '',
          byweekday,
          bymonthday,
        }
      : {
          frequency: '',
          start_time: dayjs().hour(8).minute(0).second(0).format(),
          end_time: dayjs().hour(11).minute(0).second(0).format(),
          event_expiry_date: dayjs()
            .hour(23)
            .minute(59)
            .second(59)
            .add(1, 'year')
            .format(),
          byweekday: [],
          bymonthday: [],
        }
  );

  const CreateNewPost = () => {
    dispatchUploadPostMedia({ type: 'DEFAULT' });
    publish &&
      dispatchPublishPost({
        type: 'LOADING',
      });
    const newPostBody = {
      merchant_id: business_id,
      merchant_tags: categoryTags,
      post_time_attribute: {
        ...offeringTimeAttribute,
        recurring: offeringTimeAttribute.frequency !== '' ? true : false,
      },
    };

    if (title) {
      newPostBody.title = title;
    }

    if (selectedSuperCategory) {
      newPostBody.super_category_ids = [selectedSuperCategory.id];
    }

    if (selectedCategory) {
      newPostBody.category_id = selectedCategory.value;
    }

    if (location?.latitude) {
      newPostBody.latitude = location.latitude;
      newPostBody.longitude = location.longitude;
      newPostBody.addressLine = location.addressLine;
    }

    // Creates new post and adds images to it
    uploadPostMedia(
      dispatchUploadPostMedia,
      files,
      newPostBody,
      postId,
      activeMerchant
    );
  };

  if (uploadPostMediaState.isProgress) {
    if (uploadPostMediaState.postId) {
      setPostId(uploadPostMediaState.postId);
    }

    if (uploadPostMediaState.updatedPostsMedia) {
      setFiles(uploadPostMediaState.updatedPostsMedia);
    }

    dispatchUploadPostMedia({ type: 'LOADING' });
  }

  // UPDATE POST
  const [updateCaptionState, dispatchUpdateCaption] = useReducer(
    PublishPostReducer,
    initialPublishPostState
  );

  const handleSaveChanges = () => {
    const asset_captions = files.map(file => ({
      asset_id: file.asset.id,
      description: file.asset.description,
    }));
    let postBody = {
      merchant_id: business_id,
      title,
      merchant_tags: categoryTags,
      asset_captions,
      super_category_ids: selectedSuperCategory && [selectedSuperCategory.id],
      category_id: selectedCategory && selectedCategory.value,
      ...(location?.addressLine && {
        latitude: location.latitude,
        longitude: location.longitude,
        addressLine: location.addressLine,
      }),
      post_time_attribute: {
        ...offeringTimeAttribute,
        recurring: offeringTimeAttribute.frequency !== '' ? true : false,
      },
    };
    uploadPostMedia(
      dispatchUploadPostMedia,
      files,
      postBody,
      postId,
      activeMerchant
    );
    UpdatePostCaption(dispatchUpdateCaption, postBody, postId);
  };

  if (uploadPostMediaState.isError) {
    dispatchPublishPost({ type: 'DEFAULT' });
    dispatchUploadPostMedia({ type: 'CLEAR_LOOP' });
  }

  if (uploadPostMediaState.isSuccess) {
    if (postId && publish && !editMode) {
      publishPost(dispatchPublishPost, postId);
    } else {
      dispatchPublishPost({
        type: 'SUCCESS',
        post: {
          id: postId,
          total_images: files.length,
          title,
          post_likes: 0,
          created_at: Date(),
          merchant_post_assets: files.map(file => ({
            asset: {
              url: file.asset.url,
            },
          })),
        },
      });
    }
    dispatchUploadPostMedia({ type: 'DEFAULT' });
  }

  const merchantProfileSummaryState = useSelector(
    store => store.merchantProfileSummaryState
  );

  const {
    merchantProfile,
    merchantProfile: { total_posts_count },
  } = merchantProfileSummaryState;

  if (publishPostState.isSuccess) {
    getMerchantTags(dispatch, business_id, 'posts', 'promotion');

    // Record post creation activity
    if (!editMode) {
      if (total_posts_count === 0) {
        logUserActivity({
          action: 'merchant_web_business_first_post',
          dispatch,
        });
      }

      logUserActivity({
        action: 'merchant_web_post_created',
        dispatch,
      });
    } else {
      logUserActivity({
        action: 'merchant_web_business_edited_post',
        dispatch,
      });
    }

    // update post count in business profile summary
    if (publish) {
      const updateMerchantProfile = merchantProfile;
      updateMerchantProfile.total_posts_count =
        merchantProfile.total_posts_count + 1;

      dispatch({
        type: 'BUSINESS_PROFILE_SUMMARY_SUCCESS',
        merchantProfile: updateMerchantProfile,
      });
    }

    // Add the new post to list
    if (
      (!editMode && postInfo.status === 'active' && publish) ||
      (postInfo.status !== 'active' && !publish)
    ) {
      setPosts(posts => [publishPostState.post, ...posts]);
      setPostsCount && setPostsCount(count => ++count);
    }

    // For existing post (post edit) update properties
    if (editMode) {
      const newPost = {
        ...post,
        title,
        merchant_post_assets: [...files],
        merchant_post_tags: categoryTags.map(tag => ({
          merchant_tag: {
            tag: {
              name: tag.name,
            },
            type: tag.type,
          },
        })),
      };

      // Differentiate post edited from post list and one edited from specific post
      if (posts) {
        const oldPostIndex = posts.findIndex(post => post.id === postId);
        const newPosts = posts;
        newPosts.splice(oldPostIndex, 1, newPost);
        setPosts([...newPosts]);
      } else {
        dispatch({ type: 'GET_POST_SUCCESS', post: newPost });
      }
    }

    dispatchPublishPost({ type: 'DEFAULT' });
    setPostId();
    setFiles([]);
    setTitle('');
    setEditorState(EditorState.createEmpty(draftCompositeDecorator));
    setPostEditorState(EditorState.createEmpty(draftCompositeDecorator));
    setActiveThumbIndex(0);
    setCategoryTags([]);
    handleCloseDialog && handleCloseDialog(publishPostState.post.uuid);
    openSnackBar({
      severity: 'success',
      message: 'Changes Applied Successfully',
    });
  }

  const [postMediaError, setPostMediaError] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 5000000,
    onDropAccepted: acceptedFiles => {
      if (postMediaError !== '') {
        setPostMediaError('');
      }
      if (files.length < 10) {
        if (acceptedFiles.length > 10 - files.length) {
          setPostMediaError(
            `${
              acceptedFiles.length - (10 - files.length)
            } image(s) ignored because the maximum limit of 10 is exceeded, you may replace already added images.`
          );
        }
        proposePostMedia(acceptedFiles);
      } else {
        setPostMediaError(
          'Maximum number of images added, you may replace the ones already added'
        );
      }
    },
    onDropRejected: () =>
      setPostMediaError(
        'Select images below 5mbs, larger images and non-image files have been ignored.'
      ),
  });

  const handleImageCaption = caption => {
    setEditorState(() => {
      if (caption) {
        return EditorState.createWithContent(
          ContentState.createFromText(caption),
          draftCompositeDecorator
        );
      } else {
        return EditorState.createEmpty(draftCompositeDecorator);
      }
    });
  };

  const [deletePostImageState, dispatchDeletePostImage] = useReducer(
    deletePostReducer,
    initialDeletePostState
  );

  const removeItem = file => {
    let newFiles = files;
    let index = newFiles.indexOf(file);
    newFiles[index].upload_loading = true;
    if (file.id !== null) {
      deletePostImage(dispatchDeletePostImage, file);
    } else {
      dispatchDeletePostImage({ type: 'SUCCESS', deletedFile: file });
    }
  };

  if (deletePostImageState.isSuccess) {
    dispatchDeletePostImage({ type: 'DEFAULT' });
    let newFiles = files;
    let index = newFiles.indexOf(deletePostImageState.deletedFile);
    newFiles.splice(index, 1);
    URL.revokeObjectURL(deletePostImageState.deletedFile);
    setFiles([...newFiles]);
    const newPost = {
      ...post,
      merchant_post_assets: [...newFiles],
    };
    handleItemChange && handleItemChange(0);
    if (posts) {
      const oldPostIndex = posts.findIndex(post => post.id === postId);
      const newPosts = posts;
      newPosts.splice(oldPostIndex, 1, newPost);
      setPosts([...newPosts]);
    } else {
      dispatch({ type: 'GET_POST_SUCCESS', post: newPost });
    }
    setPreviewPost(post => ({
      ...post,
      merchant_post_assets: newFiles,
    }));
  }

  if (deletePostImageState.isError) {
    dispatchDeletePostImage({ type: 'ERROR_DISPLAY' });
    let newFiles = files;
    let index = newFiles.indexOf(deletePostImageState.file);
    newFiles[index].upload_loading = false;
    setSnackInfo({
      open: true,
      severity: 'error',
      message: deletePostImageState.errorMessage,
    });
  }

  const thumbs = files.map((file, index) => {
    return (
      <div
        key={file.asset.url}
        className={cn(thumbsStyles.thumbHolder, thumbsStyles.postPhotoHolder)}
      >
        <ImageThumbnail
          file={file}
          activeThumbIndex={activeThumbIndex}
          index={index}
          handleRetry={CreateNewPost}
          handleClickThumbnail={() => {
            handleImageCaption(file.asset.description);
            setActiveThumbIndex(index);
          }}
          showControls={!file.upload_loading && !uploadPostMediaState.isLoading}
          handleRemoveItem={() => {
            setPostMediaError('');
            dispatchUploadPostMedia({ type: 'DEFAULT' });
            if (files.length === 1 && editMode) {
              setSnackInfo(info => ({
                ...info,
                open: true,
                message:
                  'You require at least one image in a post, add a replacement before removing the last image',
              }));
              return;
            }
            if (
              index === activeThumbIndex && // it is the active thumb image and
              activeThumbIndex !== 0 // it's not the first thumb image
            ) {
              let index = activeThumbIndex - 1;
              handleImageCaption(files[index].asset.description);
              setActiveThumbIndex(index);
            } else if (
              activeThumbIndex === index && // it is the active thumb image at index 0 and
              files.length > 1 // there are other thumb images
            ) {
              let index = activeThumbIndex + 1;
              handleImageCaption(files[index].asset.description);
              setActiveThumbIndex(index);
            } else if (index < activeThumbIndex) {
              // It is not the active thumb image and it is before the active thumb image
              let index = activeThumbIndex - 1;
              setActiveThumbIndex(index);
            } else if (files.length === 1) {
              handleImageCaption('');
            }
            removeItem(file, files, setFiles);
          }}
        />
      </div>
    );
  });

  const handleChangeImageCaption = state => {
    setEditorState(state);
    const updatedCaption = state.getCurrentContent().getPlainText();
    const updateProposedFiles = files;
    updateProposedFiles[activeThumbIndex].asset.description = updatedCaption;

    const updatedPostAssets = previewPost.merchant_post_assets;

    updatedPostAssets[activeThumbIndex].asset.description = updatedCaption;

    const updatePreviewPost = {
      ...previewPost,
      merchant_post_assets: [...updatedPostAssets],
    };

    setPreviewPost({ ...updatePreviewPost });

    setFiles([...updateProposedFiles]);
  };

  const handleChangePostTitle = state => {
    setPostEditorState(state);
    const postTitle = state.getCurrentContent().getPlainText();
    setTitle(postTitle);
    setPreviewPost(post => ({ ...post, title: postTitle }));
  };

  const handleHorizontalScroll = (direction, elmClassName) => {
    const element = document.querySelector(elmClassName);
    const scrollLeft = element.scrollLeft;
    const offSet =
      direction === 'right'
        ? scrollLeft + element.clientWidth / 2
        : scrollLeft - element.clientWidth / 2;
    element.scroll({
      left: offSet,
      behavior: 'smooth',
    });
  };

  const thumbsContainerRef = useRef();

  const handleImagesScroll = useCallback(() => {
    const elm = thumbsContainerRef.current;

    const offsetLeft = elm.scrollLeft;
    const offsetRight = elm.scrollWidth - elm.clientWidth - offsetLeft;
    if (offsetLeft < 5) {
      !disableImageLeftScroll && setDisableImageLeftScroll(true);
    } else {
      disableImageLeftScroll && setDisableImageLeftScroll(false);
    }

    if (offsetRight < 5) {
      !disableImageRightScroll && setDisableImageRightScroll(true);
    } else {
      disableImageRightScroll && setDisableImageRightScroll(false);
    }
  }, [disableImageLeftScroll, disableImageRightScroll]);

  useEffect(() => {
    const elm = thumbsContainerRef.current;
    handleImagesScroll();
    elm &&
      elm.addEventListener('scroll', handleImagesScroll, { passive: true });

    return () => {
      elm && elm.removeEventListener('scroll', handleImagesScroll);
    };
  }, [handleImagesScroll]);

  const closeDialog = () => {
    const addedImageIndex = files.findIndex(file => file.upload_success);

    // Ensure a post has at least one image before closing edit popper
    if (editMode && addedImageIndex === -1) {
      const newPostBody = {
        merchant_id: business_id,
        merchant_tags: categoryTags,
      };

      if (title) {
        newPostBody.title = title;
      }

      uploadPostMedia(
        dispatchUploadPostMedia,
        files,
        newPostBody,
        postId,
        activeMerchant
      );
    } else {
      handleCloseDialog();
    }
  };

  return (
    <Fragment>
      <div className={addPostStyles.addPhotoContainer}>
        <div className={addPostStyles.newPostContent}>
          <div className="create">
            <div className={addPostStyles.newPostHeader}>
              <div
                className={addPostStyles.back}
                onClick={() => handleCloseDialog()}
              >
                <span className={cn('material-icons', addPostStyles.icon)}>
                  &#xe5c4;
                </span>
              </div>
              <span>{editMode ? 'Edit Deal' : 'Create a new deal'}</span>
            </div>
            <div
              className={addPostStyles.textfieldContainer}
              id="textfield-container"
            >
              <div className={addPostStyles.header}>
                <div className={addPostStyles.title}>Deal Title *</div>
                <div className={addPostStyles.subText}>
                  You can include
                  <span className={addPostStyles.hashTag}> #hashtags</span> to
                  group your deals e.g.{' '}
                  <span className={addPostStyles.hashTag}>
                    #mothersday #blackfriday #amazingdiscounts
                  </span>
                </div>
              </div>
              <DraftTextEditor
                editorState={postEditorState}
                setEditorState={handleChangePostTitle}
              />
            </div>
            <div className="assets-holder">
              <div className={addPostStyles.header}>
                <div className={addPostStyles.title}>Add Images *</div>

                <div className={addPostStyles.subText}>
                  You require at least one image and at most ten images to make
                  a post.
                </div>
              </div>
              <div className={thumbsStyles.thumbsWrapper}>
                {!disableImageRightScroll && (
                  <div
                    className={thumbsStyles.rightScrollControl}
                    onClick={() =>
                      handleHorizontalScroll(
                        'right',
                        `.${thumbsStyles.thumbsContainer}`
                      )
                    }
                  >
                    <span className={cn('material-icons', thumbsStyles.icon)}>
                      &#xeaaa;
                    </span>
                  </div>
                )}
                {!disableImageLeftScroll && (
                  <div
                    className={thumbsStyles.leftScrollControl}
                    onClick={() =>
                      handleHorizontalScroll(
                        'left',
                        `.${thumbsStyles.thumbsContainer}`
                      )
                    }
                  >
                    <span className={cn('material-icons', thumbsStyles.icon)}>
                      &#xeaa7;
                    </span>
                  </div>
                )}
                <div
                  ref={thumbsContainerRef}
                  className={thumbsStyles.thumbsContainer}
                >
                  {thumbs}
                  <div
                    className={cn(
                      thumbsStyles.thumbHolder,
                      thumbsStyles.postPhotoHolder
                    )}
                  >
                    <div
                      {...getRootProps({
                        className: 'add-photo-dropzone',
                      })}
                    >
                      <input {...getInputProps()} />
                      <AddPhotoAlternateOutlined htmlColor="#a5b2c1" />
                      <span>
                        Drag &apos;n drop or click here, to add post images
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {postMediaError !== '' && (
              <div className="error-section">{postMediaError}</div>
            )}
            {files.length > 0 && (
              <div className="textfield-container" id="textfield-container">
                <div className={addPostStyles.header}>
                  <div className={addPostStyles.title}>
                    Describe the selected image (click on a different image to
                    select)
                  </div>
                  <div className={addPostStyles.subText}>
                    You can include{' '}
                    <span className={addPostStyles.hashTag}>#hashtags</span> to
                    group related posts. E.g.{' '}
                    <span className={addPostStyles.hashTag}>
                      #leather #casual
                    </span>
                    .
                  </div>
                </div>
                <DraftTextEditor
                  editorState={editorState}
                  setEditorState={handleChangeImageCaption}
                />
              </div>
            )}

            <div
              className={addPostStyles.textfieldContainer}
              id="textfield-container"
            >
              <div className={addPostStyles.header}>
                <div className={addPostStyles.title}>Select Location</div>
              </div>
              <div>
                <GooglePlacesAutocomplete
                  debounce={700}
                  selectProps={{
                    value: location
                      ? {
                          label: location.addressLine,
                          value: location.addressLine,
                        }
                      : null,
                    onChange: (data, actionMeta) => {
                      if (actionMeta.action === 'clear') {
                        setLocation();
                      }
                      if (data) {
                        geocodeByPlaceId(data.value.place_id)
                          .then(results => {
                            const latitude = results[0].geometry.location.lat();
                            const longitude =
                              results[0].geometry.location.lng();
                            setLocation({
                              latitude,
                              longitude,
                              addressLine: data.value.description,
                            });
                          })
                          .catch(error => console.error(error));
                      }
                    },
                    placeholder: 'Type to search location',
                    menuPlacement: 'auto',
                    openMenuOnClick: false,
                    isClearable: true,
                    components: {
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    },
                    styles: selectStyles,
                    theme: theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#ccc',
                        primary: '#e51a23',
                      },
                    }),
                  }}
                />
              </div>
            </div>

            {(!postUUID || (postUUID && post)) && (
              <DealsCategory
                selectedSuperCategory={selectedSuperCategory}
                setSelectedSuperCategory={setSelectedSuperCategory}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                post={post}
              />
            )}

            <div
              className={addPostStyles.textfieldContainer}
              id="textfield-container"
            >
              <div className={addPostStyles.header}>
                <div className={addPostStyles.title}>Schedule deal</div>
              </div>
              <ScheduleEvent
                offeringTimeAttribute={offeringTimeAttribute}
                setOfferingTimeAttribute={setOfferingTimeAttribute}
              />
            </div>

            <Fragment>
              {uploadPostMediaState.errorMessage && (
                <div className="error-section">
                  {uploadPostMediaState.errorMessage}
                </div>
              )}
              {publishPostState.isError && (
                <div className="error-section">
                  {publishPostState.errorMessage}. Post has been drafted.
                </div>
              )}
              <div className={addPostStyles.postButton}>
                <div className={cn(addPostStyles.actionable, 'mt-8')}>
                  <div
                    className={cn(
                      addPostStyles.button,
                      addPostStyles.cancelButton
                    )}
                  >
                    <button
                      className="custom-button default outlined lc"
                      onClick={closeDialog}
                      disabled={
                        publishPostState.isLoading ||
                        updateCaptionState.isLoading
                      }
                    >
                      Discard post
                    </button>
                  </div>
                  {!editMode && (
                    <div className={cn(addPostStyles.button, 'button')}>
                      <button
                        className="custom-button outlined lc"
                        onClick={() => {
                          setPublish(false);
                          CreateNewPost();
                        }}
                        disabled={
                          files.length < 1 ||
                          publishPostState.isLoading ||
                          updateCaptionState.isLoading ||
                          uploadPostMediaState.isLoading ||
                          !title
                        }
                      >
                        {uploadPostMediaState.errorMessage && !publish
                          ? 'Retry'
                          : 'Save as Draft'}
                      </button>
                      {!publish && uploadPostMediaState.isLoading && (
                        <CircularProgress size={25} className="button-loader" />
                      )}
                    </div>
                  )}
                  <div className={cn(addPostStyles.button, 'button')}>
                    <button
                      className="custom-button"
                      disabled={
                        files.length < 1 ||
                        publishPostState.isLoading ||
                        updateCaptionState.isLoading ||
                        uploadPostMediaState.isLoading ||
                        !title
                      }
                      onClick={() => {
                        if (editMode) {
                          handleSaveChanges();
                        } else {
                          !publish && setPublish(true);
                          CreateNewPost();
                        }
                      }}
                    >
                      {(uploadPostMediaState.errorMessage ||
                        publishPostState.isError) &&
                      publish
                        ? 'Retry'
                        : editMode
                        ? 'Publish Changes'
                        : 'Publish Post'}
                    </button>
                    {((publishPostState.isLoading && publish) ||
                      (uploadPostMediaState.isLoading && publish) ||
                      updateCaptionState.isLoading) && (
                      <CircularProgress size={25} className="button-loader" />
                    )}
                  </div>
                </div>
              </div>
              {(files.length === 0 || !title) && (
                <div className={addPostStyles.note}>
                  * Post title and at least one image required to publish or
                  create draft
                </div>
              )}
            </Fragment>
          </div>
          <div className={addPostStyles.separator}></div>
          <div className={addPostStyles.preview}>
            <h1>Preview</h1>
            <div className="posts-container">
              <Post
                post={previewPost}
                setSnackInfo={setSnackInfo}
                postInfo={postInfo}
                hideCommentsSection={true}
                isPreviewSection={true}
              />
            </div>
          </div>
        </div>
      </div>
      {proposedFiles.length > 0 && (
        <Dialog
          open={true}
          TransitionComponent={Transition}
          className="override"
          maxWidth="lg"
        >
          <ImageCropper
            cropFiles={proposedFiles.map(file => file.asset.url)}
            setCropFiles={setproposedFiles}
            proposedFiles={proposedFiles}
            onCropComplete={(croppedFiles, assetDescriptions) => {
              const previewFiles = croppedFiles.map((file, index) => ({
                asset: {
                  url: URL.createObjectURL(file),
                  status: 'active',
                  description: assetDescriptions[index],
                },
              }));

              setPreviewPost(post => ({
                ...post,
                merchant_post_assets: [
                  ...(post.merchant_post_assets || []),
                  ...previewFiles,
                ],
              }));
              const transformedFiles = proposedFiles.map((file, index) => ({
                ...file,
                asset: {
                  url: URL.createObjectURL(croppedFiles[index]),
                  status: 'active',
                  description: assetDescriptions[index],
                },
                file: croppedFiles[index],
              }));
              setFiles(files => [...files, ...transformedFiles]);
              setproposedFiles([]);
            }}
          />
        </Dialog>
      )}
      <SnackInfo
        snackInfo={snackInfo}
        handleClose={() =>
          setSnackInfo(snackInfo => ({ ...snackInfo, open: false }))
        }
      />
    </Fragment>
  );
};

export default AddPost;
