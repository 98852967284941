import {
  LOADING,
  SUCCESS,
  ERROR,
  BUSINESS_KEYWORDS,
  BUSINESS_KEYWORDS_SUCCESS,
  BUSINESS_KEYWORDS_ERROR,
} from '../dispatchTypes';

export const initialCreateBusinessKeywordReducer = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  businessKeywords: [],
};

export const createBusinessKeywordReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        businessKeywords: action.businessKeywords,
      };
    }
    case ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialCreateBusinessKeywordReducer;
  }
};

export const deleteBusinessKeywordReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }
    case ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialCreateBusinessKeywordReducer;
  }
};

export const initialGetBusinessKeywordsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  businessKeywords: [],
};

export const getBusinessKeywordsReducer = (
  state = initialGetBusinessKeywordsState,
  action
) => {
  switch (action.type) {
    case BUSINESS_KEYWORDS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BUSINESS_KEYWORDS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        businessKeywords: action.businessKeywords,
      };
    }
    case BUSINESS_KEYWORDS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};
