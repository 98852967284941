import { Dialog } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { updateTimeAttributeById } from '../../../state/actions/timeAttributeAction';
import ScheduleEvent from '../ScheduleEvent';

const ScheduleEventDialog = ({
  setOfferingTimeAttributes,
  setOpenEventScheduleData,
  openEventScheduleData,
  offeringTimeAttributes,
  isExperience,
}) => {
  const { timeAttributeIdx, timeAttributeForEdit } = openEventScheduleData;

  const [timeAttribute, setTimeAttribute] = useState(
    timeAttributeForEdit || {
      frequency: isExperience ? 'WEEKLY' : '',
      start_time: dayjs().hour(8).minute(0).second(0).format(),
      end_time: dayjs()
        .hour(isExperience ? 18 : 11)
        .minute(0)
        .second(0)
        .format(),
      event_expiry_date: isExperience
        ? dayjs().add(5, 'years').hour(18).minute(0).second(0).format()
        : null,
      byweekday: [],
      bymonthday: [],
    }
  );

  const handleAddTimeSchedule = () => {
    const updateTimeFromList = () => {
      const updatedTimeAttributes = offeringTimeAttributes;
      updatedTimeAttributes.splice(timeAttributeIdx, 1, timeAttribute);
      setOfferingTimeAttributes([...updatedTimeAttributes]);
    };
    if (timeAttributeForEdit) {
      const id = timeAttributeForEdit.id;
      if (id) {
        updateTimeAttributeById({ id, payload: timeAttribute }).then(() =>
          updateTimeFromList()
        );
      } else {
        updateTimeFromList();
      }
    } else {
      setOfferingTimeAttributes(timeAttributes => [
        ...timeAttributes,
        {
          ...timeAttribute,
          event_expiry_date: timeAttribute.frequency
            ? timeAttribute.event_expiry_date
            : null,
        },
      ]);
    }
    setOpenEventScheduleData();
  };
  return (
    <Dialog
      open={true}
      onClose={() => setOpenEventScheduleData()}
      fullWidth
      maxWidth="sm"
    >
      <div className="py-4 px-8">
        <div className="text-lg font-semibold">Add time and date</div>
        <ScheduleEvent
          timeAttribute={timeAttribute}
          setTimeAttribute={setTimeAttribute}
          isExperience={isExperience}
        />
        <div className="flex space-x-4 justify-end mt-8">
          <div
            className="custom-button outlined"
            onClick={() => setOpenEventScheduleData()}
          >
            Cancel
          </div>
          <div className="custom-button" onClick={handleAddTimeSchedule}>
            {timeAttributeForEdit ? 'Save Changes' : 'Add'}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ScheduleEventDialog;
