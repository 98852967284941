import {
  GET_BUSINESS_REVIEWS,
  GET_BUSINESS_REVIEWS_SUCCESS,
  GET_BUSINESS_REVIEWS_ERROR,
  GET_REVIEWS_SUMMARY,
  GET_REVIEWS_SUMMARY_SUCCESS,
  GET_REVIEWS_SUMMARY_ERROR,
  GET_REVIEWS_COMMENTS,
  REVIEWS_COMMENTS_SUCCESS,
  REVIEWS_COMMENTS_ERROR,
  RESPOND_REVIEW,
  RESPOND_REVIEW_SUCCESS,
  RESPOND_REVIEW_ERROR,
  LIKE_REVIEW,
  LIKE_REVIEW_SUCCESS,
  LIKE_REVIEW_ERROR,
  DELETE_REVIEW_COMMENT,
  DELETE_REVIEW_COMMENT_SUCCESS,
  DELETE_REVIEW_COMMENT_ERROR,
  GET_REVIEWS_BREAKDOWN,
  GET_REVIEWS_BREAKDOWN_SUCCESS,
  GET_REVIEWS_BREAKDOWN_ERROR,
  GET_REVIEW_TAGS,
  GET_REVIEW_TAGS_SUCCESS,
  GET_REVIEW_TAGS_ERROR,
} from '../dispatchTypes';

const initialBusinessReviewsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  transaction_reviews: [],
  transaction_review: null,
  reviews_pagination: {
    count: 0,
    per: 20,
    page: 1,
  },
};

export const businessReviewsReducer = (
  state = initialBusinessReviewsState,
  action
) => {
  switch (action.type) {
    case GET_BUSINESS_REVIEWS: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case GET_BUSINESS_REVIEWS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        transaction_reviews: action.transaction_reviews || [],
        transaction_review: action.transaction_review || null,
        reviews_pagination: action.reviews_pagination,
      };
    }
    case GET_BUSINESS_REVIEWS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }

    case 'RESET_BUSINESS_REVIEWS': {
      return {
        ...state,
        isSuccess: false,
        isError: false,
      };
    }
    default:
      return state;
  }
};

export const initialReviewsSummaryState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  review_summary: {
    merchant_average_rating: 0,
    merchant_reviews_count: 0,
    merchant_reviews_count_per_rating: {
      rating_five: null,
      rating_four: null,
      rating_three: null,
      rating_two: null,
      rating_one: null,
    },
  },
};

export const reviewsSummaryReducer = (
  state = initialReviewsSummaryState,
  action
) => {
  switch (action.type) {
    case GET_REVIEWS_SUMMARY: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case GET_REVIEWS_SUMMARY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        review_summary: action.review_summary,
      };
    }
    case GET_REVIEWS_SUMMARY_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }

    case 'RESET_REVIEWS_SUMMARY': {
      return {
        ...state,
        isSuccess: false,
        isError: false,
      };
    }
    default:
      return state;
  }
};

export const initialReviewCommentsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  transaction_review_comments: [],
  review_comments_pagination: {
    count: 0,
    per: 20,
    page: 1,
  },
};

export const reviewCommentsReducer = (state, action) => {
  switch (action.type) {
    case GET_REVIEWS_COMMENTS: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case REVIEWS_COMMENTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        transaction_review_comments: action.transaction_review_comments,
        review_comments_pagination: action.review_comments_pagination,
      };
    }
    case REVIEWS_COMMENTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }

    case 'RESET_REVIEWS_COMMENTS': {
      return {
        ...state,
        isSuccess: false,
        isError: false,
      };
    }
    default:
      return state;
  }
};

export const initialRespondReviewState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const respondReviewReducer = (state, action) => {
  switch (action.type) {
    case RESPOND_REVIEW: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case RESPOND_REVIEW_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        reply: action.reply,
      };
    }
    case RESPOND_REVIEW_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }

    case 'RESET_REVIEWS_RESPONSE': {
      return {
        ...state,
        isSuccess: false,
        isError: false,
        errorMessage: '',
      };
    }
    default:
      return state;
  }
};

export const initialReviewsLikeState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const likeReviewReducer = (state, action) => {
  switch (action.type) {
    case LIKE_REVIEW: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LIKE_REVIEW_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        likeId: action.likeId,
      };
    }
    case LIKE_REVIEW_ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
        rollBackId: action.rollBackId,
      };
    }
    default:
      return initialReviewsLikeState;
  }
};

export const initialDeleteReviewCommentState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const deleteReviewCommentReducer = (state, action) => {
  switch (action.type) {
    case DELETE_REVIEW_COMMENT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DELETE_REVIEW_COMMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        deletedComment: action.deletedComment,
      };
    }
    case DELETE_REVIEW_COMMENT_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialDeleteReviewCommentState;
  }
};

export const initialReviewsBreakdown = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const reviewBreakdownReducer = (state, action) => {
  switch (action.type) {
    case GET_REVIEWS_BREAKDOWN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_REVIEWS_BREAKDOWN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        transaction_review_ratings: action.transaction_review_ratings,
      };
    }
    case GET_REVIEWS_BREAKDOWN_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialReviewsBreakdown;
  }
};

export const initialReviewTagsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  reviewTags: [],
};

export const reviewTagsReducer = (state = initialReviewTagsState, action) => {
  switch (action.type) {
    case GET_REVIEW_TAGS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_REVIEW_TAGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        reviewTags: action.reviewTags,
      };
    }
    case GET_REVIEW_TAGS_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};
