import useSWR from 'swr';
import axiosConfig from '../../config/axios';

export const getBusinesses = async ({
  account_type,
  search,
  per,
  page = 1,
  location,
  filter_tag,
  business_category,
  user_feed,
  search_terms,
  merchant_account_status,
}) => {
  const response = await axiosConfig.get('/merchants', {
    params: {
      per,
      account_type,
      search,
      page,
      latitude: location?.latitude,
      longitude: location?.longitude,
      radius: location?.radius,
      filter_tag,
      business_category,
      user_feed,
      search_terms,
      merchant_account_status,
    },
  });
  return response.data;
};

/**
 * SWR hook for getting businesses
 *
  account_type,
  search,
  per,
  page = 1,
  location,
  filter_tag,
  business_category,
  user_feed,
  search_terms,
 * @param {*} Object
 * @returns
 */

export const useBusinesses = ({
  account_type,
  search,
  per,
  page = 1,
  latitude,
  longitude,
  filter_tag,
  business_category,
  user_feed,
  search_terms,
  preventFetch,
  onSuccess,
  super_category_slug,
  radius,
}) => {
  const { data, error } = useSWR(
    preventFetch
      ? null
      : {
          url: '/merchants',
          account_type,
          search,
          per,
          page,
          latitude,
          longitude,
          filter_tag,
          business_category,
          user_feed,
          search_terms,
          super_category_slug,
          radius,
        },
    ({ url }) =>
      axiosConfig.get(url, {
        params: {
          account_type,
          search,
          per,
          page,
          latitude,
          longitude,
          radius,
          filter_tag,
          business_category,
          user_feed,
          search_terms,
          super_category_slug,
        },
      }),
    {
      onSuccess: onSuccess,
    }
  );

  return {
    businesses: data?.data,
    error,
    loading: !data && !error,
  };
};

export const useOfferingBusinesses = ({
  super_category_slug,
  preventFetch,
  parent_category_slug,
  onSuccess,
  latitude,
  longitude,
  radius,
  page,
}) => {
  const { data, error } = useSWR(
    preventFetch
      ? null
      : {
          url: '/offering_merchants',
          parent_category_slug,
          super_category_slug,
          latitude,
          longitude,
          radius,
          page,
        },
    ({ url }) =>
      axiosConfig.get(url, {
        params: {
          parent_category_slug,
          super_category_slug,
          latitude,
          longitude,
          radius,
          page,
        },
      }),
    {
      onSuccess: onSuccess,
    }
  );

  return {
    businesses: data?.data,
    error,
    loading: !data && !error,
  };
};

export const getBusinessByUsername = async ({ business_username }) => {
  const response = await axiosConfig.get(
    `/merchant_profiles/${business_username}`
  );
  return response.data;
};

export const useBusinessProfile = ({ business_username }) => {
  const { data, isValidating } = useSWR(
    business_username ? `/merchant_profiles/${business_username}` : null,
    url => axiosConfig.get(url)
  );

  return {
    merchant: data?.data,
    isLoading: isValidating && !data,
  };
};
