import React from 'react';
import cn from 'classnames';
import AppImage from '@ictlife/core/components/app-image/AppImage';
import styles from './styles/AppAvatar.module.scss';

/**
 *
 * @param {string} props.src Image source
 * @param {string} props.alt Image alt text
 * @param {string} props.applyInitialsTo A name to be initialized when an image is not available
 * @param {string} props.priority True if the image needs to be preloaded
 * @param {string} props.blur_hash Image blur hash
 * @param {string | Boolean} props.showBadge Image blur hash
 * @returns {HTMLDivElement} An avatar with an image or name initial
 */

const AppAvatar = props => {
  return (
    <div className="relative h-full w-full">
      <Avatar {...props} />
      {props.showBadge && (
        <span
          className="material-icons text-primary absolute right-0 bottom-0 text-[1.2em]"
          title="ICTLife merchant"
        >
          &#xef76;
        </span>
      )}
    </div>
  );
};

const Avatar = ({ src, alt, applyInitialsTo, priority, blur_hash }) => {
  if (src) {
    return (
      <div className={styles.avatarHolder}>
        <AppImage
          asset={{
            url: src,
            blur_hash,
            description: alt,
          }}
          priority={priority}
        />
      </div>
    );
  }

  if (applyInitialsTo && applyInitialsTo.replace(/\s+/g, '') !== '') {
    return (
      <div className={cn(styles.avatarHolder, 'theme-text')}>
        {applyInitialsTo
          .match(/\b(\w)/g)
          .slice(0, 2)
          .join('')
          .toUpperCase()}
      </div>
    );
  } else if (alt && alt.replace(/\s+/g, '') !== '') {
    return (
      <div className={cn(styles.avatarHolder, 'theme-text')}>
        {alt
          .match(/\b(\w)/g)
          .slice(0, 2)
          .join('')
          .toUpperCase()}
      </div>
    );
  }

  return <div className={styles.avatarHolder} />;
};

export default AppAvatar;
