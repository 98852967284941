import useSWR from 'swr';
import axiosConfig from '../../config/axios';

export const useNotifications = ({
  merchantId,
  userId,
  cursor,
  per,
  onSuccess,
}) => {
  const { data, isValidating, error, mutate } = useSWR(
    !userId && !merchantId
      ? null
      : {
          url: merchantId
            ? `/merchants/${merchantId}/merchant_notifications`
            : `/users/${userId}/notifications`,
          cursor,
          per,
          userId,
          merchantId,
        },
    ({ url }) => axiosConfig.get(url, { params: { per, cursor } }),
    {
      onSuccess: data => onSuccess(data.data),
    }
  );
  return {
    notifications: data?.data,
    isLoading: isValidating,
    error,
    mutate,
  };
};

export const editNotificationStatus = async ({ user_id, payload }) => {
  const response = await axiosConfig.put(
    `/users/${user_id}/notifications`,
    payload
  );
  return { ...response.data, status: payload.status };
};
