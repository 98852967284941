export const initialAboutBusinessState = {
  businesses: {
    merchant_infos: [],
    pagination: {
      page: null,
      per: null,
      count: null,
    },
  },
};

export const businessesReducer = (
  state = initialAboutBusinessState,
  action
) => {
  switch (action.type) {
    case 'SET_BUSINESS_LIST': {
      return {
        ...state,
        businesses: action.businesses,
      };
    }
    default:
      return state;
  }
};
