import SparkMD5 from 'spark-md5';

const computeChecksumMd5 = file => {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    let cursor = 0; // current cursor in file

    fileReader.onerror = function () {
      reject('MD5 computation failed - error reading the file');
    };

    // read chunk starting at `cursor` into memory
    function processChunk(chunk_start) {
      const chunk_end = Math.min(file.size, chunk_start + chunkSize);
      fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
    }

    fileReader.onload = function (e) {
      spark.append(e.target.result); // Accumulate chunk to md5 computation
      cursor += chunkSize; // Move past this chunk

      if (cursor < file.size) {
        // Enqueue next chunk to be accumulated
        processChunk(cursor);
      } else {
        resolve(btoa(spark.end(true)));
      }
    };

    processChunk(0);
  });
};

export default computeChecksumMd5;
