import React from 'react';

const NotificationRequestDialogBody = () => {
  return (
    <div className="dialog-body">
      Be the first to know when people:
      <div className="list">
        <div className="list-item">
          <span className="material-icons theme-text">&#xe5ca;</span>
          <span>Make a review of your business</span>
        </div>
        <div className="list-item">
          <span className="material-icons theme-text">&#xe5ca;</span>
          <span>Comment or like your posts</span>
        </div>
        <div className="list-item ">
          <span className="material-icons theme-text">&#xe5ca;</span>
          <span>Like or reply to your comments on reviews and posts</span>
        </div>
        <div className="list-item">
          <span className="material-icons theme-text">&#xe5ca;</span>
          <span>Message or mention your business</span>
        </div>
        <div className="list-item">
          <span className="material-icons theme-text">&#xe5ca;</span>
          <span>Share your business profile</span>
        </div>
      </div>
    </div>
  );
};
export default NotificationRequestDialogBody;
