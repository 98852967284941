export const WEEK_STARTS_ON = 1;
export const DAYS = {
  1: {
    name: 'Monday',
    value: 'MO',
  },
  2: {
    name: 'Tuesday',
    value: 'TU',
  },
  3: {
    name: 'Wednesday',
    value: 'WE',
  },
  4: {
    name: 'Thursday',
    value: 'TH',
  },
  5: {
    name: 'Friday',
    value: 'FR',
  },
  6: {
    name: 'Saturday',
    value: 'SA',
  },
  0: {
    name: 'Sunday',
    value: 'SU',
  },
};
export const DEFINED_DATES = {
  now: {
    label: 'Live Now',
    value: 'now',
  },
  today: {
    label: 'Today',
    value: 'today',
  },
  thisWeek: {
    label: 'This Week',
    value: 'this-week',
  },
  thisWeekend: {
    label: 'This Weekend',
    value: 'this-weekend',
  },
  thisMonth: {
    label: 'This Month',
    value: 'this-month',
  },
};
export const DAYS_ABBR = {
  MO: {
    name: 'Monday',
    value: 1,
  },
  TU: {
    name: 'Tuesday',
    value: 2,
  },
  WE: {
    name: 'Wednesday',
    value: 3,
  },
  TH: {
    name: 'Thursday',
    value: 4,
  },
  FR: {
    name: 'Friday',
    value: 5,
  },
  SA: {
    name: 'Saturday',
    value: 6,
  },
  SU: {
    name: 'Sunday',
    value: 0,
  },
};
