import useSWR from 'swr';
import axiosConfig from '../../config/axiosConfig';
import {
  GET_BUSINESS_REVIEWS,
  GET_BUSINESS_REVIEWS_SUCCESS,
  GET_BUSINESS_REVIEWS_ERROR,
  GET_REVIEWS_SUMMARY,
  GET_REVIEWS_SUMMARY_SUCCESS,
  GET_REVIEWS_SUMMARY_ERROR,
  GET_REVIEWS_COMMENTS,
  REVIEWS_COMMENTS_SUCCESS,
  REVIEWS_COMMENTS_ERROR,
  RESPOND_REVIEW,
  RESPOND_REVIEW_SUCCESS,
  RESPOND_REVIEW_ERROR,
  LIKE_REVIEW,
  LIKE_REVIEW_SUCCESS,
  LIKE_REVIEW_ERROR,
  DELETE_REVIEW_COMMENT,
  DELETE_REVIEW_COMMENT_SUCCESS,
  DELETE_REVIEW_COMMENT_ERROR,
  GET_REVIEWS_BREAKDOWN,
  GET_REVIEWS_BREAKDOWN_SUCCESS,
  GET_REVIEWS_BREAKDOWN_ERROR,
  GET_REVIEW_TAGS,
  GET_REVIEW_TAGS_SUCCESS,
  GET_REVIEW_TAGS_ERROR,
} from '../dispatchTypes';

export const getBusinessReviews = async ({
  pagination,
  filters,
  business_id,
}) => {
  const { per, page } = pagination;
  const {
    rating,
    date: { start_date, end_date },
    product,
  } = filters;

  const response = await axiosConfig.get(
    `/merchants/${business_id}/transaction_reviews`,
    {
      params: {
        per,
        page,
        rating,
        start_date,
        end_date,
        search: product,
      },
    }
  );

  return response;
};

export const getReviewsSummary = (dispatch, business_id) => {
  dispatch({
    type: GET_REVIEWS_SUMMARY,
  });

  axiosConfig
    .get(`/merchants/${business_id}/transaction_reviews_summary`)
    .then(response => {
      dispatch({
        type: GET_REVIEWS_SUMMARY_SUCCESS,
        review_summary: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_REVIEWS_SUMMARY_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_REVIEWS_SUMMARY_ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
      });
    });
};

export const getReviewComments = (dispatch, reviewId, pagination) => {
  dispatch({
    type: GET_REVIEWS_COMMENTS,
  });

  const { page, per } = pagination;

  axiosConfig
    .get(`/transaction_reviews/${reviewId}/comments`, {
      params: {
        page,
        per,
      },
    })
    .then(response => {
      const { transaction_review_comments, pagination } = response.data;

      dispatch({
        type: REVIEWS_COMMENTS_SUCCESS,
        transaction_review_comments: transaction_review_comments,
        review_comments_pagination: pagination,
      });
    })
    .catch(error => {
      dispatch({
        type: REVIEWS_COMMENTS_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: REVIEWS_COMMENTS_ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
      });
    });
};

export const useReviewComments = ({ review_id, per, page = 1, onSuccess }) => {
  const url = `/transaction_reviews/${review_id}/comments`;
  const { data, error, mutate, isValidating } = useSWR(
    {
      url,
      page,
      per,
    },
    () =>
      axiosConfig.get(url, {
        params: {
          per,
          page,
        },
      }),
    {
      onSuccess: data => {
        const pagination = {
          per,
          page,
          pagesCount: Math.ceil(data.data.pagination.count / per),
        };
        onSuccess(pagination);
      },
    }
  );

  return {
    comments: data?.data.transaction_review_comments,
    error,
    loading: isValidating,
    mutate,
  };
};

export const respondReview = (dispatch, bodyData) => {
  dispatch({
    type: RESPOND_REVIEW,
  });

  const url = bodyData.merchant_id
    ? `/merchants/${bodyData.merchant_id}/transaction_review_comments`
    : 'transaction_review_comments';
  axiosConfig
    .post(url, bodyData)
    .then(response => {
      dispatch({
        type: RESPOND_REVIEW_SUCCESS,
        reply: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: RESPOND_REVIEW_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: RESPOND_REVIEW_ERROR,
        errorMessage: 'Failed to send reply. Check your internet connection',
      });
    });
};

/**
 *
 * @param {function} dispatchLikeReview
 * @param {Object} bodyData
 * @param {bool} reviewLike
 */

export const likeReview = (dispatchLikeReview, bodyData, reviewLike) => {
  dispatchLikeReview({
    type: LIKE_REVIEW,
  });
  const url = reviewLike
    ? '/transaction_review_likes'
    : 'transaction_review_comment_likes';
  axiosConfig
    .post(url, bodyData)
    .then(response => {
      dispatchLikeReview({
        type: LIKE_REVIEW_SUCCESS,
        likeId: response.data.id,
      });
    })
    .catch(error => {
      dispatchLikeReview({
        type: LIKE_REVIEW_ERROR,
        rollBackId: null,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchLikeReview({
        type: LIKE_REVIEW_ERROR,
        rollBackId: null,
        errorMessage: 'Failed to like',
      });
    });
};

export const unLikeReview = (dispatchLikeReview, likeId, reviewLike) => {
  dispatchLikeReview({
    type: LIKE_REVIEW,
  });

  const url = reviewLike
    ? `/transaction_review_likes/${likeId}`
    : `transaction_review_comment_likes/${likeId}`;

  axiosConfig
    .delete(url)
    .then(() => {
      dispatchLikeReview({
        type: LIKE_REVIEW_SUCCESS,
        likeId: null,
      });
    })
    .catch(error => {
      dispatchLikeReview({
        type: LIKE_REVIEW_ERROR,
        rollBackId: likeId,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchLikeReview({
        type: LIKE_REVIEW_ERROR,
        rollBackId: likeId,
        errorMessage: 'Failed to unlike',
      });
    });
};

export const deleteReviewComment = (dispatchDeleteReviewComment, comment) => {
  dispatchDeleteReviewComment({
    type: DELETE_REVIEW_COMMENT,
  });

  axiosConfig
    .delete(`/transaction_review_comments/${comment.id}`)
    .then(() => {
      dispatchDeleteReviewComment({
        type: DELETE_REVIEW_COMMENT_SUCCESS,
        deletedComment: comment,
      });
    })
    .catch(error => {
      dispatchDeleteReviewComment({
        type: DELETE_REVIEW_COMMENT_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchDeleteReviewComment({
        type: DELETE_REVIEW_COMMENT_ERROR,
        errorMessage: 'Something went wrong. Refresh page and try again',
      });
    });
};

export const getReviewsBreakdown = (dispatch, reviewId) => {
  dispatch({
    type: GET_REVIEWS_BREAKDOWN,
  });

  axiosConfig
    .get(`/transaction_reviews/${reviewId}/ratings`)
    .then(response => {
      dispatch({
        type: GET_REVIEWS_BREAKDOWN_SUCCESS,
        transaction_review_ratings: response.data.transaction_review_ratings,
        pagination: response.data.pagination,
      });
    })
    .catch(error => {
      console.error(error.response);
      dispatch({
        type: GET_REVIEWS_BREAKDOWN_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_REVIEWS_BREAKDOWN_ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
      });
    });
};

export const getSpecificReview = (dispatch, review_uuid) => {
  dispatch({ type: GET_BUSINESS_REVIEWS });
  return axiosConfig
    .get(`/transaction_reviews/${review_uuid}/share_info`)
    .then(response => {
      dispatch({
        type: GET_BUSINESS_REVIEWS_SUCCESS,
        transaction_review: response.data,
        reviews_pagination: {
          count: 1,
          page: 1,
          per: 1,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_BUSINESS_REVIEWS_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_BUSINESS_REVIEWS_ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
      });
    });
};

export const getReviewTags = (dispatch, business_id) => {
  dispatch({
    type: GET_REVIEW_TAGS,
  });

  axiosConfig
    .get(`/merchants/${business_id}/transaction_review_tags`)
    .then(response => {
      dispatch({
        type: GET_REVIEW_TAGS_SUCCESS,
        reviewTags: response.data.transaction_review_tags,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_REVIEW_TAGS_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_REVIEW_TAGS_ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
      });
    });
};
