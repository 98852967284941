import { CircularProgress, Dialog } from '@mui/material';
import React from 'react';

const NewCategoryDialog = ({
  newCategoryObject,
  setNewCategoryObject,
  isLoading,
  handleAddNewCategory,
  errorCreatingCategory,
  setErrorCreatingCategory,
  handleClearInput,
}) => {
  const handleCloseDialog = () => {
    setNewCategoryObject();
    setErrorCreatingCategory();
    handleClearInput();
  };
  return (
    <Dialog open={true} onClose={handleCloseDialog}>
      <div className="dialog-wrapper">
        <div className="dialog-title">
          <span className="title">Add a new category</span>

          <span className="material-icons icon" onClick={handleCloseDialog}>
            &#xe5cd;
          </span>
        </div>
        <div
          className="dialog-body"
          style={{
            fontSize: '0.95em',
          }}
        >
          <div>
            Do you want to create a new category named{' '}
            <span style={{ fontWeight: '600' }}>
              {' '}
              &apos;{newCategoryObject.name}&apos;
            </span>{' '}
            under:
          </div>
          <div
            style={{
              fontWeight: '600',
              color: '#e52a23',
              fontSize: '0.9em',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span>{newCategoryObject.selectedSuperCategory.name}</span>
            {newCategoryObject.categoriesTree.map(category => (
              <div
                key={category.value}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <span className="material-icons">&#xe5cc;</span>
                <span>{category.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="dialog-controls">
          {errorCreatingCategory && (
            <div className="error-section">{errorCreatingCategory}</div>
          )}
          <div className="button">
            <button
              onClick={handleCloseDialog}
              className="custom-button outlined sm"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>

          <div className="button">
            <button
              className="custom-button sm"
              disabled={isLoading}
              onClick={() => handleAddNewCategory(newCategoryObject)}
            >
              CREATE CATEGORY &apos;{newCategoryObject.name}&apos;
            </button>
            {isLoading && (
              <CircularProgress size={25} className="button-loader" />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default NewCategoryDialog;
