import axiosConfig from '../../config/axiosConfig';
import {
  LOADING,
  SUCCESS,
  ERROR,
  ABOUT_BUSINESS,
  ABOUT_BUSINESS_SUCCESS,
  ABOUT_BUSINESS_ERROR,
  UPDATE_ABOUT_BUSINESS,
  UPDATE_ABOUT_BUSINESS_SUCCESS,
  UPDATE_ABOUT_BUSINESS_ERROR,
} from '../dispatchTypes';

const createMerchantAboutSection = (business_id, dispatch) => {
  axiosConfig
    .post('/merchant_about_sections', {
      merchant_id: business_id,
      description: '',
    })
    .then(() => {
      getAboutBusiness(dispatch, business_id);
    })
    .catch(err => {
      const errorMessage = err.response
        ? `${err.response.status}: ${err.response.data.error_message}`
        : 'Something went wrong. Check internet connection and try again';
      dispatch({
        type: ABOUT_BUSINESS_ERROR,
        errorMessage,
      });
    });
};

export const getAboutBusiness = (dispatch, business_id) => {
  dispatch({
    type: ABOUT_BUSINESS,
  });

  axiosConfig
    .get(`/merchants/${business_id}/merchant_about_sections`)
    .then(response => {
      dispatch({
        type: ABOUT_BUSINESS_SUCCESS,
        aboutBusiness: response.data,
      });
    })
    .catch(error => {
      if (error.response && error.response.status === 404)
        return createMerchantAboutSection(business_id, dispatch);

      const errorMessage = error.response
        ? error.response.data.error_message
        : 'Section could not be loaded at the moment ';
      dispatch({
        type: ABOUT_BUSINESS_ERROR,
        errorMessage,
      });
    });
};

export const updateAboutBusiness = (
  dispatchUpdateAboutBusiness,
  bodyData,
  merchantAboutSectionId
) => {
  dispatchUpdateAboutBusiness({
    type: UPDATE_ABOUT_BUSINESS,
  });

  axiosConfig
    .put(`/merchant_about_sections/${merchantAboutSectionId}`, bodyData)
    .then(response => {
      dispatchUpdateAboutBusiness({
        type: UPDATE_ABOUT_BUSINESS_SUCCESS,
        aboutBusiness: response.data,
      });
    })
    .catch(error => {
      const errorMessage = error.response
        ? error.response.data.error_message
        : 'Section could not be initialized at the moment';
      dispatchUpdateAboutBusiness({
        type: UPDATE_ABOUT_BUSINESS_ERROR,
        errorMessage,
      });
    });
};

export const deleteBusinessMedia = async (
  dispatchDeleteMedia,
  photoId,
  photoIndex
) => {
  dispatchDeleteMedia({
    type: LOADING,
  });
  try {
    await axiosConfig
      .delete(`/merchant_about_section_assets/${photoId}`)
      .then(() => {
        dispatchDeleteMedia({
          type: SUCCESS,
          photoIndex,
        });
      });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.error_message
      : 'Something went wrong, try again later!';

    dispatchDeleteMedia({
      type: ERROR,
      photoIndex,
      errorMessage,
    });
  }
};
