import useSWRImmutable from 'swr/immutable';

export const useLocationInformation = address => {
  const fetcher = url => fetch(url).then(res => res.json());

  const { data, isValidating } = useSWRImmutable(
    address ? `/api/geocode?address=${address}` : null,
    fetcher
  );

  return {
    locationInformation: data && data[0],
    isLoading: isValidating && !data,
  };
};
