export const initialAboutBusinessState = {
  socketComment: null,
  socketReply: null,
  socketPostLike: null,
  socketCommentLike: null,
};

export const socketEventsReducer = (
  state = initialAboutBusinessState,
  action
) => {
  switch (action.type) {
    case 'POST_COMMENT_IN_SOCKET': {
      return {
        ...state,
        socketComment: action.comment,
      };
    }
    case 'CLEAR_POST_COMMENT_IN_SOCKET': {
      return {
        ...state,
        socketComment: null,
      };
    }
    case 'POST_COMMENT_REPLY_IN_SOCKET': {
      return {
        ...state,
        socketReply: action.reply,
      };
    }
    case 'CLEAR_POST_COMMENT_REPLY_IN_SOCKET': {
      return {
        ...state,
        socketReply: null,
      };
    }
    case 'POST_LIKE_IN_SOCKET': {
      return {
        ...state,
        socketPostLike: action.like,
      };
    }
    case 'CLEAR_POST_LIKE_IN_SOCKET': {
      return {
        ...state,
        socketPostLike: null,
      };
    }
    case 'POST_COMMENT_LIKE_IN_SOCKET': {
      return {
        ...state,
        socketCommentLike: action.like,
      };
    }
    case 'CLEAR_POST_COMMENT_LIKE_IN_SOCKET': {
      return {
        ...state,
        socketCommentLike: null,
      };
    }
    default:
      return state;
  }
};
