import { LOADING, SUCCESS, ERROR } from '../dispatchTypes';

export const initialGetPostCommentsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  posts_comments: {
    merchant_post_comments: [],
    pagination: {
      count: 0,
    },
  },
  comment_replies: {
    merchant_post_comment_replies: [],
    pagination: {
      count: 0,
    },
  },
};

export const getPostCommentsReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isError: false,
        isLoading: true,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        posts_comments: action.posts_comments,
        comment_replies: action.comment_replies,
        asset_id: action.asset_id,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
        asset_id: action.asset_id,
      };
    }
    default:
      return initialGetPostCommentsState;
  }
};

export const initialCreateCommentState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const createPostCommentReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        post_comment: action.post_comment,
        comment_reply: action.comment_reply,
        asset_id: action.asset_id,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
        asset_id: action.asset_id,
      };
    }
    default:
      return initialCreateCommentState;
  }
};

export const initialDeletePostCommentState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const deletePostCommentReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
        replyIndex: action.replyIndex,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        replyIndex: action.replyIndex,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialDeletePostCommentState;
  }
};
