import axiosConfig from '../../config/axiosConfig';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_ERROR,
} from '../dispatchTypes';

export const getNotifications = ({
  dispatch,
  merchantId,
  userId,
  pagination,
  currentNotifications,
  cursor,
}) => {
  dispatch({
    type: GET_NOTIFICATIONS,
  });

  const url = merchantId
    ? `/merchants/${merchantId}/merchant_notifications`
    : `/users/${userId}/notifications`;

  axiosConfig
    .get(url, {
      params: {
        per: 100,
        cursor,
      },
    })
    .then(response => {
      let notifications = response.data;
      if (pagination) {
        notifications = [...currentNotifications, ...notifications];
      }
      dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        notifications,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_NOTIFICATIONS_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_NOTIFICATIONS_ERROR,
        errorMessage: 'Error getting notifications',
      });
    });
};

export const updateNotificationStatus = ({
  dispatch,
  notification,
  bodyData,
  currentNotifications,
  userId,
  merchantId,
  url,
}) => {
  dispatch({
    type: UPDATE_NOTIFICATION,
  });

  axiosConfig
    .put(url, bodyData)
    .then(() => {
      if (notification) {
        let notifications = currentNotifications;
        const replaceIndex =
          currentNotifications.merchant_notification_events.findIndex(
            notif => notif.id === notification.id
          );
        notifications.splice(replaceIndex, 1, {
          ...notification,
          status: bodyData.status,
        });
        notifications.total_unread_count =
          bodyData.status === 'read'
            ? --notifications.total_unread_count
            : ++notifications.total_unread_count;
        dispatch({
          type: GET_NOTIFICATIONS_SUCCESS,
          notifications,
        });
      } else {
        getNotifications({ dispatch, userId, merchantId });
      }
    })
    .catch(error => {
      dispatch({
        type: UPDATE_NOTIFICATION_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: UPDATE_NOTIFICATION_ERROR,
        errorMessage: 'Notification status update failed',
      });
    });
};
