import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const AppToolTip = props => {
  const useStylesBootstrap = makeStyles(() => ({
    arrow: {
      color: 'orange',
    },
    tooltip: {
      backgroundColor: 'orange',
      fontWeight: '600',
    },
  }));

  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
};

export default AppToolTip;
