import { clearMessagingDatabase } from '@ictlife/merchant/components/dashboard-blueprint/TopBar';
import Cookies from '../../cookies';

export default function unAuthorizedInterceptor(axiosConfig) {
  axiosConfig.interceptors.response.use(
    response => response,
    error => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.config.url !== '/forgot_password' &&
        error.response.config.url !== '/sessions'
      ) {
        let activeMerchant = localStorage.activeMerchant;
        localStorage.clear();

        Cookies.remove('ictl_token', {
          domain: process.env.NODE_ENV === 'production' && 'ictlife.com',
        });
        Cookies.remove('ictl_user', {
          domain: process.env.NODE_ENV === 'production' && 'ictlife.com',
        });
        Cookies.remove('ictl_token');
        Cookies.remove('ictl_user');
        Cookies.remove('ictl_push_notification_status');

        clearMessagingDatabase();

        if (activeMerchant) {
          localStorage.activeMerchant = activeMerchant;
        }
        window.location.replace(
          `/user/login?redirect=${window.location.pathname}`
        );
      }

      return Promise.reject(error);
    }
  );
}
