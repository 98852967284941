import { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import AppImage from '@ictlife/core/components/app-image/AppImage';
import ReactPlayer from 'react-player';

const AppCarousel = ({
  photos = [],
  handleItemChange,
  autoPlay,
  infiniteLoop,
  selectedItem,
  showArrows,
  transitionTime,
  showIndicators = true,
  errorMessage = 'Image could not be fully loaded',
  additionalProps = {},
  onClick,
}) => {
  useEffect(
    () => () => {
      // To avoid memory leaks
      photos.forEach(photo => URL.revokeObjectURL(photo));
    },
    [photos]
  );

  if (photos === null) {
    photos = [];
  }

  return (
    <Carousel
      {...additionalProps}
      showThumbs={false}
      showIndicators={
        showIndicators &&
        photos.length > 1 &&
        !photos.find(photo => photo?.asset?.content_type?.includes('video/'))
      }
      showStatus={false}
      showArrows={showArrows}
      transitionTime={transitionTime}
      interval={4000}
      selectedItem={selectedItem}
      swipeScrollTolerance={50}
      preventMovementUntilSwipeScrollTolerance
      onChange={handleItemChange}
      swipeable={false}
      autoPlay={autoPlay}
      infiniteLoop={infiniteLoop}
      renderArrowPrev={(onClickHandler, hasPrev) => (
        <div className="prev-section min-w-[1.5rem]">
          <div
            onClick={onClickHandler}
            title="Previous Image"
            className="z-10 h-1/2 pr-4 grid items-center"
          >
            {hasPrev && (
              <span className="material-icons shadow-sm">&#xeaa7;</span>
            )}
          </div>
        </div>
      )}
      renderArrowNext={(onClickHandler, hasNext) => (
        <div className="next-section min-w-[1.5rem]">
          <div
            onClick={onClickHandler}
            title="Next Image"
            className="z-10 h-1/2 pl-4 grid items-center"
          >
            {hasNext && (
              <span className="material-icons shadow-sm">&#xeaaa;</span>
            )}
          </div>
        </div>
      )}
    >
      {photos.map((photo, index) => {
        const asset = photo?.asset || photo;
        const itsAVideoFile = asset?.content_type?.includes('video/');
        return (
          <div
            key={index}
            onClick={() => !itsAVideoFile && onClick && onClick(index)}
            className="h-full w-full"
          >
            {itsAVideoFile ? (
              <div className="grid content-center h-full w-full bg-black">
                <ReactPlayer
                  url={asset.url}
                  controls
                  width="auto"
                  height="100%"
                  style={{
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    justifyItems: 'center',
                  }}
                />
              </div>
            ) : (
              <AppImage
                asset={asset}
                message={{ error: errorMessage }}
                isStaticImport={photo.isStaticImport}
              />
            )}
          </div>
        );
      })}
    </Carousel>
  );
};

export default AppCarousel;
