import { CircularProgress, Dialog } from '@mui/material';
import React from 'react';

const DeleteAttributeDialog = ({
  deleteAttribute,
  setDeleteAttribute,
  isLoading,
  handleDeleteCategoryAttributes,
  errorDeletingAttribute,
  setErrorDeletingAttribute,
  handleClearInput,
}) => {
  const handleCloseDialog = () => {
    setDeleteAttribute();
    setErrorDeletingAttribute();
    handleClearInput();
  };
  return (
    <Dialog open={true} onClose={handleCloseDialog}>
      <div className="dialog-wrapper">
        <div className="dialog-title">
          <span className="title">Delete attribute</span>

          <span className="material-icons icon" onClick={handleCloseDialog}>
            &#xe5cd;
          </span>
        </div>
        <div
          className="dialog-body"
          style={{
            fontSize: '0.95em',
          }}
        >
          <div>
            Are you sure you want to delete{' '}
            <span style={{ fontWeight: '600' }}>
              {' '}
              &apos;{deleteAttribute.label}&apos;
            </span>{' '}
            attribute under:
          </div>
          <div
            className="slug-text"
            style={{
              color: '#e52a23',
            }}
          >
            <span>{deleteAttribute?.slug}</span>
          </div>
        </div>
        <div className="dialog-controls">
          {errorDeletingAttribute && (
            <div className="error-section">{errorDeletingAttribute}</div>
          )}
          <div className="button">
            <button
              onClick={handleCloseDialog}
              className="custom-button outlined sm"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>

          <div className="button">
            <button
              className="custom-button sm"
              disabled={isLoading}
              onClick={() => handleDeleteCategoryAttributes(deleteAttribute)}
            >
              DELETE ATTRIBUTE &apos;{deleteAttribute.label}&apos;
            </button>
            {isLoading && (
              <CircularProgress size={25} className="button-loader" />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default DeleteAttributeDialog;
