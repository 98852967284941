import {
  SEND_VERIFICATION_CODE,
  VERIFICATION_CODE_SUCCESS,
  VERIFICATION_CODE_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  RESET_REGISTER_USER_MESSAGES,
  RESET_VERIFICATION_CODE_MESSAGES,
  LIST_SESSIONS,
  LIST_SESSIONS_SUCCESS,
  LIST_SESSIONS_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
} from '../dispatchTypes';

export const initialVerificationCodeState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  isOpen: false,
};

export const verificationCodeReducer = (
  state = initialVerificationCodeState,
  action
) => {
  switch (action.type) {
    case SEND_VERIFICATION_CODE: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case VERIFICATION_CODE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }
    case VERIFICATION_CODE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.errorMessage,
      };
    }

    case RESET_VERIFICATION_CODE_MESSAGES: {
      return initialVerificationCodeState;
    }

    case 'OPEN_LOGIN_POPUP': {
      return {
        ...state,
        isOpen: action.isOpen,
      };
    }

    default:
      return state;
  }
};

export const initialRegisterState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const registerUserReducer = (state = initialRegisterState, action) => {
  switch (action.type) {
    case REGISTER_USER: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case REGISTER_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }
    case REGISTER_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.errorMessage,
      };
    }

    case RESET_REGISTER_USER_MESSAGES: {
      return initialRegisterState;
    }

    default:
      return state;
  }
};

export const initialSessionsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  userSessions: {
    sessions: [],
    pagination: {
      count: 0,
      per: 20,
      page: 1,
    },
  },
};

export const listUserSessionsReducer = (
  state = initialSessionsState,
  action
) => {
  switch (action.type) {
    case LIST_SESSIONS: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case LIST_SESSIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        userSessions: action.userSessions,
      };
    }
    case LIST_SESSIONS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export const initialLogoutState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const logoutReducer = (state = initialLogoutState, action) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isRedirect: action.isRedirect,
      };
    }
    case LOGOUT_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.errorMessage,
        isRedirect: action.isRedirect,
      };
    }
    case 'RESET_LOGOUT': {
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};
