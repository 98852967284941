import {
  LOADING,
  SUCCESS,
  ERROR,
  ABOUT_BUSINESS,
  ABOUT_BUSINESS_SUCCESS,
  ABOUT_BUSINESS_ERROR,
  UPDATE_ABOUT_BUSINESS,
  UPDATE_ABOUT_BUSINESS_SUCCESS,
  UPDATE_ABOUT_BUSINESS_ERROR,
} from '../dispatchTypes';

export const initialAboutBusinessState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  aboutBusiness: {
    description: '',
    merchant_about_section_assets: [],
  },
};

export const aboutBusinessReducer = (
  state = initialAboutBusinessState,
  action
) => {
  switch (action.type) {
    case ABOUT_BUSINESS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ABOUT_BUSINESS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        aboutBusiness: action.aboutBusiness,
      };
    }
    case ABOUT_BUSINESS_ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }

    case 'CLEAR_ABOUT_BUSINESS_MESSAGES': {
      return {
        ...state,
        isError: false,
        isSuccess: false,
        isLoading: false,
        errorMessage: '',
      };
    }
    default:
      return state;
  }
};

export const initialUpdateAboutBusinessState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  aboutBusiness: { merchant_about_section_assets: [], description: '' },
};

export const updateAboutBusinessReducer = (
  state = initialUpdateAboutBusinessState,
  action
) => {
  switch (action.type) {
    case UPDATE_ABOUT_BUSINESS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_ABOUT_BUSINESS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        aboutBusiness: action.aboutBusiness,
      };
    }
    case UPDATE_ABOUT_BUSINESS_ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }

    case 'CLEAR_ABOUT_BUSINESS_MESSAGES':
      return {
        ...state,
        isError: false,
        isSuccess: false,
        isLoading: false,
        errorMessage: '',
      };
    default:
      return state;
  }
};

export const initialUploadBusinessMediaState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isProgress: false,
  errorMessage: '',
  updatedMediaFiles: [],
};

export const uploadBusinessMediaReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isProgress: false,
        isLoading: true,
      };
    }
    case 'PROGRESS': {
      return {
        ...state,
        isProgress: true,
        updatedMediaFiles: action.updatedMediaFiles,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialUploadBusinessMediaState;
  }
};

export const initialDeleteBusinessMediaState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const deleteBusinessMediaReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        photoIndex: action.photoIndex,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        isSuccess: false,
        photoIndex: action.photoIndex,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialDeleteBusinessMediaState;
  }
};
