import Cookies from '@ictlife/core/config/cookies';

import {
  BUSINESS_PROFILE,
  BUSINESS_PROFILE_SUCCESS,
  BUSINESS_PROFILE_ERROR,
  BUSINESS_PROFILE_UPDATE_SUCCESS,
  BUSINESS_PROFILE_UPDATE_ERROR,
  BUSINESS_PROFILE_SUMMARY,
  BUSINESS_PROFILE_SUMMARY_SUCCESS,
  BUSINESS_PROFILE_SUMMARY_ERROR,
  SEARCH_BUSINESS,
  SEARCH_BUSINESS_SUCCESS,
  SEARCH_BUSINESS_ERROR,
  UPDATE_LIVE_STATUS,
  UPDATE_LIVE_STATUS_SUCCESS,
  UPDATE_LIVE_STATUS_ERROR,
} from '../dispatchTypes';

const initialMerchantProfileState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  viewMode: true,
  isBusinessRetrieve: false,
  merchantProfile: {
    merchant_profile: null,
    cover_photos: [],
    merchant: {
      business_name: '',
      business_username: '',
    },
  },
};

export const merchantProfileReducer = (
  state = initialMerchantProfileState,
  action
) => {
  switch (action.type) {
    case BUSINESS_PROFILE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errorMessage: '',
        errorCode: null,
        isError: false,
        isBusinessRetrieve: action.isBusinessRetrieve,
      };
    }
    case BUSINESS_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isBusinessRetrieve: false,
        isSuccess: true,
        isUpdated: action.isUpdated,
        merchantProfile: action.merchantProfile,
        isBusinessOwner: !action.viewMode,
        viewMode: action.viewMode,
      };
    }
    case BUSINESS_PROFILE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isBusinessRetrieve: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
      };
    }

    case BUSINESS_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        updateSuccess: true,
        isLoading: false,
        isBusinessRetrieve: false,
        username: action.username,
      };

    case BUSINESS_PROFILE_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        isBusinessRetrieve: false,
        updateError: true,
        errorMessage: action.errorMessage,
      };

    case UPDATE_LIVE_STATUS:
      return {
        ...state,
        updatingLiveStatus: true,
        updateLiveStatusError: false,
      };

    case UPDATE_LIVE_STATUS_SUCCESS:
      return {
        ...state,
        updatingLiveStatus: false,
        merchantProfile: {
          ...state.merchantProfile,
          merchant: {
            ...state.merchantProfile.merchant,
            status: action.status,
          },
        },
      };

    case 'UPDATE_FOLLOW_MERCHANT':
      return {
        ...state,
        merchantProfile: {
          ...state.merchantProfile,
          merchant: {
            ...state.merchantProfile.merchant,
            merchant_follower: action.merchant_follower,
          },
        },
      };

    case UPDATE_LIVE_STATUS_ERROR:
      return {
        ...state,
        updateLiveStatusError: true,
      };

    case 'SWITCH_VIEW':
      return {
        ...state,
        viewMode: action.viewMode,
      };

    case 'CLEAR_MERCHANT_MESSAGES':
      return {
        ...state,
        isError: false,
        isSuccess: false,
        updateSuccess: false,
        updateError: false,
      };
    default:
      return state;
  }
};

const initialMerchantProfileSummaryState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  merchantProfile: {
    total_reviews_count: 0,
    active_posts_count: 0,
  },
};

export const merchantProfileSummaryReducer = (
  state = initialMerchantProfileSummaryState,
  action
) => {
  switch (action.type) {
    case BUSINESS_PROFILE_SUMMARY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BUSINESS_PROFILE_SUMMARY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        merchantProfile: action.merchantProfile,
      };
    }
    case BUSINESS_PROFILE_SUMMARY_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    case 'UPDATE_FOLLOWER_COUNT': {
      return {
        ...state,
        merchantProfile: {
          ...state.merchantProfile,
          followers_count: action.followers_count,
        },
      };
    }

    default:
      return state;
  }
};

const initialSearchMerchantState = {
  merchants: null,
};

export const searchMerchantReducer = (
  state = initialSearchMerchantState,
  action
) => {
  switch (action.type) {
    case SEARCH_BUSINESS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SEARCH_BUSINESS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        merchants: action.merchants,
      };
    }
    case SEARCH_BUSINESS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const COOKIE_NAME = 'theme_preference';
let themePreference = Cookies.get(COOKIE_NAME);

if (themePreference === 'system') {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    themePreference = 'dark';
  } else {
    themePreference = 'light';
  }
}

const initialAppThemeState = {
  theme: typeof window !== 'undefined' && themePreference,
};

export const appThemeReducer = (state = initialAppThemeState, action) => {
  switch (action.type) {
    case 'SWITCH_APP_THEME': {
      return {
        ...state,
        theme: action.theme,
      };
    }
    default:
      return state;
  }
};
