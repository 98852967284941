import Cookies from '@ictlife/core/config/cookies';
import { clearMessagingDatabase } from '../../components/dashboard-blueprint/TopBar';
import axiosConfig from '../../config/axiosConfig';
import {
  GET_USER_PROFILE,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_ERROR,
  GET_BUSINESS_LIST,
  GET_BUSINESS_LIST_SUCCESS,
  GET_BUSINESS_LIST_ERROR,
} from '../dispatchTypes';

export const loginUser = async payload => {
  const response = await axiosConfig.post('/sessions', payload);
  return response;
};

export const fetchUserBusinesses = dispatch => {
  const userId = JSON.parse(Cookies.get('ictl_user')).id;
  dispatch({
    type: GET_BUSINESS_LIST,
  });

  return axiosConfig
    .get(`/users/${userId}/merchant_infos`)
    .then(response => {
      dispatch({
        type: GET_BUSINESS_LIST_SUCCESS,
        userBusinessList: response.data.merchant_infos,
      });

      return response.data.merchant_infos;
    })
    .catch(error => {
      dispatch({
        type: GET_BUSINESS_LIST_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_BUSINESS_LIST_ERROR,
        errorMessage:
          'Failed to process request. Kindly check your internet connection',
      });
    });
};

export const fetchUserInfo = dispatch => {
  const user = localStorage.user
    ? JSON.parse(localStorage.user)
    : Cookies.get('ictl_user') && JSON.parse(Cookies.get('ictl_user'));
  dispatch({
    type: GET_USER_PROFILE,
  });

  if (user.profile_photo_id) {
    return axiosConfig
      .get(`/users/${user.id}/assets/${user.profile_photo_id}/upload_url`)
      .then(response => {
        dispatch({
          type: USER_PROFILE_SUCCESS,
          profile_photo: response.data.asset.url,
          user,
          avatar: response.data.asset,
        });
        return user;
      })
      .catch(error => {
        let activeMerchant = localStorage.activeMerchant;
        localStorage.clear();
        Cookies.remove('ictl_token');
        Cookies.remove('ictl_user');
        Cookies.remove('ictl_token', {
          domain: process.env.NODE_ENV === 'production' && 'ictlife.com',
        });
        Cookies.remove('ictl_user', {
          domain: process.env.NODE_ENV === 'production' && 'ictlife.com',
        });

        Cookies.remove('ictl_push_notification_status');

        clearMessagingDatabase();

        if (activeMerchant) {
          localStorage.activeMerchant = activeMerchant;
        }

        dispatch({
          type: USER_PROFILE_ERROR,
          errorMessage: error.response.data.error_message,
        });

        window.location.replace('/user/login');

        return error.response;
      })
      .catch(error => {
        dispatch({
          type: USER_PROFILE_ERROR,
          errorMessage:
            'Failed to process request. Kindly check your internet connection',
        });
        return error;
      });
  } else {
    dispatch({
      type: USER_PROFILE_SUCCESS,
      user,
    });

    return Promise.resolve(user);
  }
};
