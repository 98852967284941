import React, {
  useState,
  useReducer,
  Fragment,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  ClickAwayListener,
  Dialog,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import uniqBy from 'lodash.uniqby';
import { useRouter } from 'next/router';
import cn from 'classnames';
import DateFormatter from '@ictlife/core/components/date';
import Social from '@ictlife/core/utils/social';
import useDebouncedEffect from '@ictlife/core/hooks/useDebouncedEffect';
import Cookies from '@ictlife/core/config/cookies';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import dynamic from 'next/dynamic';
import logUserActivity from '@ictlife/core/utils/logUserActivity';
import AppCarousel from '../../utils/AppCarousel';
import {
  initialDeletePostState,
  deletePostReducer,
  likePostReducer,
  initialLikePostState,
  PublishPostReducer,
  initialPublishPostState,
} from '../../state/reducers/postsReducer';
import {
  getLikesList,
  getUserPostLike,
  likePost,
  publishPost,
  unLikePost,
  deletePost,
} from '../../state/actions/postsAction';

import {
  getPostComments,
  createPostComment,
  getSpecificPostComment,
  getSpecificPostCommentReply,
  getPostCommentLike,
} from '../../state/actions/postCommentsAction';
import {
  getPostCommentsReducer,
  initialGetPostCommentsState,
  createPostCommentReducer,
  initialCreateCommentState,
} from '../../state/reducers/postCommentsReducer';
import { recordResourceShare } from '../../state/actions/merchantProfileAction';
import { getReportReasons } from '../../state/actions/flagsAction';

import AppAvatar from '../../utils/AppAvatar';
import formatTextWithLinks from '../../utils/formatTextWithLinks';
import PostComments from './PostComments';
import AddPost from './AddPost';
import styles from './styles/Post.module.scss';

const DeleteDialog = dynamic(() => import('./dialogs/DeleteDialog'));
const LikeListPopper = dynamic(() => import('./dialogs/LikeListPopper'));
const ReportPost = dynamic(() => import('./ReportPost'));

/**
 * @description PostList Component
 * @param {*} props
 */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Post = ({
  post,
  posts,
  setPosts,
  removeItem,
  exampleMode,
  setSnackInfo,
  postInfo = { status: '' },
  setPostsCount,
  hideCommentsSection,
  openShareDialog,
  isPreviewSection,
}) => {
  const router = useRouter();
  const sourcePackage = process.env.NEXT_PUBLIC_SOURCE_PACKAGE;
  const {
    notificationCommentId,
    notificationReplyId,
    notificationCommentLikeId,
    notificationPostLike,
    commentuuid,
    replyuuid,
    commentLikeuuid,
    postlikeuuid,
  } = router.query;
  const dispatch = useDispatch();
  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      merchant: { business_name, business_username, id: business_id },
      profile_photo,
    },
    viewMode,
    isBusinessOwner,
  } = merchantProfileState;

  const userInfoState = useSelector(store => store.userInfoState);
  const {
    user: { id: user_id, first_name, last_name },
  } = userInfoState;

  const likesListState = useSelector(store => store.likesListState);
  const {
    likesList: { merchant_post_likes },
  } = likesListState;

  const socketEventsState = useSelector(store => store.socketEventsState);

  const { socketComment, socketPostLike } = socketEventsState;

  const [currentPost, setCurrentPost] = useState(post);
  const [likeId, setLikeId] = useState(
    post?.merchant_post_assets && post.merchant_post_assets[0]?.user_like?.id
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMore, setOpenMore] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [comments, setComments] = useImmer({});
  const [postForEdit, setPostForEdit] = useState({ openEdit: false });
  const [postComment, setPostComment] = useState('');

  const openMobileComments =
    notificationCommentId ||
    notificationReplyId ||
    notificationCommentLikeId ||
    commentuuid ||
    replyuuid ||
    commentLikeuuid;

  const [openComments, setOpenComments] = useState(
    openMobileComments ? true : false
  );

  useEffect(() => {
    setOpenComments(openMobileComments);
  }, [
    notificationCommentId,
    notificationReplyId,
    notificationCommentLikeId,
    commentuuid,
    replyuuid,
    commentLikeuuid,
    openMobileComments,
  ]);

  const [activeImage, setActiveAsset] = useState({
    index: 0,
    asset: post.merchant_post_assets?.filter(
      photo => photo.asset.status === 'active'
    )[0],
  });
  const [notifReplyId, setNotifReplyId] = useState(notificationReplyId);
  const [notificationReply, setNotificationReply] = useState();
  const [notifCommentId, setNotifCommentId] = useState(notificationCommentId);
  const [notifCommentLikeId, setNotifCommentLikeId] = useState(
    notificationCommentLikeId
  );
  const [notifPostLike, setNotifPostLike] = useState(notificationPostLike);
  const [notificationPostCommentLike, setNotificationPostCommentLike] =
    useState();

  if (
    !activeImage.asset &&
    post.merchant_post_assets?.filter(
      photo => photo.asset.status === 'active'
    )[0]
  ) {
    setActiveAsset({
      index: 0,
      asset: post.merchant_post_assets?.filter(
        photo => photo.asset.status === 'active'
      )[0],
    });
  }

  if (
    !post.merchant_post_assets?.filter(
      photo => photo.asset.status === 'active'
    )[0] &&
    activeImage.asset
  ) {
    setActiveAsset({
      asset: undefined,
      index: 0,
    });
  }

  if (
    activeImage.index !== 0 &&
    activeImage.index + 1 >
      post.merchant_post_assets?.filter(
        photo => photo.asset.status === 'active'
      ).length
  ) {
    setActiveAsset({
      index: --activeImage.index,
      asset: post.merchant_post_assets?.filter(
        photo => photo.asset.status === 'active'
      )[activeImage.index],
    });
  }

  const [openLikeList, setOpenLikeList] = useState(false);
  const [reportedPost, setReportedPost] = useState(post.user_flag || false);

  const [deletePostState, dispatchDeletePost] = useReducer(
    deletePostReducer,
    initialDeletePostState
  );
  const [likePostState, dispatchLikePost] = useReducer(
    likePostReducer,
    initialLikePostState
  );

  const [postCommentsState, dispatchGetPostsComments] = useReducer(
    getPostCommentsReducer,
    initialGetPostCommentsState
  );
  const [createPostCommentState, dispatchCreatePostComment] = useReducer(
    createPostCommentReducer,
    initialCreateCommentState
  );

  const [publishPostState, dispatchPublishPost] = useReducer(
    PublishPostReducer,
    initialPublishPostState
  );

  const {
    isLoading: commentsLoading,
    isSuccess: postCommentsLoadSuccess,
    isError: commentsLoadError,
    errorMessage,
  } = postCommentsState;

  if (errorMessage) {
    if (notifCommentId) {
      setNotifCommentId();
      setSnackInfo(snackInfo => ({
        ...snackInfo,
        open: true,
        message:
          errorMessage === 'Resource not found.'
            ? 'The comment was removed'
            : errorMessage,
        vertical: 'bottom',
        horizontal: 'right',
        severity: 'error',
        autoHideDuration: null,
      }));
    }

    if (notifReplyId) {
      setNotifReplyId(null);
      setSnackInfo(snackInfo => ({
        ...snackInfo,
        open: true,
        message:
          errorMessage === 'Resource not found.'
            ? 'The reply or the replied comment was removed'
            : errorMessage,
        vertical: 'bottom',
        horizontal: 'right',
        severity: 'error',
        autoHideDuration: null,
      }));
    }

    if (notifCommentLikeId) {
      setNotifCommentLikeId(null);
      setSnackInfo(snackInfo => ({
        ...snackInfo,
        open: true,
        message: errorMessage,
        vertical: 'bottom',
        horizontal: 'right',
        severity: 'error',
        autoHideDuration: null,
      }));
    }

    if (notifPostLike) {
      setNotifPostLike(null);
    }
  }

  const { page, per } = activeImage.asset
    ? comments[activeImage.asset.id]?.pagination ?? { page: 1, per: 20 }
    : { page: 1, per: 20 };

  const handleOpenMoreOptions = event => {
    setAnchorEl(event.currentTarget);
    setOpenMore(!openMore);
  };

  const handleToggleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleRemovePost = post => () => {
    return deletePost(dispatchDeletePost, post).then(() => {
      dispatchDeletePost({ type: 'DEFAULT' });
      if (posts) {
        const newPosts = posts;
        newPosts.splice(posts.indexOf(post), 1);
        setPosts([...newPosts]);
        setPostsCount && setPostsCount(count => --count);
        handleToggleDialog();
      } else {
        router.push(`/business/${business_username}/posts`);
      }
      setSnackInfo(snackInfo => ({
        ...snackInfo,
        open: true,
        message: 'Post has been deleted successfully',
        severity: 'success',
      }));
    });
  };

  useEffect(() => {
    setComments(() => ({}));
  }, [post.id, setComments]);

  useEffect(() => {
    if (
      post.id !== 'example' &&
      activeImage.asset &&
      !notifCommentId &&
      !notifReplyId &&
      !notifCommentLikeId &&
      !notifPostLike
    ) {
      getPostComments(
        dispatchGetPostsComments,
        post.id,
        page,
        per,
        activeImage.asset.id
      );
    }

    if (notifCommentId) {
      getSpecificPostComment(dispatchGetPostsComments, notifCommentId);
    }
  }, [
    post.id,
    page,
    per,
    activeImage.asset,
    notifCommentId,
    notifReplyId,
    notifCommentLikeId,
    notifPostLike,
  ]);

  const refetchComments = () => {
    getPostComments(
      dispatchGetPostsComments,
      post.id,
      page,
      per,
      activeImage.asset.id
    );
  };

  const bottomScroll = useRef({
    [activeImage?.asset?.id]: 0,
  });

  const handleFetchMorePostComments = () => {
    dispatchGetPostsComments({ type: 'LOADING' });
    setComments(draft => {
      draft[activeImage.asset.id].pagination.page += 1;
    });
  };

  const handleCheckScrollPosition = useDebouncedEffect(() => {
    let holder = document.querySelector('.comments');
    const currentCommentsElement = document.querySelector(
      `#comments-holder-${activeImage.asset.id}`
    );

    if (currentCommentsElement) {
      bottomScroll.current = {
        ...bottomScroll.current,
        [activeImage?.asset?.id]:
          currentCommentsElement.scrollHeight -
          currentCommentsElement.scrollTop,
      };
    }

    if (
      holder?.scrollTop < 100 &&
      !commentsLoading &&
      !commentsLoadError &&
      comments[activeImage.asset.id]?.pagination.count !==
        comments[activeImage.asset.id]?.comments.length
    ) {
      handleFetchMorePostComments();
    }
  }, 100);

  const handleTogglePostLike = () => {
    if (likePostState.isLoading || !user_id) {
      return;
    }
    !exampleMode &&
      dispatchLikePost({
        type: 'LOADING',
      });

    const updatedPost = post;
    const postAssets = updatedPost.merchant_post_assets.filter(
      photo => photo.asset.status === 'active'
    );
    postAssets[activeImage.index].likes_count = likeId
      ? --postAssets[activeImage.index].likes_count
      : ++postAssets[activeImage.index].likes_count;

    updatedPost.merchant_post_assets = [...postAssets];
    setCurrentPost(updatedPost);

    if (!likeId) {
      const bodyData = {
        user_id,
        merchant_post_asset_id: activeImage.asset.id,
      };

      if (isBusinessOwner) {
        bodyData.user_merchant_id = business_id;
      }

      if (!exampleMode) {
        setLikeId(true);
        likePost(dispatchLikePost, bodyData);
      } else setLikeId(true);
    } else {
      if (!exampleMode) {
        setLikeId(null);
        unLikePost(dispatchLikePost, likeId);
      } else setLikeId(null);
    }
  };

  if (likePostState.isSuccess) {
    openLikeList &&
      getLikesList(
        dispatch,
        post.id,
        activeImage.asset?.id,
        merchant_post_likes,
        null,
        null,
        null
      );
    const updatedPost = post;
    const postAssets = updatedPost.merchant_post_assets.filter(
      photo => photo.asset.status === 'active'
    );
    postAssets[activeImage.index].user_like = { id: likePostState.likeId };
    updatedPost.merchant_post_assets = [...postAssets];
    setCurrentPost(updatedPost);
    setLikeId(likePostState.likeId);
    dispatchLikePost({ type: 'DEFAULT' });
  }

  if (likePostState.isError) {
    const updatedPost = post;
    const postAssets = updatedPost.merchant_post_assets.filter(
      photo => photo.asset.status === 'active'
    );
    postAssets[activeImage.index].likes_count = likeId
      ? --postAssets[activeImage.index].likes_count
      : ++postAssets[activeImage.index].likes_count;
    updatedPost.merchant_post_assets = [...postAssets];
    setCurrentPost(updatedPost);
    setLikeId(likePostState.rollBackId);
    dispatchLikePost({ type: 'DEFAULT' });
  }

  const openEditPost = post => {
    setPostForEdit(postForEdit => ({ post, openEdit: !postForEdit.openEdit }));
  };

  const handleSuccessfullyCreatedComment = comment => {
    setPostComment('');
    setComments(draft => {
      if (!draft[comment.merchant_post_asset_id]) {
        draft[comment.merchant_post_asset_id] = {
          comments: [],
          pagination: { page, per, count: 0 },
        };
      }
      draft[comment.merchant_post_asset_id].comments.push(comment);
      draft[comment.merchant_post_asset_id].pagination.count += 1;
    });
    dispatchCreatePostComment({ type: 'DEFAULT' });
    /**
     * Record post comment
     */

    logUserActivity({ action: 'merchant_web_post_comment', dispatch });

    if (isBusinessOwner) {
      logUserActivity({
        action: 'merchant_web_business_post_comment',
        dispatch,
      });
    }

    const commentsElement = document.querySelector(
      `#comments-holder-${activeImage.asset.id}`
    );

    commentsElement.scroll({
      top: commentsElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleCreatePostComment = () => {
    if (user_id) {
      const bodyData = {
        merchant_post_id: post.id,
        message: postComment,
        user_id,
        merchant_post_asset_id: activeImage.asset.id,
      };

      if (isBusinessOwner) {
        bodyData.merchant_id = business_id;
      }

      createPostComment(dispatchCreatePostComment, bodyData).then(
        handleSuccessfullyCreatedComment
      );
    } else {
      dispatch({ type: 'OPEN_LOGIN_POPUP', isOpen: true });
    }
  };

  if (postCommentsLoadSuccess) {
    // TODO: will refactor this if statement later.
    const reversedPostComments =
      postCommentsState.posts_comments.merchant_post_comments.reverse();
    setComments(assetComments => ({
      ...assetComments,
      [postCommentsState.asset_id]: {
        comments: uniqBy(
          [
            ...reversedPostComments,
            ...(comments[postCommentsState.asset_id]?.comments || []),
          ],
          'id'
        ),
        pagination: postCommentsState.posts_comments.pagination,
      },
    }));

    if (notifCommentId) {
      setActiveAsset({
        asset: post.merchant_post_assets.find(
          file => file.id === postCommentsState.asset_id
        ),
        index: post.merchant_post_assets.findIndex(
          file => file.id === postCommentsState.asset_id
        ),
      });
    }

    dispatchGetPostsComments({ type: 'DEFAULT' });
  }

  useEffect(() => {
    if (socketPostLike && socketPostLike?.merchant_post_id === post.id) {
      const updatedCurrentPostAssets = currentPost.merchant_post_assets;
      const targetAsset = updatedCurrentPostAssets.find(
        asset => asset.id === socketPostLike.merchant_post_asset_id
      );
      targetAsset.likes_count = targetAsset.likes_count + 1;
      setCurrentPost(post => ({
        ...post,
        merchant_post_assets: updatedCurrentPostAssets,
      }));
      dispatch({ type: 'CLEAR_POST_LIKE_IN_SOCKET' });
    }
  }, [currentPost.merchant_post_assets, dispatch, post.id, socketPostLike]);

  useEffect(() => {
    if (socketComment && socketComment.merchant_post_id === post.id) {
      setComments(draft => {
        if (!draft[socketComment.merchant_post_asset_id]) {
          draft[socketComment.merchant_post_asset_id] = {
            comments: [],
            pagination: { page, per, count: 0 },
          };
        }
        draft[socketComment.merchant_post_asset_id].comments.push(
          socketComment
        );
        draft[socketComment.merchant_post_asset_id].pagination.count += 1;
      });

      dispatch({ type: 'CLEAR_POST_COMMENT_IN_SOCKET' });
    }
  }, [dispatch, page, per, post.id, setComments, socketComment]);

  useEffect(() => {
    const currentCommentsElement = document.querySelector(
      `#comments-holder-${activeImage.asset?.id}`
    );

    currentCommentsElement?.scroll({
      top:
        currentCommentsElement.scrollHeight -
          bottomScroll.current[activeImage?.asset?.id] || 0,
    });
  }, [activeImage?.asset?.id, comments]);

  useEffect(() => {
    if (notificationCommentId) {
      setNotifCommentId(notificationCommentId);
    }
  }, [notificationCommentId, commentuuid]);

  useEffect(() => {
    if (notificationReplyId) {
      setNotifReplyId(notificationReplyId);
      getSpecificPostCommentReply(
        dispatchGetPostsComments,
        notificationReplyId,
        setNotificationReply
      );
    } else {
      setNotifReplyId(notificationReplyId);
    }
  }, [notificationReplyId, replyuuid]);

  useEffect(() => {
    if (notificationReply) {
      setNotifCommentId(notificationReply.original_post_comment_id);
      setNotifReplyId(null);
    }
  }, [notificationReply]);

  useEffect(() => {
    if (notificationCommentLikeId) {
      setNotifCommentLikeId(notificationCommentLikeId);
      getPostCommentLike(
        dispatchGetPostsComments,
        notificationCommentLikeId,
        setNotificationPostCommentLike
      );
    } else {
      setNotifCommentLikeId(notificationCommentLikeId);
    }
  }, [notificationCommentLikeId, commentLikeuuid]);

  useEffect(() => {
    if (notificationPostLike) {
      setNotifPostLike(notificationPostLike);
      getUserPostLike({
        dispatch: dispatchGetPostsComments,
        notificationPostLike,
        setActiveAsset,
        post,
        setNotifPostLike,
      });
    } else {
      setNotifPostLike(notificationPostLike);
    }
  }, [notificationPostLike, postlikeuuid, post]);

  useEffect(() => {
    if (notificationPostCommentLike) {
      setNotifCommentId(notificationPostCommentLike.merchant_post_comment_id);
      setNotifCommentLikeId(null);
    }
  }, [notificationPostCommentLike]);

  useEffect(() => {
    const handleClickTag = hashtag => {
      const tag = hashtag.replace('#', '');
      router.push(`/business/${business_username}?hashtag=${tag}`);
    };

    let captions = document.querySelectorAll(
      `.hashtag-${activeImage.asset?.id ?? ''}`
    );

    captions.forEach(caption => {
      caption.addEventListener(
        'click',
        () => handleClickTag(caption.innerHTML),
        {
          passive: true,
        }
      );
    });

    return () => {
      captions.forEach(caption => {
        caption.removeEventListener('click', () =>
          handleClickTag(caption.innerHTML)
        );
      });
    };
  }, [post, activeImage.asset, router, business_username]);

  useEffect(() => {
    const handleClickTag = hashtag => {
      const tag = hashtag.replace('#', '');
      router.push(`/business/${business_username}?hashtag=${tag}`);
      document.querySelector('.main-content').scroll({ top: 0 });
    };

    let captions = document.querySelectorAll(`.titletag-${post.id}`);

    captions.forEach(caption => {
      caption.addEventListener(
        'click',
        () => handleClickTag(caption.innerHTML),
        {
          passive: true,
        }
      );
    });

    return () => {
      captions.forEach(caption => {
        caption.removeEventListener('click', () =>
          handleClickTag(caption.innerHTML)
        );
      });
    };
  }, [post, router, business_username]);

  const postShareURL = `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/business/${business_username}/posts/${post.uuid}`;

  const shareSocial = platform => {
    const recordResourceShareHandler = () => {
      const bodyData = {
        merchant_post_id: post.id,
        user_id,
      };
      recordResourceShare(bodyData, 'post');
    };
    if (platform !== 'facebook') recordResourceShareHandler();

    if (
      !post.shares_count.anonymous_users_share_count &&
      !post.shares_count.unique_registered_users_share_count
    ) {
      logUserActivity({ action: 'merchant_web_post_first_share', dispatch });
    }

    const twitter = `${post.title
      .replace(/[#]/g, '')
      .substr(0, 200)} \n ${postShareURL}`;

    const whatsapp = `${post.title.replace(/[#]/g, '')} \n ${postShareURL}`;

    const socialMediaHandler = Social({
      facebookLink: postShareURL,
      twitter,
      whatsapp,
      copyLink: postShareURL,
      copyCallback: () => {},
      recordResourceShareHandler,
    });

    socialMediaHandler.shareLinkOn(platform);
  };

  const openSnackBar = ({ severity, message }) => {
    setSnackInfo(snackInfo => ({
      ...snackInfo,
      open: true,
      message,
      severity,
      autoHideDuration: null,
      vertical: 'top',
      horizontal: 'center',
    }));
  };

  const postImages =
    post.merchant_post_assets?.filter(
      photo => photo.asset.status === 'active'
    ) ?? [];

  const handleItemChange = index => {
    setActiveAsset({ index, asset: postImages[index] });
    setLikeId(currentPost.merchant_post_assets[index]?.user_like?.id);
  };

  useEffect(() => {
    const carousel_elm = document.querySelector(
      `#post-carousel-wrapper-${post.id}`
    );
    if (carousel_elm) {
      carousel_elm.style.height = `${(carousel_elm.offsetWidth * 1) / 1}px`;
    }
    const post_elm = document.querySelector(`#post-section-${post.id}`);
    const comments_elm = document.querySelector(`#comment-section-${post.id}`);

    if (comments_elm) {
      comments_elm.style.height = `${post_elm.offsetHeight}px`;
    }
  }, [post, reportedPost]);

  const appThemeState = useSelector(store => store.appThemeState);
  const { theme } = appThemeState;

  const publishADraftPost = () => {
    return publishPost(dispatchPublishPost, post.id).then(() => {
      const newPosts = posts;
      newPosts.splice(posts.indexOf(post), 1);
      setPosts([...newPosts]);
      setSnackInfo(snackInfo => ({
        ...snackInfo,
        open: true,
        message: 'Your post has been published to the marketplace successfully',
        vertical: 'top',
        horizontal: 'center',
        severity: 'success',
        autoHideDuration: null,
      }));
      dispatchPublishPost({ type: 'DEFAULT' });
    });
  };
  const postHeader = section => {
    return (
      <div className={styles.headerInfo}>
        {activeImage.asset?.asset.description && (
          <div
            className={styles.caption}
            id={`caption-${post.id}-${activeImage.asset?.id ?? ''}`}
            dangerouslySetInnerHTML={formatTextWithLinks({
              description: activeImage.asset?.asset.description,
              hashtagid: `hashtag-${activeImage.asset?.id}`,
            })}
          />
        )}

        <div className={styles.time}>
          <DateFormatter date={post.published_at || post.created_at} />
          <span style={{ visibility: 'hidden' }}>time</span>
        </div>
        {isPreviewSection ? (
          <div className={styles.postInfo}>
            <div
              className={cn({
                [styles.likeArea]: true,
                'theme-text': likeId,
              })}
            >
              <span className="material-icons">&#xe8dc;</span>
              <span>0 likes</span>
            </div>
            <div className={styles.commentArea}>
              <span className="material-icons">&#xe0ca;</span>
              <span>0 comments</span>
            </div>
            {section === 'post-section' && (
              <div className="share-area">
                <div className={styles.commentArea}>
                  <span className="material-icons">&#xe80d;</span>
                  <span></span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.postInfo}>
            {postInfo.status === 'unpublished' ? (
              <div>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  disabled={publishPostState.isLoading}
                  onClick={publishADraftPost}
                >
                  {!publishPostState.isLoading ? 'PUBLISH' : 'PUBLISHING'}
                </Button>
              </div>
            ) : (
              <Fragment>
                <div
                  className={cn({
                    [styles.likeArea]: true,
                    'theme-text': likeId,
                  })}
                >
                  <span
                    className="material-icons"
                    onClick={() =>
                      isLoggedIn
                        ? handleTogglePostLike()
                        : dispatch({ type: 'OPEN_LOGIN_POPUP', isOpen: true })
                    }
                  >
                    &#xe8dc;
                  </span>
                  <span
                    onClick={() => {
                      if (isLoggedIn) {
                        getLikesList(
                          dispatch,
                          post.id,
                          activeImage.asset?.id,
                          [],
                          null,
                          null,
                          null
                        );

                        setOpenLikeList(true);
                      } else {
                        dispatch({ type: 'OPEN_LOGIN_POPUP', isOpen: true });
                      }
                    }}
                  >{`  ${
                    (currentPost?.merchant_post_assets &&
                      currentPost?.merchant_post_assets[activeImage.index]
                        ?.likes_count) ||
                    0
                  } ${
                    currentPost?.merchant_post_assets &&
                    currentPost?.merchant_post_assets[activeImage.index]
                      ?.likes_count === 1
                      ? 'like'
                      : 'likes'
                  }`}</span>
                </div>
                <div
                  className={styles.commentArea}
                  onClick={() => setOpenComments(true)}
                >
                  <span className="material-icons">&#xe0ca;</span>
                  <span>{`  ${
                    comments[activeImage.asset?.id]?.pagination?.count ||
                    (currentPost?.merchant_post_assets &&
                      currentPost?.merchant_post_assets[activeImage.index]
                        ?.comments_count) ||
                    0 ||
                    0
                  } ${
                    comments[activeImage.asset?.id]?.pagination?.count === 1
                      ? 'comment'
                      : 'comments'
                  }`}</span>
                </div>
                {section === 'post-section' && (
                  <div className="share-area">
                    {post.shares_count && (
                      <ClickAwayListener
                        onClickAway={() => setOpenShare(false)}
                      >
                        <Tooltip
                          title={`${
                            post.shares_count.registered_users_share_count || 0
                          } share(s) by ${
                            post.shares_count
                              .unique_registered_users_share_count || 0
                          } unique registered user(s). ${
                            post.shares_count.anonymous_users_share_count || 0
                          } share(s) by anonymous user(s)`}
                          placement="top"
                          arrow
                        >
                          <div
                            className={styles.commentArea}
                            onClick={event => {
                              setAnchorEl(event.currentTarget);
                              setOpenShare(open => !open);
                            }}
                          >
                            <span className="material-icons">&#xe80d;</span>
                            <span>
                              {'  '}
                              {post.shares_count.registered_users_share_count +
                                post.shares_count.anonymous_users_share_count ||
                                ''}
                            </span>
                          </div>
                        </Tooltip>
                      </ClickAwayListener>
                    )}
                  </div>
                )}
              </Fragment>
            )}
          </div>
        )}
      </div>
    );
  };

  const postComments = () => {
    return (
      <div className={styles.postCommentsSection} id="post-comments-section">
        <div className={styles.mobileHeader}>
          <div className={styles.back} onClick={() => setOpenComments(false)}>
            <span>Comments for image {activeImage.index + 1}</span>
            <span className="material-icons">&#xe5cd;</span>
          </div>
        </div>
        <div className={styles.postHeader}>
          <div className={styles.postAvatar}>
            <div className={styles.profileAvatar}>
              {post.merchant_info ? (
                <AppAvatar
                  src={post.merchant_info.profile_photo?.url}
                  alt={post.merchant_info.merchant.business_name}
                />
              ) : (
                <AppAvatar src={profile_photo?.url} alt={business_name} />
              )}
            </div>
            <div className={styles.postUserInfo}>
              <div className={styles.name}>
                <span>
                  {post.merchant_info
                    ? post.merchant_info.merchant.business_name
                    : business_name}
                </span>
              </div>
            </div>
          </div>
          {postHeader()}
        </div>
        <div
          onScroll={handleCheckScrollPosition}
          className={cn(styles.comments, 'comments')}
          id={activeImage.asset && `comments-holder-${activeImage.asset.id}`}
        >
          {activeImage.asset &&
            comments[activeImage.asset.id]?.comments?.length === 0 && (
              <div className={styles.note}>-- comments will appear here --</div>
            )}

          {
            <div className={styles.note}>
              {postCommentsState.isLoading || notifCommentId ? (
                <CircularProgress size={15} />
              ) : (
                activeImage.asset &&
                comments[activeImage.asset.id]?.comments?.length > 0 && (
                  <div className={styles.morePostsSection}>
                    {commentsLoadError && <div>{errorMessage}</div>}
                    {(comments[activeImage.asset.id]?.pagination.count !==
                      comments[activeImage.asset.id]?.comments.length ||
                      commentsLoadError ||
                      notifCommentId) && (
                      <div
                        onClick={
                          commentsLoadError
                            ? () => refetchComments()
                            : notifCommentId
                            ? () => setNotifCommentId()
                            : () => handleFetchMorePostComments()
                        }
                        className={styles.commentsAction}
                      >
                        {!commentsLoadError
                          ? notifCommentId
                            ? 'Other comments'
                            : 'load older comments'
                          : 'Retry'}
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          }
          {activeImage.asset &&
            comments[activeImage.asset.id]?.comments?.map((comment, idx) => (
              <PostComments
                idx={idx}
                key={comment.id}
                setComments={setComments}
                comment={comment}
                postReplyId={`repy-post-id-${post.id}`}
                activeImage={activeImage.asset}
                notificationReply={notificationReply}
                setNotificationReply={setNotificationReply}
                notifCommentId={notifCommentId}
                setNotifCommentId={setNotifCommentId}
              />
            ))}
        </div>
        <div className={cn(styles.addComment, 'override')}>
          <TextField
            value={postComment}
            className="post-reply-field"
            fullWidth
            placeholder={
              postInfo.status === 'active'
                ? !currentPost?.merchant_post_assets ||
                  currentPost?.merchant_post_assets?.length < 1
                  ? 'Post requires image to enable commenting'
                  : isLoggedIn && (!first_name || !last_name)
                  ? 'Set your full name to comment. Go to Settings > Personal profile'
                  : 'Write your comment'
                : 'Publish post to create comments'
            }
            disabled={
              postInfo.status !== 'active' ||
              !currentPost?.merchant_post_assets ||
              currentPost?.merchant_post_assets?.length < 1 ||
              (isLoggedIn && (!first_name || !last_name))
            }
            multiline
            variant="outlined"
            onChange={e => setPostComment(e.target.value)}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                e.preventDefault();
                !createPostCommentState.isLoading &&
                  postComment !== '' &&
                  handleCreatePostComment();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={
                      !createPostCommentState.isLoading &&
                      handleCreatePostComment
                    }
                    disabled={
                      postComment === '' || createPostCommentState.isLoading
                    }
                  >
                    {createPostCommentState.isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SendIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 250,
            }}
          />
        </div>
      </div>
    );
  };

  if (reportedPost) {
    return (
      <div className={styles.postCardContainer} id={`repy-post-id-${post.id}`}>
        <div className={cn(styles.reportedPost, 'theme-container')}>
          <div className="message">
            You reported this post. It will be investigated promptly and action
            taken
          </div>
          <div className={styles.action}>
            <button
              className="custom-button outlined sm"
              onClick={() => setReportedPost(false)}
            >
              View post
            </button>
          </div>
        </div>
      </div>
    );
  }

  const isLoggedIn =
    typeof window !== 'undefined' ? Cookies.get('ictl_token') : false;

  return (
    <Fragment>
      <div className={styles.postCardContainer} id={`repy-post-id-${post.id}`}>
        <div className={styles.postCard}>
          <div className={styles.postSection} id={`post-section-${post.id}`}>
            <div className={styles.postSectionHeader}>
              <div className={styles.merchantInfo}>
                <div className={styles.info}>
                  <div className={styles.businessIdentity}>
                    <div className={styles.profileAvatar}>
                      {post.merchant_info ? (
                        <AppAvatar
                          src={post.merchant_info.profile_photo?.url}
                          alt={post.merchant_info.merchant.business_name}
                        />
                      ) : (
                        <AppAvatar
                          src={profile_photo?.url}
                          alt={business_name}
                        />
                      )}
                    </div>
                    <div className={styles.name}>
                      <div>
                        <span>
                          {post.merchant_info
                            ? post.merchant_info.merchant.business_name
                            : business_name}
                        </span>
                      </div>
                      <div className={styles.username}>
                        @
                        {post.merchant_info?.merchant.business_username ??
                          business_username}
                      </div>
                    </div>
                  </div>
                  <div className={styles.moreOptions}>
                    {isPreviewSection ? (
                      <div className={styles.more}>
                        <span className="material-icons">&#xe5d3;</span>
                      </div>
                    ) : (
                      <div
                        className={styles.more}
                        onClick={handleOpenMoreOptions}
                      >
                        <ClickAwayListener
                          onClickAway={() => setOpenMore(false)}
                        >
                          <span className="material-icons">&#xe5d3;</span>
                        </ClickAwayListener>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={styles.postTitle}
                  id={`post-title-${post.id}`}
                  dangerouslySetInnerHTML={formatTextWithLinks({
                    description: post.title,
                    hashtagid: `titletag-${post?.id}`,
                  })}
                />
              </div>
            </div>
            <div
              className={styles.postCarouselWrapper}
              id={`post-carousel-wrapper-${post.id}`}
            >
              {postImages === null || postImages.length === 0 ? (
                <div className={styles.mainCaption}>
                  <span className="material-icons">&#xe3b6;</span>
                </div>
              ) : (
                <AppCarousel
                  photos={postImages}
                  showCaption
                  handleItemChange={handleItemChange}
                  selectedItem={activeImage.index}
                />
              )}
            </div>
            <div className={styles.postSectionFooter}>
              {postHeader('post-section')}
            </div>
          </div>
          {!hideCommentsSection && (
            <div
              className={styles.commentsSectionHolder}
              id={`comment-section-${post.id}`}
            >
              {postComments()}
            </div>
          )}
        </div>
      </div>

      <Popper
        open={openMore || openShare}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        className={`more-options-popper ${theme || ''}`}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className="more-options-wrapper">
              {isBusinessOwner && !viewMode && !openShare && (
                <div
                  className="option"
                  onClick={exampleMode ? () => null : () => openEditPost(post)}
                >
                  <span className="material-icons icon">&#xe3c9;</span>
                  <span>Edit Post</span>
                </div>
              )}

              <div className="option" onClick={() => shareSocial('facebook')}>
                <FacebookIcon className="facebook-icon" fontSize="inherit" />
                <span>Share to Facebook</span>
              </div>
              <div className="option" onClick={() => shareSocial('whatsapp')}>
                <WhatsAppIcon className="whatsapp-icon" fontSize="inherit" />
                <span>Share to WhatsApp</span>
              </div>
              <div className="option" onClick={() => shareSocial('twitter')}>
                <TwitterIcon className="twitter-icon" fontSize="inherit" />
                <span>Share to Twitter</span>
              </div>
              <div className="option" onClick={() => shareSocial('copy')}>
                <span className="material-icons icon">&#xe173;</span>
                <span>Copy Post Share Link</span>
              </div>
              {isBusinessOwner && !viewMode && !openShare && !openShareDialog && (
                <div className="option" onClick={handleToggleDialog}>
                  <span className="material-icons icon">&#xe872;</span>
                  <span>Delete Post</span>
                </div>
              )}

              {viewMode && !isBusinessOwner && isLoggedIn && (
                <div
                  className="option"
                  onClick={() => {
                    getReportReasons(dispatch, 'merchant_posts');
                    setOpenReportDialog(true);
                  }}
                >
                  <span className="material-icons icon">&#xe160;</span>
                  <span> Report Post</span>
                </div>
              )}
            </div>
          </Fade>
        )}
      </Popper>
      {openReportDialog && sourcePackage === 'marketplace' && (
        <ReportPost
          setOpenReportDialog={setOpenReportDialog}
          setReportedPost={setReportedPost}
          setSnackInfo={setSnackInfo}
          postId={post.id}
        />
      )}

      {openDialog && (
        <DeleteDialog
          handleToggleDialog={handleToggleDialog}
          handleRemovePost={handleRemovePost}
          deletePostState={deletePostState}
          post={post}
          openEditPost={openEditPost}
          exampleMode={exampleMode}
        />
      )}

      {openLikeList && (
        <LikeListPopper
          setOpenLikeList={setOpenLikeList}
          currentPost={currentPost}
          selectedItem={activeImage.index}
          likeId={likeId}
          handleTogglePostLike={handleTogglePostLike}
          activeImageId={activeImage.asset?.id}
          postId={post.id}
        />
      )}
      <Dialog
        fullScreen
        open={openComments}
        onClose={() => setOpenComments(false)}
        TransitionComponent={Transition}
        className={cn(styles.mobileCommentsWrapper, 'override', theme)}
      >
        <div
          className={styles.mobileCommentsHolder}
          id={`comment-section-${post.id}`}
        >
          {postComments()}
        </div>
      </Dialog>
      <Dialog
        fullScreen
        open={postForEdit.openEdit}
        TransitionComponent={Transition}
        className={`override ${theme || ''}`}
      >
        <div className={styles.editPostWrapper}>
          <AddPost
            posts={posts}
            setPosts={setPosts}
            addPost={() => null}
            removeItem={removeItem}
            imageFiles={post.merchant_post_assets || []}
            postInfo={postInfo}
            postUUID={post.uuid}
            handleCloseDialog={() => setPostForEdit({ openEdit: false })}
            editMode={true}
            openSnackBar={openSnackBar}
            handleItemChange={handleItemChange}
          />
        </div>
      </Dialog>
    </Fragment>
  );
};

export default Post;
