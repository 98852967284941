import { getBusinesses } from '@ictlife/core/state/actions/businessesAction';
import { getBusinessCategories } from '@ictlife/core/state/actions/businessTypesAction';
import { capitalizeFirstLetter } from '@ictlife/core/utils/capitalizeFirstLetter';
import { getBusinessTypeAndLocation } from '@ictlife/core/utils/getBusinessTypeAndLocation';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import cn from 'classnames';
import { useRef, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import ReactSelect from 'react-select/async-creatable';
import useSuperCategoryAndLocation from '../../hooks/useSuperCategoryAndLocation';
import { selectStyles } from './CreateOffering';
import st from './styles/create-offering.module.scss';

const OfferingMerchantInfo = ({ merchantInfo, setMerchantInfo }) => {
  const [offeredByMerchant, setIsOfferedByMerchant] = useState('yes');
  const [wasDelivered] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState();

  const { superCategory } = useSuperCategoryAndLocation();

  const handleUpdateMerchantInfo = e => {
    setMerchantInfo(info => ({ ...info, [e.target.name]: e.target.value }));
  };

  const merchantNameRef = useRef();
  const businessTypeRef = useRef();
  const businessLocationRef = useRef();

  return (
    <>
      {superCategory !== 'events' && (
        <div className={cn(st.field, st.offeringField, 'override')}>
          <label htmlFor="description">
            Is this offering provided by a business ?
          </label>
          <div>
            <FormControl>
              <RadioGroup
                value={offeredByMerchant}
                onChange={(_, value) => {
                  value === 'no' && setMerchantInfo({});
                  setIsOfferedByMerchant(value);
                }}
              >
                <div style={{ display: 'flex' }}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="primary" />}
                    label="Yes, it is offered by a business"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      )}

      {offeredByMerchant === 'yes' && (
        <>
          <div className={cn(st.field, st.offeringField, 'override')}>
            <div className="flex">
              <label htmlFor="name">
                Name of the{' '}
                {superCategory === 'events' ? 'Organiser' : 'business'} *
              </label>
              <div className="h-0">
                <input
                  value={merchantInfo?.id || merchantInfo?.name || ''}
                  required
                  className="h-0 opacity-0"
                  autoComplete="off"
                  tabIndex={-1}
                  onFocus={() => {
                    merchantNameRef?.current.focus();
                  }}
                  onChange={() => null}
                />
              </div>
            </div>

            <ReactSelect
              createOptionPosition="first"
              ref={merchantNameRef}
              value={
                merchantInfo?.id
                  ? {
                      label: merchantInfo.name,
                      value: merchantInfo.id,
                      merchant: merchantInfo.merchant,
                    }
                  : merchantInfo?.name
                  ? {
                      label: merchantInfo.name,
                      value: merchantInfo.name,
                      merchant: merchantInfo.merchant,
                    }
                  : null
              }
              placeholder="Type to search businesses"
              isClearable
              loadOptions={input =>
                getBusinesses({
                  search: input,
                  per: 100,
                  merchant_account_status: 'unclaimed',
                }).then(businesses => {
                  return businesses.merchant_infos.map(business => {
                    return {
                      label: business.merchant.business_name,
                      value: business.merchant.id,
                      merchant: business.merchant,
                    };
                  });
                })
              }
              styles={selectStyles}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#ccc',
                  primary: '#ff8e22',
                },
              })}
              onChange={(business, actionMeta) => {
                if (actionMeta.action === 'create-option') {
                  setMerchantInfo(info => ({
                    ...info,
                    name: business.label,
                    value: business.label,
                  }));
                }
                if (actionMeta.action === 'select-option') {
                  setMerchantInfo(info => ({
                    ...info,
                    id: business.value,
                    name: business.label,
                  }));
                  setSelectedMerchant(business);
                }
                if (actionMeta.action === 'clear') {
                  setMerchantInfo(info => ({
                    ...info,
                    name: '',
                    id: null,
                  }));
                  setSelectedMerchant();
                }
              }}
            />
          </div>
          {selectedMerchant ? (
            <div className="flex shadow p-4 w-3/5 rounded-sm">
              <div className="flex-1 grid gap-1">
                <span className="text-xs">
                  This post will be associated with you and:
                </span>
                <span>{capitalizeFirstLetter(selectedMerchant.label)}</span>
                <span className="text-sm text-gray-400">
                  {selectedMerchant?.merchant &&
                    `@${selectedMerchant.merchant.business_username}`}
                </span>
                <span className="text-sm font-semibold">
                  {selectedMerchant?.merchant &&
                    getBusinessTypeAndLocation({
                      merchant: selectedMerchant.merchant,
                      merchant_profile:
                        selectedMerchant.merchant.merchant_profile,
                    })}
                </span>
              </div>
              <div className="flex items-center">
                <IconButton
                  onClick={() => {
                    setMerchantInfo(info => ({
                      ...info,
                      name: '',
                      id: null,
                    }));
                    setSelectedMerchant();
                  }}
                >
                  <span className="material-icons">&#xe5cd;</span>
                </IconButton>
              </div>
            </div>
          ) : (
            <>
              <div className={cn(st.field, st.offeringField, 'override')}>
                <div className="flex">
                  <label htmlFor="name">
                    What kind of business is this ? *
                  </label>

                  <div className="h-0">
                    <input
                      value={merchantInfo?.business_type || ''}
                      required
                      className="h-0 opacity-0"
                      autoComplete="off"
                      tabIndex={-1}
                      onFocus={() => {
                        businessTypeRef.current.focus();
                      }}
                      onChange={() => null}
                    />
                  </div>
                </div>
                <div className={cn(st.description, 'mb-2')}>
                  Keyword that best describes the business e.g. Dance Club etc.
                </div>

                <ReactSelect
                  value={
                    merchantInfo?.business_type
                      ? {
                          label: merchantInfo.business_type,
                          value: merchantInfo.business_type,
                        }
                      : null
                  }
                  placeholder="Type to search business types"
                  defaultOptions
                  isClearable
                  ref={businessTypeRef}
                  loadOptions={input =>
                    getBusinessCategories({
                      search: input,
                      per: 100,
                    }).then(categories =>
                      categories.map(category => ({
                        value: category.name,
                        label: category.name,
                      }))
                    )
                  }
                  styles={selectStyles}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#ccc',
                      primary: '#ff8e22',
                    },
                  })}
                  onChange={(category, actionMeta) => {
                    if (actionMeta.action === 'create-option') {
                      setMerchantInfo(info => ({
                        ...info,
                        business_type: category.label,
                      }));
                    }
                    if (actionMeta.action === 'select-option') {
                      setMerchantInfo(info => ({
                        ...info,
                        business_type: category.label,
                      }));
                    }
                    if (actionMeta.action === 'clear') {
                      setMerchantInfo(info => ({ ...info, business_type: '' }));
                    }
                  }}
                />
              </div>

              <div className={cn(st.field, st.offeringField, 'override')}>
                <div className="flex">
                  <label htmlFor="location">
                    {wasDelivered
                      ? 'Where was the product delivered ? *'
                      : 'Location of the business *'}
                  </label>
                  <div className="h-0">
                    <input
                      value={merchantInfo.latitude || ''}
                      required
                      className="h-0 opacity-0"
                      autoComplete="off"
                      tabIndex={-1}
                      onFocus={() => {
                        businessLocationRef.current.focus();
                      }}
                      onChange={() => null}
                    />
                  </div>
                </div>

                <GooglePlacesAutocomplete
                  debounce={700}
                  selectProps={{
                    ref: businessLocationRef,
                    value: merchantInfo.latitude
                      ? {
                          label:
                            merchantInfo.address_line ||
                            `${merchantInfo.latitude}${merchantInfo.longitude}`,
                          value: `${merchantInfo.latitude}${merchantInfo.longitude}`,
                        }
                      : null,
                    onChange: (data, actionMeta) => {
                      if (actionMeta.action === 'clear') {
                        setMerchantInfo(info => ({
                          ...info,
                          latitude: null,
                          longitude: null,
                          address_line: null,
                          location_message: '',
                        }));
                      }
                      if (data) {
                        geocodeByPlaceId(data.value.place_id)
                          .then(results => {
                            const latitude = results[0].geometry.location.lat();
                            const longitude =
                              results[0].geometry.location.lng();
                            const address_line = results[0].formatted_address;
                            setMerchantInfo(info => ({
                              ...info,
                              latitude,
                              longitude,
                              address_line,
                              location_message: '',
                            }));
                          })
                          .catch(error => console.error(error));
                      }
                    },
                    placeholder: 'Type to search location',
                    menuPlacement: 'auto',
                    openMenuOnClick: false,
                    isClearable: true,
                    components: {
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    },
                    styles: selectStyles,
                    theme: theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#ccc',
                        primary: '#ff8e22',
                      },
                    }),
                  }}
                />
                <div className="text-primary text-xs font-semibold mt-1">
                  {merchantInfo.location_message}
                </div>
              </div>
              <div className={cn(st.field, st.offeringField, 'override')}>
                <label htmlFor="contact_phone">
                  Business Contact No. / Website
                </label>
                <TextField
                  value={merchantInfo.contact_phone || ''}
                  name="contact_phone"
                  id="contact_phone"
                  onChange={handleUpdateMerchantInfo}
                  fullWidth
                  variant="outlined"
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default OfferingMerchantInfo;
