import AppToolTip from '@ictlife/marketplace/components/shared/ToolTip';
import { IconButton, Skeleton } from '@mui/material';
import cn from 'classnames';
import s from './styles/link-preview.module.scss';

const LinkPreview = ({ linkMetaData, limitLines, setRemovePreviewLink }) => {
  const { url, title, description, logo, publisher, image } = linkMetaData;
  return (
    <div className={s.linkWrapper}>
      {setRemovePreviewLink && (
        <div className={s.close}>
          <AppToolTip title="Remove link preview" placement="top">
            <IconButton onClick={() => setRemovePreviewLink(true)}>
              <span className="material-icons">&#xe5c9;</span>
            </IconButton>
          </AppToolTip>
        </div>
      )}

      <a className={s.linkPreview} href={url} target="_blank" rel="noreferrer">
        <div className={s.images}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          {image && <img src={image?.url} />}
        </div>
        <div className={s.details}>
          <div
            className={cn(s.title, 'font-semibold', {
              'line-clamp-2': limitLines,
            })}
          >
            {title}
          </div>
          <div
            className={cn(s.description, {
              'line-clamp-4': limitLines,
            })}
          >
            {description}
          </div>
          <div className={s.site}>
            <div className={s.logo}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              {logo && <img src={logo.url} />}
            </div>
            <div className={s.url}>{publisher}</div>
          </div>
        </div>
      </a>
    </div>
  );
};

export const LinkPreviewSkeleton = () => {
  return (
    <div className={s.linkPreview}>
      <div className={s.images}>
        <Skeleton variant="rounded" height={100} width={100} />
      </div>
      <div className={s.details}>
        <div className={s.title}>
          <Skeleton />
        </div>
        <div className={s.description}>
          <Skeleton />
          <Skeleton width="30%" />
        </div>
        <div className={s.site}>
          <div className={s.logo}>
            <Skeleton width={20} height={20} variant="rounded" />
          </div>
          <div className={s.url}>
            <Skeleton width="15%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkPreview;
