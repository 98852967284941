/**
 *
 * @param {*} count
 * @param {*} singular
 * @param {*} plural
 * @returns string
 */

export default function pluralize(count, singular, plural) {
  if (typeof count !== 'number') return '';
  if (count === 1) return `${count} ${singular}`;
  return `${count} ${plural}`;
}
