import { LOADING, SUCCESS, ERROR } from '../dispatchTypes';

export const initialUploadAssetState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isProgress: false,
  errorMessage: '',
  updatedMediaFiles: [],
};

export const assetUploadReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isProgress: false,
        isLoading: true,
        isError: 'false',
        errorMessage: '',
      };
    }
    case 'PROGRESS': {
      return {
        ...state,
        isProgress: true,
        updatedMediaFiles: action.updatedMediaFiles,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialUploadAssetState;
  }
};
