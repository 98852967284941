import { CircularProgress, Dialog } from '@mui/material';
import React, { useState } from 'react';

const attributeHolderStyles = {
  display: 'grid',
  gap: '1em',
  gridTemplateColumns: '1fr 1fr 1fr max-content',
  marginBottom: '1.5em',
};

const AttributeField = ({ attributeOption, setAttributeOption }) => {
  return (
    <div style={attributeHolderStyles}>
      <div className="app-form">
        <label>Attribute Option name</label>
        <input
          type="text"
          placeholder="Attribute name e.g. color"
          style={{ height: '2.5em', backgroundColor: '#fff' }}
          value={attributeOption.label}
          onChange={e => {
            attributeOption.label = e.target.value;
            setAttributeOption({ ...attributeOption });
          }}
        />
      </div>
    </div>
  );
};

const EditOptionDialog = ({
  setEditOption,
  isLoading,
  isError,
  setError,
  handleEditAttributeOptions,
  editOption,
}) => {
  const [attributeOption, setAttributeOption] = useState({
    label: editOption?.label,
    optionID: editOption?.id,
  });
  const handleCloseDialog = () => {
    setEditOption(false);
    setError();
  };

  return (
    <Dialog open={true} onClose={handleCloseDialog} maxWidth="md">
      <div className="dialog-wrapper">
        <div className="dialog-title">
          <span className="title">Edit Option</span>

          <span className="material-icons icon" onClick={handleCloseDialog}>
            &#xe5cd;
          </span>
        </div>
        <div
          className="dialog-body"
          style={{
            fontSize: '0.95em',
            minHeight: '10em',
            paddingBottom: '2em',
          }}
        >
          <AttributeField
            key={0}
            attributeOption={attributeOption}
            setAttributeOption={setAttributeOption}
          />
        </div>
        <div className="dialog-controls">
          {isError && <div className="error-section">{isError}</div>}
          <div className="button">
            <button
              onClick={handleCloseDialog}
              className="custom-button outlined sm"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>

          <div className="button">
            <button
              className="custom-button sm"
              disabled={isLoading}
              onClick={() => {
                handleEditAttributeOptions(attributeOption);
              }}
            >
              Edit Option
            </button>
            {isLoading && (
              <CircularProgress size={25} className="button-loader" />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default EditOptionDialog;
