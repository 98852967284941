const Social = ({
  twitter,
  facebookLink,
  whatsapp,
  copyLink,
  copyCallback,
  recordResourceShareHandler,
}) => {
  const openLinkInNewWindow = link => {
    window.open(link, '_blank');
  };
  const shareOnFaceBook = () => {
    window.FB.ui(
      {
        method: 'share',
        href: facebookLink,
      },
      function (response) {
        if (response && !response.error_message && recordResourceShareHandler) {
          recordResourceShareHandler();
        }
      }
    );
  };

  const shareOnWhatsApp = () => {
    const link = `https://api.whatsapp.com/send?text=${whatsapp}`;
    return openLinkInNewWindow(link);
  };

  const shareOnTwitter = () => {
    const link = `https://twitter.com/intent/tweet?text=${twitter}`;
    return openLinkInNewWindow(link);
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(copyLink);
    copyCallback();
  };

  const shareLinkOn = platform => {
    const actions = {
      whatsapp: shareOnWhatsApp,
      twitter: shareOnTwitter,
      facebook: shareOnFaceBook,
      copy: copyToClipBoard,
    };

    if (actions[platform]) return actions[platform]();
    return null;
  };
  return {
    shareLinkOn,
  };
};

export default Social;
