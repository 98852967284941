import {
  GET_QR_JWT_TOKEN,
  QR_JWT_TOKEN_SUCCESS,
  QR_JWT_TOKEN_ERROR,
  REFRESH_QR_TOKEN,
  REFRESH_QR_TOKEN_SUCCESS,
  REFRESH_QR_TOKEN_ERROR,
} from '../dispatchTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QR_JWT_TOKEN:
      return { ...state, isError: false, isSuccess: false, isLoading: true };
    case QR_JWT_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        isInitial: true,
        token: action.data.token,
      };
    case QR_JWT_TOKEN_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case REFRESH_QR_TOKEN:
      return {
        ...state,
        isInitial: false,
        isSuccess: false,
        isRefresh: true,
      };
    case REFRESH_QR_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        token: action.data.token,
      };
    case REFRESH_QR_TOKEN_ERROR:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isInitial: false,
        isError: true,
        error: action.error,
      };
    case 'RESET_QR_CODE_STATE':
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isInitial: false,
        isError: false,
        error: '',
      };
    default:
      return state;
  }
};
