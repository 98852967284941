import { useRef, useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor, EditorState, CompositeDecorator, Modifier } from 'draft-js';
import debounce from 'lodash.debounce';
import { CircularProgress, ClickAwayListener } from '@mui/material';

import { searchTags } from '../state/actions/tagsAction';
import s from './styles/DraftTextEditor.module.scss';

const styles = {
  editor: {
    border: '1px solid #ccc',
    borderRadius: '7px',
    padding: '2px 10px',
  },
  handle: {
    color: '#4b6584',
  },
  hashtag: {
    color: '#2e89ff',
  },
};

const HANDLE_REGEX = /@[\w]+/g;
const HASHTAG_REGEX = /#[\w\u0590-\u05ff]+/g;
const LINK_REGEX = /(\b(https?|):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

function handleStrategy(contentBlock, callback) {
  findWithRegex(HANDLE_REGEX, contentBlock, callback);
}

function hashtagStrategy(contentBlock, callback) {
  findWithRegex(HASHTAG_REGEX, contentBlock, callback);
}

function linkStrategy(contentBlock, callback) {
  findWithRegex(LINK_REGEX, contentBlock, callback);
}

const HandleSpan = props => {
  return (
    <span style={styles.handle} data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};

const HashtagSpan = props => {
  return (
    <span style={styles.hashtag} data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};

const LinkSpan = props => {
  return (
    <span data-offset-key={props.offsetKey}>
      <a href={props.children}>{props.children}</a>
    </span>
  );
};

export const draftCompositeDecorator = new CompositeDecorator([
  {
    strategy: handleStrategy,
    component: HandleSpan,
  },
  {
    strategy: hashtagStrategy,
    component: HashtagSpan,
  },
  {
    strategy: linkStrategy,
    component: LinkSpan,
  },
]);

/**
 *
 * @param {Object} props
 * @param {Object} props.editorState A valid draftJS editor state
 * @param {function} props.setEditorState A function that takes the editorState and sets it to state directly or indirectly
 * @param {String} props.placeholder Editor Placeholder
 */
const DraftTextEditor = ({ editorState, setEditorState, placeholder }) => {
  const dispatch = useDispatch();
  const searchTagsState = useSelector(store => store.searchTagsState);
  const {
    tag_results: { tags },
    isLoading: suggestionsLoading,
  } = searchTagsState;

  const [autoCompleteState, setAutoCompleteState] = useState(null);

  const editorRef = useRef();

  const hasEntityAtSelection = editorState => {
    const selection = editorState.getSelection();
    if (!selection.getHasFocus()) {
      return false;
    }

    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(selection.getStartKey());
    return !!block.getEntityAt(selection.getStartOffset() - 1);
  };

  const getAutocompleteRange = editorState => {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) {
      return null;
    }

    if (hasEntityAtSelection(editorState)) {
      return null;
    }

    const currentContent = editorState.getCurrentContent();
    const currentSelectionState = editorState.getSelection();
    const end = currentSelectionState.getAnchorOffset();
    const anchorKey = currentSelectionState.getAnchorKey();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const blockText = currentBlock.getText();
    let text = blockText.substring(0, end);
    const index = text.lastIndexOf('#');

    const range = selection.getRangeAt(0);
    let t = range.startContainer.textContent;
    t = t.substring(0, range.startOffset);
    const start = t.lastIndexOf('#');
    if (index === -1) {
      return null;
    }
    text = text.substring(index);
    if (/[^a-zA-Z0-9_]+$/.test(text)) {
      return null;
    }

    text = text.replace(/[^\w]/gi, '');
    if (!text) {
      return null;
    }
    return {
      text,
      start,
      end: range.startOffset,
    };
  };

  const getAutocompleteState = editorState => {
    const range = getAutocompleteRange(editorState);
    if (!range) {
      setAutoCompleteState(null);
      return null;
    }

    const tempRange = window.getSelection().getRangeAt(0).cloneRange();
    try {
      tempRange.setStart(tempRange.startContainer, range.start);
    } catch (error) {
      return null;
    }
    const rangeRect = tempRange.getBoundingClientRect();
    let [left, top] = [rangeRect.left, rangeRect.bottom];

    let autocompleteState = {
      left,
      top,
      text: range.text,
      selectedIndex: 0,
    };

    const debouncedFn = debounce(() => {
      searchTags(dispatch, autocompleteState.text, 'posts', 'promotion');
    }, 700);
    debouncedFn();
    setAutoCompleteState(autocompleteState);
    return autocompleteState;
  };

  const insertText = text => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const currentSelectionState = editorState.getSelection();
    const end = currentSelectionState.getAnchorOffset();
    const anchorKey = currentSelectionState.getAnchorKey();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const blockText = currentBlock.getText();
    const start = blockText.substring(0, end).lastIndexOf('#');

    const mentionTextSelection = currentSelection.merge({
      anchorOffset: start,
      focusOffset: end,
    });

    const newContent = Modifier.replaceText(
      currentContent,
      mentionTextSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-hashtag'
    );
    setEditorState(
      EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
    );
    setAutoCompleteState(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      autoCompleteState && setAutoCompleteState(null);
    };

    const elm = document.querySelector('.main-content');

    elm?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      elm?.removeEventListener('scroll', handleScroll);
    };
  }, [autoCompleteState]);

  return (
    <Fragment>
      <div style={styles.editor} className={s.postEditor}>
        <Editor
          editorState={editorState}
          ref={editorRef}
          onChange={newEditorState => {
            if (
              newEditorState.getCurrentContent() !==
              editorState.getCurrentContent()
            ) {
              getAutocompleteState(newEditorState);
            }
            setEditorState(newEditorState);
          }}
          spellCheck
          placeholder={placeholder}
          autoCapitalize="on"
        />
      </div>
      {autoCompleteState && (
        <ClickAwayListener
          onClickAway={() =>
            autoCompleteState ? setAutoCompleteState(null) : undefined
          }
        >
          <div
            style={{
              top: autoCompleteState.top,
              left: autoCompleteState.left,
            }}
            className={s.suggestionsList}
          >
            {tags.findIndex(tag => tag.name === autoCompleteState.text) ===
              -1 && (
              <div
                className={s.suggestion}
                onClick={() => insertText(`#${autoCompleteState.text} `)}
              >
                {`New tag '#${autoCompleteState.text}'`}
              </div>
            )}
            {tags.map(tag => (
              <div
                className={s.suggestion}
                key={tag.id}
                onClick={() => insertText(`#${tag.name} `)}
              >
                {`#${tag.name}`}
              </div>
            ))}
            {suggestionsLoading && (
              <div className={s.loader}>
                <CircularProgress size={15} />
              </div>
            )}
          </div>
        </ClickAwayListener>
      )}
    </Fragment>
  );
};

export default DraftTextEditor;
