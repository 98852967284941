import {
  ASSOSIATED_NUMBERS,
  ASSOSIATED_NUMBERS_SUCCESS,
  ASSOSIATED_NUMBERS_ERROR,
} from '../dispatchTypes';

const initialAssociatedNumbersState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  associatedNumbers: [],
};

export const associatedNumbersReducer = (
  state = initialAssociatedNumbersState,
  action
) => {
  switch (action.type) {
    case ASSOSIATED_NUMBERS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ASSOSIATED_NUMBERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        associatedNumbers: action.associatedNumbers,
      };
    }
    case ASSOSIATED_NUMBERS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};
