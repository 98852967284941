import {
  LIST_REPORT_REASONS,
  LIST_REPORT_REASONS_SUCCESS,
  LIST_REPORT_REASONS_ERROR,
  REPORT_ISSUE,
  REPORT_ISSUE_SUCCESS,
  REPORT_ISSUE_ERROR,
} from '../dispatchTypes';

const initialReportReasonsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  flagReasons: [],
};

export const reportReasonsReducer = (
  state = initialReportReasonsState,
  action
) => {
  switch (action.type) {
    case LIST_REPORT_REASONS: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    }
    case LIST_REPORT_REASONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        flagReasons: action.flagReasons,
      };
    }
    case LIST_REPORT_REASONS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const initialReportState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const reportReducer = (state = initialReportState, action) => {
  switch (action.type) {
    case REPORT_ISSUE: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    }
    case REPORT_ISSUE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        reportData: action.reportData,
      };
    }
    case REPORT_ISSUE_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    case 'RESET_REPORT': {
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: '',
      };
    }
    default:
      return state;
  }
};
