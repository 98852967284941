import { recordUserActivity } from '../state/actions/analyticsAction';
import { getSessionTokenCookie } from './cookies';

const logUserActivity = ({ action, dispatch }) => {
  const session_token = getSessionTokenCookie();

  const bodyData = {
    action,
    session_token,
  };
  recordUserActivity(dispatch, bodyData);
};

export default logUserActivity;
