import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getAboutBusiness } from '@ictlife/merchant/state/actions/aboutBusinessAction';
import { getBusinessKeywords } from '@ictlife/merchant/state/actions/businessKeywordsAction';
import {
  getReviewsSummary,
  // getReviewTags,
} from '@ictlife/merchant/state/actions/businessReviewsAction';
import { getMerchantTags } from '@ictlife/merchant/state/actions/tagsAction';
import {
  getMerchantProfile,
  getMerchantProfileSummary,
} from '@ictlife/merchant/state/actions/merchantProfileAction';

const useAccountInit = ({ initialServerSideState }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { b_username } = router.query;

  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      merchant: { id: merchant_id, business_username },
    },
  } = merchantProfileState;

  useEffect(() => {
    if (b_username !== business_username)
      dispatch({
        type: 'RESET_STORE',
        persistedState: initialServerSideState?.specificPostState && {
          specificPostState: initialServerSideState?.specificPostState,
        },
      });
  }, [
    b_username,
    dispatch,
    initialServerSideState?.specificPostState,
    business_username,
    initialServerSideState,
  ]);

  useEffect(() => {
    if (merchant_id && b_username === business_username) {
      getAboutBusiness(dispatch, merchant_id);
      getBusinessKeywords(dispatch, merchant_id);
      getMerchantProfileSummary(dispatch, merchant_id);
      getReviewsSummary(dispatch, merchant_id);
      getMerchantTags(dispatch, merchant_id, 'posts', 'promotion');
      // getReviewTags(dispatch, merchant_id);
    }
  }, [b_username, business_username, dispatch, merchant_id]);

  // for businesses that are not live and for pages that don't SSR
  useEffect(() => {
    if (business_username === '' && b_username) {
      getMerchantProfile(dispatch, b_username, false);
    }
  }, [b_username, business_username, dispatch]);
};

export default useAccountInit;
