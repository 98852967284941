import { combineReducers } from 'redux';
import { businessesReducer } from './businessesReducer';
import { socketEventsReducer } from './socketEventsReducer';

export const CORE_REDUCERS = {
  socketEventsState: socketEventsReducer,
  businessesState: businessesReducer,
};

const rootReducer = combineReducers(CORE_REDUCERS);

export default rootReducer;
