import { Transition } from '@ictlife/merchant/components/posts/AddPost';
import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import Cookies from '@ictlife/core/config/cookies';
import firebaseCloudMessaging from '../../utils/firebaseCloudMessaging';

const NotificationRequestDialog = ({
  setOpenNotificationPopper,
  notificationConfigs,
}) => {
  const [permissionStage, setPermissionStage] = useState('');
  const appThemeState = useSelector(store => store.appThemeState);
  const { theme } = appThemeState;

  const { firebaseConfig, icon, DialogBody } = notificationConfigs;

  const handleUserNotificationChoice = remind_after_days => {
    if (remind_after_days) {
      Cookies.set('ictl_push_notification_status', 'remind_later', {
        expires: remind_after_days,
      });
    }

    setOpenNotificationPopper(false);
  };

  const handleInitializePushNotifications = () => {
    setPermissionStage('requesting');

    const { init, handleRegisterPushToken } = firebaseCloudMessaging;
    init(firebaseConfig)
      .then(pushToken => {
        setPermissionStage('');
        setOpenNotificationPopper(false);
        handleRegisterPushToken(pushToken)
          .then(() => {
            Cookies.set(
              'ictl_push_notification_status',
              process.env.NEXT_PUBLIC_SOURCE_PACKAGE,
              {
                expires: 1,
              }
            );
          })
          .catch(() =>
            Cookies.set('ictl_push_notification_status', 'granted', {
              expires: 2100,
            })
          )
          .finally(() => {
            new Notification('Notifications set successfully!', {
              body: 'You will be notified this way when you are away',
              icon,
            });
          });
      })
      .catch(error => {
        // ex. codes: messaging/permission-blocked, messaging/unsupported-browser
        Cookies.set('ictl_push_notification_status', error?.code, {
          expires: 2100,
          sameSite: 'Strict',
        });

        if (
          error?.code === 'messaging/permission-blocked' ||
          error?.code === 'messaging/unsupported-browser'
        ) {
          setPermissionStage(error.code);
        } else {
          new Notification('Notifications set successfully!', {
            body: 'You will be notified this way when you are away',
            icon,
          });
          setOpenNotificationPopper(false);
        }
      });
  };

  const getControls = () => {
    switch (permissionStage) {
      case 'requesting':
        return (
          <div className="dialog-controls">
            <div
              className="theme-text"
              style={{ display: 'grid', alignItems: 'center' }}
            >
              Click &quot;Allow&quot; in the prompt above to enable
            </div>
            <div className="button">
              <button
                className="custom-button sm outlined lc"
                onClick={() => handleUserNotificationChoice()}
              >
                <span>OK</span>
              </button>
            </div>
          </div>
        );

      case 'messaging/permission-blocked':
        return (
          <div className="dialog-controls">
            <div className="error-section">
              Permission denied. You can change this later by visiting your
              browser settings
            </div>
            <div className="button">
              <button
                className="custom-button sm outlined lc"
                onClick={() => handleUserNotificationChoice()}
              >
                <span>OK</span>
              </button>
            </div>
          </div>
        );

      case 'messaging/unsupported-browser':
        return (
          <div className="dialog-controls">
            <div className="error-section">
              This browser version does not support push notifications. Try
              another browser.
            </div>
            <div className="button">
              <button
                className="custom-button sm outlined lc"
                onClick={() => handleUserNotificationChoice()}
              >
                <span>OK</span>
              </button>
            </div>
          </div>
        );

      case '':
        return (
          <div className="dialog-controls">
            <div className="button">
              <button
                className="custom-button sm outlined lc"
                onClick={() => handleUserNotificationChoice(7)}
              >
                <span>Later</span>
              </button>
            </div>
            <div className="button">
              <button
                className="custom-button sm lc with-icon"
                onClick={handleInitializePushNotifications}
              >
                <span className="material-icons">&#xe7f7;</span>
                <span>Turn On Push Notifications</span>
              </button>
            </div>
          </div>
        );

      default:
        return (
          <div className="dialog-controls">
            <div className="error-section">{permissionStage}</div>
            <div className="button">
              <button
                className="custom-button sm outlined lc"
                onClick={() => handleUserNotificationChoice()}
              >
                <span>OK</span>
              </button>
            </div>
          </div>
        );
    }
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      className={cn('override', theme)}
    >
      <div className="dialog-wrapper">
        <div className="dialog-title">
          <span className="title">Be notified when you are away</span>
          <span
            className="material-icons icon"
            onClick={() => handleUserNotificationChoice(30)}
          >
            &#xe5cd;
          </span>
        </div>

        <DialogBody />

        {getControls()}
      </div>
    </Dialog>
  );
};

export default NotificationRequestDialog;
