import axiosConfig from '../../config/axiosConfig';
import {
  GET_CHAT_LIST,
  GET_CHAT_LIST_SUCCESS,
  GET_CHAT_LIST_ERROR,
  GET_USER_ACTIVITY,
  GET_USER_ACTIVITY_SUCCESS,
  GET_USER_ACTIVITY_ERROR,
} from '../dispatchTypes';

export const getChatList = (dispatch, businessId) => {
  dispatch({
    type: GET_CHAT_LIST,
  });

  return axiosConfig
    .get(`/merchants/${businessId}/interactive_users`)
    .then(response => {
      dispatch({
        type: GET_CHAT_LIST_SUCCESS,
        interactive_users: response.data.interactive_users,
      });

      return response.data.interactive_users;
    })
    .catch(error => {
      dispatch({
        type: GET_CHAT_LIST_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_CHAT_LIST_ERROR,
        errorMessage: 'Failed to load chat list',
      });
    });
};

export const getUserActivity = ({
  dispatch,
  businessId,
  userId,
  activeTab,
  activityInfo,
}) => {
  dispatch({
    type: GET_USER_ACTIVITY,
  });

  const { page, per } = activityInfo;

  axiosConfig
    .get(
      `/merchants/${businessId}/user/${userId}/activities?event_type=${activeTab}&per=${per}&page=${page}`
    )
    .then(response => {
      dispatch({
        type: GET_USER_ACTIVITY_SUCCESS,
        userActivity: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_USER_ACTIVITY_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_USER_ACTIVITY_ERROR,
        errorMessage: 'Failed to load user activity',
      });
    });
};
