import { CircularProgress, Dialog } from '@mui/material';
import React from 'react';

const DeleteCategoryDialog = ({
  deleteCategory,
  setDeleteCategory,
  isLoading,
  handleDeleteCategory,
  errorDeletingCategory,
  setErrorDeletingCategory,
  handleClearInput,
}) => {
  const handleCloseDialog = () => {
    setDeleteCategory();
    setErrorDeletingCategory();
    handleClearInput();
  };
  return (
    <Dialog open={true} onClose={handleCloseDialog}>
      <div className="dialog-wrapper">
        <div className="dialog-title">
          <span className="title">Delete category</span>

          <span className="material-icons icon" onClick={handleCloseDialog}>
            &#xe5cd;
          </span>
        </div>
        <div
          className="dialog-body"
          style={{
            fontSize: '0.95em',
          }}
        >
          <div>
            Are you sure you want to delete{' '}
            <span style={{ fontWeight: '600' }}>
              {' '}
              &apos;{deleteCategory.label}&apos;
            </span>{' '}
            category
          </div>
        </div>
        <div className="dialog-controls">
          {errorDeletingCategory && (
            <div className="error-section">{errorDeletingCategory}</div>
          )}
          <div className="button">
            <button
              onClick={handleCloseDialog}
              className="custom-button outlined sm"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>

          <div className="button">
            <button
              className="custom-button sm"
              disabled={isLoading}
              onClick={() => handleDeleteCategory(deleteCategory)}
            >
              DELETE CATEGORY &apos;{deleteCategory.label}&apos;
            </button>
            {isLoading && (
              <CircularProgress size={25} className="button-loader" />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default DeleteCategoryDialog;
