import React, { useState, Fragment, useReducer, useEffect } from 'react';
import { CircularProgress, Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import Cookies from '@ictlife/core/config/cookies';

import DateFormatter from '@ictlife/core/components/date';

import {
  likePostComment,
  deletePostComment,
  unLikePostComment,
} from '../../state/actions/postCommentsAction';
import {
  deletePostCommentReducer,
  initialDeletePostCommentState,
} from '../../state/reducers/postCommentsReducer';
import {
  initialLikePostState,
  likePostReducer,
} from '../../state/reducers/postsReducer';
import { getLikesList } from '../../state/actions/postsAction';

import AppAvatar from '../../utils/AppAvatar';
import PostCommentReplies from './PostCommentReplies';
import { Transition } from './AddPost';
import styles from './styles/PostComments.module.scss';

const PostComments = ({
  idx,
  comment,
  setComments,
  postReplyId,
  activeImage,
  notificationReply,
  setNotificationReply,
  notifCommentId,
  setNotifCommentId,
}) => {
  const [openLikeList, setOpenLikeList] = useState(false);

  const dispatch = useDispatch();
  const userInfoState = useSelector(store => store.userInfoState);
  const {
    user: { id: user_id },
  } = userInfoState;

  const appThemeState = useSelector(store => store.appThemeState);
  const { theme } = appThemeState;

  const [commentLikeId, setCommentLikeId] = useState(
    comment.user_like ? comment.user_like.id : null
  );
  const [commentLikesCount, setCommentLikesCount] = useState(
    comment.likes_count || 0
  );
  const [commentRepliesCount, setCommentRepliesCount] = useState(
    comment.replies_count || 0
  );
  const [showComments, setShowComments] = useState(
    notificationReply?.original_post_comment_id === comment.id ? true : false
  );
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (notificationReply?.original_post_comment_id === comment.id) {
      setShowComments(true);
    }
  }, [notificationReply, comment.id]);

  const [deletePostCommentState, dispatchDeletePostComment] = useReducer(
    deletePostCommentReducer,
    initialDeletePostCommentState
  );
  const [likeCommentState, dispatchLikeComment] = useReducer(
    likePostReducer,
    initialLikePostState
  );

  const socketEventsState = useSelector(store => store.socketEventsState);

  const { socketReply, socketCommentLike } = socketEventsState;

  useEffect(() => {
    if (socketReply && socketReply.original_post_comment_id === comment.id) {
      setCommentRepliesCount(count => ++count);
      dispatch({ type: 'CLEAR_POST_COMMENT_REPLY_IN_SOCKET' });
    }
  }, [comment.id, dispatch, socketReply]);

  const handleToggleDialog = () => {
    setOpenDialog(open => !open);
  };

  const handleDeleteComment = () => {
    return deletePostComment(dispatchDeletePostComment, comment.id).then(() => {
      setComments(draft => {
        if (draft[activeImage.id]) {
          draft[activeImage.id].comments.splice(idx, 1);
          draft[activeImage.id].pagination.count -= 1;
        }
      });
      handleToggleDialog();
      dispatchDeletePostComment({ type: 'DEFAULT' });
    });
  };

  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      merchant: { id: business_id, business_name, business_username },
      profile_photo,
    },
    isBusinessOwner,
  } = merchantProfileState;

  const handleToggleCommentLike = () => {
    if (likeCommentState.isLoading) {
      return;
    }
    dispatchLikeComment({ type: 'LOADING' });
    setCommentLikesCount(count => (commentLikeId ? --count : ++count));
    if (!commentLikeId) {
      const bodyData = {
        merchant_post_comment_id: comment.id,
        user_id,
      };

      if (isBusinessOwner) {
        bodyData.user_merchant_id = business_id;
      }

      setCommentLikeId(true);
      likePostComment(dispatchLikeComment, bodyData);
    } else {
      setCommentLikeId(null);
      unLikePostComment(dispatchLikeComment, commentLikeId);
    }
  };

  const likesListState = useSelector(store => store.likesListState);
  const {
    likesList: { merchant_post_comment_likes, pagination: likesListPagination },
    isLoading: likesListLoading,
    isError: likesListError,
    isSuccess: likesListSuccess,
  } = likesListState;

  if (likeCommentState.isSuccess) {
    openLikeList &&
      getLikesList(
        dispatch,
        null,
        null,
        merchant_post_comment_likes,
        comment.id,
        null
      );
    setCommentLikeId(likeCommentState.likeId);
    dispatchLikeComment({ type: 'DEFAULT' });
  }

  if (likeCommentState.isError) {
    setCommentLikesCount(count => (commentLikeId ? --count : ++count));
    setCommentLikeId(likeCommentState.rollBackId);
    dispatchLikeComment({ type: 'DEFAULT' });
  }

  useEffect(() => {
    if (socketCommentLike?.merchant_post_comment_id === comment.id) {
      setCommentLikesCount(count => ++count);
      dispatch({ type: 'CLEAR_POST_COMMENT_LIKE_IN_SOCKET' });
    }
  }, [comment.id, dispatch, socketCommentLike]);

  const isLoggedIn = Cookies.get('ictl_token');

  useEffect(() => {
    if (notifCommentId) {
      const commentCardElements = document.querySelectorAll(
        `.comment-${notifCommentId}`
      );
      setNotifCommentId(null);

      commentCardElements.forEach(commentCardElement => {
        setTimeout(() => {
          commentCardElement?.classList.remove(styles.targetResource);
        }, 1600);
        commentCardElement?.classList.add(styles.targetResource);
      });
    }
  }, [notifCommentId, setNotifCommentId]);

  return (
    <Fragment>
      <div
        className={cn({
          [styles.commentCard]: true,
          [styles.merchantMessage]:
            comment.merchant_info?.merchant.id === business_id,
          [`comment-${comment.id}`]: true,
        })}
      >
        <div className={styles.commentHolder}>
          <div className={styles.commentHeader}>
            <div className={styles.left}>
              <div className={styles.commentAvatar}>
                <AppAvatar
                  src={
                    comment.merchant_info?.profile_photo?.url ||
                    comment.user.profile_photo?.thumbnail_url
                  }
                  alt={
                    comment.merchant_info?.merchant.business_name ||
                    `${comment.user.first_name} ${comment.user.last_name}`
                  }
                />
              </div>
              <div
                className={cn({
                  [styles.commentIdentity]: true,
                  [styles.externalMerchant]:
                    comment.merchant_info &&
                    comment.merchant_info.merchant.id !== business_id,
                })}
                onClick={
                  comment.merchant_info &&
                  comment.merchant_info.merchant.id !== business_id
                    ? () =>
                        window.open(
                          `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${comment.merchant_info?.merchant.business_username}`,
                          '_blank'
                        )
                    : undefined
                }
              >
                <span>
                  {comment.merchant_info?.merchant.business_name ||
                    `${comment.user.first_name} ${comment.user.last_name} ${
                      user_id === comment.user.id ? '(You)' : ''
                    }`}
                </span>
                {comment.merchant_info && (
                  <span className={styles.username}>
                    @{comment.merchant_info?.merchant.business_username}
                  </span>
                )}
              </div>
            </div>

            <div className={styles.commentTime}>
              <DateFormatter date={comment.created_at} />
            </div>
          </div>
          <div className={styles.commentBody}>{comment.message}</div>
          <div className={styles.commentFooter}>
            {commentRepliesCount > 0 && (
              <div
                className={styles.replyCount}
                onClick={() =>
                  commentRepliesCount > 0 &&
                  setShowComments(showComments => !showComments)
                }
              >
                {showComments ? (
                  <span className="material-icons">&#xe5c7;</span>
                ) : (
                  <span className="material-icons">&#xe5c5;</span>
                )}

                {`${
                  commentRepliesCount > 0
                    ? showComments
                      ? 'hide'
                      : 'show'
                    : ''
                }  ${commentRepliesCount} ${
                  commentRepliesCount === 1 ? 'reply' : 'replies'
                }`}
              </div>
            )}

            <div
              className={cn({
                [styles.likeCount]: true,
                'theme-text': commentLikeId,
              })}
            >
              <span
                onClick={handleToggleCommentLike}
                className="material-icons"
              >
                &#xe817;
              </span>

              <div
                onClick={() => {
                  if (isLoggedIn) {
                    getLikesList(dispatch, null, null, null, comment.id, null);
                    setOpenLikeList(true);
                  } else {
                    dispatch({ type: 'OPEN_LOGIN_POPUP', isOpen: true });
                  }
                }}
              >
                {`  ${commentLikesCount} ${
                  commentLikesCount === 1 ? 'like' : 'likes'
                }`}
              </div>
            </div>
            <div
              className={styles.iconButton}
              onClick={() => {
                setShowComments(true);
                const element = document.querySelector(
                  `#field-comment-id-${comment.id}`
                );
                if (element !== null) {
                  element.scrollIntoView(false);
                  document.getElementById(postReplyId).scrollIntoView(false);
                  document
                    .getElementById(`input-comment-id-${comment.id}`)
                    .focus();
                }
              }}
            >
              <span
                title={
                  showComments ? 'hide reply section' : 'reply to this comment'
                }
                className="material-icons"
              >
                &#xe15e;
              </span>

              <span>Reply</span>
            </div>
            {user_id === comment.user.id && (
              <div
                className={styles.iconButton}
                onClick={
                  !deletePostCommentState.isLoading && handleToggleDialog
                }
              >
                {deletePostCommentState.isLoading ? (
                  <CircularProgress size={15} />
                ) : (
                  <span className="material-icons">&#xe872;</span>
                )}
                <span>Delete</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {showComments && (
        <div className={styles.commentRepliesWrapper}>
          <PostCommentReplies
            comment={comment}
            setCommentRepliesCount={setCommentRepliesCount}
            commentRepliesCount={commentRepliesCount}
            notificationReply={notificationReply}
            setNotificationReply={setNotificationReply}
          />
        </div>
      )}

      <Dialog
        open={openLikeList}
        onClose={() => setOpenLikeList(false)}
        TransitionComponent={Transition}
        className={`override likes-list-wrapper`}
      >
        <div className={`likes-list-holder ${theme || ''}`}>
          <Fragment>
            <div className="likes-list-header">
              <div className="likes-count">
                {commentLikesCount !== 0
                  ? `${commentLikesCount} ${
                      commentLikesCount === 1
                        ? 'person likes this comment'
                        : 'people like this comment'
                    } `
                  : 'Be the first to like this comment'}
              </div>
              <div
                className={cn({
                  like: true,
                  'theme-text': commentLikeId,
                })}
                onClick={() => handleToggleCommentLike()}
              >
                <span className="material-icons">&#xe8dc;</span>
                <span>like</span>
              </div>
            </div>
            <div className="likes-list-body">
              {likesListSuccess && merchant_post_comment_likes?.length > 0 ? (
                merchant_post_comment_likes?.map(like => (
                  <div key={like.id} className="user-like">
                    <div className="display-pic">
                      <AppAvatar
                        src={
                          !like.by_merchant_user
                            ? like.user.profile_photo?.thumbnail_url
                            : profile_photo?.url
                        }
                        alt={
                          !like.by_merchant_user
                            ? `${like.user.first_name} ${like.user.last_name}`
                            : business_name
                        }
                      />
                    </div>
                    <div className="name">
                      {like.by_merchant_user ? (
                        <div className="business-like">
                          <div>{business_name}</div>
                          <div className="username">@{business_username}</div>
                        </div>
                      ) : (
                        <div className="business-like">
                          <div>{`${like.user.first_name} ${like.user.last_name}`}</div>
                          <div className="username">
                            <DateFormatter date={like.created_at} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="loading-list">
                  -- People who like this will appear here --
                </div>
              )}
              {likesListLoading && (
                <div className="loading-list">
                  <CircularProgress size={20} />
                </div>
              )}

              {!likesListLoading &&
                merchant_post_comment_likes?.length !==
                  likesListPagination.count &&
                !likesListError && (
                  <div className="loading-list">
                    <button
                      className="custom-button outlined sm"
                      onClick={() =>
                        getLikesList(
                          dispatch,
                          null,
                          null,
                          merchant_post_comment_likes,
                          comment.id,
                          likesListPagination
                        )
                      }
                    >
                      See more
                    </button>
                  </div>
                )}

              {likesListError && (
                <div className="loading-list">
                  <div
                    className="custom-button outlined sm"
                    onClick={() =>
                      getLikesList(
                        dispatch,
                        null,
                        null,
                        merchant_post_comment_likes,
                        null,
                        likesListPagination
                      )
                    }
                  >
                    Retry
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        </div>
      </Dialog>
      <Dialog open={openDialog} onClose={handleToggleDialog}>
        <div className="dialog-wrapper">
          <div className="dialog-title">
            <span className="title">Delete Comment</span>
            <span className="material-icons icon" onClick={handleToggleDialog}>
              &#xe5cd;
            </span>
          </div>
          <div className="dialog-body">
            You are about to delete this comment permanently.
          </div>
          <div className="dialog-controls">
            {deletePostCommentState && (
              <div className="error-section">
                {deletePostCommentState.errorMessage}
              </div>
            )}
            <div className="button">
              <button
                onClick={handleToggleDialog}
                className="custom-button sm outlined"
                disabled={deletePostCommentState.isLoading}
              >
                Cancel
              </button>
            </div>

            <div className="button">
              <button
                onClick={() => handleDeleteComment()}
                className="custom-button sm"
                disabled={deletePostCommentState.isLoading}
              >
                {deletePostCommentState.isLoading ? 'Deleting...' : 'Delete'}
              </button>
              {deletePostCommentState.isLoading && (
                <CircularProgress size={25} className="button-loader" />
              )}
            </div>
          </div>
        </div>
      </Dialog>
      <hr className={styles.commentSeparator} />
    </Fragment>
  );
};

export default PostComments;
