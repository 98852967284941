import postBoundary from '@ictlife/core/boundaries/posts/specificpost';

import {
  LOADING,
  SUCCESS,
  ERROR,
  GET_POSTS,
  GET_POSTS_SUCCESS,
  GET_POSTS_ERROR,
  GET_POST,
  GET_POST_SUCCESS,
  GET_POST_ERROR,
  GET_LIKES_LIST,
  LIKES_LIST_SUCCESS,
  LIKES_LIST_ERROR,
} from '../dispatchTypes';

export const initialBusinessPostsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  posts: {
    merchant_posts: [],
    pagination: {
      count: 0,
      page: null,
      per: null,
    },
  },
};

export const businessPostsReducer = (
  state = initialBusinessPostsState,
  action
) => {
  switch (action.type) {
    case GET_POSTS: {
      return {
        ...state,
        isError: false,
        isLoading: true,
      };
    }
    case GET_POSTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        posts: action.posts,
      };
    }
    case GET_POSTS_ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }

    case 'OPEN_ADD_POST':
      return {
        ...state,
        openAddPost: action.openAddPost,
      };

    case 'CLEAR_POSTS_MESSAGES': {
      return {
        ...state,
        isError: false,
        isSuccess: false,
        isLoading: false,
        errorMessage: '',
      };
    }

    default:
      return state;
  }
};

export const initialLikeState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  likes: {
    post_likes: 0,
    count: 0,
  },
};

export const getLikesReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        likes: action.likes,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialLikeState;
  }
};

export const initialLikePostState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const likePostReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        likeId: action.likeId,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
        rollBackId: action.rollBackId,
      };
    }
    default:
      return initialLikePostState;
  }
};

export const initialPublishPostState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const PublishPostReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        post: action.post,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isSuccess: false,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialPublishPostState;
  }
};

export const initialUploadPostMediaState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isProgress: false,
  errorMessage: '',
  updatedMediaFiles: [],
};

export const UploadPostMediaReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isProgress: false,
        isLoading: true,
        isSuccess: false,
      };
    }
    case 'PROGRESS': {
      return {
        ...state,
        isProgress: true,
        updatedMediaFiles: action.updatedMediaFiles,
        postId: action.postId,
        milestone: action.milestone,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }

    case 'CLEAR_LOOP': {
      return {
        ...state,
        isError: false,
      };
    }
    default:
      return initialUploadPostMediaState;
  }
};

export const initialDeletePostState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const deletePostReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        deletedPost: action.deletedPost,
        deletedFile: action.deletedFile,
      };
    }
    case ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
        file: action.file,
      };
    }
    case 'ERROR_DISPLAY': {
      return {
        ...state,
        isError: false,
        isLoading: false,
      };
    }
    default:
      return initialDeletePostState;
  }
};

export const initialGetPostState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  post: null,
  errorMessage: '',
};

export const getPostReducer = (state = initialGetPostState, action) => {
  switch (action.type) {
    case GET_POST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
      };
    }
    case GET_POST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        post: postBoundary(action.post),
      };
    }
    case GET_POST_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }

    case 'CLEAR_POST_MESSAGES': {
      return {
        ...state,
        isError: false,
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
      };
    }
    default:
      return state;
  }
};

export const initialGetLikesListState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  likesList: {
    merchant_post_likes: [],
    pagination: {
      per: 20,
      page: 1,
    },
  },
  errorMessage: '',
};

export const getLikesListReducer = (
  state = initialGetLikesListState,
  action
) => {
  switch (action.type) {
    case GET_LIKES_LIST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
        likesList: action.likesList,
      };
    }
    case LIKES_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        likesList: action.likesList,
      };
    }
    case LIKES_LIST_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};
