export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const RESET = 'RESET';

export const SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE';
export const VERIFICATION_CODE_SUCCESS = 'VERIFICATION_CODE_SUCCESS';
export const VERIFICATION_CODE_ERROR = 'VERIFICATION_CODE_ERROR';
export const RESET_VERIFICATION_CODE_MESSAGES =
  'RESET_VERIFICATION_CODE_MESSAGES';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const RESET_REGISTER_USER_MESSAGES = 'RESET_REGISTER_USER_MESSAGES';

export const GET_BUSINESS_LIST = 'GET_BUSINESS_LIST';
export const GET_BUSINESS_LIST_SUCCESS = 'GET_BUSINESS_LIST_SUCCESS';
export const GET_BUSINESS_LIST_ERROR = 'GET_BUSINESS_LIST_ERROR';

export const BUSINESS_PROFILE = 'BUSINESS_PROFILE';
export const BUSINESS_PROFILE_SUCCESS = 'BUSINESS_PROFILE_SUCCESS';
export const BUSINESS_PROFILE_ERROR = 'BUSINESS_PROFILE_ERROR';
export const BUSINESS_PROFILE_UPDATE_SUCCESS =
  'BUSINESS_PROFILE_UPDATE_SUCCESS';
export const BUSINESS_PROFILE_UPDATE_ERROR = 'BUSINESS_PROFILE_UPDATE_ERROR';

export const GET_QR_JWT_TOKEN = 'GET_QR_JWT_TOKEN';
export const QR_JWT_TOKEN_SUCCESS = 'QR_JWT_TOKEN_SUCCESS';
export const QR_JWT_TOKEN_ERROR = 'QR_JWT_TOKEN_ERROR';
export const REFRESH_QR_TOKEN = 'REFRESH_QR_TOKEN';
export const REFRESH_QR_TOKEN_SUCCESS = 'REFRESH_QR_TOKEN_SUCCESS';
export const REFRESH_QR_TOKEN_ERROR = 'REFRESH_QR_TOKEN_ERROR';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';

export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE';
export const PROFILE_PICTURE_SUCCESS = 'PROFILE_PICTURE_SUCCESS';
export const PROFILE_PICTURE_ERROR = 'PROFILE_PICTURE_ERROR';

export const GET_BUSINESS_REVIEWS = 'GET_BUSINESS_REVIEWS';
export const GET_BUSINESS_REVIEWS_SUCCESS = 'GET_BUSINESS_REVIEWS_SUCCESS';
export const GET_BUSINESS_REVIEWS_ERROR = 'GET_BUSINESS_REVIEWS_ERROR';

export const GET_REVIEWS_SUMMARY = 'GET_REVIEWS_SUMMARY';
export const GET_REVIEWS_SUMMARY_SUCCESS = 'GET_REVIEWS_SUMMARY_SUCCESS';
export const GET_REVIEWS_SUMMARY_ERROR = 'GET_REVIEWS_SUMMARY_ERROR';

export const GET_REVIEWS_COMMENTS = 'GET_REVIEWS_COMMENTS';
export const REVIEWS_COMMENTS_SUCCESS = 'REVIEWS_COMMENTS_SUCCESS';
export const REVIEWS_COMMENTS_ERROR = 'REVIEWS_COMMENTS_ERROR';

export const RESPOND_REVIEW = 'RESPOND_REVIEW';
export const RESPOND_REVIEW_SUCCESS = 'RESPOND_REVIEW_SUCCESS';
export const RESPOND_REVIEW_ERROR = 'RESPOND_REVIEW_ERROR';

export const LIKE_REVIEW = 'LIKE_REVIEW';
export const LIKE_REVIEW_SUCCESS = 'LIKE_REVIEW_SUCCESS';
export const LIKE_REVIEW_ERROR = 'LIKE_REVIEW_ERROR';

export const DELETE_REVIEW_COMMENT = 'DELETE_REVIEW_COMMENT';
export const DELETE_REVIEW_COMMENT_SUCCESS = 'DELETE_REVIEW_COMMENT_SUCCESS';
export const DELETE_REVIEW_COMMENT_ERROR = 'DELETE_REVIEW_COMMENT_ERROR';

export const REMOVE_ASSOCIATED_METHOD = 'REMOVE_ASSOCIATED_METHOD';
export const REMOVE_ASSOCIATED_METHOD_SUCCESS =
  'REMOVE_ASSOCIATED_METHOD_SUCCESS';
export const REMOVE_ASSOCIATED_METHOD_ERROR = 'REMOVE_ASSOCIATED_METHOD_ERROR';

export const BUSINESS_KEYWORDS = 'GET_BUSINESS_KEYWORDS';
export const BUSINESS_KEYWORDS_SUCCESS = 'GET_BUSINESS_KEYWORDS_SUCCESS';
export const BUSINESS_KEYWORDS_ERROR = 'GET_BUSINESS_KEYWORDS_ERROR';

export const ASSOSIATED_NUMBERS = 'ASSOSIATED_NUMBERS';
export const ASSOSIATED_NUMBERS_SUCCESS = 'ASSOSIATED_NUMBERS_SUCCESS';
export const ASSOSIATED_NUMBERS_ERROR = 'ASSOSIATED_NUMBERS_ERROR';

export const GET_POSTS = 'GET_POSTS';
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS';
export const GET_POSTS_ERROR = 'GET_POSTS_ERROR';

export const GET_POST = 'GET_POST';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_ERROR = 'GET_POST_ERROR';

export const ABOUT_BUSINESS = 'GET_ABOUT_BUSINESS';
export const ABOUT_BUSINESS_SUCCESS = 'GET_ABOUT_BUSINESS_SUCCESS';
export const ABOUT_BUSINESS_ERROR = 'GET_ABOUT_BUSINESS_ERROR';

export const UPDATE_ABOUT_BUSINESS = 'UPDATE_ABOUT_BUSINESS';
export const UPDATE_ABOUT_BUSINESS_SUCCESS = 'UPDATE_ABOUT_BUSINESS_SUCCESS';
export const UPDATE_ABOUT_BUSINESS_ERROR = 'UPDATE_ABOUT_BUSINESS_ERROR';

export const GET_PROMOTION_MERCHANT_TAGS = 'GET_PROMOTION_MERCHANT_TAGS';
export const GET_CATEGORY_MERCHANT_TAGS = 'GET_CATEGORY_MERCHANT_TAGS';
export const GET_PROMOTION_MERCHANT_TAGS_SUCCESS =
  'GET_PROMOTION_MERCHANT_TAGS_SUCCESS';
export const GET_CATEGORY_MERCHANT_TAGS_SUCCESS =
  'GET_CATEGORY_MERCHANT_TAGS_SUCCESS';
export const GET_MERCHANT_TAGS_ERROR = 'GET_MERCHANT_TAGS_ERROR';

export const SEARCH_TAGS = 'SEARCH_TAGS';
export const SEARCH_TAGS_SUCCESS = 'SEARCH_TAGS_SUCCESS';
export const SEARCH_TAGS_ERROR = 'SEARCH_TAGS_ERROR';

export const BUSINESS_PROFILE_SUMMARY = 'BUSINESS_PROFILE_SUMMARY';
export const BUSINESS_PROFILE_SUMMARY_SUCCESS =
  'BUSINESS_PROFILE_SUMMARY_SUCCESS';
export const BUSINESS_PROFILE_SUMMARY_ERROR = 'BUSINESS_PROFILE_SUMMARY_ERROR';

export const SEARCH_BUSINESS = 'SEARCH_BUSINESS';
export const SEARCH_BUSINESS_SUCCESS = 'SEARCH_BUSINESS_SUCCESS';
export const SEARCH_BUSINESS_ERROR = 'SEARCH_BUSINESS_ERROR';

export const GET_LIKES_LIST = 'GET_LIKES_LIST';
export const LIKES_LIST_SUCCESS = 'LIKES_LIST_SUCCESS';
export const LIKES_LIST_ERROR = 'LIKES_LIST_ERROR';

export const LIST_SESSIONS = 'LIST_SESSIONS';
export const LIST_SESSIONS_SUCCESS = 'LIST_SESSIONS_SUCCESS';
export const LIST_SESSIONS_ERROR = 'LIST_SESSIONS_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const LIST_REPORT_REASONS = 'LIST_REPORT_REASONS';
export const LIST_REPORT_REASONS_SUCCESS = 'LIST_REPORT_REASONS_SUCCESS';
export const LIST_REPORT_REASONS_ERROR = 'LIST_REPORT_REASONS_ERROR';

export const REPORT_ISSUE = 'REPORT_ISSUE';
export const REPORT_ISSUE_SUCCESS = 'REPORT_ISSUE_SUCCESS';
export const REPORT_ISSUE_ERROR = 'REPORT_ISSUE_ERROR';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_ERROR = 'UPDATE_NOTIFICATION_ERROR';

export const GET_REVIEWS_BREAKDOWN = 'GET_REVIEWS_BREAKDOWN';
export const GET_REVIEWS_BREAKDOWN_SUCCESS = 'GET_REVIEWS_BREAKDOWN_SUCCESS';
export const GET_REVIEWS_BREAKDOWN_ERROR = 'GET_REVIEWS_BREAKDOWN_ERROR';

export const UPDATE_LIVE_STATUS = 'UPDATE_LIVE_STATUS';
export const UPDATE_LIVE_STATUS_SUCCESS = 'UPDATE_LIVE_STATUS_SUCCESS';
export const UPDATE_LIVE_STATUS_ERROR = 'UPDATE_LIVE_STATUS_ERROR';

export const GET_CHAT_LIST = 'GET_CHAT_LIST';
export const GET_CHAT_LIST_SUCCESS = 'GET_CHAT_LIST_SUCCESS';
export const GET_CHAT_LIST_ERROR = 'GET_CHAT_LIST_ERROR';

export const GET_USER_ACTIVITY = 'GET_USER_ACTIVITY';
export const GET_USER_ACTIVITY_SUCCESS = 'GET_USER_ACTIVITY_SUCCESS';
export const GET_USER_ACTIVITY_ERROR = 'GET_USER_ACTIVITY_ERROR';

export const GET_REVIEW_TAGS = 'GET_REVIEW_TAGS';
export const GET_REVIEW_TAGS_SUCCESS = 'GET_REVIEW_TAGS_SUCCESS';
export const GET_REVIEW_TAGS_ERROR = 'GET_REVIEW_TAGS_ERROR';
