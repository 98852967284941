import axios from 'axios';
import Cookies from '../cookies';

import internetConnectionInterceptor from './interceptors/internetConnection';
import unAuthorizedInterceptor from './interceptors/unAuthorized';
import timedOutRequestsInterceptor from './interceptors/timedOutRequests';

const axiosConfig = axios;

const applicationType = {
  merchant: 'merchant',
  marketplace: 'user',
  admin: 'admin',
};

const application = applicationType[process.env.NEXT_PUBLIC_SOURCE_PACKAGE];

axiosConfig.defaults.headers = {
  'Content-Type': 'application/json',
  'X-ICTLIFE-APPLICATION': application,
  'X-CLIENT-IDENTIFIER': 'web',
};

axiosConfig.defaults.headers['X-ICTLIFE-TOKEN'] =
  application === 'admin'
    ? typeof window !== 'undefined' && localStorage.token
    : Cookies.get('ictl_token');

axiosConfig.defaults.baseURL = `${process.env.NEXT_PUBLIC_ICTLIFE_API_URL}/v1`;

internetConnectionInterceptor(axiosConfig);
unAuthorizedInterceptor(axiosConfig);
timedOutRequestsInterceptor(axiosConfig);

export default axiosConfig;
