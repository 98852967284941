import cn from 'classnames';

export default function FilterAttributes({
  mainAttributes,
  setMainAttributes,
}) {
  const handleSelectAttributes = (attribute, value) => {
    const currentValues = mainAttributes[attribute] || [];
    const currentIndex = currentValues.indexOf(value) ?? -1;
    if (currentIndex > -1) {
      currentValues.splice(currentIndex, 1);
    } else {
      currentValues.push(value);
    }
    mainAttributes[attribute] = currentValues;
    setMainAttributes({ ...mainAttributes });
  };
  return (
    <div className="w-2/3 max-w-[25rem] space-y-4">
      <div className="border-gray-900/10">
        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
          <div className="col-span-2 flex flex-col gap-4">
            <div>
              <div className="text-xl font-semibold">
                Tell us more about your target audience
              </div>
              <p>
                You can select multiple options. This helps Africasasa find the
                right audience for your listing.
              </p>
            </div>

            <>
              <div className="col-span-1">
                <label
                  htmlFor="visitor_type"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Visitor type
                </label>
                <div className="mt-1">
                  <div className="flex items-center gap-4 flex-wrap">
                    {[
                      {
                        value: 'resident',
                        label: 'Resident',
                      },
                      {
                        value: 'non_resident',
                        label: 'Non resident',
                      },
                    ].map(option => {
                      const selected =
                        mainAttributes.visitor_type &&
                        mainAttributes.visitor_type.indexOf(option.value) > -1;
                      return (
                        <button
                          key={option.value}
                          className={cn(
                            'border-2 outlined rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-100',
                            {
                              'border-primary': selected,
                            }
                          )}
                          type="button"
                          onClick={() =>
                            handleSelectAttributes('visitor_type', option.value)
                          }
                        >
                          {option.label}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="budget_level"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Budget Level
                </label>
                <div className="mt-1">
                  <div className="flex items-center gap-4 flex-wrap">
                    {[
                      {
                        value: 'free',
                        label: 'Free',
                      },
                      {
                        value: 'friendly',
                        label: 'Friendly',
                      },
                      {
                        value: 'moderate',
                        label: 'Moderate',
                      },
                      {
                        value: 'luxury',
                        label: 'Luxury',
                      },
                      {
                        value: 'premium',
                        label: 'Premium',
                      },
                    ].map(option => {
                      const selected =
                        mainAttributes.budget_level &&
                        mainAttributes.budget_level.indexOf(option.value) > -1;
                      return (
                        <button
                          key={option.value}
                          className={cn(
                            'border-2 outlined rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-100',
                            {
                              'border-primary': selected,
                            }
                          )}
                          type="button"
                          onClick={() =>
                            handleSelectAttributes('budget_level', option.value)
                          }
                        >
                          {option.label}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-span-1">
                <label
                  htmlFor="special_needs"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Special Needs
                </label>
                <div className="mt-1">
                  <div className="flex items-center gap-4 flex-wrap">
                    {[
                      {
                        value: 'none',
                        label: 'None',
                      },
                      {
                        value: 'wheelchair',
                        label: 'Wheelchair',
                      },
                      {
                        value: 'hearing_impaired',
                        label: 'Hearing Impaired',
                      },
                      {
                        value: 'visually_impaired',
                        label: 'Visually Impaired',
                      },
                    ].map(option => {
                      const selected =
                        mainAttributes.special_needs &&
                        mainAttributes.special_needs.indexOf(option.value) > -1;
                      return (
                        <button
                          key={option.value}
                          variant={selected ? 'primary' : 'plain'}
                          className={cn(
                            'border-2 outlined rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-100',
                            {
                              'border-primary': selected,
                            }
                          )}
                          type="button"
                          onClick={() =>
                            handleSelectAttributes(
                              'special_needs',
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="going_with"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Going With
                </label>
                <div className="mt-1">
                  <div className="flex items-center gap-4 flex-wrap">
                    {[
                      {
                        value: 'alone',
                        label: 'Alone',
                      },
                      {
                        value: 'partner',
                        label: 'Partner',
                      },
                      {
                        value: 'colleagues',
                        label: 'Colleagues',
                      },
                      {
                        value: 'friends',
                        label: 'Friends',
                      },
                      {
                        value: 'kids',
                        label: 'Kids',
                      },
                    ].map(option => {
                      const selected =
                        mainAttributes.going_with &&
                        mainAttributes.going_with.indexOf(option.value) > -1;
                      return (
                        <button
                          key={option.value}
                          variant={selected ? 'primary' : 'plain'}
                          className={cn(
                            'border-2 outlined rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-100',
                            {
                              'border-primary': selected,
                            }
                          )}
                          type="button"
                          onClick={() =>
                            handleSelectAttributes('going_with', option.value)
                          }
                        >
                          {option.label}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              {mainAttributes.going_with?.includes('kids') && (
                <div className="col-span-1">
                  <label
                    htmlFor="kids_age"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Kids Age
                  </label>
                  <div className="mt-1">
                    <div className="flex items-center gap-4 flex-wrap">
                      {[
                        {
                          value: 'infants',
                          label: 'Infants',
                        },
                        {
                          value: 'preschoolers',
                          label: 'Pre-Schoolers',
                        },
                        {
                          value: 'young_kids',
                          label: 'Young kids',
                        },
                        {
                          value: 'older_kids',
                          label: 'Older Kids',
                        },
                        {
                          value: 'teenagers',
                          label: 'Teenagers',
                        },
                      ].map(option => {
                        const selected =
                          mainAttributes.kids_age &&
                          mainAttributes.kids_age.indexOf(option.value) > -1;
                        return (
                          <button
                            key={option.value}
                            variant={selected ? 'primary' : 'plain'}
                            className={cn(
                              'border-2 outlined rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-100',
                              {
                                'border-primary': selected,
                              }
                            )}
                            type="button"
                            onClick={() =>
                              handleSelectAttributes('kids_age', option.value)
                            }
                          >
                            {option.label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
