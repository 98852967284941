const seo_meta = {
  title: 'AfricaSasa | Your Trusted Local Marketplace',
  titleTemplate: '%s',
  description: 'Find great events near you',
  openGraph: {
    type: 'website',
    url: process.env.NEXT_PUBLIC_MARKETPLACE_URL,
    site_name: 'AfricaSasa',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/africasasabanner.jpg`,
        width: '840',
        height: '438',
        alt: 'AfricaSasa',
      },
    ],
  },
  twitter: {
    handle: '@goictlife',
    site: '@goictlife',
    cardType: 'summary_large_image',
  },
};

export default seo_meta;
