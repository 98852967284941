import DOMPurify from 'isomorphic-dompurify';

export const LINK_REGEX =
  /(\b(https?|):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

const formatTextWithLinks = ({ description, hashtagid }) => {
  let formatedText = DOMPurify.sanitize(description, {
    ALLOWED_TAGS: [],
    KEEP_CONTENT: false,
  });

  let hashtagRegex = /#(\w+)/g;
  let HandleRegex = /@(\w+)/g;
  if (formatedText) {
    formatedText = formatedText
      .replace(LINK_REGEX, '<a target="_blank" href="$1">$1</a>')
      .replace(
        hashtagRegex,
        `<span
          class="hash-tag ${hashtagid}"
          title="${hashtagid ? 'Filter posts tagged $1' : ''}"
        >#$1</span>`
      )
      .replace(
        HandleRegex,
        `<a class="text-handle" target="_blank" href="${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/$1" title="Visit @$1 in the marketplace">@$1</a>`
      );
  }
  return { __html: formatedText || '-----' };
};

export default formatTextWithLinks;
