import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  addCategoryAttributes,
  createNewAttributeOption,
  createNewCategory,
  deleteAttributeOption,
  deleteCategories,
  deleteCategoryAttributes,
  editAttributeOption,
  editCategory,
  editCategoryAttributes,
  getCategories,
  getCategoryAttributes,
  getSuperCategories,
  useCategoryInformation,
} from '../../state/actions/categoriesAction';
import { deleteTimeAttributeById } from '../../state/actions/timeAttributeAction';
import { DEFAULT_SUPER_CATEGORY } from '../../constants';
import st from './styles/create-offering.module.scss';
import NewCategoryDialog from './dialogs/NewCategoryDialog';
import NewAttributeDialog from './dialogs/NewAttributeDialog';
import EditAttributeDialog from './dialogs/EditAttributeDialog';
import DeleteAttributeDialog from './dialogs/DeleteAttribute';
import EditOptionDialog from './dialogs/EditOptionDialog';
import DeleteOptionDialog from './dialogs/DeleteOptionDialog';
import NewAttributeOptionDialog from './dialogs/NewAttributeOptionDialog';
import EditCategoryDialog from './dialogs/EditCategoryDialog';
import DeleteCategoryDialog from './dialogs/DeleteCategory';
import ScheduledEventCard from './ScheduledEventCard';
import ScheduleEventDialog from './dialogs/ScheduleEventDialog';

const OfferingCategory = ({
  categoriesTree,
  setCategoriesTree,
  offering,
  setCategoryAttributes,
  reFetchCategories,
  selectedSuperCategory,
  setSelectedSuperCategory,
  offeringTimeAttributes = [],
  setOfferingTimeAttributes,
  offering_category_type,
  merchant_category_ids,
  set_merchant_category_ids,
}) => {
  const router = useRouter();
  const { selectedCategory } = router.query;

  // const { superCategory: superCategorySectionSlug } =
  //   useSuperCategoryAndLocation();

  const [loadingAddAttributes, setLoadingAddAttributes] = useState(false);
  const [loadingEditAttributeOptions, setLoadingEditAttributeOptions] =
    useState(false);
  const [errorEditingAttributeOptions, setErrorEditingAttributeOptions] =
    useState(false);
  const [loadingEditAttributes, setLoadingEditAttributes] = useState(false);
  const [errorEditingAttributes, setErrorEditingAttributes] = useState(false);
  const [errorAddingAttributes, setErrorAddingAttributes] = useState(false);
  const [loadingCreateNewCategory, setLoadingCreateNewCategory] =
    useState(false);
  const [loadingAddAttributeOptions, setLoadingAddAttributeOptions] =
    useState(false);
  const [loadingDeleteAttributeOption, setLoadingDeleteAttributeOption] =
    useState(false);
  const [errorDeletingAttributeOption, setErrorDeletingAttributeOption] =
    useState();
  const [errorAddingAttributeOptions, setErrorAddingAttributeOptions] =
    useState();
  const [loadingDeleteCategory, setLoadingDeleteCategory] = useState(false);
  const [errorDeletingCategory, setErrorDeletingCategory] = useState();
  const [loadingDeleteAttribute, setLoadingDeleteAttribute] = useState(false);
  const [errorDeletingAttribute, setErrorDeletingAttribute] = useState();
  const [errorCreatingCategory, setErrorCreatingCategory] = useState();
  const [updateCategory, setUpdateCategory] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [loadingEditCategory, setLoadingEditCategory] = useState(false);
  const [errorEditingCategory, setErrorEditingCategory] = useState(false);
  const [addNewAttribute, setAddNewAttribute] = useState(false);
  const [editAttribute, setEditAttribute] = useState(false);
  const [deleteAttribute, setDeleteAttribute] = useState(false);
  const [editOption, setEditOption] = useState(false);
  const [addNewAttributeOption, setAddNewAttributeOption] = useState(false);
  const [deleteOption, setDeleteOption] = useState(false);
  const [newCategoryObject, setNewCategoryObject] = useState();
  const [categories, setCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState(
    categoriesTree ? categoriesTree[0] : null
  );
  const [openEventScheduleData, setOpenEventScheduleData] = useState();

  // const categorySelectRef = useRef(null);

  const { setSnackInfo } = useSelector(store => store.notificationsState);

  useEffect(() => {
    getSuperCategories().then(superCategories => {
      // setSuperCategories(superCategories || []);
      setSelectedSuperCategory(
        superCategories.find(sc => sc.slug === DEFAULT_SUPER_CATEGORY)
      );

      if (offering?.merchant_category?.super_category_id)
        setSelectedSuperCategory(
          superCategories.find(
            sc => sc.id === offering?.merchant_category?.super_category_id
          )
        );
    });
  }, [
    offering?.merchant_category?.super_category_id,
    setSelectedSuperCategory,
  ]);

  const handleFetchCategoryAttributes = useCallback(
    (category_id, category_slug, parent_id) => {
      getCategoryAttributes({ category_id })
        .then(attributes => {
          setCategoryAttributes(catAttributes => ({
            ...catAttributes,
            [category_slug]: attributes,
          }));

          const indexOfEdit = categoriesTree?.findIndex(
            ({ id }) => id === parent_id
          );

          if (indexOfEdit > -1) {
            const { id = '', slug = '' } = categoriesTree[indexOfEdit];

            getCategoryAttributes({ category_id: id })
              .then(attributes => {
                setCategoryAttributes(catAttributes => ({
                  ...catAttributes,
                  [slug]: attributes,
                }));
              })
              .catch(error => console.error('error', error));
          }
        })
        .catch(error => console.error('error', error));
    },
    [categoriesTree, setCategoryAttributes]
  );

  useEffect(() => {
    setCategoryValue(null);

    if (selectedSuperCategory) {
      getCategories({
        super_category_slug: selectedSuperCategory.slug,
      })
        .then(categories => {
          setCategories(categories.merchant_categories);
        })
        .catch(() => null);
    }
  }, [selectedSuperCategory]);

  useEffect(() => {
    if (categoriesTree?.length > 0) {
      categoriesTree.map(category => {
        handleFetchCategoryAttributes(category.id, category.slug);
      });
    }
  }, [categoriesTree, handleFetchCategoryAttributes, selectedSuperCategory]);

  useCategoryInformation({
    super_category_slug: DEFAULT_SUPER_CATEGORY,
    category_slug: selectedCategory,
    preventFetch:
      !DEFAULT_SUPER_CATEGORY || !selectedCategory || categoriesTree.length > 0,
    onSuccess: category => {
      setCategoriesTree([
        {
          label: category.name,
          slug: category.slug,
          value: category.id,
        },
      ]);
    },
  });

  const handleAddNewCategory = bodyData => {
    setLoadingCreateNewCategory(true);
    setErrorCreatingCategory();
    createNewCategory(bodyData)
      .then(data => {
        const category = {
          label: data.name,
          value: data.id,
          slug: data.slug,
        };
        setCategoriesTree(branches => [...branches, category]);
        if (!bodyData.parent_id) {
          setCategoryValue(category);
        }
        setNewCategoryObject();
      })
      .catch(error =>
        setErrorCreatingCategory(error.response.data.error_message)
      )
      .finally(() => setLoadingCreateNewCategory(false));
  };

  const userInfoState = useSelector(store => store.userInfoState);
  const {
    user: { id: userId },
  } = userInfoState;

  const handleAddAttributeOptions = attributeData => {
    setLoadingAddAttributeOptions(true);

    createNewAttributeOption({
      ...attributeData,
      user_id: userId,
    })
      .then(() => {
        const { attribute: selectedCategory } = addNewAttributeOption;

        const { parent_id } = selectedCategory;

        setAddNewAttributeOption(false);
        const { value, slug } = categoryValue;
        handleFetchCategoryAttributes(value, slug, parent_id);
      })
      .catch(error => console.log('error :>> ', error))
      .finally(() => setLoadingAddAttributeOptions(false));
  };

  const handleEditAttributeOptions = attributeOption => {
    setLoadingEditAttributeOptions(true);
    editAttributeOption(attributeOption)
      .then(() => {
        const { attribute: selectedCategory } = editOption;

        const { parent_id } = selectedCategory;
        setEditOption(false);
        const { value, slug } = categoryValue;
        handleFetchCategoryAttributes(value, slug, parent_id);
      })
      .catch(error => console.log('error :>> ', error))
      .finally(() => setLoadingEditAttributeOptions(false));
  };

  const handleDeleteAttributeOptions = attributeOption => {
    setLoadingDeleteAttributeOption(true);
    deleteAttributeOption(attributeOption)
      .then(() => {
        const { attribute: selectedCategory } = attributeOption;

        const { parent_id } = selectedCategory;
        setDeleteOption(false);
        const { value, slug } = categoryValue;
        handleFetchCategoryAttributes(value, slug, parent_id);
      })
      .catch(error =>
        setErrorDeletingAttributeOption(error.response.data.error_message)
      )
      .finally(() => setLoadingDeleteAttributeOption(false));
  };

  const handleEditCategory = attribute => {
    setLoadingEditCategory(true);
    const { value: category_id } = updateCategory;
    const { id } = selectedSuperCategory;
    editCategory({
      category_id,
      data: {
        ...attribute,
        parent_id: id,
        status: attribute?.status ? 'active' : 'inactive',
        user_id: userId,
      },
    })
      .then(() => {
        setEditAttribute(false);
        const { value, slug } = categoryValue;
        reFetchCategories();
        handleFetchCategoryAttributes(value, slug, category_id);
      })
      .catch(error => console.log('error :>> ', error))
      .finally(() => setLoadingEditCategory(false));
  };

  const handleDeleteCategory = categoryObject => {
    setLoadingDeleteCategory(true);
    const { value: category_id } = categoryObject;

    deleteCategories(category_id)
      .then(() => {
        setDeleteCategory(false);
        reFetchCategories();
      })
      .catch(error =>
        setErrorDeletingCategory(error.response.data.error_message)
      )
      .finally(() => setLoadingDeleteCategory(false));
  };

  const handleEditCategoryAttributes = attribute => {
    setLoadingEditAttributes(true);
    editCategoryAttributes(attribute)
      .then(() => {
        const { parent_id } = editAttribute;
        setEditAttribute(false);
        const { value, slug } = categoryValue;
        handleFetchCategoryAttributes(value, slug, parent_id);
      })
      .catch(error => console.log('error :>> ', error))
      .finally(() => setLoadingEditAttributes(false));
  };

  const handleDeleteCategoryAttributes = attribute => {
    setLoadingDeleteAttribute(true);
    deleteCategoryAttributes(attribute)
      .then(() => {
        const { parent_id } = deleteAttribute;
        setDeleteAttribute(false);
        const { value, slug } = categoryValue;
        handleFetchCategoryAttributes(value, slug, parent_id);
      })
      .catch(error =>
        setErrorDeletingAttribute(error.response.data.error_message)
      )
      .finally(() => setLoadingDeleteAttribute(false));
  };

  const handleAddCategoryAttributes = (attributes, parent) => {
    setLoadingAddAttributes(true);
    errorAddingAttributes && setErrorAddingAttributes();
    const bodyData = {
      parent_id: parent.id,
      user_id: userId,
      category_attributes: attributes,
    };
    addCategoryAttributes(bodyData)
      .then(createdAttributes => {
        setAddNewAttribute(false);
        setCategoryAttributes(categoryAttributes => ({
          ...categoryAttributes,
          [parent.slug]: [
            ...(categoryAttributes[parent.slug] || []),
            ...createdAttributes,
          ],
        }));
      })
      .catch(error =>
        setErrorAddingAttributes(error.response.data.error_message)
      )
      .finally(() => setLoadingAddAttributes(false));
  };

  const handleUpdateCategories = categoryId => {
    const index = merchant_category_ids?.findIndex(
      selectedId => selectedId == categoryId
    );

    if (index > -1) {
      merchant_category_ids.splice(index, 1);
      set_merchant_category_ids([...merchant_category_ids]);
    } else {
      set_merchant_category_ids(ids => [...ids, categoryId]);
    }
  };

  if (!offering_category_type) return null;

  return (
    <>
      <div className={st.mainSectionTitle}>
        <h3>Select Categories *</h3>
      </div>
      <div className="h-0">
        <input
          required={merchant_category_ids.length > 0 ? false : true}
          className="h-0 opacity-0"
          autoComplete="off"
          tabIndex={-1}
        />
      </div>
      <div className="flex flex-wrap gap-3">
        {categories?.map(category => {
          const selected = merchant_category_ids.includes(category.id);
          return (
            <button
              key={category.id}
              className={cn(
                'border-2 outlined rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-100',
                {
                  'border-primary': selected,
                }
              )}
              type="button"
              onClick={() => handleUpdateCategories(category.id)}
            >
              {category.name}
            </button>
          );
        })}
      </div>

      {selectedSuperCategory?.slug === 'events' && (
        <div className="py-4">
          <div className={cn(st.mainSectionTitle, 'flex')}>
            <h3>Add Schedule *</h3>
            <div className="h-0">
              <input
                value={
                  offeringTimeAttributes.length > 0 ? 'event scheduled' : ''
                }
                required
                className="h-0 opacity-0"
                autoComplete="off"
                tabIndex={-1}
                onChange={() => null}
              />
            </div>
          </div>
          {offeringTimeAttributes.map((offeringTimeAttribute, idx) => (
            <div className="pb-4" key={idx}>
              <ScheduledEventCard
                offeringTimeAttribute={offeringTimeAttribute}
                handleSetTimeAttributeForEdit={() => {
                  setOpenEventScheduleData({
                    timeAttributeIdx: idx,
                    timeAttributeForEdit: offeringTimeAttribute,
                  });
                }}
                handleRemoveTimeAttribute={() => {
                  if (offeringTimeAttributes.length === 1 && offering) {
                    setSnackInfo(info => ({
                      ...info,
                      open: true,
                      vertical: 'top',
                      horizontal: 'center',
                      severity: 'info',
                      autoHideDuration: null,
                      message:
                        'You require at least one schedule, add a replacement before removing the last schedule',
                    }));
                    return;
                  }
                  function removeAttributeFromList() {
                    const currentTimeAttributes = offeringTimeAttributes;
                    currentTimeAttributes.splice(idx, 1);
                    setOfferingTimeAttributes([...currentTimeAttributes]);
                  }

                  const id = offeringTimeAttribute.id;

                  if (id) {
                    deleteTimeAttributeById(id).then(() => {
                      removeAttributeFromList();
                    });
                  } else {
                    removeAttributeFromList();
                  }
                }}
                timeAttributeIdx={idx}
                isExperience={offering_category_type === 'experience'}
              />
            </div>
          ))}

          <div className="pb-2">
            {offeringTimeAttributes.length > 0 ? (
              <div
                className={cn(st.addNewAttribute, 'font-normal')}
                onClick={() => setOpenEventScheduleData({})}
              >
                <span className="material-icons">&#xea5d;</span>
                <span>Add an additional time and date ( optional )</span>
              </div>
            ) : (
              <div
                className="custom-button sm outlined with-icon lc rounded-xl px-4 py-2"
                onClick={() => setOpenEventScheduleData({})}
              >
                <span className="material-icons icon">&#xea5d;</span>
                <span>Add time and date</span>
              </div>
            )}
          </div>
        </div>
      )}

      {/* <div className={st.mainSectionTitle}>
        <h3>Item Attributes / Options</h3>
      </div> */}

      {/* {selectedSuperCategory && (
        <>
          <SuperCategoryAttributes
            superCategory={selectedSuperCategory}
            categoryAttributeValues={categoryAttributeValues}
            setCategoryAttributeValues={setCategoryAttributeValues}
            setNewCategoryObject={setNewCategoryObject}
          />
        </>
      )}

      <CategoryAttributes
        categoriesTree={categoriesTree}
        categoryAttributes={categoryAttributes}
        categoryAttributeValues={categoryAttributeValues}
        setCategoriesTree={setCategoriesTree}
        setCategoryValue={setCategoryValue}
        categorySelectRef={categorySelectRef}
        handleSubCategoryChange={categorySelectRef}
        setCategoryAttributeValues={setCategoryAttributeValues}
      /> */}

      {newCategoryObject && (
        <NewCategoryDialog
          newCategoryObject={newCategoryObject}
          setNewCategoryObject={setNewCategoryObject}
          isLoading={loadingCreateNewCategory}
          handleAddNewCategory={handleAddNewCategory}
          errorCreatingCategory={errorCreatingCategory}
          setErrorCreatingCategory={setErrorCreatingCategory}
          handleClearInput={() => {
            !newCategoryObject.parent_id && setCategoryValue(null);
          }}
        />
      )}
      {updateCategory && (
        <EditCategoryDialog
          updateCategory={updateCategory}
          setUpdateCategory={setUpdateCategory}
          isLoading={loadingEditCategory}
          isError={errorEditingCategory}
          setError={setErrorEditingCategory}
          s={st}
          handleEditCategory={handleEditCategory}
        />
      )}
      {deleteCategory && (
        <DeleteCategoryDialog
          deleteCategory={deleteCategory}
          setDeleteCategory={setDeleteCategory}
          isLoading={loadingDeleteCategory}
          handleDeleteCategory={handleDeleteCategory}
          errorDeletingCategory={errorDeletingCategory}
          setErrorDeletingCategory={setErrorDeletingCategory}
          handleClearInput={() => {}}
        />
      )}
      {deleteAttribute && (
        <DeleteAttributeDialog
          deleteAttribute={deleteAttribute}
          setDeleteAttribute={setDeleteAttribute}
          isLoading={loadingDeleteAttribute}
          handleDeleteCategoryAttributes={handleDeleteCategoryAttributes}
          errorCreatingCategory={errorDeletingAttribute}
          setErrorDeletingAttribute={setErrorDeletingAttribute}
          handleClearInput={() => {}}
        />
      )}
      {addNewAttribute && (
        <NewAttributeDialog
          addNewAttribute={addNewAttribute}
          setAddNewAttribute={setAddNewAttribute}
          isLoading={loadingAddAttributes}
          isError={errorAddingAttributes}
          setError={setErrorAddingAttributes}
          handleAddCategoryAttributes={handleAddCategoryAttributes}
        />
      )}
      {editAttribute && (
        <EditAttributeDialog
          editAttribute={editAttribute}
          setEditAttribute={setEditAttribute}
          isLoading={loadingEditAttributes}
          isError={errorEditingAttributes}
          setError={setErrorEditingAttributes}
          s={st}
          handleEditCategoryAttributes={handleEditCategoryAttributes}
        />
      )}
      {editOption && (
        <EditOptionDialog
          editOption={editOption}
          setEditOption={setEditOption}
          isLoading={loadingEditAttributeOptions}
          isError={errorEditingAttributeOptions}
          setError={setErrorEditingAttributeOptions}
          s={st}
          handleEditAttributeOptions={handleEditAttributeOptions}
        />
      )}
      {deleteOption && (
        <DeleteOptionDialog
          deleteOption={deleteOption}
          setDeleteOption={setDeleteOption}
          isLoading={loadingDeleteAttributeOption}
          handleDeleteAttributeOptions={handleDeleteAttributeOptions}
          errorDeletingAttributeOption={errorDeletingAttributeOption}
          setErrorDeletingAttributeOption={setErrorDeletingAttributeOption}
          handleClearInput={() => {}}
        />
      )}
      {addNewAttributeOption && (
        <NewAttributeOptionDialog
          addNewAttributeOption={addNewAttributeOption}
          setAddNewAttributeOption={setAddNewAttributeOption}
          isLoading={loadingAddAttributeOptions}
          isError={errorAddingAttributeOptions}
          setError={setErrorAddingAttributeOptions}
          s={st}
          handleAddAttributeOptions={handleAddAttributeOptions}
        />
      )}
      {openEventScheduleData && (
        <ScheduleEventDialog
          setOfferingTimeAttributes={setOfferingTimeAttributes}
          openEventScheduleData={openEventScheduleData}
          setOpenEventScheduleData={setOpenEventScheduleData}
          offeringTimeAttributes={offeringTimeAttributes}
          isExperience={offering_category_type === 'experience'}
        />
      )}
    </>
  );
};

export default OfferingCategory;
