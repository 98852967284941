import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import cookies from 'next-cookies';

import InitializeApp from '@ictlife/core/containers/app/InitializeApp';
import Script from 'next/script';
import { init } from '@sentry/node';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SnackInfo from '@ictlife/core/components/SnackInfo';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from '@ictlife/core/utils/createEmotionCache';

import SEO from '@ictlife/marketplace/components/shared/SEO';
import { useStore } from '../state';
import '../styles/index.scss';
import '../components/dashboard-blueprint/styles/TopBar.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'draft-js/dist/Draft.css';
import axiosConfig from '../config/axiosConfig';
import { handleAppEvents } from '../sockets/handleAppEvents';

import { handleUserInfoChange } from '../utils/sentry';
import NotificationRequestDialogBody from '../components/shared/NotificationRequestDialogBody';
import { initialNotificationsState } from '../state/reducers/notificationsReducer';

init();
const themePallete = createTheme({
  palette: {
    primary: { main: '#E51A23' },
    secondary: { main: '#4b6584' },
  },
  typography: {
    fontFamily: "'Quicksand', sans-serif",
  },
});

const clientSideEmotionCache = createEmotionCache();

function MerchantApp({
  Component,
  pageProps,
  err,
  emotionCache = clientSideEmotionCache,
}) {
  const { initialServerSideState } = pageProps;

  useEffect(() => {
    if (window.location.hostname === 'merchant.ictlife.com') {
      const pathname = window.location.pathname;
      window.location.href = `${process.env.NEXT_PUBLIC_MERCHANT_WEB_URL}${pathname}`;
    }
  }, []);

  const [snackInfo, setSnackInfo] = useState({
    open: false,
    message: '',
    vertical: 'bottom',
    horizontal: 'left',
    severity: 'error',
    autoHideDuration: 5000,
  });

  const store = useStore(
    initialServerSideState?.merchantProfileState?.errorCode === 404
      ? { notificationsState: { ...initialNotificationsState, setSnackInfo } }
      : {
          ...initialServerSideState,
          notificationsState: { ...initialNotificationsState, setSnackInfo },
        }
  );

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  if (
    !process.env.NEXT_PUBLIC_SOURCE_PACKAGE ||
    process.env.NEXT_PUBLIC_SOURCE_PACKAGE !== 'merchant'
  ) {
    return 'Env not set correctly: Add NEXT_PUBLIC_SOURCE_PACKAGE with the correct value to env';
  }

  return (
    <Provider store={store}>
      <SEO
        title="Event Listing & Ticketing Platform"
        openGraph={{
          site_name:
            'AfricaSasa Merchant | All In One Platform for Event Organisers',
          images: {
            url: `${process.env.NEXT_PUBLIC_MERCHANT_WEB_URL}/africasasabanner.jpeg`,
            width: 1176,
            height: 613,
            alt: 'AfricaSasa Merchant',
          },
        }}
        description="List your free or paid events, add tickets, sell tickets for paid events, issue & verify tickets & interact with ticket holders"
      >
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        <meta property="fb:app_id" content="1790338334447523" />
        <meta
          name="google-site-verification"
          content="fvPtTkyC0AOcOfoKBI9YDgsyC7hMAgup4M04HAGNJIg"
        />
        <meta name="mobile-web-app-capable" content="yes"></meta>
        <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
        <link rel="dns-prefetch" href="https://cdnjs.cloudflare.com" />
        <link
          rel="preload"
          href="https://cdnjs.cloudflare.com/ajax/libs/nprogress/0.2.0/nprogress.min.css"
          as="style"
          onLoad="this.onload=null;this.rel='stylesheet'"
        />

        <noscript>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/nprogress/0.2.0/nprogress.min.css"
          />
        </noscript>
      </SEO>
      <Script id="facebook-script">
        {typeof window !== 'undefined' &&
          (window.fbAsyncInit = function () {
            window.FB.init({
              appId: 1790338334447523,
              autoLogAppEvents: true,
              xfbml: true,
              version: 'v10.0',
            });
          })}
      </Script>
      <Script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js"
      ></Script>

      <Script defer id="twitter-script">
        {typeof window !== 'undefined' &&
          (window.twttr = (function (d, s, id) {
            var js,
              fjs = d.getElementsByTagName(s)[0],
              t = window.twttr || {};
            if (d.getElementById(id)) return t;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://platform.twitter.com/widgets.js';
            fjs.parentNode.insertBefore(js, fjs);

            t._e = [];
            t.ready = function (f) {
              t._e.push(f);
            };

            return t;
          })(document, 'script', 'twitter-wjs'))}
      </Script>

      <Script
        type="text/javascript"
        async
        strategy="beforeInteractive"
        src={`https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY}`}
      ></Script>

      {/* Global site tag (gtag.js) - Google Analytics */}
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID && (
        <>
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}`}
          ></Script>

          <Script
            id="google-analytics"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}');
            `,
            }}
          />
        </>
      )}

      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={themePallete}>
          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_CLIENT_ID}>
            <InitializeApp
              initialServerSideState={initialServerSideState}
              handleAppEvents={handleAppEvents}
              handleUserInfoChange={handleUserInfoChange}
              notificationConfigs={{
                DialogBody: NotificationRequestDialogBody,
                icon: '/images/logo/icon-logo.png',
                firebaseConfig: {
                  apiKey: 'AIzaSyCF29Or1H0NtoBB2t4DHeXo7nn9zXoLQ4A',
                  authDomain: 'ictlife-ke.firebaseapp.com',
                  databaseURL: 'https://ictlife-ke.firebaseio.com',
                  projectId: 'ictlife-ke',
                  storageBucket: 'ictlife-ke.appspot.com',
                  messagingSenderId: '99594612610',
                  appId: '1:99594612610:web:cfbde4971b78868e7d299b',
                },
              }}
            >
              <SnackInfo
                snackInfo={snackInfo}
                handleClose={() =>
                  setSnackInfo(snackInfo => ({
                    ...snackInfo,
                    open: false,
                  }))
                }
              />
              <CssBaseline />
              <Component {...pageProps} err={err} />
            </InitializeApp>
          </GoogleOAuthProvider>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  );
}

export const fetchMerchantProfileSSR = async (
  b_username,
  dispatch,
  context
) => {
  const { ictl_token, ictl_user } = cookies(context);

  await axiosConfig
    .get(`/merchant_profiles/${b_username}`, {
      headers: {
        'X-ICTLIFE-TOKEN': ictl_token || null,
      },
    })
    .then(response => {
      dispatch({
        type: 'BUSINESS_PROFILE_SUCCESS',
        merchantProfile: response.data,
        viewMode: response.data.merchant.user_id !== ictl_user?.id,
        isUpdated: false,
      });
      return response.data.merchant.id;
    })
    .catch(error =>
      dispatch({
        type: 'BUSINESS_PROFILE_ERROR',
        errorMessage: error.response.data.error_message,
        errorCode: error.response.status,
      })
    )
    .catch(() =>
      dispatch({
        type: 'BUSINESS_PROFILE_ERROR',
        errorMessage:
          'Business profile could not be loaded. Lost connection to the server',
        errorCode: null,
      })
    );
};

export default MerchantApp;
