import { LINK_REGEX } from '@ictlife/merchant/utils/formatTextWithLinks';
import { TextField } from '@mui/material';
import cn from 'classnames';
import LinkPreview, { LinkPreviewSkeleton } from '../link-preview/LinkPreview';

const AppTextField = props => {
  const {
    openGraphProps: {
      ogData,
      isLoading,
      requestURL,
      removePreviewLink,
      setRemovePreviewLink,
    },
  } = props;

  return (
    <div
      className={cn('rounded p-2', {
        shadow: (requestURL && ogData && !removePreviewLink) || isLoading,
      })}
    >
      <TextField {...props} />
      {isLoading && !removePreviewLink && <LinkPreviewSkeleton />}
      {!isLoading && requestURL && ogData && !removePreviewLink && (
        <LinkPreview
          linkMetaData={{
            url: requestURL,
            title: ogData.title,
            description: ogData.description,
            logo: ogData.icon?.match(LINK_REGEX) && {
              url: ogData.icon,
            },
            publisher: ogData.provider,
            image: ogData.image && {
              url: ogData.image,
            },
          }}
          limitLines
          setRemovePreviewLink={setRemovePreviewLink}
        />
      )}
    </div>
  );
};

export default AppTextField;
