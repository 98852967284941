import {
  AppDatePicker,
  AppDateTimePicker,
  AppTimePicker,
} from '@ictlife/core/components/datetime-picker';
import { DAYS } from '@ictlife/core/constants';
import { createReadableNumber } from '@ictlife/core/utils/createReadableNumber';
import { formatMinuteToReadableTime } from '@ictlife/core/utils/formatTime';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useRef, useCallback, useEffect, useMemo, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import st from './styles/create-offering.module.scss';
import { selectStyles } from './CreateOffering';

const ScheduleEvent = ({ timeAttribute, setTimeAttribute, isExperience }) => {
  const {
    frequency,
    start_time,
    end_time,
    event_expiry_date,
    byweekday,
    bymonthday,
    latitude,
    event_location,
  } = timeAttribute;

  const [monthlyDateOptions, setMonthlyDateOptions] = useState([]);

  const byweekdayRef = useRef();

  useEffect(() => {
    if (start_time) {
      const date = dayjs(start_time).date();
      const dayIndex = dayjs(start_time).day();
      const day = DAYS[dayIndex];

      const options = [
        {
          label: `On the ${createReadableNumber(date)} day of each month`,
          value: `${date}`,
          type: 'bymonthday',
        },
        {
          label: `On the 1st ${day.name} of each month`,
          value: `1${day.value}`,
          type: 'byweekday',
        },
        {
          label: `On the 2nd ${day.name} of each month`,
          value: `2${day.value}`,
          type: 'byweekday',
        },
        {
          label: `On the 3rd ${day.name} of each month`,
          value: `3${day.value}`,
          type: 'byweekday',
        },
        {
          label: `On the last ${day.name} of each month`,
          value: `-1${day.value}`,
          type: 'byweekday',
        },
      ];

      setMonthlyDateOptions(options);
    }
  }, [start_time]);

  const eventLength = useMemo(() => {
    const difference = dayjs(end_time).diff(dayjs(start_time), 'minute');
    return formatMinuteToReadableTime(difference);
  }, [end_time, start_time]);

  const eventRunTime = useMemo(() => {
    if (event_expiry_date) {
      const difference = dayjs(event_expiry_date).diff(
        dayjs(start_time),
        'minute'
      );
      return formatMinuteToReadableTime(difference);
    }

    return '';
  }, [event_expiry_date, start_time]);

  const WEEK_DAY_OPTIONS = useMemo(
    () => Object.keys(DAYS).map(key => DAYS[key]),
    []
  );

  const calculateEndTimeBasedOnStart = useCallback(
    (newStartTime, time_unit) => newStartTime.add(3, time_unit),
    []
  );

  const handleUpdateTimeAttribute = newAttribute => {
    setTimeAttribute(newAttribute);
  };

  const handleAllDaySwitch = e => {
    const isAllDay = e.target.checked;

    const { start_time, end_time } = timeAttribute;

    if (isAllDay) {
      handleUpdateTimeAttribute({
        ...timeAttribute,
        start_time: dayjs(start_time).hour(0).minute(0).second(0).format(),
        end_time: dayjs(end_time).hour(23).minute(59).second(59).format(),
      });
    } else {
      handleUpdateTimeAttribute({
        ...timeAttribute,
        start_time: dayjs(start_time).hour(8).minute(0).second(0).format(),
        end_time: dayjs(end_time).hour(11).minute(0).second(0).format(),
      });
    }
  };

  const isAllDay = useMemo(() => {
    const { start_time, end_time } = timeAttribute;
    const formatStartTime = dayjs(start_time).format('HHmmss');
    const formatEndTime = dayjs(end_time).format('HHmmss');

    if (formatStartTime === '000000' && formatEndTime === '235959') return true;

    return false;
  }, [timeAttribute]);

  return (
    <div>
      {!isExperience && (
        <>
          <div className={st.offeringField}>
            <label>Event Location *</label>
            <GooglePlacesAutocomplete
              debounce={700}
              selectProps={{
                isClearable: true,
                value: latitude
                  ? {
                      label: event_location,
                      value: event_location,
                    }
                  : null,
                onChange: (data, actionMeta) => {
                  if (actionMeta.action === 'clear') {
                    setTimeAttribute(timeAttribute => ({
                      ...timeAttribute,
                      geolocation: null,
                      event_location: null,
                    }));
                  }
                  if (data) {
                    geocodeByPlaceId(data.value.place_id)
                      .then(results => {
                        const latitude = results[0].geometry.location.lat();
                        const longitude = results[0].geometry.location.lng();
                        setTimeAttribute(timeAttribute => ({
                          ...timeAttribute,
                          latitude,
                          longitude,
                          event_location: data.value.description,
                        }));
                      })
                      .catch(error => console.error(error));
                  }
                },
                placeholder: 'Type to search location',
                menuPlacement: 'auto',
                openMenuOnClick: false,
                components: {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                },
                styles: selectStyles,
                theme: theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#ccc',
                    primary: '#ff8e22',
                  },
                }),
              }}
            />
          </div>
          <div className={cn(st.offeringField, 'override')}>
            <label htmlFor="start-date">Start date</label>
            <div>
              <AppDatePicker
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="outlined"
                    onKeyDown={e => e.preventDefault()}
                    id="start-date"
                    fullWidth
                    required
                  />
                )}
                value={start_time}
                inputFormat="dddd MMMM DD, YYYY"
                clearable={true}
                onChange={newValue => {
                  const recreated_end_time = calculateEndTimeBasedOnStart(
                    newValue,
                    'hours'
                  );

                  const recreated_expiry_date = calculateEndTimeBasedOnStart(
                    recreated_end_time,
                    'days'
                  );

                  const newAttribute = {
                    ...timeAttribute,
                    start_time: newValue.format(),
                    end_time: recreated_end_time.format(),
                    event_expiry_date: recreated_expiry_date.format(),
                  };

                  handleUpdateTimeAttribute(newAttribute);
                }}
                closeOnSelect
              />
            </div>
          </div>
          <div
            className={cn(
              st.offeringField,
              'override flex items-center space-x-1'
            )}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch checked={isAllDay} onChange={handleAllDaySwitch} />
                }
                label="All Day"
              />
            </FormGroup>
          </div>
        </>
      )}

      {!isAllDay && (
        <div
          className={cn(
            st.offeringField,
            'override flex items-center space-x-4'
          )}
        >
          <div className="w-2/5">
            <label htmlFor="start-time">Start time</label>
            <div>
              <AppTimePicker
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="outlined"
                    onKeyDown={e => e.preventDefault()}
                    id="start-time"
                    fullWidth
                    minutesStep={5}
                    required
                  />
                )}
                value={start_time}
                clearable={true}
                onChange={newValue => {
                  let recreated_end_time = calculateEndTimeBasedOnStart(
                    newValue,
                    'hours'
                  );

                  const recreated_expiry_date = calculateEndTimeBasedOnStart(
                    recreated_end_time,
                    'days'
                  );

                  const newAttribute = {
                    ...timeAttribute,
                    start_time: newValue.format(),
                    end_time: recreated_end_time.format(),
                    ...(!isExperience &&
                      recreated_expiry_date && {
                        event_expiry_date: recreated_expiry_date.format(),
                      }),
                  };
                  handleUpdateTimeAttribute(newAttribute);
                }}
                minutesStep={5}
                closeOnSelect
              />
            </div>
          </div>
          <div className="w-3/5">
            <label htmlFor="end-time">End time</label>
            <div>
              <AppDateTimePicker
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="outlined"
                    onKeyDown={e => e.preventDefault()}
                    id="end-time"
                    fullWidth
                    required
                  />
                )}
                value={end_time}
                inputFormat={`hh:mm A [(${eventLength})]`}
                clearable={true}
                onChange={newValue => {
                  const recreated_expiry_date = calculateEndTimeBasedOnStart(
                    newValue,
                    'days'
                  );
                  handleUpdateTimeAttribute({
                    ...timeAttribute,
                    end_time: newValue.format(),
                    ...(!isExperience &&
                      recreated_expiry_date && {
                        event_expiry_date: recreated_expiry_date.format(),
                      }),
                  });
                }}
                minutesStep={5}
                openTo="hours"
                minDateTime={dayjs(start_time)}
                maxDateTime={dayjs(start_time).add(24, 'hour')}
                ampm={false}
                closeOnSelect
              />
            </div>
          </div>
        </div>
      )}

      {!isExperience && (
        <div className={cn(st.offeringField, 'override')}>
          <label htmlFor="start-date">Occurs</label>
          <div>
            <FormControl
              variant="outlined"
              style={{ minWidth: '10em' }}
              fullWidth
            >
              <Select
                value={frequency}
                onChange={e => {
                  const RFrequency = e.target.value;

                  const newAttribute = {
                    ...timeAttribute,
                    event_expiry_date: !RFrequency
                      ? null
                      : timeAttribute.event_expiry_date,
                    frequency: RFrequency,
                    byweekday: [],
                    bymonthday:
                      RFrequency === 'MONTHLY'
                        ? [monthlyDateOptions[0].value]
                        : [],
                  };
                  if (RFrequency !== '' && !newAttribute.event_expiry_date) {
                    newAttribute.event_expiry_date = dayjs()
                      .add(3, 'day')
                      .hour(11)
                      .minute(0)
                      .second(0)
                      .format();
                  }
                  handleUpdateTimeAttribute(newAttribute);
                }}
                margin="dense"
                displayEmpty
              >
                <MenuItem value="">Once</MenuItem>
                <MenuItem value="DAILY">Daily</MenuItem>
                <MenuItem value="WEEKLY">Weekly</MenuItem>
                <MenuItem value="MONTHLY">Monthly</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      )}

      {frequency === 'WEEKLY' && (
        <div className={cn(st.offeringField, 'override')}>
          <label htmlFor="week-days">Weekly on these days</label>
          <div className="h-0">
            <input
              value={byweekday.length > 0 ? 'days filled' : ''}
              required
              className="h-0 opacity-0"
              autoComplete="off"
              tabIndex={-1}
              onFocus={() => {
                byweekdayRef?.current.focus();
              }}
            />
          </div>
          <div>
            <FormGroup
              className="flex !flex-row"
              onChange={e => e.target.value}
              id="week-days"
            >
              {WEEK_DAY_OPTIONS.map(weekday => (
                <FormControlLabel
                  key={weekday.value}
                  control={
                    <Checkbox
                      value={weekday.value}
                      checked={
                        byweekday.findIndex(
                          wdValue => wdValue === weekday.value
                        ) > -1
                      }
                      onChange={(_, checked) => {
                        const itemIndex = byweekday.findIndex(
                          wdValue => wdValue === weekday.value
                        );
                        if (checked) {
                          if (itemIndex === -1) {
                            byweekday.push(weekday.value);
                            handleUpdateTimeAttribute({
                              ...timeAttribute,
                              byweekday,
                            });
                          }
                        } else {
                          if (itemIndex > -1) {
                            byweekday.splice(itemIndex, 1);
                            handleUpdateTimeAttribute({
                              ...timeAttribute,
                              byweekday,
                            });
                          }
                        }
                      }}
                    />
                  }
                  label={weekday.name}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      )}

      {frequency === 'MONTHLY' && monthlyDateOptions?.length > 0 && (
        <div className={cn(st.offeringField, 'override')}>
          <div>
            <FormControl
              variant="outlined"
              style={{ minWidth: '10em' }}
              fullWidth
            >
              <Select
                value={monthlyDateOptions.find(
                  option =>
                    option.value === bymonthday[0] ||
                    option.value === byweekday[0]
                )}
                onChange={e => {
                  const occurenceOption = e.target.value;
                  handleUpdateTimeAttribute({
                    ...timeAttribute,
                    bymonthday:
                      occurenceOption.type === 'bymonthday'
                        ? [occurenceOption.value]
                        : [],
                    byweekday:
                      occurenceOption.type === 'byweekday'
                        ? [occurenceOption.value]
                        : [],
                  });
                }}
                margin="dense"
                required
              >
                {monthlyDateOptions.map(option => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      )}

      {frequency !== '' && !isExperience && (
        <div className={cn(st.offeringField, 'override')}>
          <label htmlFor="start-date">Repeats Until</label>
          <AppDatePicker
            renderInput={props => (
              <TextField
                {...props}
                variant="outlined"
                onKeyDown={e => e.preventDefault()}
                id="end-date"
                fullWidth
                required
              />
            )}
            value={event_expiry_date}
            inputFormat={`dddd MMMM DD, YYYY hh:mm a [(for ${eventRunTime})]`}
            clearable={true}
            onChange={newValue =>
              handleUpdateTimeAttribute({
                ...timeAttribute,
                event_expiry_date: newValue.format(),
              })
            }
            minDate={start_time}
            closeOnSelect
          />
        </div>
      )}
    </div>
  );
};

export default ScheduleEvent;
