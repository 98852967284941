import axiosConfig from '../../config/axiosConfig';
import {
  GET_PROMOTION_MERCHANT_TAGS,
  GET_CATEGORY_MERCHANT_TAGS,
  GET_PROMOTION_MERCHANT_TAGS_SUCCESS,
  GET_CATEGORY_MERCHANT_TAGS_SUCCESS,
  GET_MERCHANT_TAGS_ERROR,
  SEARCH_TAGS,
  SEARCH_TAGS_SUCCESS,
  SEARCH_TAGS_ERROR,
} from '../dispatchTypes';

export const getMerchantTags = (
  dispatch,
  businessId,
  tag_resource,
  tag_type
) => {
  switch (tag_type) {
    case 'promotion':
      dispatch({
        type: GET_PROMOTION_MERCHANT_TAGS,
      });
      break;
    case 'category':
      dispatch({
        type: GET_CATEGORY_MERCHANT_TAGS,
      });
      break;
    default:
      break;
  }

  axiosConfig
    .get(`/merchants/${businessId}/tags`, {
      params: {
        tag_resource,
        tag_type,
      },
    })
    .then(response => {
      switch (tag_type) {
        case 'promotion':
          dispatch({
            type: GET_PROMOTION_MERCHANT_TAGS_SUCCESS,
            tags: response.data,
          });
          break;
        case 'category':
          dispatch({
            type: GET_CATEGORY_MERCHANT_TAGS_SUCCESS,
            tags: response.data,
          });
          break;
        default:
          break;
      }
    })
    .catch(error => {
      dispatch({
        type: GET_MERCHANT_TAGS_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_MERCHANT_TAGS_ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
      });
    });
};

export const searchTags = (dispatch, searchTag, tag_resource, tag_type) => {
  dispatch({
    type: SEARCH_TAGS,
  });

  axiosConfig
    .get('/tags', {
      params: {
        search: searchTag,
        tag_resource,
        tag_type,
        per: 8,
      },
    })
    .then(response => {
      dispatch({
        type: SEARCH_TAGS_SUCCESS,
        tag_results: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: SEARCH_TAGS_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: SEARCH_TAGS_ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
      });
    });
};
