import {
  GET_USER_PROFILE,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_ERROR,
  GET_BUSINESS_LIST,
  GET_BUSINESS_LIST_SUCCESS,
  GET_BUSINESS_LIST_ERROR,
  LOADING,
  SUCCESS,
  ERROR,
} from '../dispatchTypes';

const initialBusinessListState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  userBusinessList: [],
};

export const businessListReducer = (
  state = initialBusinessListState,
  action
) => {
  switch (action.type) {
    case GET_BUSINESS_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_BUSINESS_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        userBusinessList: action.userBusinessList,
      };
    }
    case GET_BUSINESS_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }

    case 'CLEAR_BUSINESS_LIST_MESSAGES':
      return {
        ...state,
        isSuccess: false,
        isError: false,
        errorMessage: '',
      };
    default:
      return state;
  }
};

const initialUserInfoState = {
  isSuccess: false,
  profile_photo: null,
  user: {
    first_name: '',
    last_name: '',
    phone_number: {
      country_code: '',
      number: '',
    },
  },
};

export const userInfoReducer = (state = initialUserInfoState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        profile_photo: action.profile_photo,
        avatar: action.avatar,
        user: action.user,
      };
    case USER_PROFILE_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case 'ANONYMOUS_USER':
      return {
        ...state,
        isLoading: false,
      };
    case 'CLEAR_USER_PROFILE':
      return initialUserInfoState;
    default:
      return state;
  }
};

export const initialLoginState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

const loginReducer = (state, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    }
    case ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return initialLoginState;
  }
};

export default loginReducer;
