import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
} from '../dispatchTypes';

export const initialNotificationsState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  notifications: {
    merchant_notification_events: [],
    pagination: {
      page: 1,
      per: 20,
    },
  },
};

export const notificationsReducer = (
  state = initialNotificationsState,
  action
) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        notifications: action.notifications,
      };
    }
    case GET_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};
