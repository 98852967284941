import axiosConfig from '../../config/axiosConfig';
import { LOADING, SUCCESS, ERROR } from '../dispatchTypes';

export const getPostComments = (
  dispatchGetPostComments,
  postId,
  page,
  per,
  asset_id = ''
) => {
  dispatchGetPostComments({
    type: LOADING,
  });

  axiosConfig
    .get(`/merchant_posts/${postId}/merchant_post_comments`, {
      params: {
        page,
        per,
        merchant_post_asset_id: asset_id,
      },
    })
    .then(response => {
      dispatchGetPostComments({
        type: SUCCESS,
        posts_comments: response.data,
        asset_id,
      });
    })
    .catch(error => {
      dispatchGetPostComments({
        type: ERROR,
        errorMessage: error.response.data.error_message,
        asset_id,
      });
    })
    .catch(() => {
      dispatchGetPostComments({
        type: ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
        asset_id,
      });
    });
};
export const getSpecificPostComment = (dispatchGetPostComment, commentId) => {
  dispatchGetPostComment({
    type: LOADING,
  });

  return axiosConfig
    .get(`/merchant_post_comments/${commentId}`)
    .then(response => {
      dispatchGetPostComment({
        type: SUCCESS,
        posts_comments: {
          pagination: {
            per: 20,
            page: 1,
            count: 1,
          },
          merchant_post_comments: [response.data],
        },
        asset_id: response.data.merchant_post_asset_id,
      });
      return { merchant_post_comments: [response.data] };
    })
    .catch(error => {
      dispatchGetPostComment({
        type: ERROR,
        errorMessage: error.response.data.error_message,
        asset_id: '',
      });
    })
    .catch(() => {
      dispatchGetPostComment({
        type: ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
        asset_id: '',
      });
    });
};

export const createPostComment = (dispatchCreatePostComment, bodyData) => {
  dispatchCreatePostComment({
    type: LOADING,
  });

  return axiosConfig
    .post('/merchant_post_comments', bodyData)
    .then(response => {
      dispatchCreatePostComment({
        type: SUCCESS,
        post_comment: response.data,
        asset_id: bodyData.merchant_post_asset_id,
      });
      return response.data;
    })
    .catch(error => {
      dispatchCreatePostComment({
        type: ERROR,
        errorMessage: error.response.data.error_message,
        asset_id: bodyData.merchant_post_asset_id,
      });
    })
    .catch(() => {
      dispatchCreatePostComment({
        type: ERROR,
        errorMessage: 'Failed to comment',
        asset_id: bodyData.merchant_post_asset_id,
      });
    });
};

export const deletePostComment = (dispatchDeleteComment, commentId) => {
  dispatchDeleteComment({
    type: LOADING,
  });

  return axiosConfig
    .delete(`/merchant_post_comments/${commentId}`)
    .then(() => {
      dispatchDeleteComment({
        type: SUCCESS,
      });
    })
    .catch(error => {
      dispatchDeleteComment({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchDeleteComment({
        type: ERROR,
        errorMessage: 'Failed to delete',
      });
    });
};

export const getPostCommentsLikes = (dispatchGetCommentsLikes, comment_id) => {
  dispatchGetCommentsLikes({
    type: LOADING,
  });

  axiosConfig
    .get(`/merchant_post_comments/${comment_id}/merchant_post_likes`)
    .then(response => {
      dispatchGetCommentsLikes({
        type: SUCCESS,
        likes: response.data,
      });
    })
    .catch(error => {
      dispatchGetCommentsLikes({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchGetCommentsLikes({
        type: ERROR,
        errorMessage: 'Failed to get likes',
      });
    });
};

export const getPostCommentLike = (
  dispatchGetCommentsLikes,
  likeId,
  setNotificationPostCommentLike
) => {
  dispatchGetCommentsLikes({
    type: LOADING,
  });

  axiosConfig
    .get(`/merchant_post_comment_likes/${likeId}`)
    .then(response => {
      setNotificationPostCommentLike(response.data);
    })
    .catch(error => {
      dispatchGetCommentsLikes({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchGetCommentsLikes({
        type: ERROR,
        errorMessage: 'Failed to get likes',
      });
    });
};

export const likePostComment = (dispatchLikePostComment, bodyData) => {
  axiosConfig
    .post('/merchant_post_comment_likes', bodyData)
    .then(response => {
      dispatchLikePostComment({
        type: SUCCESS,
        likeId: response.data.id,
      });
    })
    .catch(error => {
      dispatchLikePostComment({
        type: ERROR,
        rollBackId: null,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchLikePostComment({
        type: ERROR,
        rollBackId: null,
        errorMessage: 'Failed to like comment',
      });
    });
};

export const unLikePostComment = (dispatchLikePostComment, likeId) => {
  axiosConfig
    .delete(`/merchant_post_comment_likes/${likeId}`)
    .then(() => {
      dispatchLikePostComment({
        type: SUCCESS,
        likeId: null,
      });
    })
    .catch(error => {
      dispatchLikePostComment({
        type: ERROR,
        rollBackId: likeId,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchLikePostComment({
        type: ERROR,
        rollBackId: likeId,
        errorMessage: 'Failed to unlike comment',
      });
    });
};

// POST COMMENT REPLIES
export const getPostCommentReplies = (
  dispatchGetReplies,
  commentId,
  page,
  per
) => {
  dispatchGetReplies({
    type: LOADING,
  });

  axiosConfig
    .get(`/merchant_post_comments/${commentId}/merchant_post_comment_replies`, {
      params: {
        page,
        per,
      },
    })
    .then(response => {
      dispatchGetReplies({
        type: SUCCESS,
        comment_replies: response.data,
      });
    })
    .catch(error => {
      console.error(error.response);
      dispatchGetReplies({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchGetReplies({
        type: ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
      });
    });
};

export const getSpecificPostCommentReply = (
  dispatchGetPostCommentReply,
  replyId,
  setNotificationReply
) => {
  dispatchGetPostCommentReply({
    type: LOADING,
  });

  return axiosConfig
    .get(`/merchant_post_comment_replies/${replyId}`)
    .then(response => {
      setNotificationReply && setNotificationReply(response.data);
      return response.data;
    })
    .catch(error => {
      dispatchGetPostCommentReply({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchGetPostCommentReply({
        type: ERROR,
        errorMessage:
          'Lost connetion to the server. Kindly check your internet connection',
      });
    });
};

export const createCommentReply = (dispatchCreatePostComment, bodyData) => {
  dispatchCreatePostComment({
    type: LOADING,
  });

  return axiosConfig
    .post('/merchant_post_comment_replies', bodyData)
    .then(response => {
      dispatchCreatePostComment({
        type: SUCCESS,
        comment_reply: response.data,
      });
    })
    .catch(error => {
      dispatchCreatePostComment({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchCreatePostComment({
        type: ERROR,
        errorMessage: 'Failed to reply',
      });
    });
};

export const deleteCommentReply = (
  dispatchDeleteReply,
  replyId,
  replyIndex
) => {
  dispatchDeleteReply({
    type: LOADING,
    replyIndex,
  });

  axiosConfig
    .delete(`/merchant_post_comment_replies/${replyId}`)
    .then(() => {
      dispatchDeleteReply({
        type: SUCCESS,
        replyIndex,
      });
    })
    .catch(error => {
      dispatchDeleteReply({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchDeleteReply({
        type: ERROR,
        errorMessage: 'Failed to delete',
      });
    });
};
