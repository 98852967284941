import axiosConfig from '@ictlife/core/config/axios';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

export const getOfferings = async ({ super_category_slug }) => {
  const response = await axiosConfig.get('/merchant_offerings', {
    params: {
      super_category_slug,
    },
  });
  return response.data;
};

/**
 * SWR Hook: /merchant_offerings
 *
 * @param {*} Object
 * @returns Object
 */
export const useOfferings = ({
  super_category_slug,
  parent_category_slug,
  per,
  search,
  latitude,
  longitude,
  radius,
  preventFetch,
  attributes,
  merchant_offering_status,
  event_start_time,
  event_end_time,
  offering_category_type,
}) => {
  const {
    data = [],
    error,
    isValidating,
    mutate,
    size,
    setSize,
  } = useSWRInfinite(
    (pageIndex, previousPageData) => {
      if (
        preventFetch ||
        (previousPageData && !previousPageData.data.merchant_offerings.length)
      )
        return null;

      return {
        url: '/merchant_offerings',
        super_category_slug,
        parent_category_slug,
        per,
        search,
        latitude,
        longitude,
        radius,
        attributes,
        merchant_offering_status,
        offering_category_type,
        event_start_time,
        event_end_time,
        page: pageIndex + 1,
      };
    },
    ({ url, page }) =>
      axiosConfig.get(url, {
        params: {
          ...attributes,
          page,
          per,
          super_category_slug,
          parent_category_slug,
          search,
          latitude,
          longitude,
          radius,
          merchant_offering_status,
          offering_category_type,
          event_start_time,
          event_end_time,
        },
      }),
    {
      revalidateFirstPage: false,
    }
  );

  const fetchNextPage = () => {
    setSize(size + 1);
  };

  const merchant_offerings =
    data?.flatMap(data => data.data.merchant_offerings) ?? [];

  const lastResultsPage = data[data.length - 1];
  const pagination = lastResultsPage?.data.pagination ?? {};

  const hasNextPage = pagination.page * pagination.per < pagination.count;

  return {
    offerings: {
      merchant_offerings,
      pagination,
    },
    error,
    isLoading: !error && isValidating,
    mutate,
    fetchNextPage,
    hasNextPage,
  };
};

export const getBusinessOfferings = async ({
  business_id,
  merchant_offering_status,
}) => {
  const response = await axiosConfig.get(
    `/merchants/${business_id}/offerings`,
    { params: { merchant_offering_status, super_category_slug: 'events' } }
  );
  return response.data;
};

export const getUserOfferings = async ({
  userId,
  merchant_offering_status,
}) => {
  const response = await axiosConfig.get(`/users/${userId}/offerings`, {
    params: { merchant_offering_status, super_category_slug: 'events' },
  });
  return response.data;
};

/**
 * SWR Hook: /merchants/[business_id]/offerings
 *
 * @param {*} Object
 * @returns Object
 */

export const useBusinessOfferings = ({
  business_id,
  page,
  per,
  search,
  super_category_slug,
  onSuccess,
  parent_category_slug,
  merchant_offering_status = 'active',
  event_start_time,
  event_end_time,
  offering_category_type,
}) => {
  const { data, error } = useSWR(
    business_id
      ? {
          url: `/merchants/${business_id}/offerings`,
          page,
          per,
          search,
          super_category_slug,
          parent_category_slug,
          merchant_offering_status,
          event_start_time,
          event_end_time,
          offering_category_type,
        }
      : null,
    ({ url }) =>
      axiosConfig.get(url, {
        params: {
          page,
          per,
          search,
          super_category_slug,
          parent_category_slug,
          merchant_offering_status,
          event_start_time,
          event_end_time,
          offering_category_type,
        },
      }),
    {
      onSuccess: data => onSuccess && onSuccess(data),
    }
  );

  return {
    offerings: data?.data,
    error,
    isLoading: !data && !error,
  };
};

export const useUserOfferings = ({
  business_id,
  userId,
  page,
  per,
  search,
  super_category_slug,
  onSuccess,
  merchant_offering_status = 'active',
}) => {
  const { data, error } = useSWR(
    !business_id
      ? {
          url: `/users/${userId}/offerings`,
          page,
          per,
          search,
          super_category_slug,
          merchant_offering_status,
        }
      : null,
    ({ url }) =>
      axiosConfig.get(url, {
        params: {
          page,
          per,
          search,
          super_category_slug,
          merchant_offering_status,
        },
      }),
    {
      onSuccess: data => onSuccess(data),
    }
  );

  return {
    offerings: data?.data,
    error,
    isLoading: !data && !error,
  };
};

export const getOfferingDetails = async ({ offering_slug }) => {
  const response = await axiosConfig.get(
    `/merchant_offerings/${offering_slug}`
  );
  return response.data;
};

export const useOfferingDetails = ({ offering_slug, onSuccess, errorCode }) => {
  const { data, isValidating, mutate, error } = useSWR(
    offering_slug
      ? {
          url: `/merchant_offerings/${offering_slug}`,
          errorCode,
        }
      : null,
    async ({ url }) => await axiosConfig.get(url),
    {
      onSuccess: data => onSuccess && onSuccess(data?.data),
    }
  );

  return {
    offering: data?.data,
    loading: isValidating && !data,
    mutateOfferingDetails: mutate,
    error: !data && error ? error : false,
  };
};

export const useOfferingStats = ({ offeringId, onSuccess, errorCode }) => {
  const { data, isValidating, mutate, error } = useSWR(
    offeringId
      ? {
          url: `/merchant_offerings/${offeringId}/summary`,
          errorCode,
        }
      : null,
    ({ url }) => axiosConfig.get(url),
    {
      onSuccess: data => onSuccess(data?.data),
    }
  );

  return {
    offeringStats: data?.data,
    loading: isValidating && !data,
    mutateOfferingDetails: mutate,
    error: !data && error ? error : false,
  };
};

export const useOfferingOrders = ({
  offeringId,
  onSuccess,
  errorCode,
  search,
  payment_mode,
}) => {
  const { data, isValidating, mutate, error } = useSWR(
    offeringId
      ? {
          url: `/merchant_offerings/${offeringId}/orders`,
          errorCode,
          search,
          payment_mode,
        }
      : null,
    ({ url }) =>
      axiosConfig.get(url, {
        params: {
          search,
          payment_mode,
        },
      }),
    {
      onSuccess: data => onSuccess(data?.data),
    }
  );

  return {
    offeringOrders: data?.data,
    loading: isValidating && !data,
    mutateOfferingDetails: mutate,
    error: !data && error ? error : false,
  };
};

export const createMerchantOffering = async bodyData => {
  const response = await axiosConfig.post('/merchant_offerings', bodyData);
  return response.data;
};

export const activateOffering = async offeringId => {
  const response = await axiosConfig.put(
    `/merchant_offerings/${offeringId}/activate`
  );
  return response.data;
};

export const updateMerchantOffering = async (bodyData, offeringId) => {
  const response = await axiosConfig.put(
    `/merchant_offerings/${offeringId}`,
    bodyData
  );
  return response.data;
};

export const deleteOfferingAsset = async file => {
  const response = await axiosConfig.delete(
    `/merchant_offering_assets/${file.id}`
  );
  return response;
};

export const createOfferingCategories = async payload => {
  const res = await axiosConfig.post('/offering_categories', payload);
  return res.data;
};
