import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import localisedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(localisedFormat);

export function formatDate(date, format) {
  const formatedDate = dayjs(date);
  const timeNow = dayjs(new Date());
  const differenceInDays = timeNow.diff(formatedDate, 'day');
  if (differenceInDays === 0) return formatedDate.fromNow();
  return formatedDate.calendar(
    null,
    format
      ? {
          sameElse: format,
        }
      : {
          lastWeek: 'ddd [at] LT',
          sameElse: 'LT [on] ddd MMM DD YYYY',
        }
  );
}

const DateFormatter = ({ date, interval = 60000, format }) => {
  const [formattedDate, setFormattedDate] = useState(date);
  useEffect(() => {
    setFormattedDate(formatDate(date, format));
  }, [date, format]);

  useEffect(() => {
    const formatDateInterval = setInterval(() => {
      setFormattedDate(formatDate(date, format));
    }, interval);
    return () => clearInterval(formatDateInterval);
  }, [date, format, interval]);

  return <>{formattedDate}</>;
};

export default DateFormatter;
