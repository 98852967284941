import axiosConfig from '../../config/axiosConfig';
import {
  LIST_REPORT_REASONS,
  LIST_REPORT_REASONS_SUCCESS,
  LIST_REPORT_REASONS_ERROR,
  REPORT_ISSUE,
  REPORT_ISSUE_SUCCESS,
  REPORT_ISSUE_ERROR,
} from '../dispatchTypes';

export const getReportReasons = (dispatch, resource = 'merchant_posts') => {
  dispatch({
    type: LIST_REPORT_REASONS,
  });

  axiosConfig
    .get('/flag_reasons', {
      params: {
        resource,
      },
    })
    .then(response => {
      dispatch({
        type: LIST_REPORT_REASONS_SUCCESS,
        flagReasons: response.data.flag_reasons,
      });
    })
    .catch(error => {
      dispatch({
        type: LIST_REPORT_REASONS_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: LIST_REPORT_REASONS_ERROR,
        errorMessage: 'Failed to load reasons',
      });
    });
};

export const reportIssue = (dispatch, bodyData) => {
  dispatch({
    type: REPORT_ISSUE,
  });

  const url = bodyData.merchant_post_id
    ? '/merchant_post_flags'
    : '/merchant_flags';

  axiosConfig
    .post(url, bodyData)
    .then(response => {
      dispatch({
        type: REPORT_ISSUE_SUCCESS,
        reportData: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: REPORT_ISSUE_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: REPORT_ISSUE_ERROR,
        errorMessage: 'Failed to report. Try again after some time',
      });
    });
};
