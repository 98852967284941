import { LINK_REGEX } from '@ictlife/merchant/utils/formatTextWithLinks';
import { useEffect, useMemo, useState } from 'react';
import {
  useEventBriteAPIData,
  useOpenGraphMetaData,
} from '../state/actions/openGraphActions';

const useOpenGraphState = message => {
  const [removePreviewLink, setRemovePreviewLink] = useState(false);

  const { requestURL, eventBriteId } = useMemo(() => {
    if (message && message.match(LINK_REGEX)) {
      const requestURL = message.match(LINK_REGEX)[0];
      let eventBriteId = null;
      if (requestURL.includes('https://www.eventbrite.com/')) {
        const urlsegments = requestURL.split('-');
        if (urlsegments.length > 0) {
          const lastSegment = urlsegments[urlsegments.length - 1];
          const id = lastSegment.split('?')[0];
          eventBriteId = isNaN(id) ? null : id;
        }
      }
      return {
        requestURL,
        eventBriteId,
      };
    }
    return {};
  }, [message]);

  useEffect(() => {
    if (requestURL) {
      setRemovePreviewLink(false);
    }
  }, [requestURL]);

  const { data: eventBriteData } = useEventBriteAPIData({
    eventBriteId,
  });

  const { ogData, isLoading: loadingOGData } = useOpenGraphMetaData({
    url: requestURL,
  });

  const open_graph_links = useMemo(() => {
    if (ogData && !removePreviewLink)
      return [
        {
          description: ogData.description,
          image_url: ogData.image,
          link_title: ogData.title,
          link_url: ogData.requestURL,
          site_name: ogData.provider,
          ...(ogData.icon?.match(LINK_REGEX) && {
            site_icon_url: ogData.icon,
          }),
        },
      ];
  }, [ogData, removePreviewLink]);

  return {
    ogData,
    loadingOGData,
    removePreviewLink,
    setRemovePreviewLink,
    requestURL,
    open_graph_links,
    eventBriteData,
  };
};

export default useOpenGraphState;
