import {
  CircularProgress,
  Dialog,
  Radio,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import React, { useState, useRef } from 'react';
import Select from 'react-select';
import cn from 'classnames';
import { selectStyles } from '../CreateOffering';
import s from './styles/new-attribute-dialog.module.scss';

const attributeHolderStyles = {
  display: 'flex',
  gap: '1em',
  marginBottom: '1.5em',
  alignItems: 'center',
};

const AttributeField = ({
  attribute,
  attributes,
  setAttributes,
  index,
  showAdminOptions,
}) => {
  const options = [
    { label: 'Word/Text Value', value: 'text', type: 'text' },
    { label: 'Number Value', value: 'number', type: 'number' },
    {
      label: 'Single Select Option',
      value: 'single_select',
      type: 'options',
    },
    {
      label: 'Multiple select Options',
      value: 'multiple_select',
      type: 'options',
      multiple_choice: true,
    },
    { label: 'Location field', value: 'location', type: 'location' },
    { label: 'Date/time field', value: 'time', type: 'time' },
  ];

  const [optionField, setOptionField] = useState('');

  const inputRef = useRef();

  const handleAddOption = () => {
    if (!optionField.trim()) {
      inputRef.current.focus();
      return;
    }

    if (attribute.error) {
      delete attribute['error'];
      setAttributes([...attributes]);
    }
    if (attribute.options) {
      attribute.options.push(optionField);
    } else {
      attribute.options = [optionField];
    }

    setOptionField('');
    inputRef.current.focus();

    setAttributes([...attributes]);
  };

  const handleRemoveOption = optionIdx => {
    attribute.options.splice(optionIdx, 1);
    setAttributes([...attributes]);
  };

  return (
    <div style={attributeHolderStyles}>
      <div className="app-form">
        <label>Attribute name</label>
        <input
          type="text"
          placeholder="Attribute name e.g. color"
          style={{ height: '2.5em', backgroundColor: '#fff' }}
          value={attribute.label}
          onChange={e => {
            attribute.label = e.target.value;
            setAttributes([...attributes]);
          }}
        />
      </div>
      {!attribute.id && (
        <div>
          <div className="app-form">
            <label>Type of answer</label>
          </div>
          <Select
            styles={selectStyles}
            options={options}
            value={options.find(
              option =>
                option.type === attribute.input_type &&
                option.multiple_choice === attribute.multiple_choice
            )}
            menuShouldScrollIntoView
            menuShouldBlockScroll={false}
            onChange={option => {
              attribute.input_type = option.type;
              if (option.multiple_choice) {
                attribute.multiple_choice = true;
              } else {
                delete attribute['multiple_choice'];
              }
              setAttributes([...attributes]);
            }}
          />
          {attribute.input_type === 'options' && (
            <div className="override" style={{ marginTop: '0.5em' }}>
              <div className="app-form" style={{ textAlign: 'center' }}>
                <label style={{ fontSize: '0.8em' }}>
                  Add {attribute.label ? `"${attribute.label}"` : ''} options
                </label>
              </div>
              {attribute.options?.map((option, index) => (
                <div key={index} className={s.option}>
                  {attribute.multiple_choice ? (
                    <Checkbox size="small" checked={false} />
                  ) : (
                    <Radio size="small" checked={false} />
                  )}

                  <span>{option}</span>
                  <div
                    className={s.icon}
                    onClick={() => handleRemoveOption(index)}
                  >
                    <span className="material-icons">&#xe15d;</span>
                  </div>
                </div>
              ))}
              <div className={s.addOption}>
                {attribute.multiple_choice ? (
                  <Checkbox size="small" checked={false} />
                ) : (
                  <Radio size="small" checked={false} />
                )}{' '}
                <TextField
                  autoFocus
                  error={attribute.error}
                  fullWidth
                  inputRef={inputRef}
                  value={optionField}
                  multiline
                  onBlur={handleAddOption}
                  onKeyDown={e => {
                    if (e.code === 'Comma' || e.code === 'Enter') {
                      e.preventDefault();
                    }
                    if (
                      e.code === 'Enter' ||
                      e.code === 'Tab' ||
                      e.code === 'Comma'
                    ) {
                      handleAddOption();
                    }
                  }}
                  onChange={e => {
                    setOptionField(e.target.value);
                  }}
                />
                <div className={cn(s.icon, 'app-text')}>
                  {attribute.multiple_choice ? (
                    <span className="material-icons" onClick={handleAddOption}>
                      &#xe146;
                    </span>
                  ) : (
                    <span className="material-icons" onClick={handleAddOption}>
                      &#xe147;
                    </span>
                  )}
                </div>
              </div>

              <div className="error-section">{attribute.error}</div>
            </div>
          )}
        </div>
      )}

      <div className="override">
        <div className="app-form">
          <label>Helper text</label>
        </div>

        <TextField
          multiline
          variant="outlined"
          size="small"
          placeholder="Describe attribute"
          value={attribute.help_text}
          onChange={e => {
            attribute.help_text = e.target.value;
            setAttributes([...attributes]);
          }}
        />
      </div>
      {showAdminOptions && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={attribute.required}
                onChange={(_, checked) => {
                  attribute.required = checked;
                  setAttributes([...attributes]);
                }}
              />
            }
            label="Required"
          />
        </div>
      )}

      {!attribute.id && (
        <div
          style={{
            display: 'grid',
            alignItems: 'center',
            marginTop: '0.8em',
            cursor: 'pointer',
          }}
          title="Remove attribute"
          onClick={() => {
            if (attributes.length > 1) {
              attributes.splice(index, 1);
            } else {
              attributes.splice(index, 1, {
                input_type: 'text',
                label: '',
                help_text: '',
              });
            }

            setAttributes([...attributes]);
          }}
        >
          <span className="material-icons">&#xe5c9;</span>
        </div>
      )}
    </div>
  );
};

const NewAttributeDialog = ({
  setAddNewAttribute,
  isLoading,
  isError,
  setError,
  handleAddCategoryAttributes,
  addNewAttribute,
  showAdminOptions,
}) => {
  const [attributes, setAttributes] = useState([
    addNewAttribute.attributeForEdit || {
      input_type: 'text',
      label: '',
      help_text: '',
    },
  ]);

  const handleCloseDialog = () => {
    setAddNewAttribute(false);
    setError();
  };

  return (
    <Dialog open={true} onClose={handleCloseDialog} maxWidth="lg">
      <div className="dialog-wrapper">
        <div className="dialog-title">
          <span className="title">
            Add a new attribute in &apos;{addNewAttribute.parent.name}&apos;
            category
          </span>

          <span className="material-icons icon" onClick={handleCloseDialog}>
            &#xe5cd;
          </span>
        </div>
        <div
          className="dialog-body"
          style={{
            fontSize: '0.95em',
            minHeight: '20em',
            paddingBottom: '2em',
          }}
        >
          {attributes.map((attribute, index) => (
            <AttributeField
              key={index}
              attribute={attribute}
              attributes={attributes}
              setAttributes={setAttributes}
              index={index}
              showAdminOptions={showAdminOptions}
            />
          ))}
          {!addNewAttribute.attributeForEdit && (
            <div>
              <div
                className={s.addNewAttribute}
                onClick={() =>
                  setAttributes(attributes => [
                    ...attributes,
                    {
                      input_type: 'text',
                      label: '',
                      help_text: '',
                    },
                  ])
                }
                style={{ fontSize: '0.9em', marginBottom: '3em' }}
              >
                <span className="material-icons">&#xe147;</span>
                <span>Add another attribute field</span>
              </div>
            </div>
          )}
        </div>
        <div className="dialog-controls">
          {isError && <div className="error-section">{isError}</div>}
          <div className="button">
            <button
              onClick={handleCloseDialog}
              className="custom-button outlined sm"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>

          <div className="button">
            <button
              className="custom-button sm"
              disabled={isLoading}
              onClick={() => {
                attributes.forEach(attr => {
                  if (
                    attr.input_type === 'options' &&
                    (!attr.options || attr.options.length < 1)
                  ) {
                    attr.error =
                      'Atleast two options required for option fields';
                  }
                });
                setAttributes([...attributes]);
                handleAddCategoryAttributes(attributes, addNewAttribute.parent);
              }}
            >
              {attributes.length > 1 ? 'Save Attributes' : 'Save Attribute'}
            </button>
            {isLoading && (
              <CircularProgress size={25} className="button-loader" />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default NewAttributeDialog;
