import { CircularProgress, Dialog } from '@mui/material';
import React from 'react';

const DeleteOptionDialog = ({
  deleteOption,
  setDeleteOption,
  isLoading,
  handleDeleteAttributeOptions,
  errorDeletingAttributeOption,
  setErrorDeletingAttributeOption,
  handleClearInput,
}) => {
  const handleCloseDialog = () => {
    setDeleteOption();
    setErrorDeletingAttributeOption();
    handleClearInput();
  };
  return (
    <Dialog open={true} onClose={handleCloseDialog}>
      <div className="dialog-wrapper">
        <div className="dialog-title">
          <span className="title">Delete Option</span>

          <span className="material-icons icon" onClick={handleCloseDialog}>
            &#xe5cd;
          </span>
        </div>
        <div
          className="dialog-body"
          style={{
            fontSize: '0.95em',
          }}
        >
          <div>
            Are you sure you want to delete{' '}
            <span style={{ fontWeight: '600' }}>
              {' '}
              &apos;{deleteOption.label}&apos;
            </span>{' '}
            attribute option under:
          </div>
          <div
            style={{
              fontWeight: '600',
              color: '#e52a23',
              fontSize: '0.9em',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span>{deleteOption?.slug}</span>
          </div>
        </div>
        <div className="dialog-controls">
          {errorDeletingAttributeOption && (
            <div className="error-section">{errorDeletingAttributeOption}</div>
          )}
          <div className="button">
            <button
              onClick={handleCloseDialog}
              className="custom-button outlined sm"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>

          <div className="button">
            <button
              className="custom-button sm"
              disabled={isLoading}
              onClick={() => handleDeleteAttributeOptions(deleteOption)}
            >
              DELETE OPTION &apos;{deleteOption.label}&apos;
            </button>
            {isLoading && (
              <CircularProgress size={25} className="button-loader" />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default DeleteOptionDialog;
