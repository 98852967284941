import { useState, useEffect, useReducer, useRef } from 'react';
import cn from 'classnames';
import { AddPhotoAlternateOutlined } from '@mui/icons-material';
import thumbsStyles from '@ictlife/merchant/components/posts/styles/Thumbs.module.scss';
import { useDropzone } from 'react-dropzone';
import { CircularProgress, Dialog, IconButton, TextField } from '@mui/material';
import { Transition } from '@ictlife/merchant/components/posts/AddPost';
import ImageCropper from '@ictlife/core/components/image-cropper';
import ImageThumbnail from '@ictlife/merchant/utils/ImageThumbnail';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { handleFilesUpload } from '@ictlife/merchant/state/actions/assetAction';
import {
  assetUploadReducer,
  initialUploadAssetState,
} from '@ictlife/merchant/state/reducers/assetUploadReducer';
import SnackInfo from '@ictlife/core/components/SnackInfo';
import GooglePlacesAutocomplete, {
  geocodeByLatLng,
} from 'react-google-places-autocomplete';
import compressImages from '@ictlife/core/utils/compressImages';

import Head from 'next/head';
import { getEventSchedule } from '@ictlife/core/hooks/useEventSchedule';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import {
  activateOffering,
  createMerchantOffering,
  createOfferingCategories,
  deleteOfferingAsset,
  updateMerchantOffering,
} from '../../state/actions/offeringActions';
import AppToolTip from '../shared/ToolTip';
import useSuperCategoryAndLocation from '../../hooks/useSuperCategoryAndLocation';
import st from './styles/create-offering.module.scss';
import OfferingCategory from './OfferingCategory';
// import OfferingPricing from './OfferingPricing';
import OfferingMerchantInfo from './OfferingMerchantInfo';
// import OfferingRating from './OfferingRating';
import ExternalLinkDialog from './ExternalLinkDialog';
import FilterAttributes from './FilterAttributes';

export const selectStyles = {
  control: provided => ({
    ...provided,
    height: '2.7em',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '2.7em',
    fontSize: '0.8em',
    fontWeight: '600',
  }),
  placeholder: provided => ({
    ...provided,
    fontWeight: '600',
  }),
  noOptionsMessage: provided => ({
    ...provided,
    fontSize: '0.8em',
    fontWeight: '600',
  }),
  option: provided => ({
    ...provided,
    fontSize: '0.8em',
    fontWeight: '600',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 10000,
  }),
};

const CreateOffering = ({
  offering,
  categoriesTree: defaultTree = [],
  redirectPathName,
  setMapCenter,
  byBusiness,
}) => {
  const router = useRouter();
  const { superCategory: superCategorySectionSlug } =
    useSuperCategoryAndLocation();
  const [files, setFiles] = useState(
    offering?.merchant_offering_assets?.map(asset => ({
      upload_success: asset.asset.status === 'active' ? true : false,
      upload_error: asset.asset.status === 'active' ? false : true,
      ...asset,
    })) || []
  );

  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      merchant: { id: business_id, business_username },
      merchant_profile,
    },
    merchantProfile: activeMerchant,
  } = merchantProfileState;

  const [proposedFiles, setproposedFiles] = useState([]);
  const [postMediaError, setPostMediaError] = useState('');
  const [activeThumbIndex, setActiveThumbIndex] = useState(-1);
  const [categoriesTree, setCategoriesTree] = useState(defaultTree || []);
  const [title, setTitle] = useState(offering?.title || '');
  const [merchant_category_ids, set_merchant_category_ids] = useState(
    offering?.offering_categories.map(
      offeringCategory => offeringCategory.merchant_category.id
    ) ?? []
  );
  const [mainAttributes, setMainAttributes] = useState({
    visitor_type: offering?.visitor_type?.split(','),
    budget_level: offering?.budget_level?.split(','),
    going_with: offering?.going_with?.split(','),
    special_needs: offering?.special_needs?.split(','),
    kids_age: offering?.kids_age?.split(','),
  });

  const [location, setLocation] = useState(
    merchant_profile?.latitude
      ? {
          latitude: merchant_profile.latitude,
          longitude: merchant_profile.longitude,
          address_line:
            merchant_profile.address_line_1 || merchant_profile.city,
        }
      : offering?.latitude
      ? {
          latitude: offering.latitude,
          longitude: offering.longitude,
          address_line: offering.address_line || offering.city,
        }
      : null
  );

  const [description, setDescription] = useState(offering?.description || '');
  const [loadingCreateOffering, setLoadingCreateOffering] = useState(false);
  const [errorCreatingOffering, setErrorCreatingOffering] = useState(false);
  const [categoryAttributes, setCategoryAttributes] = useState({});
  const [categoryAttributeValues, setCategoryAttributeValues] = useState([]);
  const [selectedSuperCategory, setSelectedSuperCategory] = useState();
  // const [overallRating, setOverallRating] = useState(offering?.overall_rating);
  const [initialCategoryAttributeValues, setInitialCategoryAttributeValues] =
    useState([]);
  const [cropperDefaultIndex, setCropperDefaultIndex] = useState();
  const [snackInfo, setSnackInfo] = useState({
    open: false,
    message: '',
    vertical: 'top',
    horizontal: 'center',
    severity: 'info',
    autoHideDuration: 5000,
  });

  // const [pricing, setPricing] = useState(
  //   offering?.prices.map(price => ({
  //     id: price.id,
  //     currency_id: price.currency.id,
  //     currency_code: price.currency.code,
  //     price_item: price.price_item,
  //     retail_price: parseInt(price.display_price.replace(/,/g, '')),
  //     units: price.units,
  //     unit_description: price?.unit_description,
  //   })) || []
  // );

  const [offeringTimeAttributes, setOfferingTimeAttributes] = useState(
    offering?.offering_time_attributes
      ? offering?.offering_time_attributes.map(time_attribute => {
          const {
            byweekday = [],
            bymonthday = [],
            frequency,
          } = getEventSchedule({ time_attribute });
          return {
            ...time_attribute,
            frequency: frequency?.toUpperCase() ?? '',
            byweekday,
            bymonthday,
          };
        })
      : []
  );

  const [createdOffering, setCreatedOffering] = useState();
  const [merchantInfo, setMerchantInfo] = useState(
    offering?.merchant_info?.merchant
      ? {
          contact_phone:
            offering.merchant_info.merchant.merchant_profile?.contact_phone,
          name: offering.merchant_info.merchant.business_name,
          latitude: offering.merchant_info.merchant.merchant_profile?.latitude,
          longitude:
            offering.merchant_info.merchant.merchant_profile?.longitude,
          address_line:
            offering.merchant_info.merchant.merchant_profile?.address_line_1,
        }
      : {}
  );

  const locationRef = useRef();

  const userInfoState = useSelector(store => store.userInfoState);
  const {
    user: { id: userId },
  } = userInfoState;

  const [uploadAssetsState, dispatchUploadAsset] = useReducer(
    assetUploadReducer,
    initialUploadAssetState
  );

  useEffect(() => {
    document
      .querySelector('#business-layout-content')
      ?.scrollIntoView({ behavior: 'smooth' });
    document.querySelector('#business-layout-content')?.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (offering) {
      const combinedAttributeValues = [];

      offering?.merchant_offering_attribute?.offering_attribute_value?.merchant_offering_attributes?.forEach(
        attribute => {
          if (attribute.category_attribute.input_type === 'location') {
            const location = attribute.attribute_value.split(',');
            try {
              geocodeByLatLng({
                lat: Number(location[0]),
                lng: Number(location[1]),
              })
                .then(results => {
                  combinedAttributeValues.push({
                    address: results[0].formatted_address,
                    attribute_value: attribute.attribute_value,
                    category_attribute_id: attribute.category_attribute_id,
                    category_attribute_option_id:
                      attribute.category_attribute_option_id || undefined,
                    parent_slug: defaultTree.find(
                      cat =>
                        cat.value === attribute.category_attribute.parent_id
                    )?.slug,
                    id: attribute.id,
                    merchant_offering_id: offering.id,
                  });
                  setCategoryAttributeValues([...combinedAttributeValues]);
                  setInitialCategoryAttributeValues([
                    ...combinedAttributeValues,
                  ]);
                })
                .catch(error => console.error(error));
            } catch (error) {
              console.error(error);
            }
          } else {
            combinedAttributeValues.push({
              attribute_value: attribute.attribute_value,
              category_attribute_id: attribute.category_attribute_id,
              category_attribute_option_id:
                attribute.category_attribute_option_id || undefined,
              parent_slug: defaultTree.find(
                cat => cat.value === attribute.category_attribute.parent_id
              )?.slug,
              id: attribute.id,
              merchant_offering_id: offering.id,
            });
          }
        }
      );

      offering?.merchant_offering_attribute?.offering_attribute_value?.option_multi_choice_values?.forEach(
        attribute => {
          for (const optionValue of attribute.option_values) {
            combinedAttributeValues.push({
              attribute_value: optionValue.attribute_value,
              category_attribute_id: optionValue.category_attribute_id,
              category_attribute_option_id:
                optionValue.category_attribute_option_id,
              parent_slug: defaultTree.find(
                cat => cat.value === attribute.category_attribute.parent_id
              )?.slug,
              id: optionValue.id,
              merchant_offering_id: offering.id,
            });
          }
        }
      );

      setCategoryAttributeValues([...combinedAttributeValues]);
      setInitialCategoryAttributeValues([...combinedAttributeValues]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offering]);

  const proposePostMedia = async acceptedFiles => {
    const compressedFiles = await compressImages(
      acceptedFiles.slice(0, 10 - files.length)
    );

    const addedFiles = compressedFiles.map(file => ({
      id: null,
      asset: {
        url: URL.createObjectURL(file),
        description: '',
      },
      file: file,
      upload_loading: false,
      upload_success: false,
      upload_error: false,
      ...file,
    }));

    setproposedFiles(addedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/jfif',
      'video/*',
    ],
    maxSize: 25000000,
    onDropAccepted: acceptedFiles => {
      if (postMediaError !== '') {
        setPostMediaError('');
      }
      if (files.length < 10) {
        if (acceptedFiles.length > 10 - files.length) {
          setPostMediaError(
            `${
              acceptedFiles.length - (10 - files.length)
            } image(s) ignored because the maximum limit of 10 is exceeded, you may replace already added images.`
          );
        }
        proposePostMedia(acceptedFiles);
      } else {
        setPostMediaError(
          'Maximum number of images added, you may replace the ones already added'
        );
      }
    },
    onDropRejected: () =>
      setPostMediaError(
        'Select images below 5mbs, larger images and non-image files have been ignored.'
      ),
    disabled: uploadAssetsState.isLoading,
  });

  const removeItem = file => {
    let newFiles = files;
    let index = newFiles.indexOf(file);
    newFiles[index].upload_loading = true;
    const removalSuccess = () => {
      newFiles.splice(index, 1);
      URL.revokeObjectURL(file);
      setFiles([...newFiles]);
    };
    if (file.id !== null) {
      deleteOfferingAsset(file).then(() => removalSuccess());
    } else {
      removalSuccess();
    }
  };

  if (uploadAssetsState.isProgress) {
    if (uploadAssetsState.updatedPostsMedia) {
      setFiles(uploadAssetsState.updatedPostsMedia);
    }

    dispatchUploadAsset({ type: 'LOADING', errorMessage: '' });
  }

  if (uploadAssetsState.isSuccess) {
    dispatchUploadAsset({ type: 'DEFAULT' });
    if (!offering || offering.status === 'inactive') {
      // for new offerings
      activateOffering(createdOffering.id || offering.id)
        .then(() => {
          if (redirectPathName) {
            router.push({
              pathname: redirectPathName,
              query: {
                specificOfferingSlug: createdOffering.uuid_slug,
              },
            });
          } else if (superCategorySectionSlug && !byBusiness) {
            router.push({
              pathname: '/[superCategory]/[specificOfferingSlug]',
              query: {
                superCategory: router.query.superCategory,
                specificOfferingSlug: createdOffering.uuid_slug,
              },
            });
          } else {
            if (selectedSuperCategory?.slug === 'events') {
              router.push(
                `/business/${business_username}/offerings/${createdOffering.uuid_slug}/tickets`
              );
            } else {
              router.push(
                `/business/${business_username}/offerings/${createdOffering.uuid_slug}`
              );
            }
          }
        })
        .catch(error => {
          setSnackInfo(info => ({
            ...info,
            open: true,
            severity: 'error',
            message: `Offering activation failed: ${error.response?.data?.error_message}`,
          }));
        });
    } else {
      // for offerings being edited
      if (redirectPathName) {
        router.push({
          pathname: redirectPathName,
          query: {
            specificOfferingSlug: offering.uuid_slug,
          },
        });
      } else if (superCategorySectionSlug && !byBusiness) {
        router.push({
          pathname: '/[superCategory]/[specificOfferingSlug]',
          query: {
            superCategory: router.query.superCategory,
            specificOfferingSlug: offering.uuid_slug,
          },
        });
      } else {
        router.push({
          pathname: '/business/[b_username]/offerings/[offering_slug]/tickets',
          query: {
            offering_slug: offering.uuid_slug,
            ...router.query,
          },
        });
      }
    }
  }

  const [openExternalLinkDialog, setOpenExternalLinkDialog] = useState(false);
  const [openGraphLink, setOpenGraphLink] = useState(offering?.open_graph_link);
  const [offering_category_type, set_offering_category_type] = useState(
    offering ? offering.offering_category_type || 'event' : ''
  );

  const handleCreateMerchantOffering = async type => {
    if (type === 'draft' && !merchant_category_ids?.length) {
      setSnackInfo(info => ({
        ...info,
        open: true,
        severity: 'error',
        message: 'Add a category for your event before saving draft',
      }));
      return;
    }
    errorCreatingOffering && setErrorCreatingOffering(false);

    if (createdOffering?.id) {
      dispatchUploadAsset({ type: 'LOADING' });
      const assetInfo = {
        type: 'merchant_offering_asset',
        offeringId: createdOffering.id,
      };
      handleFilesUpload(
        dispatchUploadAsset,
        files,
        assetInfo,
        activeMerchant,
        type,
        setSnackInfo
      );
      return;
    }

    // filter the relevant attribute values
    let category_attribute_values = [];
    category_attribute_values = categoryAttributeValues.filter(
      cav =>
        cav.parent_slug === superCategorySectionSlug ||
        categoryAttributes[cav.parent_slug] !== undefined
    );

    const merchant_offering_attributes =
      offering &&
      category_attribute_values.map(cav => {
        const id = initialCategoryAttributeValues.find(
          ica =>
            ica.category_attribute_id === cav.category_attribute_id &&
            (ica.category_attribute_option_id ===
              cav.category_attribute_option_id ||
              (!ica.category_attribute_option_id &&
                !cav.category_attribute_option_id))
        )?.id;
        return {
          ...cav,
          id,
          merchant_offering_id: offering.id,
        };
      });

    if (initialCategoryAttributeValues) {
      category_attribute_values = category_attribute_values.filter(cav => {
        return (
          initialCategoryAttributeValues.findIndex(
            ica =>
              ica.category_attribute_id === cav.category_attribute_id &&
              (ica.category_attribute_option_id ===
                cav.category_attribute_option_id ||
                (!ica.category_attribute_option_id &&
                  !cav.category_attribute_option_id))
          ) === -1
        );
      });
    }

    // const offering_prices =
    //   pricing?.length > 0
    //     ? pricing.map(price => ({
    //         id: price.id,
    //         currency_id: price.currency_id,
    //         price_item: price.price_item,
    //         retail_price: parseFloat(price.retail_price),
    //         units: parseInt(price.units) || 1,
    //         unit_description:
    //           price?.unit_description ||
    //           (price.units && price.units > 1 ? 'items' : 'item'),
    //       }))
    //     : undefined;

    const {
      id: existingMerchantId,
      name,
      latitude,
      longitude,
      is_delivered,
      contact_phone,
      address_line,
      business_type,
    } = merchantInfo;

    const bodyData = {
      title,
      description,
      merchant_id: existingMerchantId || business_id,
      merchant_category_id: merchant_category_ids[0],
      user_id: userId,
      category_attribute_values,
      // ...(offering_prices && { offering_prices }),
      merchant_offering_attributes, // relevant to offering updates
      latitude: location?.latitude,
      longitude: location?.longitude,
      address_line: location?.address_line,
      ...(!business_id &&
        !existingMerchantId &&
        Object.keys(merchantInfo).length !== 0 &&
        merchantInfo.name && {
          merchant_info_form: {
            contact_phone,
            merchant_name: name,
            latitude: latitude,
            longitude: longitude,
            address_line,
            business_type,
          },
        }),
      is_delivered,
      // overall_rating: overallRating,
      visibility_status: 'public',

      // EVENT TIME ATTRIBUTES
      ...(superCategorySectionSlug === 'events' && {
        offering_time_attributes: offeringTimeAttributes
          .filter(attribute => !attribute.id)
          .map(time_attribute => ({
            ...time_attribute,
            recurring: time_attribute.frequency !== '' ? true : false,
          })),
      }),

      // OG data
      ...(openGraphLink && {
        open_graph_link: openGraphLink,
      }),
      ...(offering_category_type === 'experience' && {
        offering_category_type: 'experience',
      }),
      ...mainAttributes,
    };

    setLoadingCreateOffering(true);
    let promise;
    if (offering) {
      promise = updateMerchantOffering(bodyData, offering.id);
    } else {
      promise = createMerchantOffering(bodyData);
    }

    promise
      .then(async data => {
        const res = await createOfferingCategories({
          merchant_offering_id: data.id,
          merchant_category_ids,
        });

        console.log(res, 'response');

        const assetInfo = {
          type: 'merchant_offering_asset',
          offeringId: data.id,
        };

        setCreatedOffering(data);

        handleFilesUpload(
          dispatchUploadAsset,
          files,
          assetInfo,
          activeMerchant,
          type,
          setSnackInfo
        );
      })
      .catch(error => {
        setErrorCreatingOffering(error.response.data.error_message);
      })
      .finally(() => setLoadingCreateOffering(false));
  };

  const thumbs = files.map((file, index) => {
    return (
      <div className={thumbsStyles.wrapper} key={file.asset.url}>
        <div
          className={cn(
            thumbsStyles.thumbHolder,
            thumbsStyles.offeringPhotoHolder
          )}
        >
          <ImageThumbnail
            file={file}
            activeThumbIndex={activeThumbIndex}
            index={index}
            handleRetry={handleCreateMerchantOffering}
            showControls={!file.upload_loading && !uploadAssetsState.isLoading}
            handleRemoveItem={() => {
              setPostMediaError('');
              // dispatchUploadPostMedia({ type: 'DEFAULT' });
              if (files.length === 1 && offering) {
                setSnackInfo(info => ({
                  ...info,
                  open: true,
                  message:
                    'You require at least one image, add a replacement before removing the last image',
                }));
                return;
              }
              if (
                index === activeThumbIndex && // it is the active thumb image and
                activeThumbIndex !== 0 // it's not the first thumb image
              ) {
                let index = activeThumbIndex - 1;
                setActiveThumbIndex(index);
              } else if (
                activeThumbIndex === index && // it is the active thumb image at index 0 and
                files.length > 1 // there are other thumb images
              ) {
                let index = activeThumbIndex + 1;
                setActiveThumbIndex(index);
              } else if (index < activeThumbIndex) {
                // It is not the active thumb image and it is before the active thumb image
                let index = activeThumbIndex - 1;
                setActiveThumbIndex(index);
              }
              removeItem(file, files, setFiles);
            }}
          />
        </div>

        <AppToolTip title={file.asset.description}>
          <div className={thumbsStyles.caption}>
            <div className={thumbsStyles.captionText}>
              {file.asset.description ? (
                <span className={thumbsStyles.capt}>
                  {file.asset.description}
                </span>
              ) : (
                <span className={thumbsStyles.noCaption}>No caption</span>
              )}
            </div>
            {!file.id && (
              <div className={thumbsStyles.icon}>
                <IconButton
                  size="small"
                  onClick={() => {
                    setFiles([]);
                    setproposedFiles(files);
                    setCropperDefaultIndex(index);
                  }}
                >
                  <span className="material-icons">&#xe3c9;</span>
                </IconButton>
              </div>
            )}
          </div>
        </AppToolTip>
      </div>
    );
  });

  return (
    <>
      <Head>
        <title>Add Product to the ICTLife marketplace</title>
      </Head>
      {!offering && (
        <div className="px-4 lg:px-[1.2em] py-2">
          <div
            className={cn(st.addNewAttribute, 'font-normal')}
            onClick={() => setOpenExternalLinkDialog(true)}
          >
            <span className="material-icons">&#xe157;</span>
            <span>
              Click here to pre-fill form using data from an external link
              (optional)
            </span>
          </div>
        </div>
      )}

      <form
        className={st.createOffering}
        onSubmit={e => {
          e.preventDefault();
          handleCreateMerchantOffering();
        }}
      >
        <div className={cn(st.mainSectionTitle, 'mt-0')}>
          <h3>
            {superCategorySectionSlug === 'events'
              ? 'Event Information'
              : ' Information'}
          </h3>
        </div>
        <div className={cn(st.field, st.offeringField, 'override')}>
          <label htmlFor="title">Title *</label>
          <TextField
            type="text"
            value={title}
            onChange={e => setTitle(e.target.value)}
            id="title"
            required
            variant="outlined"
            fullWidth
            multiline
            inputProps={{ maxLength: 200 }}
          />
          {title?.length === 200 && (
            <span
              className={cn('text-xs font-medium', {
                'text-red-600': title?.length === 200,
              })}
            >
              200 character limit reached{' '}
              {openGraphLink ? '(excess characters excluded)' : ''}
            </span>
          )}
        </div>

        <div className={st.offeringField}>
          <label>Add images and videos *</label>
          <div className="h-0">
            <input
              required={
                files.length > 0 || (openGraphLink && openGraphLink.image_url)
                  ? false
                  : true
              }
              className="h-0 opacity-0"
              autoComplete="off"
              tabIndex={-1}
              type="file"
            />
          </div>
          <div className={cn(thumbsStyles.thumbsContainer, st.thumbsContainer)}>
            {openGraphLink && openGraphLink.image_url && (
              <div className={thumbsStyles.wrapper}>
                <div
                  className={cn(
                    thumbsStyles.thumbHolder,
                    thumbsStyles.offeringPhotoHolder,
                    'overflow-hidden rounded-lg'
                  )}
                >
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img src={openGraphLink.image_url} />
                </div>
                <div className="font-medium text-xs mt-2">
                  External link image
                </div>
              </div>
            )}

            {thumbs}

            <div
              className={cn(
                thumbsStyles.thumbHolder,
                thumbsStyles.offeringPhotoHolder
              )}
            >
              <div
                {...getRootProps({
                  className: 'add-photo-dropzone',
                })}
              >
                <input {...getInputProps()} />
                <AddPhotoAlternateOutlined htmlColor="#a5b2c1" />
                <span>Drag &apos;n drop or click here, to add images</span>
              </div>
            </div>
          </div>
        </div>

        <div className={cn(st.field, st.offeringField, 'override')}>
          <label htmlFor="description">Description</label>
          <TextField
            value={description}
            onChange={e => setDescription(e.target.value)}
            id="description"
            fullWidth
            multiline
            variant="outlined"
            minRows={3}
          />
        </div>

        <div className={cn(st.field, st.offeringField, 'override')}>
          <label htmlFor="location">Location *</label>
          <div className="h-0">
            <input
              required={location?.address_line ? false : true}
              className="h-0 opacity-0"
              autoComplete="off"
              tabIndex={-1}
              onFocus={() => {
                locationRef?.current.focus();
              }}
            />
          </div>
          <GooglePlacesAutocomplete
            debounce={700}
            selectProps={{
              ref: locationRef,
              value: location
                ? {
                    label: location.address_line,
                    value: location.address_line,
                  }
                : null,
              onChange: (data, actionMeta) => {
                if (actionMeta.action === 'clear') {
                  setLocation();
                }
                if (data) {
                  geocodeByPlaceId(data.value.place_id)
                    .then(results => {
                      const latitude = results[0].geometry.location.lat();
                      const longitude = results[0].geometry.location.lng();
                      setLocation({
                        latitude,
                        longitude,
                        address_line: data.value.description,
                      });

                      setMapCenter &&
                        setMapCenter({
                          lat: latitude,
                          lng: longitude,
                        });

                      (!merchantInfo.latitude ||
                        merchantInfo.location_message) &&
                        setMerchantInfo(info => ({
                          ...info,
                          latitude,
                          longitude,
                          address_line: data.value.description,
                          location_message:
                            'Pre-filled from the location of the event. Update if need.',
                        }));
                    })
                    .catch(error => console.error(error));
                }
              },
              placeholder: 'Type to search location',
              menuPlacement: 'auto',
              openMenuOnClick: false,
              isClearable: false,
              components: {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              },
              styles: selectStyles,
              theme: theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#ccc',
                  primary: '#ff8e22',
                },
              }),
            }}
          />
        </div>

        <div>
          <div className="font-semibold text-sm mb-2">Type of listing *</div>
          <div className="h-0">
            <input
              required={offering_category_type ? false : true}
              className="h-0 opacity-0"
              autoComplete="off"
              tabIndex={-1}
            />
          </div>
          <div className="flex items-center gap-4">
            {[
              {
                label: 'Daily Experiences and Things to do',
                value: 'experience',
              },
              { label: 'One time or Few times Event', value: 'event' },
            ].map(type_of_listing => (
              <button
                key={type_of_listing.value}
                className={cn(
                  'border-2 outlined rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-100',
                  {
                    'border-primary':
                      type_of_listing.value === offering_category_type,
                  }
                )}
                type="button"
                onClick={() =>
                  set_offering_category_type(type_of_listing.value)
                }
              >
                {type_of_listing.label}
              </button>
            ))}
          </div>
        </div>

        {/* {!business_id && (
          <OfferingRating
            overallRating={overallRating}
            setOverallRating={setOverallRating}
          />
        )} */}

        {/* {business_id && (
          <OfferingPricing pricing={pricing} setPricing={setPricing} />
        )} */}

        <OfferingCategory
          categoriesTree={categoriesTree}
          setCategoriesTree={setCategoriesTree}
          offering={offering}
          setCategoryAttributes={setCategoryAttributes}
          selectedSuperCategory={selectedSuperCategory}
          setSelectedSuperCategory={setSelectedSuperCategory}
          business_id={business_id}
          offeringTimeAttributes={offeringTimeAttributes}
          setOfferingTimeAttributes={setOfferingTimeAttributes}
          offering_category_type={offering_category_type}
          merchant_category_ids={merchant_category_ids}
          set_merchant_category_ids={set_merchant_category_ids}
        />

        {offering_category_type && (
          <FilterAttributes
            experience={offering}
            mainAttributes={mainAttributes}
            setMainAttributes={setMainAttributes}
          />
        )}

        {!business_id && !offering && (
          <>
            <div className={st.mainSectionTitle}>
              <h3>
                {superCategorySectionSlug === 'events'
                  ? 'Event Organiser Information'
                  : 'Business Information'}
              </h3>
            </div>
            <OfferingMerchantInfo
              merchantInfo={merchantInfo}
              setMerchantInfo={setMerchantInfo}
            />
          </>
        )}

        {errorCreatingOffering && (
          <div className="error-section mt-4">{errorCreatingOffering}</div>
        )}
        {uploadAssetsState.errorMessage && (
          <div className="error-section mt-4">
            {uploadAssetsState.errorMessage}
          </div>
        )}
        <div className={cn(st.controls, 'flex items-center space-x-4')}>
          <div className="button">
            <button
              className="custom-button outlined !rounded-md"
              disabled={loadingCreateOffering || uploadAssetsState.isLoading}
              type="button"
              onClick={() => handleCreateMerchantOffering('draft')}
            >
              Save Draft
            </button>
            {(loadingCreateOffering || uploadAssetsState.isLoading) && (
              <CircularProgress size={25} className="button-loader" />
            )}
          </div>
          <div className="button">
            <button
              className="custom-button !rounded-md"
              disabled={loadingCreateOffering || uploadAssetsState.isLoading}
            >
              {errorCreatingOffering || uploadAssetsState.errorMessage
                ? 'RETRY CREATING EVENT'
                : offering
                ? 'PUBLISH CHANGES'
                : 'Next: Ticketing'}
            </button>
            {(loadingCreateOffering || uploadAssetsState.isLoading) && (
              <CircularProgress size={25} className="button-loader" />
            )}
          </div>
        </div>
      </form>
      {proposedFiles.length > 0 && (
        <Dialog
          open={true}
          TransitionComponent={Transition}
          className="override"
          maxWidth="lg"
        >
          <ImageCropper
            cropFiles={proposedFiles.map(file => file.asset.url)}
            setCropFiles={setproposedFiles}
            proposedFiles={proposedFiles}
            fileIndex={cropperDefaultIndex}
            onCropComplete={(croppedFiles, assetDescriptions) => {
              const transformedFiles = proposedFiles.map(
                (proposedFile, index) => {
                  const file =
                    proposedFile.id || proposedFile.cropped
                      ? {
                          ...proposedFile,
                          asset: {
                            ...proposedFile.asset,
                            description: assetDescriptions[index],
                          },
                        }
                      : {
                          ...proposedFile,
                          cropped: true,
                          asset: {
                            url: URL.createObjectURL(croppedFiles[index]),
                            status: 'active',
                            description: assetDescriptions[index],
                          },
                          file: croppedFiles[index],
                        };
                  return file;
                }
              );

              setCropperDefaultIndex(0);
              setFiles(files => [...files, ...transformedFiles]);
              setproposedFiles([]);
            }}
          />
        </Dialog>
      )}
      {openExternalLinkDialog && (
        <ExternalLinkDialog
          setOpenExternalLinkDialog={setOpenExternalLinkDialog}
          handleClear={() => {
            setTitle('');
            setDescription('');
          }}
          handleSetOpenGraphLink={({ OGLink, title, eventBriteData }) => {
            setOpenGraphLink(OGLink);
            setTitle(eventBriteData?.name?.text ?? title);
            setDescription(
              eventBriteData?.description?.text || OGLink.description || ''
            );
            setOfferingTimeAttributes([
              {
                start_time: eventBriteData.start.utc,
                end_time: eventBriteData.end.utc,
                frequency: '',
              },
            ]);
          }}
        />
      )}
      <SnackInfo
        snackInfo={snackInfo}
        handleClose={() =>
          setSnackInfo(snackInfo => ({
            ...snackInfo,
            open: false,
          }))
        }
      />
    </>
  );
};

export default CreateOffering;
