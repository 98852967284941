import axiosConfig from '../../config/axiosConfig';
import {
  LOADING,
  SUCCESS,
  ERROR,
  BUSINESS_KEYWORDS,
  BUSINESS_KEYWORDS_SUCCESS,
  BUSINESS_KEYWORDS_ERROR,
} from '../dispatchTypes';

export const createBusinessKeyword = (
  dispatchCreateBusinessKeyword,
  bodyData
) => {
  dispatchCreateBusinessKeyword({
    type: LOADING,
  });
  return new Promise(resolve => {
    axiosConfig
      .post('/merchant_keywords', bodyData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.error(error.response);
        dispatchCreateBusinessKeyword({
          type: ERROR,
          errorMessage: error.response.data.error_message,
        });
      })
      .catch(() =>
        dispatchCreateBusinessKeyword({
          type: ERROR,
          errorMessage: 'Something went wrong',
        })
      );
  });
};

export const getBusinessKeywords = (dispatch, business_id) => {
  dispatch({
    type: BUSINESS_KEYWORDS,
  });

  axiosConfig
    .get(`/merchants/${business_id}/merchant_keywords`)
    .then(response => {
      dispatch({
        type: BUSINESS_KEYWORDS_SUCCESS,
        businessKeywords: response.data.merchant_keywords,
      });
    })
    .catch(error => {
      dispatch({
        type: BUSINESS_KEYWORDS_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: BUSINESS_KEYWORDS_ERROR,
        errorMessage: 'Something went wrong',
      });
    });
};

export const deleteBusinessKeyword = (
  dispatchDeleteBusinessKeyword,
  keyword_ids
) => {
  const merchant_id = JSON.parse(localStorage.activeMerchant).merchant.id;
  dispatchDeleteBusinessKeyword({
    type: LOADING,
  });

  try {
    axiosConfig
      .delete(`/merchants/${merchant_id}/merchant_keywords`, {
        params: {
          keyword_ids,
        },
      })
      .then(() => {
        dispatchDeleteBusinessKeyword({
          type: SUCCESS,
        });
      })
      .catch(error => {
        console.error(error.response);
        dispatchDeleteBusinessKeyword({
          type: ERROR,
          errorMessage: error.response.data.error_message,
        });
      });
  } catch (error) {
    dispatchDeleteBusinessKeyword({
      type: ERROR,
      errorMessage: 'Something went wrong, try again later!',
    });
  }
};
