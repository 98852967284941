import axiosConfig from '../../config/axiosConfig';
import computeChecksumMd5 from '../../utils/computeMD5Checksum';
import { SUCCESS, ERROR } from '../dispatchTypes';

const resolveAssetAndMd5 = async ({ fileObject }) => {
  const md5 = await computeChecksumMd5(fileObject.file);
  const asset = {
    async: true,
    content_md5: md5,
    content_size: fileObject.file.size,
    content_type: fileObject.file.type,
    duration: null,
    image_length: Math.round(fileObject.file.height),
    image_width: Math.round(fileObject.file.width),
  };
  return { asset, md5 };
};

const merchantPostAsset = ({ asset, fileObject, assetInfo }) => {
  const bodyData = {
    merchant_post_id: assetInfo.postId,
    asset: { ...asset, description: fileObject.asset.description },
  };
  const url = '/merchant_post_assets';
  return {
    url,
    bodyData,
  };
};

const coverPhotoAsset = ({ activeMerchant, index, asset, fileObject }) => {
  const bodyData = {
    merchant_id: activeMerchant.merchant.id,
    cover_photo: {
      rank: index + 1,
      asset: {
        ...asset,
        description: fileObject.asset.description,
      },
    },
  };
  const url = '/merchant_cover_photos';

  return {
    url,
    bodyData,
  };
};

const merchantAboutSectionAsset = ({ assetInfo, asset, fileObject }) => {
  const bodyData = {
    merchant_about_section_id: assetInfo.merchantAboutSectionId,
    asset: {
      ...asset,
      description: fileObject.asset.description,
    },
  };
  const url = '/merchant_about_section_assets';
  return {
    url,
    bodyData,
  };
};

const merchantOfferingAsset = ({ assetInfo, asset, fileObject }) => {
  const bodyData = {
    merchant_offering_id: assetInfo.offeringId,
    asset: {
      ...asset,
      description: fileObject.asset.description,
    },
  };
  const url = '/merchant_offering_assets';
  return {
    url,
    bodyData,
  };
};

export const handleFilesUpload = (
  dispatch,
  files,
  assetInfo,
  activeMerchant,
  type,
  setSnackInfo
) => {
  const filesWithIndexes = files.map((file, idx) => ({ ...file, index: idx }));
  const validFilesForUpload = filesWithIndexes.filter(
    photo => photo.upload_success === false
  );
  if (validFilesForUpload.length === 0) {
    if (type === 'draft') {
      setSnackInfo(info => ({
        ...info,
        open: true,
        severity: 'success',
        message: 'Latest Changes Saved to Draft',
      }));
      return dispatch({
        type: 'DEFAULT',
      });
    } else {
      return dispatch({
        type: SUCCESS,
      });
    }
  }

  const requests = validFilesForUpload.map(async fileObject => {
    const { index } = fileObject;
    files[index].upload_loading = true;
    files[index].upload_error = false;
    dispatch({
      type: 'PROGRESS',
      updatedMediaFiles: files,
    });

    if (!fileObject.is_added_to_bucket && !fileObject.asset.content_md5) {
      const { asset, md5 } = await resolveAssetAndMd5({ fileObject });

      const knownAssetTypeFunctions = {
        merchant_post_asset: merchantPostAsset,
        cover_photo_asset: coverPhotoAsset,
        merchant_about_section_asset: merchantAboutSectionAsset,
        merchant_offering_asset: merchantOfferingAsset,
      };

      const assetFunction = knownAssetTypeFunctions[assetInfo.type];
      if (!assetFunction) return null;
      const { url, bodyData } = assetFunction({
        activeMerchant,
        index,
        asset,
        fileObject,
        assetInfo,
      });

      return uploadHandler(
        dispatch,
        bodyData,
        fileObject,
        md5,
        index,
        files,
        url,
        assetInfo
      );
    } else {
      return uploadHandler(
        dispatch,
        undefined,
        fileObject,
        undefined,
        index,
        files,
        undefined,
        assetInfo
      );
    }
  });

  const validRequests = requests.filter(request => request != null);

  Promise.all(validRequests).then(data => {
    if (data.find(value => value.status === 'error')) {
      dispatch({
        type: ERROR,
        errorMessage:
          'Some images could not be uploaded. Check the failed ones and retry them',
      });
    } else {
      if (type === 'draft') {
        setSnackInfo(info => ({
          ...info,
          open: true,
          severity: 'success',
          message: 'Latest Changes Saved to Draft',
        }));
        return dispatch({
          type: 'DEFAULT',
        });
      } else {
        dispatch({
          type: SUCCESS,
          data,
        });
      }
    }
  });
};

const updateAssetStatus = async ({
  array,
  index,
  assetInfo,
  assetId,
  resolve,
}) => {
  // success uploading to bucket
  array[index].upload_progress = null;

  const statusUpdate = {
    reason: 'update status to active',
    status: 'success',
  };

  const knownAssetStatusUrl = {
    merchant_post_asset: `merchant_post_assets/${assetId}/status`,
    cover_photo_asset: `merchant_cover_photos/${assetId}/status`,
    merchant_about_section_asset: `merchant_about_section_assets/${assetId}/status`,
    merchant_offering_asset: `merchant_offering_assets/${assetId}/status`,
  };

  const status_url = knownAssetStatusUrl[assetInfo.type] || '';

  try {
    await axiosConfig.put(status_url, statusUpdate);
    array[index].asset.status = 'active';
    array[index].upload_loading = false;
    array[index].upload_success = true;
    array[index].error_section = '';
    resolve({
      index,
      status: 'success',
    });
  } catch {
    array[index].asset.status = 'pending';
    array[index].upload_loading = false;
    array[index].error_section = 'Failed to activate asset. Replace this asset';
    resolve({
      index,
      status: 'error',
      message: 'Failed to activate asset',
    });
  }
};

const uploadFileToBucket = async ({
  response,
  assetInfo,
  array,
  index,
  dispatch,
  fileObject,
  md5,
}) => {
  let presigned_url = response.data.upload_url;
  const assetId = response.data.id || response.data[assetInfo.type].id;

  array[index].id = assetId;
  array[index].upload_url = presigned_url;

  dispatch({
    type: 'PROGRESS',
    updatedMediaFiles: array,
  });

  await axiosConfig.put(presigned_url, fileObject.file, {
    headers: {
      'Content-MD5': md5,
      'x-amz-acl': 'public-read',
      'Content-Type': fileObject.file.type, //'application/x-www-form-urlencoded'
    },
    onUploadProgress: progressEvent => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      array[index].upload_progress = percentCompleted;
      dispatch({
        type: 'PROGRESS',
        updatedMediaFiles: array,
      });
    },
  });
};

const uploadHandler = (
  dispatch,
  bodyData,
  fileObject,
  md5,
  index,
  array,
  url,
  assetInfo
) => {
  return new Promise(resolve => {
    let promise;

    if (fileObject.upload_url || fileObject.asset.content_md5) {
      promise = new Promise(resolve =>
        resolve({
          data: {
            upload_url: fileObject.upload_url,
            id: fileObject.id,
          },
        })
      );
    } else {
      promise = axiosConfig.post(url, bodyData);
    }
    promise
      .then(async response => {
        const assetId = response.data.id || response.data[assetInfo.type].id;
        try {
          if (!fileObject.is_added_to_bucket && !fileObject.asset.content_md5) {
            await uploadFileToBucket({
              response,
              assetInfo,
              array,
              index,
              dispatch,
              fileObject,
              md5,
            });
          }
          array[index].is_added_to_bucket = true;
          await updateAssetStatus({
            array,
            index,
            assetInfo,
            assetId,
            resolve,
          });
        } catch (error) {
          array[index].upload_loading = false;
          array[index].upload_error = true;
          array[index].error_section = 'Failed to upload image';
          dispatch({
            type: 'PROGRESS',
            updatedMediaFiles: array,
          });
          resolve({
            index,
            status: 'error',
            message: 'Failed to upload image',
          });
        }
      })
      .catch(error => {
        // Error getting upload url
        array[index].upload_loading = false;
        array[index].upload_error = true;
        array[
          index
        ].error_section = `Upload url error: ${error.response?.data?.error_message}`;

        if (error.response.status === 404) {
          resolve({
            index,
            status: 'error',
            message: 'Cannot process request. Contact us.',
          });
        } else {
          resolve({
            index,
            status: 'error',
            message: error.response.data.error_message,
          });
        }
      })
      .catch(() => {
        // second catch getting upload url
        // TODO: we wont need this once the internetError receptor is merged.
        resolve({
          index,
          status: 'error',
          message:
            'Failed to process request. Kindly check your internet connection',
        });
      });
  });
};
