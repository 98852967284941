import React, { useState, useCallback, useMemo } from 'react';
import ReactCropper from 'react-cropper';
import { IconButton, TextField } from '@mui/material';
import cn from 'classnames';
import ReactPlayer from 'react-player';
import { deleteOfferingAsset } from '../../../marketplace/state/actions/offeringActions';
import s from './styles/image-cropper.module.scss';
import 'cropperjs/dist/cropper.css';

const ImageCropper = ({
  cropFiles,
  cropAspectRatio,
  onCropComplete: handleDoneCropping,
  setCropFiles,
  proposedFiles,
  cropShape,
  fileIndex = 0,
}) => {
  const [cropper, setCropper] = useState();

  const [croppedFiles, setCroppedFiles] = useState(cropFiles);
  const [currentFileIndex, setCurrentFileIndex] = useState(fileIndex);
  const [assetDescriptions, setAssetDescriptions] = useState(
    proposedFiles.map(file => file.asset.description || '')
  );
  const [error, setError] = useState('');

  const filesCount = useMemo(() => cropFiles.length, [cropFiles.length]);
  const itsAnImageFile = useMemo(
    () => proposedFiles[currentFileIndex].file.type.includes('image/'),
    [currentFileIndex, proposedFiles]
  );

  const cropImage = useCallback(async () => {
    try {
      const loadNextCropItem = currentNewItem => {
        const croppedFilesList = [...croppedFiles];
        croppedFilesList[currentFileIndex] = currentNewItem;
        setCroppedFiles([...croppedFilesList]);
        if (currentFileIndex + 1 === filesCount) {
          handleDoneCropping(croppedFilesList, assetDescriptions);
          setCurrentFileIndex(0);
        } else {
          setCurrentFileIndex(cropIndex => ++cropIndex);
        }
      };

      if (itsAnImageFile) {
        await cropper.getCroppedCanvas().toBlob(blob => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = 'pic.jpeg';
          const croppedImageFile = new File([blob], 'pic.jpeg', {
            type: blob.type,
          });

          croppedImageFile.height = cropper.containerData.height;
          croppedImageFile.width = cropper.containerData.width;
          loadNextCropItem(croppedImageFile);
        }, 'image/jpeg');
      } else {
        loadNextCropItem(proposedFiles[currentFileIndex].file);
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    assetDescriptions,
    croppedFiles,
    cropper,
    currentFileIndex,
    filesCount,
    handleDoneCropping,
    itsAnImageFile,
    proposedFiles,
  ]);

  const handleRemoveImage = async () => {
    error && setError();
    const file = proposedFiles[currentFileIndex];
    if (file.id) {
      if (proposedFiles.length > 1) {
        await deleteOfferingAsset(file);
      } else {
        setError('Add a replacement before removing the last image');
        return;
      }
    }

    proposedFiles.splice(currentFileIndex, 1);
    croppedFiles.splice(currentFileIndex, 1);
    assetDescriptions.splice(currentFileIndex, 1);

    if (currentFileIndex === filesCount - 1) {
      handleDoneCropping(croppedFiles, assetDescriptions);
    } else {
      setAssetDescriptions([...assetDescriptions]);
      setCroppedFiles([...croppedFiles]);
      setCropFiles([...proposedFiles]);
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.cropperTitle}>
        <div className={s.switchImage}>
          <IconButton
            onClick={() => {
              setCurrentFileIndex(cropIndex => --cropIndex);
            }}
            disabled={
              currentFileIndex === 0 || proposedFiles[currentFileIndex - 1].id
            }
            size="large"
          >
            <span className="material-icons">&#xe2ea;</span>
          </IconButton>
        </div>
        <div>{`${currentFileIndex + 1} / ${filesCount}`}</div>
        <div className={s.switchImage}>
          <IconButton
            onClick={() => cropImage()}
            disabled={currentFileIndex + 1 === filesCount}
            size="large"
          >
            <span className="material-icons">&#xe5e1;</span>
          </IconButton>
        </div>
      </div>
      <div>
        {itsAnImageFile ? (
          <ReactCropper
            key={currentFileIndex}
            src={cropFiles[currentFileIndex]}
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={1}
            guides={false}
            onInitialized={instance => {
              setCropper(instance);
            }}
            background={false}
            viewMode={1}
            aspectRatio={cropAspectRatio}
            shape={cropShape}
          />
        ) : (
          <ReactPlayer url={cropFiles[currentFileIndex]} controls />
        )}
      </div>

      <div className={s.captionContainer}>
        <label>
          Add a description for this {itsAnImageFile ? 'image' : 'video'}
        </label>
        <TextField
          placeholder="Add a caption or description for this image"
          fullWidth
          variant="outlined"
          value={assetDescriptions[currentFileIndex]}
          onChange={e => {
            assetDescriptions[currentFileIndex] = e.target.value;
            setAssetDescriptions([...assetDescriptions]);
          }}
        />
      </div>
      {error && <div className="error-section">{error}</div>}
      <div className={s.buttonControl}>
        <div className={s.controls}>
          <div className={s.remove} onClick={handleRemoveImage}>
            <span className="material-icons">&#xe872;</span>
            <span>Remove this {itsAnImageFile ? 'image' : 'video'}</span>
          </div>
        </div>
        <div className={s.buttons}>
          {currentFileIndex !== 0 && !proposedFiles[currentFileIndex - 1].id ? (
            <span
              onClick={() => setCurrentFileIndex(index => --index)}
              className={s.previous}
            >
              Previous
            </span>
          ) : (
            <div />
          )}

          <button
            className={cn('custom-button sm', {
              outlined: currentFileIndex !== filesCount - 1,
            })}
            onClick={() => {
              if (!proposedFiles[currentFileIndex].id) {
                cropImage();
              } else {
                if (currentFileIndex + 1 === filesCount) {
                  handleDoneCropping(
                    croppedFiles,
                    assetDescriptions,
                    'existing'
                  );
                  setCurrentFileIndex(0);
                } else {
                  setCurrentFileIndex(cropIndex => ++cropIndex);
                }
              }
            }}
          >
            {currentFileIndex !== filesCount - 1 ? 'Next' : 'Finish'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
