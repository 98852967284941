import { ClickAwayListener, Popper } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import Cookies from '@ictlife/core/config/cookies';

export const setTheme = dispatch => {
  const COOKIE_NAME = 'theme_preference';
  const themePreference = Cookies.get(COOKIE_NAME);

  const darkSystemPreference =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');

  if (themePreference === 'system') {
    if (darkSystemPreference?.matches) {
      dispatch({ type: 'SWITCH_APP_THEME', theme: 'dark' });
    } else {
      dispatch({ type: 'SWITCH_APP_THEME', theme: 'light' });
    }
  }
};

const ThemePopper = ({ setOpenThemePopper, anchor }) => {
  const dispatch = useDispatch();

  const COOKIE_NAME = 'theme_preference';
  const themePreference = Cookies.get(COOKIE_NAME);

  const handleThemeSwitch = theme => {
    Cookies.set(COOKIE_NAME, theme, { expires: 2100 });

    const darkSystemPreference =
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');

    if (theme !== 'system') {
      dispatch({ type: 'SWITCH_APP_THEME', theme });
      darkSystemPreference?.removeEventListener(
        'change',
        () => setTheme(dispatch),
        true
      );
    } else {
      if (darkSystemPreference?.matches) {
        dispatch({ type: 'SWITCH_APP_THEME', theme: 'dark' });
      } else {
        dispatch({ type: 'SWITCH_APP_THEME', theme: 'light' });
      }

      try {
        darkSystemPreference?.addEventListener(
          'change',
          () => setTheme(dispatch),
          true
        );
      } catch (error) {
        console.error(error);
      }
    }
    setOpenThemePopper(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenThemePopper(false)}>
      <Popper open={true} anchorEl={anchor} style={{ zIndex: 5 }}>
        <div className="dialog-wrapper theme-switcher">
          <div className="dialog-title">Select your preferred app theme</div>
          <div className="dialog-body theme-popper">
            <div className="description">
              This preference applies to this browser only
            </div>
            <div
              className="theme default"
              onClick={() => handleThemeSwitch('light')}
            >
              <div>
                {typeof window !== 'undefined' &&
                  (themePreference === undefined ||
                    themePreference === 'light') && (
                    <span className="material-icons">&#xe5ca;</span>
                  )}
              </div>
              <span>Light (default)</span>
            </div>
            <div
              className="theme dark"
              onClick={() => handleThemeSwitch('dark')}
            >
              <div>
                {typeof window !== 'undefined' &&
                  themePreference === 'dark' && (
                    <span className="material-icons">&#xe5ca;</span>
                  )}
              </div>
              <span>Dark</span>
            </div>
            <div
              className="theme system"
              onClick={() => handleThemeSwitch('system')}
            >
              <div>
                {typeof window !== 'undefined' &&
                  themePreference === 'system' && (
                    <span className="material-icons">&#xe5ca;</span>
                  )}
              </div>
              <div>
                <div>Device Option</div>
                <div className="sub-text">
                  Selects dark or light theme depending on your device
                  system-wide choice.
                </div>
              </div>
            </div>
            <div className="action">
              <button
                className="custom-button sm"
                onClick={() => setOpenThemePopper(false)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Popper>
    </ClickAwayListener>
  );
};
export default ThemePopper;
