import dayjs from 'dayjs';
import { useMemo } from 'react';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DAYS_ABBR } from '../constants';
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';

dayjs.extend(relativeTime);

// const getEventTimeStatus = ({
//   next_time,
//   event_end_time,
//   frequency,
//   expiry_date,
//   upcoming_event_times,
// }) => {
//   const timeNow = dayjs();

//   if (next_time.diff(timeNow) < 1 && timeNow.diff(event_end_time) < 1) {
//     return {
//       value: 'now',
//       label: 'Happening Now',
//     };
//   }

//   if (next_time.diff(timeNow) > 1 && next_time.diff(timeNow, 'minute') < 60) {
//     return {
//       value: 'soon',
//       label: 'Starting Soon',
//     };
//   }

//   if (
//     timeNow.diff(event_end_time) > 1 &&
//     (!frequency || (frequency && timeNow.diff(expiry_date) > 1))
//   ) {
//     return {
//       value: 'past',
//       label: 'Event Ended',
//     };
//   }

//   if (
//     timeNow.diff(event_end_time) > 1 &&
//     frequency &&
//     timeNow.diff(expiry_date) < 1 &&
//     upcoming_event_times?.length > 0
//   ) {
//     const date = dayjs().to(upcoming_event_times[0]);

//     return {
//       value: 'past',
//       label: `Occurs again ${date}`,
//     };
//   }

//   if (upcoming_event_times?.length > 0) {
//     const date = dayjs().to(upcoming_event_times[0]);

//     return {
//       value: 'delta',
//       label: `Happening ${date}`,
//     };
//   }
// };

export const getEventSchedule = ({
  time_attribute,
  next_event_time,
  upcoming_event_times,
  event_stop_time,
}) => {
  if (time_attribute) {
    const { end_time, rule, start_time, event_expiry_date } = time_attribute;

    const next_time = next_event_time
      ? dayjs(next_event_time)
      : dayjs(start_time);
    const event_end_time = event_stop_time
      ? dayjs(event_stop_time)
      : dayjs(end_time);
    const event_times =
      upcoming_event_times?.map(time =>
        dayjs(time).format('dddd, MMM DD [at] hh:mm a')
      ) ?? [];

    let frequency;
    let byweekday = [];
    let bymonthday = [];

    if (time_attribute.frequency) {
      frequency = capitalizeFirstLetter(time_attribute.frequency);
    }
    if (time_attribute.byweekday) {
      byweekday = time_attribute.byweekday;
    }
    if (time_attribute.bymonthday) {
      bymonthday = time_attribute.bymonthday;
    }

    rule?.split(';').map(rule => {
      if (rule.includes('FREQ=')) {
        frequency = capitalizeFirstLetter(rule.replace('FREQ=', ''));
      }
      if (rule.includes('BYDAY=')) {
        const byDayValue = rule.replace('BYDAY=', '');
        byweekday = byDayValue.split(',');
      }
      if (rule.includes('BYMONTHDAY=')) {
        const byDayValue = rule.replace('BYMONTHDAY=', '');
        bymonthday = byDayValue.split(',');
      }
    });

    const expiry_date =
      event_expiry_date &&
      dayjs(event_expiry_date).isValid() &&
      dayjs(event_expiry_date).format('dddd, MMM DD, YYYY');

    let isAllDay = false;

    if (
      event_end_time.format('HHmmss') === '235959' &&
      next_time.format('HHmmss') === '000000'
    ) {
      isAllDay = 'All day';
    }

    const daysOfTheWeek = byweekday
      .map(dayAbbr => {
        if (DAYS_ABBR[dayAbbr]) return DAYS_ABBR[dayAbbr].name;
      })
      .filter(day => day);

    // const eventStatus = getEventTimeStatus({
    //   next_time,
    //   event_end_time,
    //   frequency,
    //   expiry_date,
    //   upcoming_event_times,
    // });

    if (next_time.isValid()) {
      return {
        isAllDay,
        start_date: next_time.format('dddd, MMM DD'),
        start_time: next_time.format('hh:mm a'),
        end_time: event_end_time.format('hh:mm a'),
        frequency,
        event_times,
        event_expiry_date: expiry_date,
        byweekday,
        bymonthday,
        daysOfTheWeek,
        eventStatus: '',
        event_location: time_attribute.event_location,
      };
    } else {
      return {};
    }
  }
  return {};
};

const useEventSchedule = ({
  time_attribute,
  time_attributes,
  next_event_time,
  upcoming_event_times,
  event_stop_time,
}) => {
  const eventSchedule = useMemo(() => {
    return getEventSchedule({
      time_attribute,
      next_event_time,
      upcoming_event_times,
      event_stop_time,
    });
  }, [next_event_time, time_attribute, upcoming_event_times, event_stop_time]);

  const time_and_location = {};
  if (time_attributes) {
    time_attributes.map(time_attribute => {
      time_and_location[time_attribute.event_location] = [
        ...(time_and_location[time_attribute.event_location] || []),
        time_attribute,
      ];
    });
  }

  return { ...eventSchedule, time_and_location };
};

export default useEventSchedule;
