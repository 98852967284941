import { getChatList } from '../state/actions/customerActivityAction';
import { getNotifications } from '../state/actions/notificationsAction';

export const handleAppEvents = ({ evt, dispatch, merchant, router }) => {
  const { id: business_id, business_username } = merchant;
  getNotifications({ dispatch, merchantId: business_id });

  const data = JSON.parse(evt.data);
  const { event_type, merchant_id } = data;

  if (
    merchant_id === business_id &&
    (event_type === 'merchant_post_comment' ||
      event_type === 'merchant_post_comment_reply' ||
      event_type === 'transaction_review')
  ) {
    const notificationSound = new Audio('/sounds/stairs.mp3');
    notificationSound.play();
  }

  if (
    router.asPath === `/business/${business_username}/activity` &&
    merchant_id === business_id
  ) {
    getChatList(dispatch, business_id);
  }
};
