import { getToken, onMessage, isSupported } from 'firebase/messaging';
import { sendPushTokenToServer } from '../state/actions/pushTokenAction';

const firebaseCloudMessaging = {
  checkDeviceMessagingSupport: async () => {
    const itSupports = await isSupported();
    return itSupports;
  },

  handleRegisterPushToken: async device_token => {
    const payload = {
      os: 'web',
      version: '1.0.0',
      device_token,
    };
    const response = await sendPushTokenToServer(payload);

    return response.data;
  },

  init: async function (messaging) {
    if (!messaging)
      return Promise.reject({
        code: 'messaging/missing-config',
      });

    const pushToken = await getToken(messaging, {
      vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
    });

    return pushToken;
  },
};

export const onPushNotificationListener = messaging =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });

export default firebaseCloudMessaging;
