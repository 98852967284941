import Compressor from 'compressorjs';

const compressImages = async images => {
  const compressionRequests = [];
  const compressImage = file => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success: result => {
          resolve(new File([result], file.name, { type: result.type }));
        },
        error: error => reject(error),
      });
    });
  };

  for (const image of images) {
    if (image.type.includes('image/')) {
      compressionRequests.push(compressImage(image));
    } else {
      compressionRequests.push(image);
    }
  }

  const compressedImages = await Promise.all(compressionRequests);
  return compressedImages;
};

export default compressImages;
