import { capitalizeFirstLetter } from './capitalizeFirstLetter';

export function getBusinessTypeAndLocation({ merchant, merchant_profile }) {
  if (!merchant_profile?.address_line_1 || merchant?.business_types?.length < 1)
    return;
  const { address_line_1 } = merchant_profile;
  const { business_types } = merchant;

  if (business_types?.length < 1 && !address_line_1) return;

  if (business_types?.length > 0 && address_line_1)
    return capitalizeFirstLetter(
      `${business_types.map(type => type.name).join(', ')} in ${address_line_1}`
    );

  if (business_types?.length > 0)
    return `Deals in ${business_types.map(type => type.name).join(', ')}`;

  return `Located in ${address_line_1}`;
}
