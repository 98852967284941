import axiosConfig from '@ictlife/core/config/axios';
import useSWR from 'swr';

export const getBusinessCategories = async ({ per, page, location }) => {
  const { latitude, longitude, radius } = location;

  const response = await axiosConfig.get('/business_categories', {
    params: {
      per,
      page,
      latitude,
      longitude,
      radius,
    },
  });
  return response.data;
};

export const getSuperCategories = async () => {
  const response = await axiosConfig.get('/merchant_super_categories');
  return response.data;
};

export const useSuperCategories = ({ onSuccess }) => {
  const { data } = useSWR(
    '/merchant_super_categories',
    url => axiosConfig.get(url),
    {
      onSuccess: data => onSuccess && onSuccess(data?.data),
    }
  );
  return {
    superCategories: data?.data,
  };
};

export const updateSuperCategory = async ({ super_category_id, payload }) => {
  const response = await axiosConfig.put(
    `/merchant_super_categories/${super_category_id}`,
    payload
  );
  return response.data;
};

export const getSuperCategoriesSummaries = async merchant_category_status => {
  let response;
  if (merchant_category_status) {
    response = await axiosConfig.get('/super_category_summaries', {
      params: {
        ...merchant_category_status,
      },
    });
  } else {
    response = await axiosConfig.get('/super_category_summaries');
  }

  return response.data;
};

export const useSuperCategorySummary = () => {
  const { data } = useSWR('/super_category_summaries', url =>
    axiosConfig.get(url)
  );

  return data?.data;
};

export const getCategories = async ({
  super_category_slug,
  search,
  merchant_category_status,
}) => {
  const response = await axiosConfig.get(
    `/merchant_super_categories/${super_category_slug}/merchant_categories`,
    {
      params: {
        per: 20,
        page: 1,
        search,
        merchant_category_status,
      },
    }
  );
  return response.data;
};

export const deleteCategory = async category_id => {
  const response = await axiosConfig.delete(
    `/merchant_categories/${category_id}`
  );
  return response.data;
};

export const getSuperCategoryAttributes = async ({
  super_category_slug,
  search,
  merchant_category_status,
}) => {
  const response = await axiosConfig.get(
    `/merchant_super_categories/${super_category_slug}/attributes`,
    {
      params: {
        per: 20,
        page: 1,
        search,
        merchant_category_status,
      },
    }
  );
  return response.data;
};

export const useSuperCagegoryAttributes = ({ super_category_slug }) => {
  const { data, error, mutate, isValidating } = useSWR(
    super_category_slug
      ? {
          url: `/merchant_super_categories/${super_category_slug}/attributes`,
        }
      : null,
    url =>
      axiosConfig.get(url.url, {
        params: {
          per: 60,
        },
      })
  );

  return {
    superCategoryAttributes: data?.data,
    loading: !data && !error && isValidating,
    mutateSuperAttributes: mutate,
  };
};

export const useCategories = ({
  super_category_slug,
  search,
  onSuccess,
  preventFetch,
  parent_category_slug,
  latitude,
  longitude,
  radius,
}) => {
  const { data, isValidating, error } = useSWR(
    !preventFetch
      ? {
          url: `/merchant_super_categories/${super_category_slug}/merchant_categories`,
          super_category_slug,
          search,
          parent_category_slug,
          latitude,
          longitude,
          radius,
        }
      : null,
    ({ url }) =>
      axiosConfig.get(url, {
        params: {
          per: 100,
          page: 1,
          search,
          super_category_slug,
          parent_category_slug,
          latitude,
          longitude,
          radius,
        },
      }),
    {
      onSuccess: data => onSuccess(data.data),
    }
  );

  return {
    data: data?.data,
    error,
    isLoading: !data && isValidating,
  };
};

export const useCategoriesTree = ({
  super_category_slug,
  search,
  onSuccess,
  preventFetch,
  parent_category_slug,
  latitude,
  longitude,
  radius,
  merchant_category_status,
  per,
  all_categories,
  merchant_id,
  event_start_time,
  event_end_time,
  content_filter_type,
}) => {
  const url = all_categories
    ? `/merchant_super_categories/${super_category_slug}/all_categories`
    : '/merchant_offering_categories';
  const { data, isValidating, error, mutate } = useSWR(
    !preventFetch
      ? {
          url,
          super_category_slug,
          search,
          parent_category_slug,
          latitude,
          longitude,
          radius,
          merchant_category_status,
          per,
          merchant_id,
          event_start_time,
          event_end_time,
          content_filter_type,
        }
      : null,
    ({ url }) =>
      axiosConfig.get(url, {
        params: {
          per,
          page: 1,
          search,
          super_category_slug,
          parent_category_slug,
          latitude,
          longitude,
          radius,
          merchant_category_status,
          merchant_id,
          event_start_time,
          event_end_time,
          content_filter_type,
        },
      }),
    {
      onSuccess: data => onSuccess(data.data),
    }
  );

  return {
    data: data?.data,
    error,
    isLoading: !data && isValidating,
    mutate,
  };
};

export const useCategoryAttributes = ({
  search,
  onSuccess,
  preventFetch,
  parent_category_slug,
  latitude,
  longitude,
  radius,
  super_category_slug,
  sub_category_slug,
}) => {
  const { data, isValidating, error, mutate } = useSWR(
    !preventFetch
      ? {
          url: '/category_attributes',
          search,
          parent_category_slug,
          super_category_slug,
          latitude,
          longitude,
          radius,
          sub_category_slug,
        }
      : null,
    ({ url }) =>
      axiosConfig.get(url, {
        params: {
          per: 100,
          page: 1,
          search,
          parent_category_slug,
          super_category_slug,
          latitude,
          longitude,
          radius,
          sub_category_slug,
        },
      }),
    {
      onSuccess: data => onSuccess(data.data),
    }
  );

  return {
    data: data?.data,
    error,
    isLoading: !data && isValidating,
    mutateCategoryAttribute: mutate,
  };
};

export const getSubCategories = async ({
  super_category_slug,
  category_slug,
  search,
}) => {
  const response = await axiosConfig.get(
    `/merchant_super_categories/${super_category_slug}/merchant_categories/${category_slug}`,
    {
      params: {
        per: 20,
        page: 1,
        search,
      },
    }
  );
  return response.data;
};

export const useSubCategories = ({
  super_category_slug,
  category_slug,
  search,
  preventFetch,
  onSuccess,
}) => {
  const { data, isValidating, error } = useSWR(
    !preventFetch
      ? {
          url: `/merchant_super_categories/${super_category_slug}/merchant_categories/${category_slug}`,
          super_category_slug,
          search,
        }
      : null,
    ({ url }) =>
      axiosConfig.get(url, {
        params: {
          per: 100,
          page: 1,
          search,
        },
      }),
    {
      onSuccess: data => onSuccess(data.data),
    }
  );

  return {
    data: data?.data,
    error,
    isLoading: !data && isValidating,
  };
};

export const useCategoryInformation = ({
  super_category_slug,
  category_slug,
  preventFetch,
  onSuccess,
}) => {
  const { data, isValidating, error } = useSWR(
    !preventFetch
      ? {
          url: `/merchant_super_categories/${super_category_slug}/category/${category_slug}`,
          super_category_slug,
        }
      : null,
    ({ url }) => axiosConfig.get(url),
    {
      onSuccess: data => onSuccess(data.data),
    }
  );

  return {
    data: data?.data,
    error,
    isLoading: !data && isValidating,
  };
};

export const createNewCategory = async bodyData => {
  const response = await axiosConfig.post('/merchant_categories', bodyData);
  return response.data;
};

export const deleteCategories = async category_id => {
  const response = await axiosConfig.delete(
    `/merchant_categories/${category_id}`
  );
  return response.data;
};

export const editCategory = async ({ category_id, data }) => {
  const response = await axiosConfig.put(
    `/merchant_categories/${category_id}`,
    data
  );

  return response.data;
};

export const addCategoryAttributes = async bodyData => {
  const response = await axiosConfig.post('/category_attributes', bodyData);
  return response.data;
};

export const createNewAttributeOption = async bodyData => {
  const response = await axiosConfig.post(
    '/category_attribute_options',
    bodyData
  );
  return response.data;
};

export const editAttributeOption = async ({ optionID, ...data }) => {
  const response = await axiosConfig.put(
    `/category_attribute_options/${optionID}`,
    { ...data }
  );
  return response.data;
};

export const deleteAttributeOption = async ({ id }) => {
  const response = await axiosConfig.delete(
    `/category_attribute_options/${id}`
  );
  return response.data;
};

export const editCategoryAttributes = async ({ id, ...data }) => {
  const response = await axiosConfig.put(`/category_attributes/${id}`, {
    ...data,
  });
  return response.data;
};

export const deleteCategoryAttributes = async ({ id }) => {
  const response = await axiosConfig.delete(`/category_attributes/${id}`);
  return response.data;
};

export const getCategoryAttributes = async ({ category_id }) => {
  const response = await axiosConfig.get(
    `/merchant_categories/${category_id}/attributes`
  );

  return response.data;
};

export const useCategoryAttributesByID = ({ category_id }) => {
  const { data, mutate } = useSWR(
    category_id ? `/merchant_categories/${category_id}/attributes` : null,
    url => axiosConfig.get(url)
  );
  return {
    data: data?.data,
    mutateCategoryAttribute: mutate,
  };
};

export const getCategoriesTree = async categoryId => {
  let categoriesTree = [];

  const getCategory = async id => {
    const response = await axiosConfig.get(`/merchant_categories/${id}`);
    categoriesTree = [
      {
        name: response.data.name,
        id: response.data.id,
        slug: response.data.slug,
      },
      ...categoriesTree,
    ];
    if (response.data.parent_id) await getCategory(response.data.parent_id);
  };

  await getCategory(categoryId);

  return categoriesTree;
};

export const useSupercategorySummary = ({ merchant_id }) => {
  const { data, isValidating, error } = useSWR(
    merchant_id
      ? {
          url: `/merchants/${merchant_id}/super_category_offering_summaries`,
        }
      : null,
    ({ url }) => axiosConfig.get(url)
  );

  return {
    superCategorySummaryList: data?.data,
    error,
    isLoading: !data && isValidating,
  };
};
