import {
  GET_CHAT_LIST,
  GET_CHAT_LIST_SUCCESS,
  GET_CHAT_LIST_ERROR,
  GET_USER_ACTIVITY,
  GET_USER_ACTIVITY_SUCCESS,
  GET_USER_ACTIVITY_ERROR,
} from '../dispatchTypes';

const initialChatListState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  chatParties: [],
};

export const chatListReducer = (state = initialChatListState, action) => {
  switch (action.type) {
    case GET_CHAT_LIST: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    }
    case GET_CHAT_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        chatParties: action.interactive_users,
      };
    }
    case GET_CHAT_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const initialUserActivityState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  userActivity: {
    merchant_notification_events: [],
    pagination: { count: 0 },
  },
};

export const userActivityReducer = (
  state = initialUserActivityState,
  action
) => {
  switch (action.type) {
    case GET_USER_ACTIVITY: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    }
    case GET_USER_ACTIVITY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        userActivity: action.userActivity,
      };
    }
    case GET_USER_ACTIVITY_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errorMessage: action.errorMessage,
      };
    }
    case 'RESET_GET_USER_ACTIVITY': {
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: '',
      };
    }
    default:
      return state;
  }
};
