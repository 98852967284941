import axiosConfig from '../../config/axiosConfig';
import {
  LOADING,
  SUCCESS,
  ERROR,
  GET_LIKES_LIST,
  LIKES_LIST_SUCCESS,
  LIKES_LIST_ERROR,
} from '../dispatchTypes';

import { handleFilesUpload } from './assetAction';

export const getBusinessPosts = async ({
  status = 'active',
  page = 1,
  per = 1,
  order_by = 'date',
  activeTag = '',
  business_id,
}) => {
  const response = await axiosConfig.get(
    `/merchants/${business_id}/merchant_posts`,
    {
      params: {
        status,
        page,
        per,
        order_by,
        tag: activeTag,
      },
    }
  );

  return response;
};

export const getSpecificPost = async postUUID => {
  const response = await axiosConfig.get(
    `/merchant_posts/${postUUID}/share_info`
  );

  return response.data;
};

export const getPostLikes = (dispatchGetPostLikes, postId) => {
  axiosConfig
    .get(`/merchant_posts/${postId}/merchant_post_likes`)
    .then(response => {
      dispatchGetPostLikes({
        type: SUCCESS,
        likes: response.data,
      });
    })
    .catch(error => {
      dispatchGetPostLikes({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchGetPostLikes({
        type: ERROR,
        errorMessage: 'Failed to get likes',
      });
    });
};

export const likePost = (dispatchLikePost, bodyData) => {
  axiosConfig
    .post('/merchant_post_likes', bodyData)
    .then(response => {
      dispatchLikePost({
        type: SUCCESS,
        likeId: response.data.id,
      });
    })
    .catch(error => {
      dispatchLikePost({
        type: ERROR,
        rollBackId: null,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchLikePost({
        type: ERROR,
        rollBackId: null,
        errorMessage: 'Failed to like',
      });
    });
};

export const unLikePost = (dispatchLikePost, likeId) => {
  dispatchLikePost({
    type: LOADING,
  });

  axiosConfig
    .delete(`/merchant_post_likes/${likeId}`)
    .then(() => {
      dispatchLikePost({
        type: SUCCESS,
        likeId: null,
      });
    })
    .catch(error => {
      dispatchLikePost({
        type: ERROR,
        rollBackId: likeId,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchLikePost({
        type: ERROR,
        rollBackId: likeId,
        errorMessage: 'Failed to like',
      });
    });
};

const createNewPost = bodyData => {
  return new Promise((resolve, reject) => {
    axiosConfig
      .post('/merchant_posts', bodyData)
      .then(response => {
        resolve({
          postId: response.data.id,
        });
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const publishPost = (dispatchPublishPost, postId) => {
  dispatchPublishPost({
    type: LOADING,
  });
  return axiosConfig
    .put(`/merchant_posts/${postId}/publish`, {})
    .then(response => {
      dispatchPublishPost({
        type: SUCCESS,
        post: response.data,
      });
      return response.data;
    })
    .catch(error => {
      console.error(error.response);
      dispatchPublishPost({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchPublishPost({
        type: ERROR,
        errorMessage:
          'Failed to process request. Refresh browser and try again',
      });
    });
};

export const UpdatePostCaption = (dispatchUpdateCaption, bodyData, postId) => {
  dispatchUpdateCaption({
    type: LOADING,
  });
  axiosConfig
    .put(`/merchant_posts/${postId}`, bodyData)
    .then(response => {
      dispatchUpdateCaption({
        type: SUCCESS,
        post: response.data,
      });
    })
    .catch(error => {
      console.error(error.response);
      dispatchUpdateCaption({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchUpdateCaption({
        type: ERROR,
        errorMessage:
          'Failed to process request. Refresh browser and try again',
      });
    });
};

export const uploadPostMedia = (
  dispatchUploadPostMedia,
  addedFiles,
  newPostBody,
  oldPostId,
  activeMerchant
) => {
  dispatchUploadPostMedia({
    type: LOADING,
  });

  let promise;

  if (oldPostId) {
    promise = new Promise(resolve =>
      resolve({
        postId: oldPostId,
      })
    );
  } else {
    promise = createNewPost(newPostBody);
  }

  promise
    .then(data => {
      const postId = data.postId;

      dispatchUploadPostMedia({
        type: 'PROGRESS',
        postId: postId,
      });

      const assetInfo = {
        type: 'merchant_post_asset',
        postId,
      };

      handleFilesUpload(
        dispatchUploadPostMedia,
        addedFiles,
        assetInfo,
        activeMerchant
      );
    })
    .catch(error => {
      console.error(error.response);
      dispatchUploadPostMedia({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchUploadPostMedia({
        type: ERROR,
        errorMessage: 'Something went wrong, refresh browser and try again',
      });
    });
};

export const deletePostImage = (dispatchDeletePostImage, file) => {
  dispatchDeletePostImage({
    type: LOADING,
  });

  axiosConfig
    .delete(`/merchant_post_assets/${file.id}`)
    .then(() => {
      dispatchDeletePostImage({
        type: SUCCESS,
        deletedFile: file,
      });
    })
    .catch(error => {
      console.error(error.response.data);
      dispatchDeletePostImage({
        type: ERROR,
        errorMessage: error.response.data.error_message,
        file,
      });
    })
    .catch(() => {
      dispatchDeletePostImage({
        type: ERROR,
        errorMessage: 'Something went wrong. Refresh page and try again',
      });
    });
};

export const deletePost = (dispatchDeletePost, post) => {
  dispatchDeletePost({
    type: LOADING,
  });

  return axiosConfig
    .delete(`/merchant_posts/${post.id}`)
    .then(() => {
      dispatchDeletePost({
        type: SUCCESS,
        deletedPost: post,
      });
    })
    .catch(error => {
      console.error(error.response.data);
      dispatchDeletePost({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatchDeletePost({
        type: ERROR,
        errorMessage: 'Something went wrong. Refresh page and try again',
      });
    });
};

export const getLikesList = (
  dispatch,
  postId,
  assetId,
  currentList,
  commentId,
  pagination,
  reviewId
) => {
  dispatch({
    type: GET_LIKES_LIST,
    likesList: {
      merchant_post_likes: currentList || [],
      merchant_post_comment_likes: currentList || [],
      transaction_review_likes: currentList || [],
      pagination: {
        per: 20,
        page: 1,
      },
    },
  });

  const page = pagination ? pagination.page + 1 : 1;

  let url = '';
  if (commentId) {
    url = `/merchant_post_comments/${commentId}/merchant_post_comment_likes?page=${page}`;
  }
  if (postId) {
    url = `/merchant_posts/${postId}/merchant_post_likes?merchant_post_asset_id=${assetId}&page=${page}`;
  }
  if (reviewId) {
    url = `/transaction_reviews/${reviewId}/transaction_review_likes?page=${page}`;
  }

  axiosConfig
    .get(url)
    .then(response => {
      const likesList = response.data;
      if (pagination) {
        if (commentId) {
          likesList.merchant_post_comment_likes = [
            ...currentList,
            ...likesList.merchant_post_comment_likes,
          ];
        } else if (reviewId) {
          likesList.transaction_review_likes = [
            ...currentList,
            ...likesList.transaction_review_likes,
          ];
        } else {
          likesList.merchant_post_likes = [
            ...currentList,
            ...likesList.merchant_post_likes,
          ];
        }
      }
      dispatch({
        type: LIKES_LIST_SUCCESS,
        likesList,
      });
    })
    .catch(error => {
      dispatch({
        type: LIKES_LIST_ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: LIKES_LIST_ERROR,
        errorMessage: 'Failed to get likes List',
      });
    });
};

export const getUserPostLike = ({
  dispatch,
  notificationPostLike,
  setActiveAsset,
  post,
  setNotifPostLike,
}) => {
  dispatch({
    type: LOADING,
  });

  return axiosConfig
    .get(`/merchant_post_likes/${notificationPostLike}`)
    .then(response => {
      setActiveAsset &&
        setActiveAsset({
          asset: post.merchant_post_assets.find(
            file => file.id === response.data.merchant_post_asset_id
          ),
          index: post.merchant_post_assets.findIndex(
            file => file.id === response.data.merchant_post_asset_id
          ),
        });
      setNotifPostLike && setNotifPostLike(null);
      return response;
    })
    .catch(error => {
      dispatch({
        type: ERROR,
        errorMessage: error.response.data.error_message,
      });
    })
    .catch(() => {
      dispatch({
        type: ERROR,
        errorMessage: 'Failed to get likes',
      });
    });
};
