import React, { useState, Fragment, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Badge, Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import SnackInfo from '@ictlife/core/components/SnackInfo';
import Cookies from '@ictlife/core/config/cookies';
import axiosConfig from '@ictlife/core/config/axios';
import useMounted from '@ictlife/core/hooks/useMounted';
import AppImage from '@ictlife/core/components/app-image/AppImage';
import { useNotifications } from '@ictlife/core/state/actions/notificationsAction';
import Link from 'next/link';
import AppAvatar from '../../../utils/AppAvatar';
import { setTheme } from './ThemePopper';

const ThemePopper = dynamic(() => import('./ThemePopper'));
const GoLiveDialog = dynamic(() => import('./GoLiveDialog'));
const NotificationsPopper = dynamic(() => import('./NotificationsPopper'));
const MobileNav = dynamic(() => import('./MobileNav'));
const ProfilePopper = dynamic(() => import('./ProfilePopper'));
const AuthenticationLinks = dynamic(() =>
  import('./Links').then(link => link.AuthenticationLinks)
);

const ReportBusiness = dynamic(() => import('./ReportBusiness'));

export const clearMessagingDatabase = () => {
  try {
    let messagingDatabase = indexedDB.open('firebase-messaging-database');

    messagingDatabase.onsuccess = () => {
      const db = messagingDatabase.result;
      if (db.objectStoreNames.length < 1) {
        indexedDB.deleteDatabase('firebase-messaging-database');
        return;
      }

      let transaction = db.transaction(
        ['firebase-messaging-store'],
        'readwrite'
      );
      let messagingStore = transaction.objectStore('firebase-messaging-store');
      messagingStore.clear();
    };
  } catch (error) {
    indexedDB.deleteDatabase('firebase-messaging-database');
  }
};

const TopBar = ({
  profile_completeness_value,
  page,
  sourcePackage = process.env.NEXT_PUBLIC_SOURCE_PACKAGE,
  Search,
  TopBarLinks,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { b_username, lat, lng, address } = router.query;

  const userInfoState = useSelector(store => store.userInfoState);
  const {
    profile_photo,
    user: { id: userId, first_name, last_name },
    isLoading,
  } = userInfoState;

  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      profile_photo: profilePhoto,
      merchant: {
        id: merchantId,
        account_status,
        business_username,
        business_name,
        status: liveStatus,
        user_id: merchantUserId,
      },
    },
  } = merchantProfileState;

  const { notifications: appNotifications } = useNotifications({
    userId,
    merchantId:
      process.env.NEXT_PUBLIC_SOURCE_PACKAGE === 'merchant' &&
      userId === merchantUserId
        ? merchantId
        : undefined,
  });

  const username_route = b_username && account_status === 'claimed';

  const isBusinessOwner =
    merchantUserId &&
    userId &&
    merchantUserId === userId &&
    sourcePackage === 'merchant';

  useEffect(() => {
    if (
      router.pathname === '/business/[b_username]' &&
      isBusinessOwner &&
      liveStatus === 'inactive' &&
      !router.preventOpen
    ) {
      setOpenLiveDialog(true);
    }
  }, [isBusinessOwner, liveStatus, router.pathname, router.preventOpen]);

  const appThemeState = useSelector(store => store.appThemeState);
  const { theme } = appThemeState;

  useEffect(() => {
    const documentStyle = document.documentElement.style;
    const darkSystemPreference =
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
    try {
      darkSystemPreference?.addEventListener(
        'change',
        () => setTheme(dispatch),
        true
      );
    } catch (error) {
      console.error(error);
    }

    if (theme === 'dark') {
      documentStyle.setProperty('--app-background', '#15202b');
      documentStyle.setProperty('--app-text', '#fff');
      documentStyle.setProperty('--text-alt-color', '#fff');
      documentStyle.setProperty('--lightened-background', '#192734');
      documentStyle.setProperty('--login-wrapper-background', '#192734');
      documentStyle.setProperty('--box-shadow-color', '#8899a633');
      documentStyle.setProperty('--text-header', '#fff');
      documentStyle.setProperty('--border-color', '#4b658433');
      documentStyle.setProperty('--dialog-title-background', '#2c4455');
      documentStyle.setProperty('--accent-text-color', '#ffffffeb');
      documentStyle.setProperty('--primary-text-color', '#dce3f4');
      documentStyle.setProperty('--text-color', '#fff');
      documentStyle.setProperty('--activity-text-color', '#fff');
      documentStyle.setProperty('--input-background-color', '#3d4955');
      documentStyle.setProperty('--hr-background-color', '#4b658433');
      documentStyle.setProperty('--icon-color', '#9ea1aa');
      documentStyle.setProperty('--disabled-button-color', '#9ea1aa');
      documentStyle.setProperty('--secondary-text-color', '#d3d7e1');
      documentStyle.setProperty('--tag-color', '#d9d9d9');
      documentStyle.setProperty('--title-color', '#fff');
      documentStyle.setProperty('--category-caption-color', '#fff');
      documentStyle.setProperty('--tagline-color', '#fff');
      documentStyle.setProperty('--section-nav-color', '#fff');
      documentStyle.setProperty('--plain-text-color', '#fff');
      documentStyle.setProperty('--field-text-color', '#fff');
      documentStyle.setProperty('--detail-text-color', '#fff');
      documentStyle.setProperty('--time-text-color', '#fff');
      documentStyle.setProperty('--market-link-color', '#fff');
      documentStyle.setProperty('--meta-data-background', '#192734');
      documentStyle.setProperty('--meta-data-color', '#fff');
      documentStyle.setProperty('--filter-text-color', '#fff');
      documentStyle.setProperty('--filter-caption-color', '#fff');
    }

    if (theme === 'light' || !theme) {
      documentStyle.setProperty('--app-background', '#fff');
      documentStyle.setProperty('--app-text', '#000000cb');
      documentStyle.setProperty('--text-alt-color', '#000000cb');
      documentStyle.setProperty('--lightened-background', '#f6f6f6');
      documentStyle.setProperty('--login-wrapper-background', '#f6f6f681');
      documentStyle.setProperty('--box-shadow-color', '#00000029');
      documentStyle.setProperty('--text-header', '#000000d1');
      documentStyle.setProperty('--border-color', '#eeebeb');
      documentStyle.setProperty('--dialog-title-background', '#f2f6f9');
      documentStyle.setProperty('--accent-text-color', '#7f807fea');
      documentStyle.setProperty('--primary-text-color', '#7f807fea');
      documentStyle.setProperty('--text-color', '#454f63');
      documentStyle.setProperty('--activity-text-color', '#43425d');
      documentStyle.setProperty('--input-background-color', '#fffeb');
      documentStyle.setProperty('--hr-background-color', '#03030314');
      documentStyle.setProperty('--icon-color', '#65676b');
      documentStyle.setProperty('--disabled-button-color', '#0000001f');
      documentStyle.setProperty('--secondary-text-color', '#707070');
      documentStyle.setProperty('--tag-color', '#686868');
      documentStyle.setProperty('--title-color', '#221e1b');
      documentStyle.setProperty('--category-caption-color', '#6b7a99');
      documentStyle.setProperty('--tagline-color', '#1e4c9a');
      documentStyle.setProperty('--section-nav-color', '#37505c');
      documentStyle.setProperty('--plain-text-color', '#000');
      documentStyle.setProperty('--field-text-color', '#6e8898');
      documentStyle.setProperty('--detail-text-color', '#666a739d');
      documentStyle.setProperty('--time-text-color', '#7a8499');
      documentStyle.setProperty('--market-link-color', '#113537');
      documentStyle.setProperty('--meta-data-background', '#f0f0f06c');
      documentStyle.setProperty('--meta-data-color', '#6b778b');
      documentStyle.setProperty('--filter-text-color', '#4d5e80');
      documentStyle.setProperty('--filter-caption-color', '#7d8fb3');
    }

    return () => {
      try {
        return darkSystemPreference?.removeEventListener(
          'change',
          () => setTheme(dispatch),
          true
        );
      } catch (error) {
        console.error(error);
      }
    };
  }, [dispatch, theme]);

  const logoutState = useSelector(store => store.logoutState);
  const { isSuccess, isError, isRedirect } = logoutState;

  const handleSignOut = () => {
    let activeMerchant = localStorage.activeMerchant;

    localStorage.clear();
    Cookies.remove('ictl_token', {
      domain: process.env.NODE_ENV === 'production' && 'ictlife.com',
    });

    Cookies.remove('ictl_token');

    axiosConfig.defaults.headers['X-ICTLIFE-TOKEN'] = undefined;

    Cookies.remove('ictl_user', {
      domain: process.env.NODE_ENV === 'production' && 'ictlife.com',
    });

    Cookies.remove('ictl_user');

    clearMessagingDatabase();

    Cookies.remove('ictl_push_notification_status');

    if (activeMerchant) {
      localStorage.activeMerchant = activeMerchant;
    }

    dispatch({
      type: 'CLEAR_USER_PROFILE',
    });

    if (sourcePackage === 'merchant') {
      router.replace('/user/login');
    }

    if (sourcePackage === 'marketplace' && page === 'settings') {
      business_username
        ? router.replace(`/business/${business_username}`)
        : router.replace('/');
    }
  };

  const currentMerchant =
    typeof window !== 'undefined' &&
    sourcePackage === 'merchant' &&
    localStorage.activeMerchant &&
    JSON.parse(localStorage.activeMerchant);

  if ((isSuccess || isError) && isRedirect) {
    dispatch({ type: 'RESET_LOGOUT' });
    handleSignOut();
  }

  const isLoggedIn = Cookies.get('ictl_token');
  const isMounted = useMounted();

  const [openNotifications, setOpenNotifications] = useState({
    open: false,
    anchor: null,
  });
  const [openProfile, setOpenProfile] = useState({ open: false, anchor: null });
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [openLiveDialog, setOpenLiveDialog] = useState(false);
  const [openThemePopper, setOpenThemePopper] = useState({ open: false });
  const [snackInfo, setSnackInfo] = useState({
    open: false,
    message: '',
    vertical: 'bottom',
    horizontal: 'left',
    severity: 'info',
    autoHideDuration: 3000,
  });

  const searchRef = useRef();

  useEffect(() => {
    const elm = document.querySelector('#content-section');

    const handleElementScroll = () => {
      if (searchRef.current) {
        const scNav = document.querySelector('#sc-search-area-wrapper');
        const offsetdifferences = elm?.scrollTop - scNav?.offsetTop;

        if (offsetdifferences < 0) {
          searchRef.current.style.display = 'none';
        } else {
          searchRef.current.style.display = 'block';
        }
      }
    };

    handleElementScroll();

    elm?.addEventListener('scroll', () => {
      handleElementScroll();
    });

    return () => {
      elm && elm.removeEventListener('scroll', handleElementScroll);
    };
  }, []);

  return (
    <Fragment>
      <div className="topbar-wrapper">
        <div
          className={`dashboard-topnav ${
            username_route ? 'username-route-topnav' : ''
          }`}
        >
          <div className="left-section">
            <aside className="business-brand-aside">
              <Link
                href={{
                  pathname: '/',
                  query: {
                    ...(lat &&
                      lng &&
                      address && {
                        lat,
                        lng,
                        address,
                      }),
                  },
                }}
              >
                <a className="business-brand">
                  <div className="logoImg">
                    <AppImage
                      asset={{
                        url: '/images/logo/sasa-logo.png',
                        description: 'Sasa logo',
                      }}
                      alt="Sasa logo"
                      src="'/images/logo/sasa-logo.png'"
                    />
                  </div>
                </a>
              </Link>
            </aside>
          </div>

          {TopBarLinks && (
            <TopBarLinks isBusinessOwner={isBusinessOwner} page={page} />
          )}

          {Search && (
            <div ref={searchRef} className="!flex justify-center w-full">
              <Search />
            </div>
          )}

          <div className="right-section">
            {isBusinessOwner && liveStatus === 'inactive' && (
              <div className="live">
                <button
                  className={cn('custom-button', {
                    outlined: page === 'profile',
                    default: page === 'profile',
                  })}
                  onClick={() => setOpenLiveDialog(true)}
                >
                  <span>GO LIVE</span>
                </button>
              </div>
            )}

            <div>
              <Link href="/contact-us">
                <a className="flex items-center space-x-2 text-sm font-semibold visited:hover:text-primary text-black visited:text-black">
                  <span className="material-icons">&#xe0b0;</span>
                  <div>Contact Us</div>
                </a>
              </Link>
            </div>

            {sourcePackage === 'marketplace' && (
              <a
                href={process.env.NEXT_PUBLIC_MERCHANT_WEB_URL}
                className="text-white visited:text-white"
                target="_blank"
                rel="noreferrer"
              >
                <button className="custom-button lc">List an Event</button>
              </a>
            )}

            {isLoading || !isMounted ? (
              <Fragment>
                <div className="settings-icon"></div>

                <div className="profile-section">
                  <div className="user-avatar">
                    <div className="topbar-avatar">
                      <Skeleton variant="circular" width="100%" height="100%" />
                    </div>
                  </div>
                  <div className="profile-details">
                    <span className="role">
                      <Skeleton width="4em" />
                    </span>
                    <span className="name">
                      <Skeleton width="7em" />
                    </span>
                  </div>
                  <div className="chevron-down">
                    <span className="material-icons">&#xe5cf;</span>
                  </div>
                </div>
              </Fragment>
            ) : isLoggedIn ? (
              <Fragment>
                {((isBusinessOwner && username_route) ||
                  (sourcePackage === 'marketplace' && userId)) && (
                  <div
                    onClick={e => {
                      const anchor = e.currentTarget;
                      setOpenNotifications(open => ({
                        open: !open.open,
                        anchor,
                      }));
                    }}
                  >
                    <Badge
                      badgeContent={appNotifications?.unread_count || 0}
                      color="primary"
                    >
                      <span
                        className={cn('material-icons', 'notification-icon', {
                          'open-notifications': openNotifications.open,
                        })}
                      >
                        &#xe7f4;
                      </span>
                    </Badge>
                  </div>
                )}

                {sourcePackage === 'merchant' &&
                  currentMerchant &&
                  currentMerchant?.merchant.user_id === userId &&
                  !username_route && (
                    <button
                      className="custom-button outlined default lc"
                      onClick={() =>
                        router.push(
                          `/business/${currentMerchant.merchant.business_username}`
                        )
                      }
                    >
                      {currentMerchant.merchant.business_username} dashboard
                    </button>
                  )}

                {business_username &&
                  username_route &&
                  sourcePackage === 'merchant' && (
                    <div
                      className="settings-icon"
                      onClick={() =>
                        router.push(
                          `/business/${business_username}/settings/sessions`
                        )
                      }
                    >
                      <span
                        className={cn('material-icons', {
                          active: page === 'settings' || page === 'profile',
                        })}
                      >
                        &#xe8b8;
                      </span>
                    </div>
                  )}

                <div
                  className="profile-section"
                  onClick={e => {
                    const anchor = e.currentTarget;
                    setOpenProfile(open => ({
                      open: !open.open,
                      anchor,
                    }));
                  }}
                >
                  <div className="user-avatar">
                    {sourcePackage === 'merchant' &&
                    username_route &&
                    isBusinessOwner ? (
                      <div className="topbar-avatar">
                        <AppAvatar
                          src={
                            profilePhoto?.thumbnail_url ||
                            (!business_name && profile_photo)
                          }
                          alt={
                            business_username || `${first_name} ${last_name}`
                          }
                          priority
                        />
                      </div>
                    ) : (
                      <div className="topbar-avatar">
                        <AppAvatar
                          src={profile_photo}
                          alt={`${first_name} ${last_name}`}
                          priority
                        />
                      </div>
                    )}
                  </div>
                  {!Search && (
                    <>
                      <div className="profile-details">
                        <span className="role">
                          {isBusinessOwner && username_route ? 'Owner' : ''}
                        </span>
                        <span className="name">
                          {sourcePackage === 'merchant' &&
                          username_route &&
                          isBusinessOwner
                            ? business_name || `${first_name} ${last_name}`
                            : `${first_name} ${last_name}`}
                        </span>
                      </div>
                      <div className="chevron-down">
                        <span className="material-icons">&#xe5cf;</span>
                      </div>
                    </>
                  )}
                </div>
              </Fragment>
            ) : (
              <AuthenticationLinks />
            )}
          </div>
        </div>
      </div>

      {/* Mobile top nav */}
      <MobileNav
        page={page}
        setOpenReportDialog={setOpenReportDialog}
        profile_completeness_value={profile_completeness_value}
      />

      {openNotifications.open && (
        <NotificationsPopper
          anchor={openNotifications.anchor}
          setOpenNotifications={setOpenNotifications}
        />
      )}

      {openProfile.open && (
        <ProfilePopper
          anchor={openProfile.anchor}
          setOpenReportDialog={setOpenReportDialog}
          setOpenProfile={setOpenProfile}
          setOpenThemePopper={setOpenThemePopper}
        />
      )}

      {openThemePopper.open && (
        <ThemePopper
          setOpenThemePopper={setOpenThemePopper}
          anchor={openThemePopper.anchor}
        />
      )}

      {openLiveDialog && sourcePackage === 'merchant' && (
        <GoLiveDialog
          setOpenLiveDialog={setOpenLiveDialog}
          profile_completeness_value={profile_completeness_value}
        />
      )}

      {sourcePackage === 'marketplace' && openReportDialog && (
        <ReportBusiness
          setOpenReportDialog={setOpenReportDialog}
          setSnackInfo={setSnackInfo}
        />
      )}

      <SnackInfo
        snackInfo={snackInfo}
        handleClose={() =>
          setSnackInfo(snackInfo => ({ ...snackInfo, open: false }))
        }
      />
    </Fragment>
  );
};

export default TopBar;
